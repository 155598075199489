import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
    providedIn: 'root'
})
export class LicenceManagementService {

    isLicenseFromPayment = false;
    baseURL = environment.licencemanagementURL;
    retailURL = environment.retailURL
    vehicleTracking = environment.vehicleTracking;
    masterIpURL = environment.baseURLMaster;
    worflowIPURL = environment.workflowconfig;
    licManageIPURL = environment.licencemanagementIPURL;
    paymentDomain = environment.paymentDomain;
    payuRequest = environment.payuRequest;
    payuStatusCheck = environment.payuStatusCheck;
    feesMasterUrl = environment.domainUrl;
    baseUrlUser = environment.baseURLUser;
    userManagementURL = environment.baseURLUser;
    // paymentListUrl = environment.paymentListUrl;
    payementBaseurl = environment.payandreconBaseUrl;
    helpdeskURL = environment.helpdeskURL;
    licenseManagement = environment.licenseManagement;
    licneseBaseUrl = environment.licneseBaseUrl;
    usermangmentBaseUrl = environment.userManagerUrl

    constructor(
        private http: HttpClient
    ) { }

    getApplicationNo(licCat, licSubCat = null): any {
        const url = this.baseURL + apiUrls.getApplicationNo + '?licenseCategory=' + licCat + '&licenseSubCategory=' + licSubCat;
        return this.http.post(url, {}).toPromise();
    }

    findFinancialYear(): any {
        const url = this.baseURL + apiUrls.findFinancialYear;
        return this.http.get(url);
    }

    findLicenseDetailsByEntityByUserId(licenseCategory): any {
        const params = encodeURIComponent(licenseCategory);
        const url = this.licManageIPURL + apiUrls.findLicenseDetails + '/' + params;
        return this.http.get(url);
    }

    findApprovedEntityByUserId(): any {
        const url = this.baseURL + apiUrls.findactiveentity;
        return this.http.get(url);
    }

    findLicenseFee(moduleName, licCat, licSubCat, feeType): any {
        const url = this.masterIpURL + apiUrls.findLicenseFee + '?moduleName=' + moduleName + '&licenseCategory=' + licCat + '&licenseSubCategory=' + licSubCat + '&feeType=' + feeType;
        return this.http.get(url);
    }

    findLicCheckList(moduleName, licCat, licSubCat, processType?): any {
        let url = '';

        if (processType) {
            url = this.masterIpURL + apiUrls.findLicCheckList + '?moduleName=' + moduleName + '&licenseCategory=' + licCat + '&licenseSubCategory=' + licSubCat + '&processType=' + processType;
        } else {
            url = this.masterIpURL + apiUrls.findLicCheckList + '?moduleName=' + moduleName + '&licenseCategory=' + licCat + '&licenseSubCategory=' + licSubCat;
        }

        return this.http.get(url);
    }

    findRetailShopByPanCard(panNumber, shopId): any {
        const url = this.masterIpURL + apiUrls.findRetailShopByPanCard + '?panCard=' + panNumber + '&shopId=' + shopId;
        return this.http.get(url);
    }

    findCheckList(applicationNumber, level): any {
        const url = this.baseURL + apiUrls.findCheckList + '?applicationNumber=' + applicationNumber +
            '&level=' + level;
        return this.http.get(url);
    }

    findAllocatedCenters(): any {
        const url = this.baseURL + apiUrls.findAllocatedCenters;
        return this.http.get(url);
    }

    findCheckListById(applicationNumber, id) {
        const url = this.baseURL + apiUrls.findCheckListById + '?applicationNumber=' + applicationNumber + '&id=' + id;
        return this.http.get(url);
    }
    createLicense(payload) {
        const url = this.baseURL + apiUrls.createLicense;
        return this.http.post(url, payload).toPromise();
    }
    saveUpdateUser(payload) {
        const url = this.baseUrlUser + apiUrls.saveUpdateUser;
        return this.http.post(url, payload).toPromise();
    }
    updateEdistrictService(payload) {
        const url = this.baseURL + apiUrls.updateEdistrictService;
        return this.http.post(url, payload).toPromise();
    }

    initiateLicenseAction(payload) {
        const url = this.baseURL + apiUrls.initiateLicenseAction;
        return this.http.post(url, payload).toPromise();
    }

    findLicenseActionApplication(applicationNumber) {
        const url = this.baseURL + apiUrls.findLicenseActionApplication + '?applicationLicenseNumber=' + applicationNumber;
        return this.http.get(url).toPromise();
    }


    deleteDraftLicenseActionApplication(statusCode, applicationNumber) {
        const url = this.baseURL + apiUrls.deleteDraftLicenseActionApplication + '?statusCode=' + statusCode + '&applicationNumber=' + applicationNumber;
        return this.http.get(url);
    }

    isTransferSurrenderAllowed(licenseCategoryCode, licenseSubCategoryCode) {
        const url = this.userManagementURL + apiUrls.isTransferSurrenderAllowed + '?licenseCategoryCode=' + licenseCategoryCode + '&licenseSubCategoryCode=' + licenseSubCategoryCode;
        return this.http.get(url);
    }

    isLicenseActionInitiated(applicationLicenseNumber) {
        const url = this.baseURL + apiUrls.isLicenseActionInitiated + '?applicationLicenseNumber=' + applicationLicenseNumber;
        return this.http.get(url);
    }

    findWorkFlowNameCode(licenseCategory, licenseSubCategory) {
        const url = this.baseUrlUser + apiUrls.findWorkFlowNameCode + '?licenseCategoryCode=' + licenseCategory + "&licenseSubCategoryCode=" + licenseSubCategory;
        return this.http.get(url, {}).toPromise();
    }

    updateHBRCategory(payload) {
        const url = this.baseURL + apiUrls.updateHBRCategory;
        return this.http.post(url, payload).toPromise();
    }

    generateHash(payload) {
        const url = this.paymentDomain + this.payuRequest;
        return this.http.post(url, payload);
    }

    paymentStatusCheck(payload) {
        const url = this.paymentDomain + this.payuStatusCheck;
        return this.http.post(url, payload);
    }

    checkRajkoshPaymentStatus(transactionNumber) {
        const url = this.baseURL + apiUrls.checkRajkoshPaymentStatus + '?transactionNumber=' + transactionNumber;
        return this.http.post(url, {});
    }

    directrajkoshcall(payload) {
        const url = this.paymentDomain + apiUrls.directrajkoshcall;
        return this.http.post(url, payload);
    }

    isGroupCodeExist(groupCode) {
        const url = this.masterIpURL + apiUrls.isGroupCodeExist + '?groupCode=' + groupCode;
        return this.http.get(url).toPromise();
    }

    saveUpdateGroupMaster(payload) {
        const url = this.masterIpURL + apiUrls.saveUpdateGroupMaster;
        return this.http.post(url, payload).toPromise();
    }

    findGroupMasterList(payload): Observable<any> {
        const url = this.baseURL + apiUrls.findGroupMasterList;
        return this.http.post(url, payload);
    }

    isRetailShopExist(retailShopId) {
        const url = this.masterIpURL + apiUrls.isRetailShopExist + '?retailShopId=' + retailShopId;
        return this.http.get(url).toPromise();
    }

    saveUpdateRetailShops(payload) {
        const url = this.masterIpURL + apiUrls.saveUpdateRetailShops;
        return this.http.post(url, payload);
    }
    // https://testapigateway.upexciseonline.co/master/v1.0.0/retail/group/generateRetailShopSequenceNumber?licenseType=fsdf
    generateRetailShopSequenceNumber(num) {
        const url = this.masterIpURL + apiUrls.generateRetailShopSequenceNumber + "?licenseType=" + num;
        return this.http.get(url);
    }

    updateRetailShops(payload) {
        const url = this.masterIpURL + apiUrls.updateRetailShops;
        return this.http.post(url, payload);
    }
    findRetailShopByLicenseDistrict(payload) {
        const url = this.masterIpURL + apiUrls.findRetailShopByLicenseDistrict;
        return this.http.post(url, payload);
    }

    saveELottery(payload) {
        const url = this.baseURL + apiUrls.saveELottery;
        return this.http.post(url, payload);
    }

    findELotteryByUser(payload) {
        const url = this.baseURL + apiUrls.findELotteryByUser;
        return this.http.post(url, payload);
    }

    findELotteryById(shopId): Observable<any> {
        const url = this.baseURL + apiUrls.findELotteryById + '?id=' + shopId;
        return this.http.get(url);
    }

    findRetailShopDetailsByUserId(): Observable<any> {
        const url = this.baseURL + apiUrls.findRetailShopDetailsByUserId;
        return this.http.get(url);
    }

    downloadAffidavits(category, subcategory): any {
        const url = this.baseURL + apiUrls.downloadAffidavits + '/category/' + category + '/subCategory/' + subcategory;
        return this.http.get(url, { responseType: 'blob' });
    }

    downloadCharacterCertificate(category, subcategory): any {
        const url = this.baseURL + apiUrls.downloadCharacterCertificate + '/category/' + category + '/subCategory/' + subcategory + '/type/CC';
        return this.http.get(url, { responseType: 'blob' });
    }

    downloadSalesmanFile(category, subcategory): any {
        const url = this.baseURL + apiUrls.downloadCharacterCertificate + '/category/' + category + '/subCategory/' + subcategory + '/type/SF';
        return this.http.get(url, { responseType: 'blob' });
    }

    downloadDeclaration(category, subcategory): any {
        const url = this.baseURL + apiUrls.downloadDeclaration + '/category/' + category + '/subCategory/' + subcategory;
        return this.http.get(url, { responseType: 'blob' });
    }

    findRetailShopList(payload): Observable<any> {
        const url = this.masterIpURL + apiUrls.findRetailShopList;
        return this.http.post(url, payload);
    }

    findByRetailShopId(retailShopId): Observable<any> {
        const url = this.masterIpURL + apiUrls.findByRetailShopId + '?retailShopId=' + retailShopId;
        return this.http.get(url);
    }

    findRetailShopMgqMgrByShopId(shopId): Observable<any> {
        const url = this.masterIpURL + apiUrls.findRetailShopMgqMgrByShopId + '?shopId=' + shopId;
        return this.http.get(url);
    }


    checkMigratedLicenseFee(applicationNumber,feeType): Observable<any> {
        const url = this.baseURL + apiUrls.checkMigratedLicenseFee + '?applicationNumber=' + applicationNumber + '&feeType=' + feeType;
        return this.http.get(url);
    }

    getPaidPaymentDetails(applicationNumber): Observable<any> {
        const url = this.baseURL + apiUrls.getPaidPaymentDetails + '?applicationNumber=' + applicationNumber;
        return this.http.get(url);
    }

    getPaymentDetailsForBeerPermit(applicationNumber): Observable<any> {
        const url = this.baseURL + 'licenseExtension/getPaymentDetailsForBeerPermit' + '?applicationNumber=' + applicationNumber;
        return this.http.get(url);
    }

    getActivateDeActiveComments(applicationNumber): Observable<any> {
        const url = this.baseURL + apiUrls.getActivateDeActiveComments + '?licenseNumber=' + applicationNumber;
        return this.http.get(url);
    }

    saveUpdateSiteDetails(payload) {
        const url = this.baseURL + apiUrls.saveUpdateSiteDetails;
        return this.http.post(url, payload).toPromise();
    }

    saveUpdateOfficerUploadDocuments(payload) {
        const url = this.baseURL + apiUrls.saveUpdateOfficerUploadDocuments;
        return this.http.post(url, payload).toPromise();
    }

    saveSecurityAmount(payload) {
        const url = this.baseURL + apiUrls.saveSecurityAmount;
        return this.http.post(url, payload).toPromise();
    }

    createSugarmillLicense(payload) {
        const url = this.baseURL + apiUrls.createSugarmillLicense;
        return this.http.post(url, payload).toPromise();
    }

    createBondWareHouseLicense(payload) {
        const url = this.baseURL + apiUrls.bondWareHouseLicense;
        return this.http.post(url, payload).toPromise();
    }

    createRetailLicense(payload) {
        const url = this.baseURL + apiUrls.retailLicense;
        return this.http.post(url, payload).toPromise();
    }

    createDistilleryLicense(payload) {
        const url = this.baseURL + apiUrls.distilleryLicense;
        return this.http.post(url, payload).toPromise();
    }

    createHBRLicense(payload) {
        const url = this.baseURL + apiUrls.hbrLicense;
        return this.http.post(url, payload).toPromise();
    }

    searchLicenseApplications(payload): Observable<any> {
        const url = this.baseURL + apiUrls.searchLicenseApps;
        return this.http.post(url, payload);
    }

    searchLicenseActiveDeactive(payload): Observable<any> {
        const url = this.baseURL + apiUrls.searchLicenseActiveDeactive;
        return this.http.post(url, payload);
    }

    activateDeActiveLicense(payload): Observable<any> {
        const url = this.baseURL + apiUrls.activateDeActiveLicense;
        return this.http.post(url, payload);
    }

    searchLicenseApplicationCounts(payload): Observable<any> {
        const url = this.baseURL + apiUrls.searchLicenseApplicationCounts;
        return this.http.post(url, payload);
    }

    showdashboardforofficerscard(): Observable<any> {
        const url = this.usermangmentBaseUrl + 'designation/isDashBoardAccess';
        return this.http.get(url); 
    }

    findFilterStatus(): Observable<any> {
        const url = this.licneseBaseUrl + apiUrls.findFilterStatus;
        return this.http.get(url);
    }

    registerVendor(payload) {
        const url = this.vehicleTracking + apiUrls.registerVendor;
        return this.http.post(url, payload).toPromise();
    }

    getVendorId(): any {
        const url = this.vehicleTracking + apiUrls.generateVendorId;
        return this.http.get(url).toPromise();
    }

    findAllVendors(payload): Observable<any> {
        const url = this.vehicleTracking + apiUrls.findAllVendors;
        return this.http.post(url, payload);
    }

    findVendorById(vendorId): Observable<any>  {
        const url = this.vehicleTracking + apiUrls.findVendorById + '?vendorId=' + vendorId;;
        return this.http.get(url);
    }

    deleteByVendorId(vendorId):  Observable<any>  {
        const url = this.vehicleTracking + apiUrls.deleteByVendorId + '?vendorId=' + vendorId;;
        return this.http.delete(url);
    }

    searchELottery(payload): Observable<any> {
        const url = this.masterIpURL + apiUrls.searchELottery;
        return this.http.post(url, payload);
    }

    findProfileDepartmentDetails(): any {
        const url = this.baseURL + apiUrls.findProfileDepartmentDetails;
        return this.http.get(url);
    }

    assetsDetails(): any {
        const url = this.baseURL + apiUrls.assetsDetails;
        return this.http.get(url);
    }

    productDepartmentDetails(payload): Observable<any> {
        const url = this.payementBaseurl + apiUrls.productDetails;
        return this.http.post(url, payload);
    }

    transactionDetails(userId): Observable<any> {
        const url = this.payementBaseurl + apiUrls.transactionDetails + '?userId=' + userId;
        return this.http.get(url);
    }

    getlicensetype(payload) {
        const url = this.retailURL + 'userallocatedunit/getapplicationnumbers';
        return this.http.post(url, payload);
      }

    getTransactionDetails(payload): Observable<any> {
        const url = this.payementBaseurl + 'payandrecon/1.0/paymentoverview/search';
        return this.http.post(url, payload)
    }
    findApprovedRetailShopIdsByUser(): Observable<any> {
        const url = this.baseURL + apiUrls.findApprovedRetailShopIdsByUser;
        return this.http.get(url);
    }

    searchSuspendedApplications(payload): Observable<any> {
        const url = this.baseURL + apiUrls.searchSuspendedApplications;
        return this.http.post(url, payload);
    }

    searchLoginLicenseApplications(payload): Observable<any> {
        const url = this.baseUrlUser + apiUrls.searchLoginLicenseApps;
        return this.http.post(url, payload);
    }

    searchSoftwareAssetsDetails(payload): Observable<any> {
        const url = this.helpdeskURL + apiUrls.searchSoftwareAssetsDetails;
        return this.http.post(url, payload);
    }

    deleteDraftApplication(statusCode, appliNum): Observable<any> {
        const url = this.baseURL + apiUrls.deleteDraftApplication + '?statusCode=' + statusCode + '&applicationNumber=' + appliNum;
        return this.http.get(url);
    }

    checkRenewal(applicationNumber) {
        const url = this.baseURL + apiUrls.checkRenewal + '?applicationNumber=' + applicationNumber;
        return this.http.get(url)
    }

    isCustomerWholeSaleRetailLicenseExist(category): Observable<any> {
        const url = this.baseURL + apiUrls.isCustomerWholeSaleRetailLicenseExist + '?licenseCategory=' + category;
        return this.http.get(url);
    }

    findLicenseApplication(appliNum): Observable<any> {
        const url = this.baseURL + apiUrls.findLicenseApplication + '?applicationNumber=' + appliNum;
        return this.http.post(url, {});
    }

    findBreweryDistilleryVinteryApplications(appliNum): Observable<any> {
        const url = this.baseURL + apiUrls.findBreweryDistilleryVinteryApplications + '?applicationNumber=' + appliNum;
        return this.http.post(url, {});
    }

    
    isNodalOfficerSelected(appliNum): Observable<any> {
        const url = this.baseURL + apiUrls.isNodalOfficerSelected + '?parentUnitLicenseNumber=' + appliNum;
        return this.http.get(url);
    }
    
    isUserSameCustomBondBIO(licNum, appliNum): Observable<any> {
        const url = this.baseURL + apiUrls.isUserSameCustomBondBIO + '?customBondLicenseNumber=' + licNum + '&applicationNumber=' + appliNum;
        return this.http.get(url);
    }

    findLicenseConfiguration(): Promise<any> {
        const url = this.baseURL + apiUrls.findLicenseConfiguration;
        return this.http.get(url).toPromise();
    }

    findParentLicenseApplication(licenseCategory, licenseSubCategory) {
        const url = this.baseURL + apiUrls.findParentLicenseApplication + '?licenseCategoryCode=' + licenseCategory + "&licenseSubCategoryCode=" + licenseSubCategory;
        return this.http.get(url, {})
    }

    findLicenseByCategoryAndSubCategory(licenseCategory, licenseSubCategory) {
        const url = this.baseURL + apiUrls.findLicenseByCategoryAndSubCategory + '?licenseCategoryCode=' + licenseCategory + "&licenseSubCategoryCode=" + licenseSubCategory;
        return this.http.get(url, {})
    }

    findParentLicenseApplicationId(licenseCategory, licenseSubCategory, processType?) {
        let url = '';

        if (processType) {
            url = this.baseURL + apiUrls.findParentLicenseApplication + '?licenseCategoryCode=' + licenseCategory + '&licenseSubCategoryCode=' + licenseSubCategory + '&processType=' + processType;
        } else {
            url = this.baseURL + apiUrls.findParentLicenseApplication + '?licenseCategoryCode=' + licenseCategory + "&licenseSubCategoryCode=" + licenseSubCategory;
        }

        return this.http.get(url, {}).toPromise();
    }

    findApprovedDS1Licenses(): Observable<any> {
        const url = this.baseURL + apiUrls.findApprovedDS1Licenses;
        return this.http.get(url, {});
    }

    downLoadApplicationData(appliNum, category, subCategory): Observable<any> {
        const url = this.baseURL + apiUrls.downLoadApplicationData + '?applicationNumber=' + appliNum + '&category=' + category + '&subCategory=' + subCategory;
        return this.http.get(url, { responseType: 'blob' });
    }

    downloadAttachedFiles(appliNum, category, subCategory): Observable<any> {
        const url = this.baseURL + apiUrls.downloadAttachedFiles + '?applicationNumber=' + appliNum + '&category=' + category + '&subCategory=' + subCategory;
        return this.http.get(url, { responseType: 'blob' });
    }

    downloadReceipt(appliNum, transNumber): Observable<any> {
        const url = this.baseURL + apiUrls.downloadReceipt + '?applicationNumber=' + appliNum + '&transactionNumber=' + transNumber;
        return this.http.get(url, { responseType: 'blob' });
    }

    downloadSecurityFeeReceipt(appliNum): Observable<any> {
        const url = this.baseURL + apiUrls.downloadSecurityFeeReceipt + '?applicationNumber=' + appliNum;
        return this.http.get(url, { responseType: 'blob' });
    }

    getLicenseDataForPDF(appliNum, category, subCategory): Observable<any> {
        const url = this.baseURL + apiUrls.getLicenseDataForPDF + '?applicationNumber=' + appliNum + '&category=' + category + '&subCategory=' + subCategory;
        return this.http.get(url, { responseType: 'blob' });
    }

    saveUpdatePaymentDetails(payload): Observable<any> {
        const url = this.baseURL + apiUrls.saveUpdatePaymentDetails;
        return this.http.post(url, payload);
    }

    saveUpdateCheckList(payload): Observable<any> {
        const url = this.baseURL + apiUrls.saveUpdateCheckList;
        return this.http.post(url, payload);
    }

    saveSiteVisitOfficer(payload): Observable<any> {
        const url = this.baseURL + apiUrls.saveSiteVisitOfficer;
        return this.http.post(url, payload);
    }

    findApplicationDistrict(appliNum): Observable<any> {
        const url = this.baseURL + apiUrls.findApplicationDistrict + '?applicationNumber=' + appliNum;
        return this.http.get(url);
    }

    findApplicationQuarter(appliNum): Observable<any> {
        const url = this.baseURL + apiUrls.findApplicationQuarter + '?applicationNumber=' + appliNum;
        return this.http.get(url);
    }

    findWorkFlowByModuleAndSubModule(moduleNameCode, subModuleCode, processType?): Observable<any> {
        let url = '';

        if (processType) {
            url = this.worflowIPURL + apiUrls.findWorkFlowByModuleAndSubModule + '?moduleNameCode=' + moduleNameCode + '&subModuleCode=' + subModuleCode + '&processType=' + processType;
        } else {
            url = this.worflowIPURL + apiUrls.findWorkFlowByModuleAndSubModule + '?moduleNameCode=' + moduleNameCode + '&subModuleCode=' + subModuleCode;
        }

        return this.http.get(url);
    }

    findPaymentDetails(appliNum, feeType): Observable<any> {
        const url = this.baseURL + apiUrls.findPaymentDetails + '?applicationNumber=' + appliNum + '&feeType=' + feeType;
        return this.http.get(url);
    }

    findWorkFlowDetailsByLoginUserDesignation(moduleNameCode, subModuleCode, processType?): Observable<any> {
        let url = '';

        if (processType) {
            url = this.worflowIPURL + apiUrls.findWorkFlowDetailsByLoginUserDesignation + '?moduleNameCode=' + moduleNameCode + '&subModuleCode=' + subModuleCode + '&processType=' + processType;
        } else {
            url = this.worflowIPURL + apiUrls.findWorkFlowDetailsByLoginUserDesignation + '?moduleNameCode=' + moduleNameCode + '&subModuleCode=' + subModuleCode;
        }
        return this.http.get(url);
    }

    findWorkFlowSiteDetailsByLoginUserDesignation(moduleNameCode, subModuleCode, processType?): Observable<any> {
        let url = '';

        if (processType) {
            url = this.worflowIPURL + apiUrls.findWorkFlowSiteDetailsByLoginUserDesignation + '?moduleNameCode=' + moduleNameCode + '&subModuleCode=' + subModuleCode + '&processType=' + processType;
        } else {
            url = this.worflowIPURL + apiUrls.findWorkFlowSiteDetailsByLoginUserDesignation + '?moduleNameCode=' + moduleNameCode + '&subModuleCode=' + subModuleCode;
        }
        return this.http.get(url);
    }

    getAllAllocatedEntity(licenseCategory, districtId): Observable<any> {
        const url = this.baseURL + apiUrls.findLicenseDetailsByLicenseCategory + '?licenseCategory=' + licenseCategory + '&locationCode=' + districtId;
        return this.http.get(url);
    }

    initiateApplicationWorkFlow(payload): Observable<any> {
        const url = this.baseURL + apiUrls.initiateApplicationWorkFlow;
        return this.http.post(url, payload);
    }

    processSiteVisitWorkflow(payload): Observable<any> {
        const url = this.baseURL + apiUrls.processSiteVisitWorkflow;
        return this.http.post(url, payload);
    }

    isCustomerBWFLParentExist(): Observable<any> {
        const url = this.baseURL + apiUrls.isCustomerBWFLParentExist;
        return this.http.get(url);
    }

    searchAuthorizePerson(payload): Observable<any> {
        const url = this.baseURL + apiUrls.searchAuthorizePerson;
        return this.http.post(url, payload);
    }

    saveUpdateBWFLAuthorizePerson(payload) {
        const url = this.baseURL + apiUrls.saveUpdateBWFLAuthorizePerson;
        return this.http.post(url, payload).toPromise();
    }

    findAuthorizedPersonDetails(id): Observable<any> {
        const url = this.baseURL + apiUrls.findAuthorizedPersonDetails + '?id=' + id;
        return this.http.get(url);
    }

    isCustomerBondLicenseEligible(): Observable<any> {
        const url = this.baseURL + apiUrls.isCustomerBondLicenseEligible;
        return this.http.get(url);
    }

    findMolassessYear(): any {
        const url = this.baseURL + apiUrls.getMolassessYear;
        return this.http.get(url);
    }

    findCustomerHBRLicenseData(): any {
        const url = environment.licneseBaseUrl + apiUrls.findCustomerHBRLicenseUrl;
        return this.http.get(url);
    }

    findParentLicenseByCategoryAndSubCategory(licenseCategoryCode, licenseSubCategoryCode): any {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("licenseCategoryCode", licenseCategoryCode);
        queryParams = queryParams.append("licenseSubCategoryCode", licenseSubCategoryCode);
        const url = environment.licneseBaseUrl + apiUrls.findParentLicenseByCategoryAndSubCategory;
        return this.http.get(url, { params: queryParams });
    }

    findParentLicenseData(licenseCategoryCode, licenseSubCategoryCode, processType?): any {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("licenseCategoryCode", licenseCategoryCode);
        queryParams = queryParams.append("licenseSubCategoryCode", licenseSubCategoryCode);

        if (processType) {
            queryParams = queryParams.append("processType", processType);
        }
        const url = environment.licneseBaseUrl + apiUrls.findParentLicenseByCategoryAndSubCategory;
        return this.http.get(url, { params: queryParams }).toPromise();
    }


    findParentLicense(): any {
        const url = environment.licneseBaseUrl + apiUrls.findParentLicenses;
        return this.http.get(url);
    }

    findBreweryLicenseForB21Data(data): any {
        const url = environment.licneseBaseUrl + apiUrls.findBreweryLicenseForB21Url + '?licenseCategoryCode=' + data;
        return this.http.get(url);
    }

    getSugerMillJson(formType) {
        if (formType == "OUTSIDE_UP") {
            return this.http.get('assets/json/sugarmill_outside.json')
        } else if (formType == "INDUSTRY_INSIDE_UP") {
            return this.http.get('assets/json/sugarmill_industries_inside_up.json')
        } else if (formType == "INDUSTRY_OUTSIDE_UP") {
            return this.http.get('assets/json/sugarmill_industries_outside_up.json')
        } else if (formType == "MOLASESS_TRADER") {
            return this.http.get('assets/json/sugarmill_trade_export.json')
        } else {
            return this.http.get('assets/json/sugarmill_within.json')
        }
    }

    getSugerMillViewJson(formType) {
        if (formType == "OUTSIDE_UP") {
            return this.http.get('assets/json/sugarmill_outside_view.json')
        } else if (formType == "INDUSTRY_INSIDE_UP") {
            return this.http.get('assets/json/sugarmill_industries_inside_up_view.json')
        } else if (formType == "INDUSTRY_OUTSIDE_UP") {
            return this.http.get('assets/json/sugarmill_industries_outside_up_view.json')
        } else if (formType == "MOLASESS_TRADER") {
            return this.http.get('assets/json/sugarmill_trade_export_view.json')
        } else {
            return this.http.get('assets/json/sugarmill_within_view.json')
        }
    }

    isWareHouseApprovedAgainstParent(applicationNumber, entityCode) {
        const url = this.baseURL + apiUrls.isWareHouseApprovedAgainstParent + '?applicationNumber=' + applicationNumber + '&entityCode=' + entityCode;
        return this.http.get(url);
    }

    parentUnitAuthorizedListByEmail(payload): Observable<any> {
        const url = this.baseURL + apiUrls.parentUnitAuthorizedListByEmail;
        return this.http.post(url, payload);
    }

    findBankDetailByIFSC(ifscCode): any {
        const url = this.masterIpURL + apiUrls.findBankDetailByIFSC + ifscCode;
        return this.http.get(url);
    }

    generateShopId(): any {
        const url = this.baseURL + apiUrls.generateShopId;
        return this.http.get(url);
    }

    getGroupData(groupCode) {
        const url = this.masterIpURL + apiUrls.findByGroupCode + '?groupCode=' + groupCode;
        return this.http.get(url).toPromise();
    }

    getImportUnitDetails(moduleName): any {
        const url = this.baseURL + apiUrls.importUnitLicDet + '?licenseCategoryCode=' + moduleName
        return this.http.get(url).toPromise();
    }

    getCustomBondDetails(moduleName): any {
        const url = this.baseURL + apiUrls.customBondUnitDet + '?licenseCategoryCode=' + moduleName
        return this.http.get(url).toPromise();
    }

    findLicenseByApplication(appliNum): any {
        const url = this.baseURL + apiUrls.findLicenseApplication + '?applicationNumber=' + appliNum;
        return this.http.post(url, {}).toPromise();
    }

    getCustomBondUnitDetails(): any {
        const url = this.baseURL + apiUrls.customBondUnitDet
        return this.http.get(url).toPromise();
    }

    getCustomBondAddress(code): any {
        const url = this.baseURL + apiUrls.customBondAddr + code
        return this.http.get(url).toPromise();
    }

    getDeboundUnitDetails(): any {
        const url = this.baseURL + apiUrls.debondUnitDet
        return this.http.get(url).toPromise();
    }

    getDebondAddress(code): any {
        const url = this.baseURL + apiUrls.debondAddr + code
        return this.http.get(url).toPromise();
    }

    saveUpdateCustomBond(payload) {
        const url = this.baseURL + apiUrls.saveUpdateCustomBond;
        return this.http.post(url, payload).toPromise();
    }

    saveUpdateDeBoundUnit(payload) {
        const url = this.baseURL + apiUrls.saveUpdateDeBoundUnit;
        return this.http.post(url, payload).toPromise();
    }

    getMicroBreweryJson(formType) {
        if (formType == "MB2") {
            return this.http.get('assets/json/micro-brewery/micro-brewery-mb2.json')
        } else {
            return this.http.get('assets/json/micro-brewery/micro-brewery-mb6.json')
        }
    }

    getMicroBreweryViewJson(formType) {
        if (formType == "MB2") {
            return this.http.get('assets/json/micro-brewery/micro-brewery-mb2-view.json')
        } else {
            return this.http.get('assets/json/micro-brewery/micro-brewery-mb6-view.json')
        }
    }

    getDistrictWarehouseJson(formType) {
        if (formType == "FL1") {
            return this.http.get('assets/json/district-warehouse/district-warehouse-fl1.json')
        } else {
            return this.http.get('assets/json/district-warehouse/district-warehouse-fl1A.json')
        }
    }

    getDistrictWarehouseViewJson(formType) {
        if (formType == "FL1") {
            return this.http.get('assets/json/district-warehouse/district-warehouse-fl1-view.json')
        } else {
            return this.http.get('assets/json/district-warehouse/district-warehouse-fl1A-view.json')
        }
    }

    getCustomBondExist(code) {
        const url = this.baseURL + apiUrls.isCustomBondCodeExist + '?customBondCode=' + code
        return this.http.get(url).toPromise();
    }

    findApprovedCustomBond(){
        const url = this.baseURL + apiUrls.findApprovedCustomBond + '?categoryCode=CUSTOM_BOND'
        return this.http.get(url).toPromise();
    }

    getDebondExist(code) {
        const url = this.baseURL + apiUrls.isDeBondingCodeExist + '?debondCode=' + code
        return this.http.get(url).toPromise();
    }

    getBreweryJson(formType) {
        if (formType == "B21") {
            return this.http.get('assets/json/brewery/brewery-b21.json')
        } else {
            return this.http.get('assets/json/brewery/brewery-b19.json')
        }
    }

    getBreweryViewJson(formType) {
        if (formType == "B21") {
            return this.http.get('assets/json/brewery/brewery-b21-view.json')
        } else {
            return this.http.get('assets/json/brewery/brewery-b19-view.json')
        }
    }

    getVintineryJson(formType) {
        if (formType == "V2") {
            return this.http.get('assets/json/vintinery/vintinery-v2.json')
        } else {
            return this.http.get('assets/json/vintinery/vintinery-v1.json')
        }
    }

    getVintineryViewJson(formType) {
        if (formType == "V2") {
            return this.http.get('assets/json/vintinery/vintinery-v2-view.json')
        } else {
            return this.http.get('assets/json/vintinery/vintinery-v1-view.json')
        }
    }

    getBottlingJson(formType) {
        if (formType == "CLB1") {
            return this.http.get('assets/json/bottling/bottling_CLB1.json')
        } else if (formType == "CLB2") {
            return this.http.get('assets/json/bottling/bottling_CLB2.json')
        } else if (formType == "FL3A") {
            return this.http.get('assets/json/bottling/bottling_FL3A.json')
        } else {
            return this.http.get('assets/json/bottling/bottling_FL3.json')
        }
    }

    getBottlingViewJson(formType) {
        if (formType == "CLB1") {
            return this.http.get('assets/json/bottling/bottling_CLB1_view.json')
        } else if (formType == "CLB2") {
            return this.http.get('assets/json/bottling/bottling_CLB2_view.json')
        } else if (formType == "FL3A") {
            return this.http.get('assets/json/bottling/bottling_FL3A_view.json')
        } else {
            return this.http.get('assets/json/bottling/bottling_FL3_view.json')
        }
    }

    findWareHouseDetailsByLicenseNumber(licenseNumber) {
        const url = this.baseURL + apiUrls.findWareHouseDetailsByLicenseNumber + '?licenseNumber=' + licenseNumber;
        return this.http.get(url).toPromise();
    }

    findFinancialYearList(): Observable<any> {
        const url = this.baseURL + apiUrls.findFinancialYearList;
        return this.http.get(url);
    }

    findAllDarpanList(): Observable<any> {
        const url = this.baseURL + apiUrls.findAllDarpan;
        return this.http.get(url);
    }

    findDarpanDatabytheKey(serviceKey) {
        const url = this.baseURL + apiUrls.findLicenseDataPublishToNicServer + '?serviceKey=' + serviceKey;
        return this.http.post(url, {}).toPromise();
    }

    findDarpanDataPushHistory(payload){
        const url = this.baseURL + apiUrls.findDarpanDataPushHistory;
        return this.http.post(url, payload);
    }

    pushNICServerbytheKey(serviceKey) {
        const url = this.baseURL + apiUrls.findLicenseDataPublishToNicServer + '?serviceKey=' + serviceKey + '&publishToNic=true';
        return this.http.post(url, {}).toPromise();
    }

    findFeesMasterLicenseFee(payload): any {
        const url = this.feesMasterUrl + apiUrls.feesMaster;
        return this.http.post(url, { filters: payload });
    }

    findHbrFeesMasterProcessingFee(payload): any {
        const url = this.feesMasterUrl + apiUrls.hbrFeesMaster;
        return this.http.post(url, payload);
    }

    findHbrFeesMasterLicenseFee(payload): any {
        const url = this.feesMasterUrl + apiUrls.hbrLicenseFeesMaster;
        return this.http.post(url, { filters: payload });
    }

    findHBRChallenHeadG6(applicationNumber): any {
        const url = this.baseURL + 'licenseExtension/findHBRChallenHeadG6?applicationNumber='+applicationNumber;
        return this.http.get(url, {});
    }

    getHbrLicensetype(payload): any {
        const url = this.feesMasterUrl + apiUrls.getHbrLicensetype;
        return this.http.post(url, payload).toPromise();
    }
    getAllSubConfigByMainConfigId(id): Observable<any> {
        const url = this.feesMasterUrl + apiUrls.getAllSubConfigByMainConfigId + `${id}`;
        return this.http.get(url);
    }

    findCheckListByApplicationNumber(applicationNumber): Observable<any> {
        const url = this.baseURL + apiUrls.findCheckListByApplicationNumber + `?applicationNumber=${applicationNumber}`;
        return this.http.get(url);
    }

    findParentUnitLicenseExist(): any {
        const url = this.baseURL + apiUrls.findParentUnitLicenseExist;
        return this.http.get(url, {}).toPromise();
    }

    getUserDetailsByEmailPanNumber(emailId, panNumber): any {
        const url = this.userManagementURL + apiUrls.getUserDetailsByEmailPanNumber + `?emailId=${emailId}&panNumber=${panNumber}`;
        return this.http.get(url, {});
    }

    updateWorkFlowDetails(payload) {
        const url = this.licenseManagement + apiUrls.updateWorkFlowDetails;
        return this.http.post(url, payload);
    }

    getDependentDataList(liquor: any, packagev: any): Observable<any> {
        const url = this.masterIpURL + 'api/masterValues/getDependentDataList?liquorType=' + liquor + '&packagingType=' + packagev;
        return this.http.get(url);
    }

    findDashBoardNumbers() {
        const url = this.licneseBaseUrl + apiUrls.findDashBoardNumbers;
        return this.http.get(url);
    }

    findLicenseAppNumberByOtherAppNumber(otherAppNum: string) {
        const url = this.baseURL + apiUrls.findLicenseAppNumberByOtherAppNumber + `?otherSourceApplicationNumber=${otherAppNum}`;
        return this.http.get(url);
    }

    getNMRedirectUrlAndParameters(otherAppNum) {
        const url = this.baseURL + apiUrls.getNMRedirectUrlAndParameters + `?otherSourceApplicationNumber=${otherAppNum}`;
        return this.http.get(url);
    }

    redirectNMPage(otherAppNum) {
        const url = this.baseURL + `license/redirectNMPage?otherSourceApplicationNumber=${otherAppNum}`;
        return this.http.get(url);
    }

    redirectNMRedirectPage(sessionkey,bkToken,cToken) {
        const url = this.baseURL + `license/redirectNMPage?sessionkey=${sessionkey}&bkToken=${bkToken}&cToken=${cToken}`;
        return this.http.get(url);
    }

    redirectSIPRedirectPage(sessionkey) {
        const url = this.baseURL + `license/redirectISPPage?sessionkey=${sessionkey}`;
        return this.http.get(url);
    }

    licenseExtension() {
        const url = this.licneseBaseUrl + `licenseExtension/checkFL5BExists`;
        return this.http.get(url);
    }

    findBeerPermitRoomByLoginId(){
        const url = this.licneseBaseUrl + `licenseExtension/findBeerPermitRoomByLoginId`;
        return this.http.get(url);
    }

    applicationConfiguration() {
        const url = this.masterIpURL + 'applicationConfiguration/findLicenseFee?moduleName=LICENSE_MANAGEMENT&licenseCategory=BEER_PERMIT_ROOM&licenseSubCategory=BEER_PERMIT_ROOM&feeType=LICENSE_FEE';
        return this.http.get(url);
    }

    createUpdateBeerPermitRoom(payload) {
        const url = this.licneseBaseUrl + 'licenseExtension/createUpdateBeerPermitRoom';
        return this.http.post(url, payload);
    }

    initiateBeerPermitWorkFlow(payload) {
        const url = this.licneseBaseUrl + 'licenseExtension/initiateBeerPermitWorkFlow';
        return this.http.post(url, payload);
    }

    searchBeerPermitRoom(payload){
        const url = this.licneseBaseUrl + `licenseExtension/searchBeerPermitRoom`;
        return this.http.post(url, payload);
    }

    saveUpdatePaymentDetailsBeerPermit(payload){
        const url = this.licneseBaseUrl + `licenseExtension/saveUpdatePaymentDetailsBeerPermit`;
        return this.http.post(url, payload);
    }

    downLoadBeerPermitApplicationData(num: any) {
        const url = this.licneseBaseUrl + `licenseExtension/downLoadBeerPermitApplicationData?applicationNumber=${num}`;
        return this.http.get(url, { responseType: 'blob' });
    }

    generateBeerPermitApplicationNumber(num: any) {
        const url = this.licneseBaseUrl + `licenseExtension/generateBeerPermitApplicationNumber?shopId=${num}`;
        return this.http.get(url);
    }

    searchBeerPermitRoomByApplicationNumber(num: any) {
        const url = this.licneseBaseUrl + `licenseExtension/searchBeerPermitRoomByApplicationNumber?applicationNumber=${num}`;
        return this.http.get(url);
    }

    deleteBeerPermitDraftApplication(statusCode, applicationNumber) {
        const url = this.licneseBaseUrl + 'licenseExtension/deleteBeerPermitDraftApplication' + '?statusCode=' + statusCode + '&applicationNumber=' + applicationNumber;
        return this.http.get(url);
    }

    downloadReceiptForBeerPermit(appliNum, transNumber): Observable<any> {
        const url = this.baseURL + 'licenseExtension/downloadReceiptForBeerPermit' + '?applicationNumber=' + appliNum + '&transactionNumber=' + transNumber;
        return this.http.get(url, { responseType: 'blob' });
    }

    updateApplicationStageStatus(payload){
        const url = this.baseURL + `license/updateApplicationStageStatus`;
        return this.http.post(url, payload);
    }

}
