export const LicenseConstants = {
    CUSTOMER: 'customer',
    OFFICER: 'officer',
    USER_TYPE:'USER_TYPE',
    LOCALITY_URBAN: 'URBAN',
    LOCALITY_RURAL: 'RURAL',
    FORM_INVALID: 'INVALID',
    MAX_FILE_SIZE: 2 * 1024 * 1024, // 2 MB
    MAX_FILE_SIZE_GAUGE_CHART: 6 * 1024 * 1024, // 6 MB
    MAX_FILE_SIZE_10MB: 10 * 1024 * 1024, // 2 MB
    PAYMENT_TIMER: 7, // minutes
    PAYMENT_STATUS_CHECK_SECONDS: 5, //seconds
    UP_STATE_CODE: '9',
    LICENSE_FILE_NAME: 'license.pdf',

    DATE_FORMAT: 'DD/MM/YYYY',
    PIPE_DATE_TIME_FORMAT: 'd/M/y H:mm:ss',
    DATE_FORMAT_SEARCH: 'DD/MM/YYYY',
    ACK_DATE_FORMAT: 'DD/MM/YYYY HH:mm',

    INDIVIDUAL_TYPE: 'ATMC001',

    PREVIOUSYEAR_ITR: 'PREVIOUSYEAR',
    CURRENTYEAR_ITR: 'CURRENTYEAR',
    BEFOREPREVIOUSYEAR_ITR: 'BEFOREPREVIOUSYEAR',
    CONST_PROCESSING_FEE: 'PROCESSING_FEE',
    CONST_RENEW_PROCESSING_FEE: 'RENEW_PROCESSGING_FEE',
    CONST_REGISTRATION_FEE: 'REGISTRATION_FEE',
    CONST_RENEW_REGISTRATION_FEE: 'RENEW_REGISTRATION_FEE',
    CONST_RENEW_PROCESSGING_FEE: 'RENEW_PROCESSGING_FEE',
    CONST_RENEWAL_FEE: 'RENEWAL_FEE',
    CONST_RENEW_LICENSE_FEE: 'RENEW_LICENSE_FEE',
    CONST_RENEW_SECURITY_AMOUNT: 'RENEW_SECURITY_AMOUNT',
    CONST_RENEW_PENALTY_FEE: 'PENALTY_FEE',

    CONST_LICENSE_FEE: 'LICENSE_FEE',
    CONST_SECURITY_AMOUNT: 'SECURITY_AMOUNT',
    CONST_RENEW: 'RENEW',
    CONST_RENEWAL: 'RENEWAL',
    CONST_NEW: 'NEW',
    CONST_PAYMENT_CHANNEL: 'PAYMENT_CHANNEL',

    STR_CUSTOMER: 'customer',
    STR_LEVEL_ONE: 'Level 1',
    STR_MODULE_NAME: 'licence-mangement',
    STR_LICENCE_FEE: 'License Fees',
    STR_PROCESSING_FEE: 'Processing Fees',
    STR_RENEWAL_FEE: 'Renewal Fees',
    STR_REGISTRATION_FEE: 'Registration Fees',
    STR_PENALTY_FEE: 'Fine',

    EVENTBAR_INDIVIDUAL_FEE : 'EVENTBAR_INDIVIDUAL_FEE',
    EVENTBAR_ORGANIZATION_FEE : 'EVENTBAR_ORGANIZATION_FEE',
    EVENTBAR_INDIVIDUAL : 'INDIVIDUAL',
    EVENTBAR_ORGANIZATION : 'ORGANIZATION',

    MSG_NO_REG: 'Applicant Registration is disabled now. Please connect with HelpDesk for further clarifications',
    MSG_NO_RESP: 'Response content not available',
    MSG_NO_PAYMENT_METHOD: 'Payment method not selected',
    MSG_NO_APPLICATION_NUMBER: 'Error while getting application number',
    MSG_NO_VENDOR_ID: 'Error while getting vendor id',
    MSG_ACT_INITIATED: 'Action Initiated',
    MSG_ERR_DIST_SITE_OFFICER: 'Error in getting site officer district',
    MSG_ERR_SITE_OFFICER_ROLES: 'Error in getting assign officier roles',
    MSG_ERR_SITE_OFFICER: 'Error in getting assign officier',
    MSG_ERR_FILE_DOWNLOAD: 'Error downloading the file',
    MSG_ERR_NO_FILE: 'File not available',
    MSG_ERR_NO_FILE_DET: 'File details not found',
    MSG_ERR_PENDING_LIC_FEE: 'Your application is waiting for license fee.Please pay the license fee from view page itself',
    MSG_ERR_PENDING_SECURITY_FEE: 'Your application is waiting for security fee.Please pay the security fee from view page itself',
    MSG_ERR_PENDING_PROCESSING_FEE: 'Your application is waiting for processing fee.Please pay the processing fee from view page itself',
    MSG_ERR_PENDING_RENEW_FEE: 'Your application is waiting for renewal fee.Please pay the renew fee from view page itself',
    MSG_ERR_APPLI_PROGRESS: 'Application under processing, so cannot edit the license',
    MSG_ERR_APPLI_APPR_REJECT: 'Approved/Rejected/InActive/Expired/Suspended application can not be edited, Please use view icon to view the data',
    MSG_ERR_APPR_LIC_DOWNLOAD: 'Only approved license can download',
    MSG_SUCC_SITE_OFFICER: 'Officier assigned successfully',
    MSG_SUCC_FILE_DOWNLOAD: 'File downloaded successfully',
    MSG_SUCC_LIC_DOWNLOAD: 'License downloaded successfully',
    MSG_ERR_FORM_INVALID: 'Please fill mandatory fields',
    MSG_ERR_WORKFLOF_NOT_CONFIG: 'Workflow not configured',
    MSG_ERR_PROCESS_FEE_NOT_ZERO: 'Processing fee should not be zero',
    MSG_ERR_LICENSE_FEE_NOT_ZERO: 'License fee should not be zero',
    MSG_ERR_SHOP_NOT_MAPPED: 'Shop detail not mapped',

    ROLE_INSPECTOR: 'ROLE_INSPECTOR',
    ROLE_STO: 'STO',
    ROLE_DEO: 'DEO_ROLE',

    STATUS_CANCELLATION: 'CANCELLATION',
    STATUS_SURRENDER: 'SURRENDER',
    STATUS_SUSPEND: 'SUSPEND',
    STATUS_TERMINATE: 'TERMINATE',
    STATUS_DRAFT: 'DRAFT',
    STATUS_DRAFT_DESC: 'Draft',
    STATUS_SAVE: 'SAVE',
    STATUS_SAVE_DESC: 'Saved',
    STATUS_SUBMITTED: 'SUBMITTED',
    STATUS_SUBMITTED_DESC: 'Submitted',
    STATUS_APPROVED: 'APPROVED',
    STATUS_REJECTED: 'REJECTED',
    STATUS_INACTIVE: 'INACTIVE',
    STATUS_EXPIRED: 'EXPIRED',
    STATUS_SENDBACK: 'SENDBACK',
    STATUS_IN_PROGRESS: 'IN_PROGRESS',
    STATUS_PENDING_LICENSE_FEE: 'PENDING_LICENSE_FEE',
    STATUS_PENDING_SECURITY_FEE: 'PENDING_SECURITY_FEE',
    STATUS_PENDING_PROCESSING_FEE: 'PENDING_PROCESSING_FEE',
    STATUS_PENDING_RENEWAL_FEE: 'PENDING_RENEWAL_FEE',
    STATUS_PENDING_PENALTY_FEE: 'PENDING_PENALTY_FEE',
    STATUS_PENDING_DSC: 'PENDING_DSC',
    STATUS_PENDING_SITE_INFO: 'PENDING_SITE_INFO',
    STATUS_PENDING_SITE_VISIT: 'PENDING_SITE_VISIT',
    STATUS_APPROVED_SITE_VISIT: 'APPROVED_SITE_VISIT',
    STATUS_PENDING_SITE_VISIT_APPROVAL: 'PENDING_SITE_VISIT_APPROVAL',
    STATUS_SENDBACK_SITE_APPROVAL_CLARIFICATION_INFO: 'SENDBACK_SITE_APPROVAL_CLARIFICATION_INFO',
    STATUS_REJECTED_SITE_INFO: 'REJECTED_SITE_INFO',
    STATUS_SENDBACK_SITE_INFO: 'SENDBACK_SITE_INFO',
    STATUS_REJECTED_SITE_VISIT: 'REJECTED_SITE_VISIT',
    STATUS_REJECTED_SITE_VISIT_INFO: 'REJECTED_SITE_INFO',
    STATUS_FORWARD_REJECTED_SITE_VISIT: 'FORWARD_REJECTED_SITE_VISIT',
    STATUS_SEND_BACK_REJECTED_SITE_VISIT: 'SEND_BACK_REJECTED_SITE_VISIT',
    STATUS_RECONSIDER_REJECTED_SITE_VISIT: 'RECONSIDER_REJECTED_SITE_VISIT',
    STATUS_PENDING_SITE_OFFICER_ASSIGNMENT: 'PENDING_SITE_OFFICER_ASSIGNMENT',
    STATUS_ASSIGMENT_FOR_SITE_VISIT_OFFICER: 'ASSIGMENT_FOR_SITE_VISIT_OFFICER',
    SENDBACK_SITE_APPROVAL_CLARIFICATION_INFO : "SENDBACK_SITE_APPROVAL_CLARIFICATION_INFO",
    SENDBACK_SITE_APPROVAL_CLARIFICATION_INFO_NEXT : "SENDBACK_SITE_APPROVAL_CLARIFICATION_INFO_NEXT",
    PENDING_SITE_VISIT_APPROVAL_NEXT : "PENDING_SITE_VISIT_APPROVAL_NEXT",
    STATUS_PENDING_SITE_VISIT_APPROVAL_ONE_RECORD: 'PENDING_SITE_VISIT_APPROVAL_ONE_RECORD',

    STATUS_PAYMENT_PENDING: 'pending',
    STATUS_PAYMENT_SUCCESS: 'success',
    STATUS_PAYMENT_FAILURE: 'failure',
    STATUS_PAYMENT_TIME_OUT: 'time-out',

    STATUS_SUCCESS: 'success',
    STATUS_FAILURE: 'failure',
    STATUS_PENDING: 'pending',

    EVENT_INITIATE: 'INITIATE',
    EVENT_SITE_VISIT_OFFICER_ASSIGMENT: 'SITE_VISIT_OFFICER_ASSIGMENT',
    EVENT_SENDBACK_SITE_INFO: 'SENDBACK_SITE_INFO',
    EVENT_PENDING_SITE_INFO: 'PENDING_SITE_INFO',
    EVENT_PENDING_DSC: 'PENDING_DSC',
    EVENT_LICENSE_RELEASE: 'LICENSE_RELEASE',
    EVENT_ASSIGMENT_FOR_SITE_VISIT_OFFICER: 'ASSIGMENT_FOR_SITE_VISIT_OFFICER',

    BTN_NAME_SEND_BACK: 'Sendback',
    BTN_NAME_FORWARD_WITHOUT_APPROVAL: 'Forward without Approval',
    BTN_NAME_FORWARD_WITH_APPROVAL: 'Forward with Approval',
    BTN_NAME_RECOMMAND: 'Recommend',
    BTN_NAME_APPROVE: 'Approve',
    BTN_NAME_REJECT: 'Reject',
    BTN_NAME_CLARIFY: 'clarify',
    BTN_NAME_PROCEED: 'Proceed',
    BTN_NAME_RECONSIDERATION: 'Re-Consideration',
    BTN_NAME_FORWARD: 'Forward',
    BTN_NAME_SUBMIT: 'Submit',
    BTN_NAME_PROCEED_TO_PAYMENT: 'Proceed to Payment',

    CODE_SALUTATION: 'SALUTATION',
    CODE_GENDER: 'GENDER',
    CODE_ENTITYOFFIRM: 'ENTITYOFFIRM',
    CODE_BREWERY_ENTITY_TYPE : 'BREWERY_ENTITY_TYPE',
    CODE_ENTITY_TYPE_SUGAR_MILL: 'ENTITY_TYPE_SUGAR_MILL',
    CODE_TANK_CATEGORY : 'TANK_CATEGORY',
    CODE_MOLASSES_TYPE: 'MOLASSES_TYPE',
    CODE_TANK_MATERIAL: 'TANK_MATERIAL',
    CODE_SUGAR_MILL_TANK_TYPE: 'SUGAR_MILL_TANK_TYPE',
    CODE_ENTITYOFFIRM_HBR: 'ENTITYOFFIRM_HBR',
    CODE_APPLICANT_TYPE: 'APPLICATION_TYPE',
    CODE_ESTABLISHMENT_TYPE: 'ESTABLISHMENT_TYPE',
    CODE_ENTITY_FOR_AUTH_PERSON: 'ENTITY_FOR_AUTH_PERSON',
    CODE_LOCALITYTYPE: 'LOCALITYTYPE',
    CODE_DOMAIN_TYPE: 'DOMAIN_TYPE',
    CODE_VENDOR_PRODUCT_TYPE: 'VENDOR_PRODUCT_TYPE',
    CODE_BANK: 'BANK',
    CODE_ITR: 'ITR',
    CODE_YEAR_OF_REGISTRATION: 'CODE_YEAR_OF_REGISTRATION',
    CODE_BOTTLING_UNIT: 'BOTTLING_UNIT',
    CODE_OWNERSHIP_TYPE: 'OWNERSHIP_TYPE',
    CODE_MODE_OF_DRUG_TRANSPORT: 'MODE_OF_DRUG_TRANSPORT',
    CODE_IMPORT_UNIT_LICENSE_TYPE: 'IMPORT_UNIT_LICENSE_TYPE',
    CODE_UNIT_TYPE: 'UNIT_TYPE',
    CODE_MODE_OF_PAYMENT: 'MODE_OF_PAYMENT',
    CODE_NUMBER_OF_ROOMS: 'NUMBER_OF_ROOMS',
    CODE_DISTRICT_CATEGORY: 'DISTRICT_CATEGORY',
    CODE_RETAIL_SHOP: 'RETAIL_SHOP',
    CODE_EVENT_TYPE: 'EVENT_TYPE',
    CODE_EVENT_HOLDER: 'EVENT_HOLDER',
    CODE_MASTER_WARE_HOUSE: 'CHECKLIST_MASTER_WARE_HOUSE',
    CODE_CHECKLIST_EVENT_BAR_FL11: 'CHECKLIST_EVENT_BAR_FL11',
    CODE_CHECKLIST_BWFL_PARENT_UNIT: 'CHECKLIST_BWFL_PARENT_UNIT',
    CODE_STATE_POPULATION_SUPPLY: 'STATE_POPULATION_SUPPLY',
    CODE_EMD_INSTRUMENT: 'EMD_INSTRUMENT',
    CODE_DOMAIN_MASTER: 'DOMAIN_MASTER',
    CODE_LICENSE_TANK_TYPE: 'LICENSE_TANK_TYPE',
    CODE_INDUSTRY_TYPE: 'INDUSTRY_TYPE',
    CODE_REGISTRATION_TYPE: 'REGISTRATION_TYPE',
    CODE_GODOWN: 'GODOWN',
    CODE_LIQUOR_TYPE:'LIQUOR_TYPE',
    CODE_LIQUOR_TYPE_EVENT_BAR:'LIQUOR_TYPE_EVENT_BAR',
    CODE_LIQUOR_SUBTYPE:'LIQUOR_SUBTYPE',
    CODE_LIQUOR_SUPPLY_TYPE:'LIQUOR_SUPPLY_TYPE',
    CODE_LIQUOR_DETAILED_DESC:'LIQUOR_DETAILED_DESC',
    CODE_BRAND:'BRAND',
    CODE_PACKAGE_SIZE:'PACKAGE_SIZE',
    CODE_SECURITY_PAYMENT_TYPES: "SECURITY_PAYMENT_TYPES",
    CODE_SECURITY_PAYMENT_INSTRUMENTS: "SECURITY_PAYMENT_INSTRUMENTS",
    CODE_BWFL_BOND_TYPE: 'BWFL_BOND_TYPE',
    CODE_STAR_LEVEL_OF_HOTEL: 'STAR_LEVEL_OF_HOTEL',
    CODE_PARKING_TYPE: 'PARKING_TYPE',
    CODE_REST_ROOM_LOCATION: 'REST_ROOM_LOCATION',
    CODE_NUMBER_OF_CLUB_MEMBERS: 'NUMBER_OF_CLUB_MEMBERS',
    CODE_TRANSIT_TYPE: 'TRANSIT_TYPE',
    CODE_CRUISE_TRANSPORT_TYPES: 'CRUISE_TRANSPORT_TYPES',
    CODE_ENTITY_TYPE: 'MWF_ENTITY',
    CODE_SPIRIT_TYPE: 'SPIRIT_TYPE',
    CODE_PD_DISTILLERY_TYPE: 'PD_DISTILLERY_TYPE',
    CODE_PD_GROUP_NAME: 'PD_GROUP_NAME',
    CODE_MANUFACTURER_ITEM: 'MANUFACTURER_ITEM',
    CODE_INDUSTRIAL_ALCOHOL_ENTITY_TYPE: 'INDUSTRIAL_ALCOHOL_ENTITY_TYPE',
    CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_DS1: 'CHECKLIST_INDUSTRIAL_ALCOHOL_DS1',
    CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_FL32: 'CHECKLIST_INDUSTRIAL_ALCOHOL_FL32',
    CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_FL16: 'CHECKLIST_INDUSTRIAL_ALCOHOL_FL16',
    CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_FL17: 'CHECKLIST_INDUSTRIAL_ALCOHOL_FL17',
    CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_FL39: 'CHECKLIST_INDUSTRIAL_ALCOHOL_FL39',
    CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_FL40: 'CHECKLIST_INDUSTRIAL_ALCOHOL_FL40',
    CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_FL41: 'CHECKLIST_INDUSTRIAL_ALCOHOL_FL41',
    CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_FL49: 'CHECKLIST_INDUSTRIAL_ALCOHOL_FL49',
    CODE_CHECKLIST_TARA_SENDHI_CL7: 'CHECKLIST_TARA_SENDHI_CL7',
    CODE_CHECKLIST_TARA_SENDHI_CL8: 'CHECKLIST_TARA_SENDHI_CL8',
    CODE_CHECKLIST_TARA_SENDHI_CL9: 'CHECKLIST_TARA_SENDHI_CL9',
    CODE_CHECKLIST_TARA_SENDHI_CL10: 'CHECKLIST_TARA_SENDHI_CL10',
    CODE_CHECKLIST_TARA_SENDHI_CL10A: 'CHECKLIST_TARA_SENDHI_CL10A',
    CODE_CHECKLIST_TARA_SENDHI_CL11: 'CHECKLIST_TARA_SENDHI_CL11',
    CODE_PRODUCT_TYPE: 'PRODUCT_TYPE',
    CODE_DESIGNATED_MATERIAL: 'DESIGNATED_MATERIAL',
    CODE_MATERIAL_CONSTRUCTION: 'MATERIAL_CONSTRUCTION',
    CODE_TANK_SHAPE: 'TANK_SHAPE',
    CODE_TANK_TYPE: 'TANK_TYPE',
    CODE_TANK_COVER_TYPE: 'TANK_COVER_TYPE',
    CODE_LICENSE_TYPE: 'LICENSE_TYPE',
    CODE_LICENSE_ACTIONS: 'LICENSE_ACTIONS',
    CODE_BOTTLING_SUPPLY_TYPE: 'BOTTLING_SUPPLY_TYPE',
    CODE_BOTTLING_LIQUOR_TYPE: 'BOTTLING_LIQUOR_TYPE',
    CODE_BOTTLING_DOMAIN: 'BOTTLING_DOMAIN',
    CODE_BOTTLING_TANK_TYPE: 'BOTTLING_TANK_TYPE',
    CODE_TANK_NATURE: 'TANK_NATURE',
    CODE_BOTTLING_LINE: 'BOTTLING_LINE',
    CODE_ACTION_CANCELLATION: 'CANCELLATION',
    CODE_ACTION_TERMINATE: 'TERMINATE',
    CODE_ACTION_SURRENDER: 'SURRENDER',
    CODE_ACTION_SUSPEND: 'SUSPEND',
    CODE_ACTION_TRANSFER: 'TRANSFER',
    CODE_ACTION_MODIFICATION: 'MODIFICATION',
    CODE_SUSPENSION_RELEASE: 'SUSPENSION_RELEASE',
    CODE_RELATION: 'RELATION',
    CODE_CSD_LICENSE_TYPE: 'CSD_LICENSE_TYPE',
    CODE_LICENSE_ENTITY_TYPE: 'LICENSE_ENTITY_TYPE',
    CODE_DENATURED_SPIRIT_FORMULA:'DENATURED_SPIRIT_FORMULA',

    FF_VENDOR: 'vendorFiles',
    FF_DOCUMENT: 'documentList',
    FF_UNIT: 'UnitFiles',
    FF_CUSTOM_BOND: 'CustomBondFiles', //FileFor Custom Bond Details
    FF_APPLI: 'ApplicantFiles', // FileFor Applicant
    FF_ADDRESS: 'AddressFiles', // FileFor Address section
    FF_ATTACHMENTS: 'Attachments', // FileFor Attachments
    FF_BANK: 'BankFiles', // FileFor BankDet
    FF_BUSS_UNIT: 'BussinessFiles', // FileFor BussinessFiles
    FF_FIRM_COMPANY: 'FirmCompanyFiles', // FileFor FirmCompanyFiles
    FF_PREMISES: 'PremisesFiles', // FileFor PremisesFiles
    FF_SITE_DOCUMENT: 'SiteDocumentFiles', // FileFor SiteDocumentFiles
    FF_ATTACHMENT_DOCUMENTS: 'AttachmentDocumentFiles',  // FileFor AttachmentDocumentFiles
    FF_ENTITY_DETAILS: 'EntityDetails',
    FF_EMD: 'emdDocumentFiles', // FileFor emdDocumentFiles
    FF_SHOP: 'shopDocumentFiles', // FileFor shopDocumentFiles
    FF_SECURITY: 'securityDocumentList', // FileFor securityDocumentList
    FF_SITE_REPORT: 'siteReportDocumentList', // FileFor siteReportDocumentList
    FF_EXCISE_INSPECTOR_REPORT: 'exciseinspectorReportDocumentList', // FileFor exciseinspectorReportDocumentList
    FF_REJECT_REPORT: 'rejectReportDocumentList', // FileFor rejectReportDocumentList
    FF_SITE_UPLOAD_REPORT: 'siteReportDocumentList', // FileFor siteUploadReportDocumentList
    FF_SITE_REPORT_DM: 'siteReportDMDocumentList', // FileFor siteReportDMDocumentList
    FF_SITE_REPORT_LEVEL: 'siteReportLevelDocumentList', // FileFor siteReportLevelDocumentList
    FF_MOLASSES_STORE: 'molassesFiles',
    FF_ADDITIONALS: 'WholeSaleAdditionalDetails', // FileFor BussinessFiles
    FF_SALESMAN: 'salesmanFiles', // FileFor BussinessFiles
    FF_SITE_DOCUMENT_CUSTOM_BOND: 'SiteDocumentCustomBondFiles', // FileFor SiteDocumentCustomBondFiles
    FF_SITE_DOCUMENT_DEBOND: 'SiteDocumentDebondFiles', // FileFor SiteDocumentDebondFiles
    FF_TANK_DOCUMENT:'tankDocumentFiles',
    FF_ADDITIONAL_INDUSTRIAL: 'IndustrialAdditionalDetails',
    FF_BRIEF_PROCESS: "briefProcessDocumentList",
    FF_TANK_DETAIL: 'distilleryTankDocumentList',
    FF_VESSEL_DOCUMENT:'vesselDocumentFiles',
    FF_SALESMAN_DOCUMENT: 'salesmanDocumentList',
    FF_WHOLESALEUNIT_DOCUMENT:'wholeSaleUnitForExportDetailsFiles',
    FF_WHOLESALE_EXPORT: 'wholesaleExportDocumentList',
    FF_IMPORT_UNIT_DETAILS: 'importUnitDetails',
    FF_LIQUOR_SERVE: 'liquorFiles',
    FF_BOTTLING_LINE: 'bottelingLineDocumentList',
    FF_TANK_LIST: 'tankDocumentList',
    FF_PARENT_UNIT: 'parentUnitFiles',
    FF_BUSINESS_UNIT_DET: 'businessUnitDocumentList',
    FF_UNDER_TAKING_DET: 'undertakingDocumentList',
    FF_VESSEL_DOCUMENT_LIST: 'vessalDocumentList',
    FF_PROCESS_DOCUMENT: 'processDocumentList',
    
    SEC_ADDR_DEBOND: 'debond_address',
    SEC_ADDR_CUSTOM_BOND: 'custom_bond_address',
    SEC_ADDR_APPLICANT: 'applicant_address',
    SEC_ADDR_AUTH_PERSON: 'authorize_person_address',
    SEC_ADDR_IU: 'import_unit_address',
    SEC_ADDR_PREMISES: 'premises_address',
    SEC_ADDR_BOTTLING_PREMISES: 'bottling_premises_address',
    SEC_ADDR_VENUE: 'venue_address',
    SEC_ADDR_UNIT: 'unit_address',
    SEC_ADDR_WORK: 'work_address',
    SEC_ADDR_HEAD: 'head_address',
    SEC_ADDR_BWFL_PREMISES: 'bwfl_parent_premises_address',
    SEC_ADDR_BWFL_PARENT_UNIT: 'bwfl_parent_unit_address',
    SEC_ADDR_GROUP: 'group_address',
    SEC_ADDR_UNDER_TAKING: 'undertaking_address',
    SEC_ADDR_SALES_LOCAL: 'salesman_local_address',
    SEC_ADDR_SALES_PERMANENT: 'salesman_permanent_address',
    SEC_ADDR_ORGANIZATION: 'organization_address',
    SEC_ADDR_PERSON_DETAILS: 'authorized_person_address',
    SEC_ADDR_VENDOR: 'vendor_address',
    
    OFFICER_OCCUPIER: 'occupier_officer',
    OFFICER_GENERAL_MANAGER: 'general_manager',
    OFFICER_SALES_MANAGER: 'sales_manager',
    OFFICER_BOARD_MEMBER: 'board_memeber',
    OFFICER_DIRECTORS_PARTNERS: 'directors_partners',

    PROP_LICENSE_PARENT_UNIT: 'parentUnitLicenses',
    PROP_PARENT_UNIT_DETAILS: 'parentUnitDetailList',
    PROP_ATTCHEMENT_DET: 'attachmentDetails',
    PROP_CUSTOMBOND_DET: 'customBondDetails',
    PROP_UNIT_DET: 'businessUnitDetails',
    PROP_REGI_DET: 'registrationDetails',
    PROP_CUSTOM_BOND_DET: 'bondDetails',
    PROP_DEBOND_DET: 'debondUnitDetails',
    PROP_GRANT_DET: 'grantDetails',
    PROP_WHOLE_SALE_UNIT_FOR_EXPORT_DET: 'bhangLicenses',
    PROP_SALESMAN_DETAILS_DET: 'salesManDetailsList',
    PROP_CAPITAL_DETAILS: 'breweryCapitalDetails',
    PROP_PLAN_MACHINERY_DET: 'breweryPlanMachineryDetails',
    PROP_BREWERY_RAW_MATERIAL: 'breweryRawMaterial',
    PROP_BREWERY_WATER_POWER: 'breweryWaterPowerDetails',
    PROP_BREWERY_PROCESS_DET: 'breweryProcess',
    PROP_BREWERY_FORECAST_DET: 'breweryForcastAssitance',
    PROP_EMPLOYMENT_POT_DET: 'breweryEmploymentDetails',

    PROP_DISTILLERY_GROUP_DET: 'groupDetails',
    PROP_AFFADAVAIT_DOCUMENT_DET: 'documentList',
    PROP_IU_ADDRESS_DET: 'importUnitAddressDetails',
    PROP_APPLI_DET: 'applicantDetails',
    PROP_VENDOR_DET: 'vendorDetails',
    PROP_ADDRESS_DET: 'addressList',
    PROP_VENDOR_ADDRESS_DET: 'vendorAddress',
    PROP_BANK_DET_NEW: 'licenseBankDetails',
    PROP_BANK_DET: 'bankList',
    PROP_BUSSIN_UNIT: 'businessUnitDetails',
    PROP_ATTACHMENT: 'attachmentDetails',
    PROP_AUTH_PERSON_ADDRESS_DET: 'authorizePersonAddressList',
    PROP_OFFICER_DET: 'licenseOfficersList',
    PROP_BOTTLING_PREMISES_DET: 'bottlingPremises',
    PROP_PAYMENT_AND_SECURITY :"paymentAndSecurity",
    PROP_TANK_REGISTRATION : "tankRegistration",
    PROP_NEW_LINE_REGISTRATION : "newLineRegistration",
    PROPOSED_BOTTLING_LINE : "detailsOfProposedBottlingLine",
    PROP_ORGANIZATION_DET: 'organizationDetails',
    PROP_SALESMAN_DET: 'salesManDetailsList',
    PROP_TANK_DET : "tankList",
    PROP_WAREHOUSE_UNIT_DET: "wareHouseList",
    PROP_COLLECTION_CENTER_DET: "collectionCenterList",
    PROP_AUTHORISED_PERSON_DETAILS:"alcoholAuthorisedPersonDetails",
    PROP_LICENSE_WL: 'wholeSaleLicenses',
    PROP_ATTACHMENT_WL: 'wholeSaleAdditionalDetails',
    PROP_ADDITIONAL_WL: 'wholeSaleAdditionalDetails',
    PROP_SCHEDULE_WL: 'scheduleList',
    PROP_ALLOCATED_CENTER_WL: 'allocatedCenterList',
    PROP_RELATED_INFO_WL: 'relatedInformationList',
    PROP_ADDITIONAL_INDUSTRIAL: 'additionalDetails',
    PROP_INDUSTRIAL_LICENSE: 'industrialAlcohol',
    PROP_VESSEL_LIST_DET: 'vesselList',
    PROP_VESSAL_LIST: 'vessalList',
    PROP_APPROVED_LIST_DET: 'listOfApprovedItems',
    PROP_DISTILLERY_EMPLOYMENT_DET: 'distilleryEmploymentDetails',
    PROP_DISTILLERY_TECHNICAL_ASSISTANCE_DET: 'distilleryTechnicalAssistance',
    PROP_DISTILLERY_FORCAST_ASSISTANCE_DET: 'distilleryForcastAssitance',
    PROP_DISTILLERY_BRIEF_PROCESS_DET: 'distilleryProcess',
    PROP_DISTILLERY_WATERPOWER_DET: 'distilleryWaterPowerDetails',
    PROP_DISTILLERY_RAW_MATERIAL_DET: "distilleryRawMaterial",
    PROP_DISTILLERY_PLANT_MACHINERY_DET: 'distilleryPlantMachineryDetails',
    PROP_DISTILLERY_CAPITAL_DET: 'distilleryCapitalDetails',

    PROP_BOTTLING_EMPLOYMENT_DET: 'bottelingEmploymentDetails',
    PROP_BOTTLING_TECHNICAL_ASSISTANCE_DET: 'bottelingTechnicalAssistance',
    PROP_BOTTLING_FORCAST_ASSISTANCE_DET: 'bottelingForcastAssitance',
    PROP_BOTTLING_BRIEF_PROCESS_DET: 'bottelingProcess',
    PROP_BOTTLING_WATERPOWER_DET: 'bottelingWaterPowerDetails',
    PROP_BOTTLING_RAW_MATERIAL_DET: "bottelingRawMaterial",
    PROP_BOTTLING_PLANT_MACHINERY_DET: 'bottelingPlantMachineryDetails',
    PROP_BOTTLING_CAPITAL_DET: 'bottelingCapitalDetails',
    PROP_BOTTLING_MANUFACTURER_LIST: 'bottelingManufacturerItemList',

    PROP_WHOLESALE_UNIT: 'bhangWholeSaleUnit',
    PROP_IMPORT_UNIT_DET: 'importUnitDetails',
    PROP_SW_REQUIREMENT_DET: 'swRequirementDetails',
    PROP_BOTTLING_NEW_LINE: 'bottelingLineList',
    PROP_TANK_BOTTELING_LIST: 'tankBottelingLineList',

    PROP_GROUP_DET: 'groupDetails',
    PROP_GROUP_DETAILS_ADDRESS_DET: 'groupDetailsAddress',
    PROP_BREWERY_UNDERTAKING_DET: 'breweryUndertakingDetails',
    PROP_LICENSE_HBR: 'hbrLicenses',
    PROP_FIRM_COMPANY_DET_HBR: 'hbrFirmCompanyDetailList',
    PROP_ATTACHMENT_HBR: 'hbrAdditionalDetails',
    PROP_PREMISES_HBR: 'hbrPremisesDetails',
    PROP_TRAINCRUISE_HBR: 'hbrTrainCruiseDetails',
    PROP_SITE_DOCUMENT: 'documentList',
    PROP_ATTACHMENT_DOCUMENT: 'licenseActionDocumentList',
    PROP_ENTITY_LIST: 'entityArrayList',
    PROP_PRODUCT_TYPE_LIST: 'productTypeList',
    PROP_ITEM_MANUFACTURER_LIST: 'itemManufacturerArrayList',
    PROP_DISTILLERY_TANK_LIST: 'distilleryTankList',
    PROP_SALES_MAN_DOCUMENT_LIST: 'salesManDocumentList',
    PROP_SALESMAN_LIST: 'salesManList',
    PROP_IMPORT_DRUG_LIST: 'importDrugList',
    PROP_METHYL_ALCOHOL_DIRECTOR_LIST: 'methylAlcoholDirectorList',
    PROP_METHYL_ALCOHOL_USES_LIST: 'methylAlcoholUsesList',
    PROP_MEDICINEWISE_IMPORT_DRUG_LIST: 'medicineWiseImportDrugList',
    PROP_LIQUOR_SERVE_HBR: 'liquorServeLocationList',

    PROP_LICENSE_EVENT_BAR: 'eventBarLicenses',
    PROP_PRODUCT_LIST: 'productList',
    PROP_SHOP_LIST: 'eventBarShopList',

    PROP_LICENSE_DL: 'distilleryLicenses',
    PROP_ATTACHMENT_DL: 'distilleryAdditionalDetails',

    PROP_LICENSE_RL: 'retailLicenses',
    PROP_ATTACHMENT_RL: 'retailAdditionalDetails',
    PROP_SHOP_RL: 'retailShopDetails',
    PROP_EMD_RL: 'retailEMDDetails',
    PROP_SALES_MAN_RL: 'salesManDetail',
    PROP_SALES_MAN_LIST_RL: 'salesManDetailList',
    PROP_RETAIL_OTHER_DETAILS: 'retailCsdOtherDetails',
    PROP_REPORT_DOC_RL: 'siteReportDocumentList',
    PROP_SECURITY_DOC_RL: 'securityAmountList',
    PROP_LICENSE_BOTTLING: 'bottelingLicenses',
    PROP_BOTTLING_PARENT_UNIT: 'bottlingParentUnitDetails',

    PROP_LICENSE_SL: 'sugarMillLicenses',
    PROP_MOLASSES_SL: 'molassesList',
    PROP_REGISTRATION_DET_SL: 'sugarMillRegistrationDetails',
    PROP_ATTACHMENT_SL: 'sugarMillAdditionalDetails',

    PROP_LIC_BREW: 'breweryLicense',
    PROP_LIC_BREW_UNDER_TAKING: 'undertaking',
    PROP_LIC_BREW_ATTACHMENT: 'breweryAdditionalDetails',

    COMMENT_ACTION_CLARIFICATION: 'SENDBACK',
    COMMENT_ACTION_REJECTED: 'REJECTED',
    COMMENT_ACTION_SAVE: 'SAVE',
    COMMENT_SENDBACK_SITE_INFO: 'SENDBACK_SITE_INFO',
    COMMENT_SENDBACK: 'SENDBACK',

    /**
     * Fee type for processing and license maintain as constant
     */
    FEE_TYPE_PROCESSING_FEE: 'PROCESSING_FEE',
    FEE_TYPE_REGISTRATION_FEE: 'REGISTRATION_FEE',
    FEE_TYPE_LICENSE_FEE: 'LICENSE_FEE',

    // Applicant Document identifiers for all licenses
    FN_COMPANY_PAN: 'COMPANY_PAN_DOC',
    FN_APPLI_PHOTO: 'APPLICANT_PHOTO_DOC',
    FN_APPLI_AADHAR: 'APPLICANT_AADHAR_DOC',
    FN_APPLI_PAN: 'APPLICANT_PAN_DOC',
    FN_APPLI_PAN_FILE_SOURCE: 'appli_pan_file_source',
    FN_APPLI_ITR: 'APPLICANT_ITR_DOC',
    FN_APPLI_LAYOUT: 'LAYOUT_PLAN_DOC',
    FN_GSTIN_FILE: 'GSTIN_DOC',
    FN_APPLI_OWNERSHIP_FILE: 'OWNERSHIP_DOC',
    FN_APPLI_AFFADAVAIT: 'APPLI_AFFADAVAIT_DOC',
    FN_APPLI_BLUEPRINT: 'APPLI_BLUEPRINT_DOC',
    FN_APPLI_CHARAC_CERT: 'APPLI_CHARAC_CERT_DOC',
    FN_APPLI_NO_DUE_BY_DM_DEO: 'APPLI_NO_DUE_BY_DM_DEO',
    FN_APPLI_SOLVENCY_CERT: 'APPLI_SOLVENCY_DOC',
    FN_APPLI_SPACE_ALLOCATION_CERT: 'APPLI_SPACE_ALLOCATION_DOC',
    FN_APPLI_FINAL_PRODUCT: 'APPLI_FINAL_PRODUCT_DOC',
    FN_APPLI_DENAULT_STORAGE: 'APPLI_DENAULT_STORAGE',

    FN_APPLI_OWNERSHIP_DOCUMENT: 'APPLI_OWNERSHIP_DOCUMENT_DOC',
    FN_APPLI_UPLOAD_THE_BOUNDARIES: 'APPLI_UPLOAD_THE_BOUNDARIES_DOC',
    FN_GOVTID: 'APPLI_GOVTID_DOC',
    FN_APPLI_SPIRIT_STORAGE_TANK: 'APPLI_SPIRIT_STORAGE_TANK_DOC',
    FN_UPLOAD_PARTNERSHIP_DEED_ARTICLE_OF_ASSOCIATION:'FN_UPLOAD_PARTNERSHIP_DEED_ARTICLE_OF_ASSOCIATION_DOC',
    FN_UPLOAD_BLUE_PRINT_OF_THE_PREMISES: 'FN_UPLOAD_BLUE_PRINT_OF_THE_PREMISES_DOC',
    FN_UPLOAD_REGISTRATION_CERTIFICATE_FROM_THE_INDUSTRY_DEPARTMENT: 'UPLOAD_REGISTRATION_CERTIFICATE_FROM_THE_INDUSTRY_DEPARTMENT_DOC',    
    FN_UPLOAD_PREVIOUS_PERMIT:'FN_UPLOAD_PREVIOUS_PERMIT_DOC',
    FN_UPLOAD_PREVIOUS_PASS_PD_25_DECLARATION: 'FN_UPLOAD_PREVIOUS_PASS_PD_25_DECLARATION_DOC',
    FN_UPLOAD_CERTIFIED_PAGE_OF_STOCK: 'FN_UPLOAD_CERTIFIED_PAGE_OF_STOCK_DOC',
    FN_UPLOAD_COPY_OF_PREMISES_BLUE_PRINT: 'FN_UPLOAD_COPY_OF_PREMISES_BLUE_PRINT_DOC',
    FN_UPLOAD_ASSESSMENT_YEAR_18_19:'FN_UPLOAD_ASSESSMENT_YEAR_18_19_DOC',
    FN_UPLOAD_ASSESSMENT_YEAR_19_20:'FN_UPLOAD_ASSESSMENT_YEAR_19_20_DOC',
    FN_UPLOAD_ASSESSMENT_YEAR_20_21:'FN_UPLOAD_ASSESSMENT_YEAR_20_21_DOC',
    FN_UPLOAD_ASSESSMENT_YEAR_21_22:'FN_UPLOAD_ASSESSMENT_YEAR_21_22_DOC',
    FN_UPLOAD_ASSESSMENT_YEAR_22_23:'FN_UPLOAD_ASSESSMENT_YEAR_22_23_DOC',
    FN_UPLOAD_APPLICATION_COUNTER_SIGN: 'FN_UPLOAD_APPLICATION_COUNTER_SIGN_DOC',
    
    FN_UPLOAD_BOUNDARIES:'FN_UPLOAD_BOUNDARIES_DOC',
    FN_UPLOAD_FIRE_SAFETY_NOC:'FN_UPLOAD_FIRE_SAFETY_NOC_DOC',
    FN_UPLOAD_AFFADAVAIT:'FN_UPLOAD_AFFADAVAIT_DOC',
    FN_UPLOAD_LAYOUT_PLAN:'FN_UPLOAD_LAYOUT_PLAN_DOC',
    FN_UPLOAD_NO_DUES_CERTIFICATE:'FN_UPLOAD_NO_DUES_CERTIFICATE_DOC',

    FN_UPLOAD_PLANT_MACHINERY_DETAILS:'FN_UPLOAD_PLANT_MACHINERY_DETAILS_DOC',
    FN_UPLOAD_INSTALLED_CAPACITY_OF_THE_PLANT:'FN_UPLOAD_INSTALLED_CAPACITY_OF_THE_PLANT_DOC',
    FN_UPLOAD_DECLARATION_INFORM_MB3:'FN_UPLOAD_DECLARATION_INFORM_MB3_DOC',
    FN_UPLOAD_NON_JUDICIAL_STAMP_OF_UNDERTAKING:'FN_UPLOAD_NON_JUDICIAL_STAMP_OF_UNDERTAKING_DOC',
    FN_UPLOAD_CHARACTER_CERTIFICATE:'FN_UPLOAD_CHARACTER_CERTIFICATE_DOC',
    FN_UPLOAD_BLUEPRINT_SAND_LAYOUT_OF_MICROBREWERY:'FN_UPLOAD_BLUEPRINT_SAND_LAYOUT_OF_MICROBREWERY_DOC',
    FN_UPLOAD_EXISTING_BAR_LICENSE:'FN_UPLOAD_EXISTING_BAR_LICENSE_DOC',
    FN_UPLOAD_NO_DUES:'FN_UPLOAD_NO_DUES_DOC',



    FN_APPLI_PLANT_MACHINERY: 'APPLI_PLANT_MACHINERY_DOC',
    FN_APPLI_PRODUCTION_PLAN: 'APPLI_PRODUCTION_PLAN_DOC',
    FN_APPLI_FINAL_ALCOHOL_PRODUCT: 'APPLI_FINAL_ALCOHOL_PRODUCT_DOC',
    FN_APPLI_NOC: 'APPLI_NOC_DOC',
    FN_APPLI_DISTRICT_CERTIFICATE: 'APPLI_DISTRICT_CERTIFICATE_DOC',
    FN_APPLI_NOC_UPPCB: 'APPLI_NOC_UPPCB_DOC',
    FN_APPLI_FOREIGN_COLLAB: 'APPLI_FOREIGN_COLLAB_DOC',
    FN_APPLI_CRIMINAL_CONVIC: 'APPLI_CRIMINAL_CONVIC_DOC',
    FN_APPLI_UPED: 'APPLI_UPED_DOC',
    FN_APPLI_CONSTITUENTS: 'APPLI_CONSTITUENTS_DOC',
    FN_APPLI_PRODUCT_ALCOHOL: 'APPLI_PRODUCT_ALCOHOL_DOC',
    FN_APPLI_ISI_SPEC: 'APPLI_ISI_SPEC_DOC',
    FN_APPLI_LITERATURE: 'APPLI_LITERATURE_DOC',
    FN_APPLI_DENATURED_SPIRIT: 'APPLI_DENATURED_SPIRIT_DOC',
    FN_APPLI_DENATURED: 'APPLI_DENATURED_DOC',
    FN_APPLI_PERCENTAGE_ALCOHOL: 'APPLI_PERCENTAGE_ALCOHOL_DOC',
    FN_APPLI_PRODUCTION_DETAIL: 'APPLI_PRODUCTION_DETAIL_DOC',
    FN_APPLI_INTERMEDIATE_STAGE: 'APPLI_INTERMEDIATE_STAGE_DOC',
    FN_APPLI_EXACT_INDUSTRY_BRANCH: 'APPLI_EXACT_INDUSTRY_BRANCH_DOC',
    FN_APPLI_SOLVENTS_HANDLED: 'APPLI_SOLVENTS_HANDLED_DOC',
    FN_APPLI_NATURAL_RESINS_HANDLED: 'APPLI_NATURAL_RESINS_HANDLED_DOC',
    FN_APPLI_MACHINE_NAME: 'APPLI_MACHINE_NAME_DOC',
    FN_APPLI_PIGMENT_NAME: 'APPLI_PIGMENT_NAME_DOC',
    FN_APPLI_OIL_NAME: 'APPLI_OIL_NAME_DOC',
    FN_APPLI_SITE_SKETCH: 'APPLI_SITE_SKETCH_DOC',
    FN_APPLI_SALES_TAX_FIGURE: 'APPLI_SALES_TAX_FIGURE_DOC',
    FN_APPLI_INCOME_TAX_FIGURE: 'APPLI_INCOME_TAX_FIGURE_DOC',
    FN_APPLI_AUTHORIZED_OFFICER: 'APPLI_AUTHORIZED_OFFICER_DOC',
    FN_APPLI_DISTRICT_OFFICER: 'APPLI_DISTRICT_OFFICER_DOC',
    FN_APPLI_SITE_ELEVATION_PLAN: 'APPLI_SITE_ELEVATION_PLAN',
    FN_APPLI_EXPECTED_DATE: 'APPLI_EXPECTED_DATE',
    FN_APPLI_LICENSE_DRUG_DEPARTMENT: 'APPLI_LICENSE_DRUG_DEPARTMENT',
    FN_APPLI_LIST_DRUG_DEPARTMENT: 'APPLI_LIST_DRUG_DEPARTMENT',
    FN_APPLI_NOC_CONTROL_BOARD: 'APPLI_NOC_CONTROL_BOARD',
    FN_APPLI_PROPOSED_BLUEPRINT: 'APPLI_PROPOSED_BLUEPRINT',
    FN_BRIEF_PROCESS_MANUFACTURE: 'BRIEF_PROCESS_MANUFACTURE_DOC',
    FN_TENDER_ALLOTMENT: 'TENDER_ALLOTMENT_DOC',
    FN_LIQUOR_SERVE: 'LIQUOR_SERVE_DOC',

    SRC_TENDER_ALLOTMENT: 'tender_allotment_file_source',
    SRC_APPLI_PHOTO: 'appli_photo_file_source',
    SRC_APPLI_AADHAR: 'appli_aadhar_file_source',
    SRC_APPLI_PAN: 'appli_pan_file_source',
    SRC_COMPANY_PAN: 'company_pan_file_source',
    SRC_APPLI_PAN_FILE_SOURCE_SOURCE: 'appli_pan_file_source_source',
    SRC_APPLI_ITR: 'appli_itr_file_source',
    SRC_APPLI_PARENT_ITR: 'appli_parent_itr_file_source',
    SRC_APPLI_LICENSE: 'appli_license_file_source',
    SRC_APPLI_AFFADAVAIT: 'appli_affadavait_file_source',
    SRC_APPLI_BLUEPRINT: 'appli_bluePrint_file_source',
    SRC_ATTACH_ATTACHMENT_BLUEPRINT_OF_PLAN_ELEVATION: 'blue_print_of_plan_elevation_file_source',
    SRC_APPLI_CHARAC_CERT: 'appli_character_file_source',
    SRC_APPLI_NO_DUE_BY_DM: 'appli_no_due_by_dm_file_source',
    SRC_APPLI_NO_DUE_BY_DEO: 'appli_no_due_by_deo_file_source',
    SRC_VENDOR_GSTIN: 'gstin_file_source',
    SRC_APPLI_GSTIN: 'appli_gstin_file_source',
    SRC_APPLI_LAYOUT: 'appli_layout_source',
    SRC_APPLI_LAYOUT_PLAN: 'appli_layout_plan_source',
    SRC_FIRE_SAFETY: 'appli_fire_noc_source',
    SRC_FSSAI_CERT: 'appli_fssai_cert_source',
    SRC_NOC_PREMISES_CERT: 'appli_noc_premises_source',
    SRC_APPLI_NO_DUE_BY_DM_DEO: 'appli_no_due_by_dm_deo_file_source',
    SRC_NEW_LINE_UPLOAD: 'appli_new_line_upload_source',
    SRC_TANK_UPLOAD_DOCUMENT: 'appli_tank_upload_document_source',
    SRC_APPLI_FINAL_PRODUCT: 'appli_final_product_file_source',
    SRC_APPLI_DENAULT_STORAGE: 'denaturent_storage_room_map_source',
    SRC_BRIEF_PROCESS_MANUFACTURE: 'brief_process_manufacture_file_source',
    SRC_UPLOAD_PARTNERSHIP_DEED_ARTICLE_OF_ASSOCIATION:'upload_partnership_deed_article_of_association_file_source',
    SRC_UPLOAD_BLUE_PRINT_OF_THE_PREMISES:'upload_blue_print_of_the_premises_file_source',
    SRC_UPLOAD_REGISTRATION_CERTIFICATE_FROM_THE_INDUSTRY_DEPARTMENT:'upload_registration_certificate_from_the_industry_department_file_source',
    SRC_UPLOAD_PREVIOUS_PERMIT:'upload_previous_permit_file_source',
    SRC_UPLOAD_PREVIOUS_PASS_PD_25_DECLARATION:'upload_previous_pass_pd_25_declaration_file_source',

    SRC_UPLOAD_ASSESSMENT_YEAR_18_19:'upload_assessment_year_18_19_file_source',
    SRC_UPLOAD_ASSESSMENT_YEAR_19_20:'upload_assessment_year_19_20_file_source',
    SRC_UPLOAD_ASSESSMENT_YEAR_20_21:'upload_assessment_year_20_21_file_source',
    SRC_UPLOAD_ASSESSMENT_YEAR_21_22:'upload_assessment_year_21_22_file_source',
    SRC_UPLOAD_ASSESSMENT_YEAR_22_23:'upload_assessment_year_22_23_file_source',

    SRC_UPLOAD_CERTIFIED_PAGE_OF_STOCK:'upload_certified_page_of_stock_file_source',
    SRC_UPLOAD_COPY_OF_PREMISES_BLUE_PRINT:'upload_copy_of_premises_blue_print_file_source',
    SRC_UPLOAD_APPLICATION_COUNTER_SIGN:'upload_application_counter_sign_file_source',

    SRC_UPLOAD_BOUNDARIES:'upload_boundaries_file_source',
    SRC_UPLOAD_FIRE_SAFETY_NOC:'upload_fire_safety_noc_file_source',
    SRC_UPLOAD_AFFADAVAIT:'upload_affadavait_file_source',
    SRC_UPLOAD_LAYOUT_PLAN:'upload_layout_plan_file_source',
    SRC_UPLOAD_NO_DUES_CERTIFICATE:'upload_no_dues_certificate_file_source',
    
    SRC_UPLOAD_PLANT_MACHINERY_DETAILS: 'upload_plant_machinery_details_file_source',
    SRC_UPLOAD_INSTALLED_CAPACITY_OF_THE_PLANT: 'upload_installed_capacity_of_the_plant_file_source',
    SRC_UPLOAD_DECLARATION_INFORM_MB3: 'upload_declaration_inform_mb3_file_source', 
    SRC_UPLOAD_NON_JUDICIAL_STAMP_OF_UNDERTAKING: 'upload_non_judicial_stamp_of_undertaking_file_source',
    SRC_UPLOAD_CHARACTER_CERTIFICATE: 'upload_character_certificate_file_source',
    SRC_UPLOAD_RAW_MATERIAL_DETAILS: 'upload_raw_material_details_file_source',
    SRC_UPLOAD_BLUEPRINT_SAND_LAYOUT_OF_MICROBREWERY: 'upload_blueprint_sand_layout_of_microbrewery_file_source', 
    SRC_UPLOAD_EXISTING_BAR_LICENSE: 'upload_existing_bar_license_file_source',
    SRC_UPLOAD_NO_DUES: 'upload_no_dues_file_source',
    
    SRC_ATTACH_INTIMATION_LETTER_FOR_INSPECTION: 'upload_intimation_letter_for_inspection_file_source',
SRC_ATTACH_NOC_FROM_UP_POLLUTION_CONTROL_BOARD: 'upload_noc_from_up_pollution_control_board_file_source',
SRC_ATTACH_NOC_FROM_LOCAL_WATER_UTILITY: 'upload_noc_from_local_water_utility_file_source',
SRC_ATTACH_FSSAI_CERTIFICATE: 'upload_fssai_certificate_file_source',
SRC_ATTACH_FURTHER_PLANS_ESTIMATES_INFORMATION_TO_BE_FURNISHED: 'upload_further_plans_estimates_information_to_be_furnished_file_source',

    SRC_APPLI_SPIRIT_STORAGE_TANK: 'appli_spirit_storage_tank_file_source',
    SRC_APPLI_PLANT_MACHINERY: 'appli_plant_machinery_file_source',
    SRC_APPLI_PRODUCTION_PLAN: 'appli_production_plan_file_source',
    SRC_APPLI_FINAL_ALCOHOL_PRODUCT: 'appli_final_alcohol_product_file_source',
    SRC_APPLI_NOC: 'appli_noc_file_source',
    SRC_APPLI_DISTRICT_CERTIFICATE: 'appli_district_certificate_file_source',
    SRC_APPLI_NOC_UPPCB: 'appli_noc_uppcb_file_source',
    SRC_APPLI_FOREIGN_COLLAB: 'appli_foreign_collab_file_source',
    SRC_APPLI_CRIMINAL_CONVIC: 'appli_criminal_convic_file_source',
    SRC_APPLI_UPED: 'appli_uped_file_source',
    SRC_APPLI_CONSTITUENTS: 'appli_constituents_file_source',
    SRC_APPLI_PRODUCT_ALCOHOL: 'appli_product_alcohol_file_source',
    SRC_APPLI_ISI_SPEC: 'appli_isi_spec_file_source',
    SRC_APPLI_LITERATURE: 'appli_literature_file_source',
    SRC_APPLI_DENATURED_SPIRIT: 'appli_denatured_spirit_file_source',
    SRC_APPLI_DENATURED: 'appli_denatured_file_source',
    SRC_APPLI_PERCENTAGE_ALCOHOL: 'appli_percentage_alcohol_file_source',
    SRC_APPLI_PRODUCTION_DETAIL: 'appli_production_detail_file_source',
    SRC_APPLI_INTERMEDIATE_STAGE: 'appli_intermediate_stage_file_source',
    SRC_APPLI_EXACT_INDUSTRY_BRANCH: 'appli_exact_industry_branch_file_source',
    SRC_APPLI_SOLVENTS_HANDLED: 'appli_solvents_handled_file_source',
    SRC_APPLI_NATURAL_RESINS_HANDLED: 'appli_natural_resins_handled_file_source',
    SRC_APPLI_MACHINE_NAME: 'appli_machine_name_file_source',
    SRC_APPLI_PIGMENT_NAME: 'appli_pigment_name_file_source',
    SRC_APPLI_OIL_NAME: 'appli_oil_name_file_source',
    SRC_APPLI_SITE_SKETCH: 'appli_site_sketch_file_source',
    SRC_APPLI_SALES_TAX_FIGURE: 'appli_sales_tax_figure_file_source',
    SRC_APPLI_INCOME_TAX_FIGURE: 'appli_income_tax_figure_file_source',
    SRC_APPLI_AUTHORIZED_OFFICER: 'appli_authorized_officer_file_source',
    SRC_APPLI_DISTRICT_OFFICER: 'appli_district_officer_file_source',
    SRC_APPLI_SOLVENCY_CERT: 'appli_solvency_file_source',
    SRC_APPLI_SPACE_ALLOCATION_CERT: 'space_allocation_file_source',
    SRC_APPLI_SITE_ELEVATION_PLAN: 'site_elevation_plan_file_source',
    SRC_APPLI_EXPECTED_DATE: 'appli_expected_date_file_source',
    SRC_APPLI_LIST_DRUG_DEPARTMENT: 'appli_list_drug_department_file_source',
    SRC_APPLI_LICENSE_DRUG_DEPARTMENT: 'appli_license_drug_department_file_source',
    SRC_APPLI_NOC_CONTROL_BOARD: 'appli_noc_control_board_file_source',
    SRC_APPLI_PROPOSED_BLUEPRINT: 'appli_proposed_blueprint_file_source',
    SRC_APPLI_OWNERSHIP_DOCUMENT: 'appli_ownership_document_file_source',
    SRC_APPLI_UPLOAD_THE_BOUNDARIES: 'appli_upload_the_boundaries_file_source',
    SRC_APPLI_GOVTID: 'appli_govtid_source',

    SRC_APPLI_LEAD_DOC: 'land_document_file_source',
    SRC_MOA_COMPANY_DIRECTORS: 'moa_company_directors_file_source',
    SRC_FIRE_DEPT: 'fireDept_file_source',
    SRC_ETHANOL_STORAGE_LICENSE_PESO: 'ethanol_storage_license_peso_file_source',
    SRC_IEM_MINISTRY_INDUSTRIES_GOI: 'iem_ministry_industries_goi_file_source',    
    SRC_CENTRAL_POLLUTION_BOARD: 'central_pollution_board_file_source',
    SRC_POLLUTION_CONTROL_BOARD: 'pollution_control_board_file_source',
    SRC_ENVIRONMENT_FOREST_CLIMATE_CHANGE_MINISTRY: 'environment_forest_climate_change_ministry_file_source',
    SRC_MOLASSES_STORAGE: 'molasses_storage_file_source',
    SRC_PLANTS_MACHINERIES: 'plants_machineries_file_source',
    SRC_PRAVIDHIK_INFO: 'pravidhik_info_file_source',
    SRC_DISTILLERY_CAPACITY: 'distillery_capacity_file_source',
    SRC_DEPICTING_FLOW_DIAGRAM: 'depicting_flow_diagram_file_source',
    SRC_ALCOHOL_RECEIVER: 'alcohol_receiver_file_source',
    SRC_STORAGE_TANK: 'storage_tank_file_source',
    SRC_FERMENTATION_DISTILLATION_BLUEPRINT: 'fermentation_distillation_blueprint_file_source',
    SRC_DISTILLERY_UTILITY_BLUEPRINT: 'distillery_utility_blueprint_file_source',
    SRC_NOTARIZED_AFFIDAVIT: 'notarized_affidavit_file_source',
    SRC_NO_DUES_BY_COLLECTOR: 'no_dues_by_collector_file_source',
    SRC_BLENDING_TANK_DIMENSION: 'blending_tank_dimension_file_source',
    SRC_LIQUOR_SERVE: 'liquor_photo_file_source',
    SRC_AFFIDAVIT: 'affidavit_file_source',
    SRC_OTHER: 'other_file_source',

    SRC_GAUGE_CHART: 'gauge_chart_file_source',
    SRC_POLLUTION_NOC: 'pollution_noc_file_source',
    SRC_POLLUTION_NOC_CENTRAL: 'pollution_noc_central_file_source',
    SRC_POLLUTION_UP: 'pollution_noc_up_file_source',

    SRC_PESO_NOC: 'peso_noc_file_source',
    SRC_TECHNICAL_INSPECTION: 'technical_inspection_file_source',
    SRC_MOLASSES_STORAGE_TANK: 'molasses_storage_tank_file_source',
    SRC_PLANS_MACHINERIES: 'plans_machineries_file_source',
    SRC_ALCOHOL_RECEIVE_TANK: 'alcohol_receive_tank_file_source',
    SRC_STORAGE_TANK_DIMENSION: 'storage_tank_dimension_file_source',
    SRC_FERMENTATION_BLUEPRINT: 'fermentation_blueprint_file_source',
    SRC_LIQUOR_PRODUCTION: 'liquor_production_file_source',
    SRC_WEIGHBRIDGE_CERTIFICATE: 'weighbridge_certificate_file_source',

    SRC_DRAWING: 'drawing_file_source',
    SRC_BLUEPRINT_TANK: 'blueprint_tank_file_source',
    SRC_FLOW_DIAGRAM: 'flow_diagram_file_source',
    SRC_ELEVATION: 'elevation_file_source',

    FN_APPLI_LEAD_DOC: 'APPLI_LEAD_DOC',
    FN_MOA_COMPANY_DIRECTORS: 'MOA_COMPANY_DIRECTORS_DOC',
    FN_FIRE_DEPT: 'FIRE_DEPT_DOC',
    FN_ETHANOL_STORAGE_LICENSE_PESO: 'ETHANOL_STORAGE_LICENSE_PESO_DOC',
    FN_IEM_MINISTRY_INDUSTRIES_GOI: 'IEM_MINISTRY_INDUSTRIES_GOI_DOC',    
    FN_CENTRAL_POLLUTION_BOARD: 'CENTRAL_POLLUTION_BOARD_DOC',
    FN_POLLUTION_CONTROL_BOARD: 'POLLUTION_CONTROL_BOARD_DOC',
    FN_ENVIRONMENT_FOREST_CLIMATE_CHANGE_MINISTRY: 'ENVIRONMENT_FOREST_CLIMATE_CHANGE_MINISTRY_DOC',
    FN_MOLASSES_STORAGE: 'MOLASSES_STORAGE_DOC',
    FN_PLANTS_MACHINERIES: 'PLANTS_MACHINERIES_DOC',
    FN_PRAVIDHIK_INFO: 'PRAVIDHIK_INFO_DOC',
    FN_DISTILLERY_CAPACITY: 'DISTILLERY_CAPACITY_DOC',
    FN_DEPICTING_FLOW_DIAGRAM: 'DEPICTING_FLOW_DIAGRAM_DOC',
    FN_ALCOHOL_RECEIVER: 'ALCOHOL_RECEIVER_DOC',
    FN_STORAGE_TANK: 'STORAGE_TANK_DOC',
    FN_FERMENTATION_DISTILLATION_BLUEPRINT: 'FERMENTATION_DISTILLATION_BLUEPRINT_DOC',
    FN_DISTILLERY_UTILITY_BLUEPRINT: 'DISTILLERY_UTILITY_BLUEPRINT_DOC',
    FN_NOTARIZED_AFFIDAVIT: 'NOTARIZED_AFFIDAVIT_DOC',
    FN_NO_DUES_BY_COLLECTOR: 'NO_DUES_BY_COLLECTOR_DOC',
    FN_BLENDING_TANK_DIMENSION: 'BLENDING_TANK_DIMENSION_DOC',

    FN_GAUGE_CHART: 'GAUGE_CHART_DOC',
    FN_POLLUTION_NOC: 'POLLUTION_NOC_DOC',
    FN_POLLUTION_NOC_CENTRAL: 'POLLUTION_NOC_CENTRAL_DOC',
    FN_POLLUTION_NOC_UP: 'POLLUTION_NOC_UP_DOC',
    FN_PESO_NOC: 'PESO_NOC_DOC',
    FN_TECHNICAL_INSPECTION: 'TECHNICAL_INSPECTION_DOC',
    FN_MOLASSES_STORAGE_TANK: 'MOLASSES_STORAGE_TANK_DOC',
    FN_PLANS_MACHINERIES: 'PLANS_MACHINERIES_DOC',
    FN_ALCOHOL_RECEIVE_TANK: 'ALCOHOL_RECEIVE_TANK_DOC',
    FN_STORAGE_TANK_DIMENSION: 'STORAGE_TANK_DIMENSION_DOC',
    FN_FERMENTATION_BLUEPRINT: 'FERMENTATION_BLUEPRINT_DOC',
    FN_LIQUOR_PRODUCTION: 'LIQUOR_PRODUCTION_DOC',
    FN_WEIGHBRIDGE_CERTIFICATE: 'WEIGHBRIDGE_CERTIFICATE_DOC',

    FN_DRAWING: 'DRAWING_DOC',
    FN_BLUEPRINT_TANK: 'BLUEPRINT_TANK_DOC',
    FN_FLOW_DIAGRAM: 'FLOW_DIAGRAM_DOC',
    FN_ELEVATION: 'ELEVATION_DOC',

    // Address Document identifiers for all licenses
    FN_ADDR_BOUNDRY: 'ADDRESS_BOUNDRIES_DOC',
    FN_ADDR_OWNERSHIIP_DOC: 'ADDRESS_OWNERSHIP_DOC',
    FN_OWNERSHIP_DOC: 'ownershipDoc_file_source',

    SRC_ADDR_OWNERSHIIP_DOC: 'ownershipDoc_file_source',
    SRC_ADDR_BOUNDRY: 'boundries_file_source',

    // Bank Document identifiers for all licenses
    FN_BK_CAN_CHEQUE: 'BANK_CANCEL_CHEQUE_DOC',
    SRC_BK_CAN_CHEQUE: 'cancel_cheque_file_source',

    // Attachment Document identifiers for all licenses
    FN_ATTACH_APPLI_PHOTO: 'ATTACHMENT_PHOTO_DOC',
    FN_ATTACH_APPLI_PAN: 'ATTACHMENT_PAN_DOC',
    FN_ATTACH_APPLI_ITR: 'ATTACHMENT_ITR_DOC',
    FN_ATTACH_OWNERSHIIP_DOC: 'ATTACHMENT_OWNERSHIP_DOC',
    FN_ATTACH_OWN_RENT_LEASE_DOC: 'ATTACHMENT_OWN_RENT_LEASE_DOC',
    FN_ATTACH_Tieup_LEASE_DOC: 'ATTACHMENT_Tieup_LEASE_DOC',
    FN_ATTACH_APPLI_AUTH_LETTER: 'ATTACHMENT_AUTHORIZATION_LETTER_OF_APPLICANT_DOC',
    FN_ATTACH_OTHERS: 'ATTACHMENT_OTHER_DOC',
    FN_ATTACH_LAND_AGREEMENT: "ATTACHMENT_LAND_AGREEMENT_DOC",
    FN_ATTACH_NOC_FROM_UPPCB: "ATTACHMENT_NOC_FROM_UPPCB",
    FN_ATTACH_NOC_FROM_FIRE_SAFETY_DEPARTMENT: "ATTACHMENT_NOC_FROM_FIRE_SAFETY_DEPARTMENT_DOC",
    FN_ATTACH_NOC_FROM_FSSAI: "ATTACHMENT_NOC_FROM_FSSAI",
    FN_ATTACH_SOLVENCYCERT: 'ATTACHMENT_SOLVENCY_DOC',
    FN_ATTACH_AFFADAVAIT: 'ATTACHMENT_AFFADAVAIT',
    FN_ATTACH_BOUNDRY: 'ATTACHMENT_BOUNDRIES_DOC',
    FN_ATTACH_LISTPRESIDENTANDBEARER:'ATTACHMENT_LISTPRESIDENTANDBEARER_DOC',
    FN_ATTACH_COPYRULESMEMB:'ATTACHMENT_COPYRULESMEMB_DOC',
    FN_ATTACH_NOCDM:'ATTACHMENT_NOCDM_DOC',
    FN_ATTACH_GSTDOC:'ATTACHMENT_GST_DOC',
    FN_ATTACH_BLUEPRINT:'ATTACHMENT_BLUEPRINT_DOC',
    FN_ATTACH_BLUEPRINT_21:'ATTACHMENT_BLUEPRINT_DOC_21',
    FN_ATTACH_LAY_PLAN: 'ATTACHMENT_LAYOUT_PLAN_DOC',
    FN_ATTACH_CERT_OF_INCORP: 'ATTACHMENT_CERTIFICATION_OF_INCORPORATION_DOC',
    FN_ATTACH_MOM_OF_ASSO: 'ATTACHMENT_MEMORANDUM_OF_ASSOCIATION_DOC',
    FN_ATTACH_ART_OF_ASSOC: 'ATTACHMENT_ARTICLE_OF_ASSOCIATION',
    FN_ATTACH_RESU_OF_AUTH: 'ATTACHMENT_RESOLUTION_OF_AUTHORIZATION',
    FN_ATTACH_TAN: 'ATTACHMENT_TAN_DOC',
    FN_ATTACH_CERT_OF_FIRM: 'ATTACHMENT_REGISTRATION_CERTIFICATE_OF_FIRM_DOC',
    FN_ATTACH_PARTNERSHIPDEED: 'ATTACHMENT_PARTNERSHIP_DEED_DOC',
    FN_ATTACH_LISTOFDIRECTORS: 'ATTACHMENT_LIST_OF_DIRECTORS_AND_PARTNERS_DOC',
    FN_ATTACH_TRADELICENCE: 'ATTACHMENT_COPY_OF_TRADE_LICENCE_DOC',
    FN_ATTACH_FIRESAFTEYNOC: 'ATTACHMENT_FIRE_SAFTEY_DOC',
    FN_ATTACH_FIRESAFTEYNOC_21: 'ATTACHMENT_FIRE_SAFTEY_DOC_21',
    FN_ATTACH_FSSAI_CERT: 'ATTACHMENT_FSSAI_CERTIFICATE_DOC',
    FN_ATTACH_CERT_OF_PREMISESUTILITY: 'ATTACHMENT_CERTIFICATION_OF_PREMISES_UTILITY_DOC',
    FN_ATTACH_LAWANDORDER: 'ATTACHMENT_LAW_AND_ORDER_DOC',
    FN_ATTACH_LISTOFPRESIDENT: 'ATTACHMENT_LIST_OFPRESIDENT_AND_OFFICE_BEARERS_DOC',
    FN_ATTACH_RULESOFMEMBERSHIP: 'ATTACHMENT_COPY_OF_RULES_OF_MEMBERSHIP_DOC',
    FN_ATTACH_PROOFOFPARKINGPLACE: 'ATTACHMENT_PROOF_OF_PARKING_DOC',
    FN_ATTACH_GSTIN: 'ATTACHMENT_GSTIN_DOC',
    FN_VENDOR_GSTIN: 'GSTIN_DOC',
    FN_ATTACH_LAYOUT: 'ATTACHMENT_LAYOUT_DOC',
    FN_ATTACH_NOC_OF_PREMISES: 'ATTACHMENT_NOC_OF_PREMISES_DOC',
    FN_ATTACH_CERT_OF_POLLUT_CNTRL: 'ATTACHMENT_CERTIFICATE_OF_POLLUTION_CONTROL_DOC',
    FN_ATTACH_LTTRFRMEMBASSY: 'ATTACHMENT_ITR_FROM_EMBASSY_DOC',
    FN_ATTACH_IMPORTEXPORTCRT: 'ATTACHMENT_IMPORT_EXPORT_CERTIFICATE_DOC',
    FN_ATTACH_CONSTENT_LETTER_OF_IMPORTER: 'ATTACHMENT_CONSTENT_LETTER_OF_IMPORTER_DOC',
    FN_ATTACH_ATTACHMENT_PLANT_MACHINERY_DETAILS_DOC: 'ATTACHMENT_PLANT_MACHINERY_DETAILS_DOC',
    FN_ATTACH_RAW_MATERIAL_DETAILS_DOC: 'ATTACHMENT_RAW_MATERIAL_DETAILS_DOC',
    FN_ATTACH_CAPACITY_OF_THE_PLANT_PER_DAY_DOC: 'ATTACHMENT_CAPACITY_OF_THE_PLANT_PER_DAY_DOC',
    FN_ATTACH_BLUEPRINTS_LAYOUT_OF_MICRO_BREWERY_DOC: 'ATTACHMENT_BLUEPRINTS_LAYOUT_OF_MICRO_BREWERY_DOC',
    FN_ATTACH_DECLARATION_IN_FORM_MB3_DOC: 'ATTACHMENT_DECLARATION_IN_FORM_MB3_DOC',
    FN_ATTACH_NO_DUES_DOC: 'ATTACHMENT_NO_DUES_DOC',
    FN_ATTACH_NON_JUDICIAL_STAMP_OF_UNDERTAKING_DOC: 'ATTACHMENT_NON_JUDICIAL_STAMP_OF_UNDERTAKING_DOC',
    FN_ATTACH_EXISTING_BAR_LICENSE_DOC: 'ATTACHMENT_EXISTING_BAR_LICENSE_DOC',
    FN_ATTACH_CHARACTER_CERTIFICATE_DOC: 'ATTACHMENT_CHARACTER_CERTIFICATE_DOC',
    FN_ATTACH_DENAULT_STORAGE_DOC: 'ATTACHMENT_DENAULT_STORAGE_DOC',
    FN_ATTACH_INTIMATION_LETTER_FOR_INSPECTION_DOC: 'ATTACHMENT_INTIMATION_LETTER_FOR_INSPECTION_DOC',
    FN_ATTACH_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_DOC: 'ATTACHMENT_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_DOC',
    FN_ATTACH_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_UP_DOC: 'ATTACHMENT_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_UP_DOC',
    FN_ATTACH_NOC_FROM_MINISTRY_OF_ENVIRONMENT_AND_CLIMATE_CHANGE_DOC:'ATTACHMENT_NOC_FROM_MINISTRY_OF_ENVIRONMENT_AND_CLIMATE_CHANGE_DOC',
    FN_ATTACH_NO_DUES_CERTIFICATE_BY_DM_OFFICE_DOC: 'ATTACHMENT_NO_DUES_CERTIFICATE_BY_DM_OFFICE_DOC',
    FN_ATTACH_MINISTRY_OF_INDUSTRIES_REGISTRATION_CERTIFICATE_DOC: 'ATTACHMENT_MINISTRY_OF_INDUSTRIES_REGISTRATION_CERTIFICATE_DOC',

    FN_ATTACH_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_DOC_21: 'ATTACHMENT_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_DOC_21',
    FN_ATTACH_NOC_FROM_LOCAL_WATER_UTILITY_DOC: 'ATTACHMENT_NOC_FROM_LOCAL_WATER_UTILITY_DOC',
    FN_ATTACH_FSSAI_CERTIFICATE_DOC: 'ATTACHMENT_FSSAI_CERTIFICATE_DOC',
    FN_ATTACH_FURTHER_PLANS_ESTIMATES_INFORMATION_TO_BE_FURNISHED_DOC: 'ATTACHMENT_FURTHER_PLANS_ESTIMATES_INFORMATION_TO_BE_FURNISHED_DOC',
    FN_ATTACH_NOC_MILITARY: 'FN_ATTACH_NOC_MILITARY',
    FN_ATTACH_PREMISESLAYOUT: 'ATTACHMENT_PREMISES_LAYOUT',
    FN_ATTACH_CHAUHADDI: 'ATTACHMENT_CHAUHADDI',
    FN_ATTACH_APPLICATION_ADDRESS_DM: 'ATTACHMENT_APPLICATION_ADDRESS_DM',
    FN_ATTACH_SITE_REPORT: 'ATTACHMENT_SITE_REPORT',
    FN_ATTACH_FASSAI_CERTIFICATE: 'ATTACHMENT_FASSAI_CERTIFICATE',
    FN_ATTACH_PREVIOUS_PERMIT: 'ATTACHMENT_PREVIOUS_PERMIT',
    FN_ATTACH_PREVIOUS_PASS_PD25: 'ATTACHMENT_PREVIOUS_PASS_PD25',
    FN_ATTACH_STOCK_REGISTER: 'ATTACHMENT_STOCK_REGISTER',
    FN_ATTACH_COMPETENT_AUTHORITY: 'ATTACHMENT_COMPETENT_AUTHORITY',
    FN_ATTACH_NO_DUES_CERTIFICATE: 'ATTACH_NO_DUES_CERTIFICATE',
    FN_ATTACH_CHARACTER_CERTIFICATE: 'ATTACH_CHARACTER_CERTIFICATE',
    FN_ATTACH_DEVELOP_LOCAL_AUTHORITY: 'ATTACH_DEVELOP_LOCAL_AUTHORITY',
    FN_ATTACH_NDCL_LICENSE_DOC: 'ATTACH_NDCL_LICENSE_DOC',
    FN_ATTACH_PREVIOUSLY_IMPORTED_MEDICINES_DOC: 'ATTACH_PREVIOUSLY_IMPORTED_MEDICINES_DOC',
    FN_ATTACH_CONCERNED_EXCISE_INSPECTOR_DOC: 'ATTACH_CONCERNED_EXCISE_INSPECTOR_DOC',
    FN_ATTACH_DEMAND_LETTER_OF_DRUGS_DOC: 'ATTACH_DEMAND_LETTER_OF_DRUGS_DOC',
    FN_ATTACH_MEDICINE_WISE_DETAIL_WITH_NAME_QUANTITY_DOC: 'ATTACH_MEDICINE_WISE_DETAIL_WITH_NAME_QUANTITY_DOC',
    FN_ATTACH_COPY_OF_DRUG_LICENSE: 'ATTACH_COPY_OF_DRUG_LICENSE_DOC',
    FN_ATTACH_BLUE_PRINT_OF_PREMISE: 'ATTACH_BLUE_PRINT_OF_PREMISE_DOC',
    FN_ATTACH_APPLICATION_ON_LETTER_HEAD: 'ATTACH_APPLICATION_ON_LETTER_HEAD_DOC',
    FN_ATTACH_BOUNDARY_DETAILS: 'ATTACH_BOUNDARY_DETAILS_DOC',

    FN_ATTACH_DEVELOP_AUTHORITY_CERTIFICATE: 'ATTACH_DEVELOP_AUTHORITY_CERTIFICATE',
    FN_ATTACH_BOUNDARIES: 'ATTACHMENT_BOUNDARIES',
    FN_ATTACH_COPY_OF_BLUEPRINT_OF_PLAN: 'ATTACHMENT_COPY_OF_BLUEPRINT_OF_PLAN_DOC',
    FN_ATTACH_ATTACHMENT_BLUEPRINT_OF_PLAN_ELEVATION: 'ATTACHMENT_BLUEPRINT_OF_PLAN_ELEVATION_DOC',
    FN_ATTACH_COPY_OF_BLUEPRINT_OF_PLAN_21: 'ATTACHMENT_COPY_OF_BLUEPRINT_OF_PLAN_DOC_21',
    FN_ATTACH_COPY_OF_MOA_OF_THE_COMPANY: 'ATTACHMENT_COPY_OF_MOA_OF_THE_COMPANY_DOC',
    FN_ATTACH_DETAILED_DOCUMENT_OF_PLANTS_AND_MACHINERIES: 'ATTACHMENT_DETAILED_DOCUMENT_OF_PLANTS_AND_MACHINERIES_DOC',
    FN_ATTACH_DETAILED_DOCUMENT_OF_PLANTS_AND_MACHINERIES_21: 'ATTACHMENT_DETAILED_DOCUMENT_OF_PLANTS_AND_MACHINERIES_DOC_21',
    FN_ATTACH_DOCUMENT_DEPICTING_FLOW_DIAGRAM: 'ATTACHMENT_DOCUMENT_DEPICTING_FLOW_DIAGRAM_DOC',
    FN_ATTACH_DOCUMENT_DEPICTING_FLOW_DIAGRAM_21: 'ATTACHMENT_DOCUMENT_DEPICTING_FLOW_DIAGRAM_DOC_21',
    FN_ATTACH_DOCUMENT_DETAILING_THE_LAND_WHERE_PLANT_INSTALLED: 'ATTACHMENT_DOCUMENT_DETAILING_THE_LAND_WHERE_PLANT_INSTALLED_DOC',
    FN_ATTACH_DOCUMENT_DETAILING_THE_LAND_WHERE_PLANT_INSTALLED_21: 'ATTACHMENT_DOCUMENT_DETAILING_THE_LAND_WHERE_PLANT_INSTALLED_DOC_21',
    FN_ATTACH_DOCUMENT_DETAILING_UTILITY_OF_BREWERY_WITH_BLUE_PRINT : 'ATTACHMENT_DOCUMENT_DETAILING_UTILITY_OF_BREWERY_WITH_BLUE_PRINT_DOC',
    FN_ATTACH_DOCUMENT_DETAILING_UTILITY_OF_BREWERY_WITH_BLUE_PRINT_21 : 'ATTACHMENT_DOCUMENT_DETAILING_UTILITY_OF_BREWERY_WITH_BLUE_PRINT_DOC_21',
    FN_ATTACH_DOCUMENT_FOR_CALCULATION_OF_CAPACITY_OF_BREWERY : 'ATTACHMENT_DOCUMENT_FOR_CALCULATION_OF_CAPACITY_OF_BREWERY_DOC',
    FN_ATTACH_DOCUMENT_FOR_CALCULATION_OF_CAPACITY_OF_BREWERY_21 : 'ATTACHMENT_DOCUMENT_FOR_CALCULATION_OF_CAPACITY_OF_BREWERY_DOC_21',
    FN_ATTACH_NOTARIZED_AFFIDAVIT: 'ATTACHMENT_NOTARIZED_AFFIDAVIT_DOC',
    FN_ATTACH_NOTARIZED_AFFIDAVIT_21: 'ATTACHMENT_NOTARIZED_AFFIDAVIT_DOC_21',
    FN_ATTACH_APPLICATION_FOR_SITE_INSPECTION: 'ATTACHMENT_APPLICATION_FOR_SITE_INSPECTION_DOC',
    FN_ATTACH_ATTACHMENT_NOC_FROM_LOCAL_AUTH: 'ATTACHMENT_NOC_FROM_LOCAL_AUTH_DOC',
    FN_ATTACH_ATTACHMENT_FASSAI_CERTIFICATE : "ATTACHMENT_FASSAI_CERTIFICATE_DOC",
    FN_ATTACH_ATTACHMENT_DOCUMENT_DOC : "ATTACHMENT_DOCUMENT_DOC",
    FN_ATTACH_TRANSFER_ORDER: "ATTACHMENT_TRANSFER_ORDER",
    FN_FULL_NAME: 'FULLNAME_DOC',
    FN_ATTACH_EMPLOYEES_LIST: 'ATTACHMENT_EMPLOYEE_LIST',
    FN_UPLOAD_PERMIT: 'UPLOAD_PERMIT_DOC',
	FN_DRUG_MANUFACTURING_LICENSE: 'DRUG_MANUFACTURING_LICENSE',
	FN_UPLOAD_PERMIT_OR_NOC: 'UPLOAD_PERMIT_OR_NOC',
	FN_DEMAND_LETTER_BY_AUTHORITY: 'UPLOAD_DEMAND_LETTER_BY_AUTHORITY',
	FN_DRUG_DEMAND_FROM_IMPORTING_UNIT: 'UPLOAD_DRUG_DEMAND_FROM_IMPORTING_UNIT',
    FN_ATTACH_AFFADIVIT_DOC: 'ATTACHMENT_AFFADIVIT_DOC',
    FN_ATTACH_APPROVAL_ORDER_CSD: 'ATTACHMENT_APPROVAL_ORDER_CSD',
    FN_ATTACH_MANUFACTURE_UNIT: 'ATTACHMENT_MANUFACTURE_UNIT',
    FN_ATTACH_NO_DUES: 'ATTACHMENT_NO_DUES_DOC',
    FN_ATTACH_AFFIDAVIT_TO_BE_UPLOADED_BY_THE_USER: 'ATTACHMENT_AFFIDAVIT_TO_BE_UPLOADED_BY_THE_USER_DOC',
    FN_ATTACH_SELF_ATTESTED_AFFIDAVIT_TO_BE_UPLOADED_BY_THE_USER: 'ATTACHMENT_SELF_ATTESTED_AFFIDAVIT_TO_BE_UPLOADED_BY_THE_USER_DOC',
    FN_ATTACH_CUSTOM_BOND_CERTIFICATE: 'ATTACHMENT_CUSTOM_BOND_CERTIFICATE',
    FN_ATTACH_CUSTOM_BOND_AGREEMENT: 'ATTACHMENT_CUSTOM_BOND_AGREEMENT',

    SRC_MINISTRY_OF_INDUSTRIES_REGISTRATION_CERTIFICATE: 'ministry_of_industries_registration_certificate_application_file_source',
    SRC_NO_DUES_CERTIFICATE_BY_DM_OFFICE_FILE:'no_dues_certificate_by_dm_office_file_source',
    SRC_NOC_FROM_MINISTRY_OF_ENVIRONMENT_AND_CLIMATE_CHANGE:'climate_Change_file_source',
    SRC_ATTACH_MANUFACTURE_UNIT: 'manufacturing_unit_file_source',
    SRC_ATTACH_APPROVAL_ORDER_CSD: 'approval_order_csd_file_source',
    SRC_FULL_NAME: 'fullname_file_source',   
    SRC_ATTACH_PREVIOUS_PERMIT: 'previous_permit_source',
    SRC_ATTACH_PREVIOUS_PASS_PD25: 'previous_pass_pd25_source',
    SRC_ATTACH_STOCK_REGISTER: 'stock_register_source',
    SRC_ATTACH_COMPETENT_AUTHORITY: 'competent_authority_source',
    SRC_ATTACH_CHARACTER_CERTIFICATE: 'character_file_source',
    SRC_ATTACH_DENAULT_STORAGE: 'denaturent_storage_room_map_source',
    SRC_ATTACH_NO_DUES_CERTIFICATE: 'noDues_file_source',
    SRC_ATTACH_OWNERSHIP_AUTH_LETTER: 'ownership_file_source',
    SRC_ATTACH_APPLI_PHOTO: 'appli_photo_file_source',
    SRC_ATTACH_APPLI_PAN: 'appli_pan_file_source',
    SRC_ATTACH_APPLI_ITR: 'appli_itr_file_source',
    SRC_ATTACH_APPLI_GSTIN: 'appli_gstin_file_source',
    SRC_ATTACH_OWNERSHIIP_DOC: 'appli_ownershipDoc_file_source',
    SRC_ATTACH_SOLVENCY_CERTIFICATE_OF_PROPERTY_DOC: 'solvency_certificate_of_propertyDoc_file_source',
    SRC_ATTACH_OWN_RENT_LEASE_DOC: 'ownRentLeaseDoc_file_source',
    SRC_ATTACH_APPLI_AUTH_LETTER: 'appliAuthLetter_file_source',
    SRC_ATTACH_OTHERS: 'other_file_source',
    SRC_ATTACH_LAND_AGREEMENT: "land_agreement_file_source",
    SRC_ATTACH_NOC_FROM_UPPCB: "noc_from_uppcb_file_source",
    SRC_ATTACH_NOC_FROM_FIRE_SAFETY_DEPARTMENT: "noc_from_fire_safety_department_file_source",
    SRC_ATTACH_NOC_FROM_FSSAI: "noc_from_fssai_file_source",
    SRC_ATTACH_SOLVENCYCERT: 'solvencyCertByAuth_file_source',
    SRC_ATTACH_AFFADAVAIT: 'affadavait_file_source',
    SRC_ATTACH_LISTPRESIDENTANDBEARER: 'listPresidentAndBearer_file_source',
    SRC_ATTACH_COPYRULESMEMB:'copyofRulesofMembers_file_source',
    SRC_ATTACH_BOUNDRY: 'boundries_file_source',
    SRC_ATTACH_BOUNDARY_DETAILS: 'boundary_details_file_source',
    SRC_ATTACH_BLUEPRINT:'bluePrint_file_source',
    SRC_ATTACH_GSTDOC:'gstDocument_file_source',
    SRC_ATTACH_LAY_PLAN: 'layoutPlan_file_source',
    SRC_ATTACH_CERT_OF_INCORP: 'certificationOfIncorporation_file_source',
    SRC_ATTACH_MOM_OF_ASSO: 'memorandumOfAssociation_file_source',
    SRC_ATTACH_ART_OF_ASSOC: 'articleOfAssociation_file_source',
    SRC_ATTACH_NOCDM:'nocOfPremisesByDM_file_source',
    SRC_ATTACH_RESU_OF_AUTH: 'resolutionOfAuthorization_file_source',
    SRC_ATTACH_TAN: 'TAN_file_source',
    SRC_ATTACH_CERT_OF_FIRM: 'certificateOfFirm_file_source',
    SRC_ATTACH_PARTNERSHIPDEED: 'partnershipDeed_file_source',
    SRC_ATTACH_LISTOFDIRECTORS: 'listOfDirectors_file_source',
    SRC_ATTACH_TRADELICENCE: 'tradeLicence_file_source',
    SRC_ATTACH_FIRESAFTEYNOC: 'fireSafteyNoc_file_source',
    SRC_ATTACH_FSSAI_CERT: 'fssaiCertificate_file_source',
    SRC_ATTACH_CERT_OF_PREMISESUTILITY: 'certificationOfPremisesUtility_file_source',
    SRC_ATTACH_LAWANDORDER: 'lawAndOrder_file_source',
    SRC_ATTACH_LISTOFPRESIDENT: 'listOfPresident_file_source',
    SRC_ATTACH_RULESOFMEMBERSHIP: 'rulesOfMembership_file_source',
    SRC_ATTACH_PROOFOFPARKINGPLACE: 'proofOfParkingPlace_file_source',
    SRC_ATTACH_GSTIN: 'uploadGstin_file_source',
    SRC_ATTACH_NOC_OF_PREMISES: 'nocOfPremises_file_source',
    SRC_ATTACH_CERT_OF_POLLUT_CNTRL: 'certOfPollutionControl_file_source',
    SRC_ATTACH_LTTRFRMEMASSY: 'itr_from_embassy_file_source',
    SRC_ATTACH_IMPORTEXPORTCRT: 'import_export_cert_file_source',
    SRC_ATTACH_NOC_MILITARY: 'noc_file_source',
    SRC_ATTACH_PREMISESLAYOUT: 'premises_layout_file_source',
    SRC_ATTACH_CHAUHADDI: 'chauhaddi_file_source',
    SRC_ATTACH_APPLICATION_ADDRESS_DM: 'application_address_dm_file_source',
    SRC_ATTACH_SITE_REPORT: 'site_report_file_source',
    SRC_ATTACH_TRANSFER_ORDER: 'transfer_order_file_source',
    SRC_ATTACH_DEVELOP_LOCAL_AUTHORITY: 'develop_local_authority_file_source',
    SRC_ATTACH_EMPLOYEES_LIST: 'employees_list_file_source',
    SRC_ATTACH_NO_DUES: 'no_dues_file_source',
    SRC_ATTACH_AFFIDAVIT_TO_BE_UPLOADED_BY_THE_USER: 'affidavit_to_be_uploaded_by_the_user_file_source',
    SRC_ATTACH_SELF_ATTESTED_AFFIDAVIT_TO_BE_UPLOADED_BY_THE_USER: 'self_attested_affidavit_to_be_uploaded_by_the_user_file_source',
    SRC_ATTACH_NDCL_LICENSE_DOC: 'ndcl_license_file_source',
    SRC_ATTACH_PREVIOUSLY_IMPORTED_MEDICINES_DOC: 'previously_imported_medicine_file_source',
    SRC_ATTACH_CONCERNED_EXCISE_INSPECTOR_DOC: 'concerned_excise_inspector_file_source',
    SRC_ATTACH_DEMAND_LETTER_OF_DRUGS_DOC: 'demand_letter_of_drugs_file_source',
    SRC_ATTACH_MEDICINE_WISE_DETAIL_WITH_NAME_QUANTITY_DOC: 'medicine_wise_detail_with_name_quantity_file_source',
    SRC_ATTACH_COPY_OF_DRUG_LICENSE_DOC: 'copy_of_drug_license_file_source',
    SRC_ATTACH_BLUE_PRINT_OF_PREMISE: 'blue_print_of_premise_file_source',
    SRC_ATTACH_APPLICATION_ON_LETTER_HEAD: 'application_on_letter_head_file_source',

    SRC_UPLOAD_PERMIT: 'appli_upload_permit_file_source',
	SRC_DRUG_MANUFACTURING_LICENSE: 'ndlc_license_file_source',
	SRC_UPLOAD_PERMIT_OR_NOC: 'concerned_excise_inspector_file_source',
	SRC_DEMAND_LETTER_BY_AUTHORITY: 'demand_letter_of_drugs_file_source',
	SRC_DRUG_DEMAND_FROM_IMPORTING_UNIT: 'medicine_wise_detail_with_name_quantity_file_source',
    SRC_ATTACH_DEVELOP_AUTHORITY_CERTIFICATE: 'develop_authority_certificate_file_source',
    SRC_ATTACH_ATTACHMENT_DOCUMENT_DOC: "document_file_source",
    SRC_ATTACH_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_DOC: 'noc_from_up_pollution_control_board_doc_file_source',
    SRC_ATTACH_FIRE_SAFTEY_DOC: 'fire_safety_doc_file_source',
    SRC_ATTACH_BLUEPRINT_DOC: 'blueprint_doc_file_source',
    SRC_ATTACH_COPY_OF_BLUEPRINT_OF_PLAN_DOC: 'blueprint_plan_doc_file_source',
    SRC_ATTACH_COPY_OF_MOA_OF_THE_COMPANY_DOC: 'moa_company_doc_file_source',
    SRC_ATTACH_DETAILED_DOCUMENT_OF_PLANTS_AND_MACHINERIES_DOC: 'plants_machiniries_doc_file_source',
    SRC_ATTACH_DOCUMENT_DEPICTING_FLOW_DIAGRAM_DOC: 'depicting_flow_diagram_doc_file_source',
    SRC_ATTACH_DOCUMENT_DETAILING_THE_LAND_WHERE_PLANT_INSTALLED_DOC: 'land_plant_installed_doc_file_source',
    SRC_ATTACH_DOCUMENT_DETAILING_UTILITY_OF_BREWERY_WITH_BLUE_PRINT_DOC: 'brewery_blue_print_doc_file_source',
    SRC_ATTACH_DOCUMENT_FOR_CALCULATION_OF_CAPACITY_OF_BREWERY_DOC: 'capacity_of_brewery_doc_file_source',
    SRC_ATTACHMENT_NOTARIZED_AFFIDAVIT_DOC: 'notarized_affadivit_doc_file_source',
    SRC_ATTACH_SITE_INSPECTION_DOC: 'application_for_siteinspection_doc_file_source',
    SRC_ATTACH_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_B21_DOC: 'noc_from_up_pollution_control_doc_file_source',
    SRC_ATTACH_FIRE_SAFTEY_NOC_DOC: 'fire_safety_noc_doc_file_source',
    SRC_ATTACH_BLUEPRINT_21_DOC: 'blueprint_21_doc_file_source',
    SRC_ATTACH_COPY_OF_BLUEPRINT_OF_PLAN_21_DOC: 'copy_of_blueprint_plan_doc_file_source',
    SRC_ATTACH_DETAILED_DOCUMENT_OF_PLANTS_AND_MACHINERIES_21_DOC: 'detailed_plants_machiniries_doc_file_source',
    SRC_ATTACH_DOCUMENT_DEPICTING_FLOW_DIAGRAM_21_DOC: 'document_depicting_flow_diagram_doc_file_source',
    SRC_ATTACH_DOCUMENT_DETAILING_THE_LAND_WHERE_PLANT_INSTALLED_21_DOC: 'detail_land_plant_installed_doc_file_source',
    SRC_ATTACH_DOCUMENT_DETAILING_UTILITY_OF_BREWERY_WITH_BLUE_PRINT_21_DOC: 'detail_brewery_blue_print_doc_file_source',
    SRC_ATTACH_DOCUMENT_FOR_CALCULATION_OF_CAPACITY_OF_BREWERY_21_DOC: 'detail_capacity_of_brewery_doc_file_source',
    SRC_ATTACHMENT_NOTARIZED_AFFIDAVIT_21_DOC: 'detail_notarized_affadivit_doc_file_source',
    SRC_ATTACH_CUSTOM_BOND_CERTIFICATE: 'custom_bond_certificate_file_source',
    SRC_ATTACH_CUSTOM_BOND_AGREEMENT: 'custom_bond_agreement_file_source',

    // Bussiness Unit Document identifiers for all licenses
    FN_BUSSUNIT_COMP_PAN: 'BUSSINESS_UNIT_COMPANY_OR_FIRM_PAN_DOC',
    FN_BUSSUNIT_ITR_LASTYR: 'BUSSINESS_UNIT_LAST_YEAR_ITR_DOC',
    FN_BUSSUNIT_CERT_INCORP: 'BUSSINESS_UNIT_CERTIFICATION_OF_INCORPORATION',
    FN_BUSSUNIT_MOM_OF_ARTICLE: 'BUSSINESS_UNIT_MEMORANDUM_OF_ARTICLE_DOC',
    FN_BUSSUNIT_ART_OF_ASSOC: 'BUSSINESS_UNIT_ARTICLE_OF_ASSOCIATION_DOC',
    FN_BUSSUNIT_RESU_OF_AUTH: 'BUSSINESS_UNIT_RESOLUTION_OF_AUTHORIZATION_DOC',
    FN_BUSSUNIT_OWNERSHIIP_DOC: 'BUSSINESS_UNIT_OWNERSHIP_DOC',
    FN_BUSSUNIT_UNIT_LICENSE: 'BUSSINESS_UNIT_LICENSE_DOC',
    FN_BUSSUNIT_AFFADAVAIT: 'BUSSINESS_UNIT_AFFADAVAIT_DOC',
    FN_BUSSUNIT_GSTIN: 'BUSSINESS_UNIT_GSTIN_DOC',
    FN_BUSSUNIT_ANNUAL_TURNOVER_CERT: 'BUSSINESS_UNIT_CERTIFICATE_OFANNUAL_TURNOVER_DOC',
    FN_BUSSUNIT_CERT_OF_OTHER_STATE_OFFICIAL: 'BUSSINESS_UNIT_CERTIFICATE_OF_OTHER_STATE_EXCISE_OFFICIAL_DOC',
    FN_BUSSUNIT_DECLARATION: 'BUSSINESS_UNIT_DECLARATION_DOC',
    FN_BUSSUNIT_BOND_DOC: 'BUSSINESS_UNIT_BWFL_BOND_DOC',
    FN_BUSSUNIT_POWER_OF_ATTORNEY: 'BUSSINESS_UNIT_POWER_OF_ATTORNEY_DOC',
    FN_BUSSUNIT_PHOTO_OF_PREMISES: 'BUSSINESS_UNIT_PHOTO_OF_GODOWN_OR_PREMISES_DOC',
    FN_BUSSUNIT_OWN_RENT_LEASE_DOCUMENT: 'BUSSINESS_UNIT_OWN_RENT_LEASE_DOC',
    FN_BUSSUNIT_ANNUAL_PRODUCTION_CAPACITY_CERT: 'BUSSINESS_UNIT_CERTIFICATE_OFANNUAL_PRODUCTION_CAPACITY_DOC',
    FN_BUSSUNIT_BOUNDRY: 'BUSSUNIT_BOUNDRIES_DOC',
    FN_BWFL_1: 'BWFL1_DOC',
    FN_BWFL_3: 'BWFL3_DOC',
    FN_AFFIDAVIT: 'AFFIDAVIT_DOC',
    FN_OTHER: 'OTHER_DOC',
    FN_BUSSUNIT_ITR: 'BUSSUNIT_ITR_DOC',
    FN_BUSSUNIT_FINAL_PRODUCT: 'BUSSUNIT_FINAL_PRODUCT',

    FN_FINISHED_PRODUCT_WILL_CONTAIN_ALCOHOL: 'FN_FINISHED_PRODUCT_WILL_CONTAIN_ALCOHOL',
    FN_ARE_THEY_MANUFACTURED_ACCORDING_TO_ISI_SPECIFICATIONS: 'FN_ARE_THEY_MANUFACTURED_ACCORDING_TO_ISI_SPECIFICATIONS',
    FN_COPY_OF_RELEVANT_EXTRACT_MAY_BE_ATTACHED: 'FN_COPY_OF_RELEVANT_EXTRACT_MAY_BE_ATTACHED',
    FN_DETAIL_OF_DENATURANT: 'FN_DETAIL_OF_DENATURANT',
    FN_PERCENTAGE_OF_ALCOHOL_RECOVERED: 'FN_PERCENTAGE_OF_ALCOHOL_RECOVERED',
    FN_NAME_OF_THE_NATURAL_RESINS_HANDLES:'FN_NAME_OF_THE_NATURAL_RESINS_HANDLES',
    FN_SALES_TAX_FIGURES_OF_LAST_YEAR:'FN_SALES_TAX_FIGURES_OF_LAST_YEAR',
    FN_INCOME_TAX_FIGURES_OF_LAST_YEAR:'FN_INCOME_TAX_FIGURES_OF_LAST_YEAR',
    FN_LIST_OF_TECHNICAL_STAFF_QUALIFICATION: 'FN_LIST_OF_TECHNICAL_STAFF_QUALIFICATION',
    FN_DO_YOU_HAVE_EXPLOSIVE_ACT_LICENSE: 'ATTACHMENT_EXPLOSIVE_ACT_LICENSE_DOC',
    FN_DO_YOU_HAVE_POISON_ACT_LICENSE: 'ATTACHMENT_POISON_ACT_LICENSE_DOC',
    FN_NO_DUES_CERTIFICATE_FROM_DM:'FN_NO_DUES_CERTIFICATE_FROM_DM',
    FN_LIST_OF_RAW_MATERIAL_OTHERS_THAN_ALCOHOL:'FN_LIST_OF_RAW_MATERIAL_OTHERS_THAN_ALCOHOL',
    FN_FLOWCHART_OF_PLANT_MACHINERY:'FN_FLOWCHART_OF_PLANT_MACHINERY',
    FN_NO_OF_GATES_IN_CHAUHADDI:'FN_NO_OF_GATES_IN_CHAUHADDI',


    FN_BUSSUNIT_SPIRIT_STORAGE: 'BUSSUNIT_SPIRIT_STORAGE',
    FN_BUSSUNIT_PLANT_MACHINERY: 'BUSSUNIT_PLANT_MACHINERY',
    FN_BUSSUNIT_ANNUAL_PROD: 'BUSSUNIT_ANNUAL_PROD',
    FN_BUSSUNIT_FINAL_PROD: 'BUSSUNIT_FINAL_PROD',
    FN_BUSSUNIT_NOC_FIRE: 'BUSSUNIT_NOC_FIRE',
    FN_BUSSUNIT_DIC_CERTIFICATE: 'BUSSUNIT_DIC_CERTIFICATE',
    FN_BUSSUNIT_NOC_UPPCB: 'BUSSUNIT_NOC_UPPCB',
    FN_BUSSUNIT_FOREIGN_COLLABRATION: 'BUSSUNIT_FOREIGN_COLLABRATION',
    FN_BUSSUNIT_CRIMINAL_CONVICTION: 'BUSSUNIT_CRIMINAL_CONVICTION',
    FN_BUSSUNIT_OTHER_UPED: 'BUSSUNIT_OTHER_UPED',
    FN_BUSSUNIT_EXACT_PORTION: 'BUSSUNIT_EXACT_PORTION',
    FN_BUSSUNIT_FINISHED_PROD: 'BUSSUNIT_FINISHED_PROD',
    FN_BUSSUNIT_ISI_SPECIFICATION: 'BUSSUNIT_ISI_SPECIFICATION',
    FN_BUSSUNIT_THIRD_NEGATIVE: 'BUSSUNIT_THIRD_NEGATIVE',
    FN_BUSSUNIT_DENATURED_SPIRIT: 'BUSSUNIT_DENATURED_SPIRIT',
    FN_BUSSUNIT_DENAURENT: 'BUSSUNIT_DENAURENT',
    FN_BUSSUNIT_PRODUCTION_PROCESS: 'BUSSUNIT_PRODUCTION_PROCESS',
    FN_BUSSUNIT_INTERMEDIATE_STAGE: 'BUSSUNIT_INTERMEDIATE_STAGE',
    FN_BUSSUNIT_EXACT_BRANCH: 'BUSSUNIT_EXACT_BRANCH',
    FN_BUSSUNIT_SOLVENT_HANDLED: 'BUSSUNIT_SOLVENT_HANDLED',
    FN_BUSSUNIT_NATURAL_RESINS: 'BUSSUNIT_NATURAL_RESINS',
    FN_BUSSUNIT_MACHINE_GRINDING: 'BUSSUNIT_MACHINE_GRINDING',
    FN_BUSSUNIT_PIGMENTS_HANDLED: 'BUSSUNIT_PIGMENTS_HANDLED',
    FN_BUSSUNIT_OILS_HANDLED: 'BUSSUNIT_OILS_HANDLED',
    FN_BUSSUNIT_SITE_PLAN: 'BUSSUNIT_SITE_PLAN',
    FN_BUSSUNIT_SALES_TAX: 'BUSSUNIT_SALES_TAX',
    FN_BUSSUNIT_INCOME_TAX: 'BUSSUNIT_INCOME_TAX',
    FN_BUSSUNIT_AUTHORIZED_OFFICER: 'BUSSUNIT_AUTHORIZED_OFFICER',
    FN_BUSSUNIT_DISTRICT_OFFICER: 'BUSSUNIT_DISTRICT_OFFICER',
    FN_BUSSUNIT_ALCOHOL_RECOVERED: 'BUSSUNIT_ALCOHOL_RECOVERED',
    FN_BUSSUNIT_TANK_VESSEL: 'BUSSUNIT_TANK_VESSEL',
    FN_BUSSUNIT_MACHINERY_DETAILS: 'BUSSUNIT_MACHINERY_DETAILS',
    FN_BUSSUNIT_COSMETIC_ACT: 'BUSSUNIT_COSMETIC_ACT',
    FN_BUSSUNIT_CGST: 'BUSSUNIT_CGST',

    SRC_BUSSUNIT_COMP_PAN: 'compFirmPan_file_source',
    SRC_BUSSUNIT_ITR_LASTYR: 'lastYrITR_file_source',
    SRC_BUSSUNIT_CERT_INCORP: 'certIncorp_file_source',
    SRC_BUSSUNIT_MOM_OF_ARTICLE: 'memoOfArti_file_source',
    SRC_BUSSUNIT_ART_OF_ASSOC: 'artiOfAssoc_file_source',
    SRC_BUSSUNIT_RESU_OF_AUTH: 'resoluOfAuth_file_source',
    SRC_BUSSUNIT_OWNERSHIIP_DOC: 'ownershipDoc_file_source',
    SRC_BUSSUNIT_UNIT_LICENSE: 'unitLicense_file_source',
    SRC_BUSSUNIT_AFFADAVAIT: 'unitAffadavait_file_source',
    SRC_BUSSUNIT_GSTIN: 'uploadGstin_file_source',
    SRC_BUSSUNIT_ANNUAL_TURNOVER_CERT: 'annualTurnOverCert_file_source',
    SRC_BUSSUNIT_CERT_OF_OTHER_STATE_OFFICIAL: 'stateExciseOfficialCert_file_source',
    SRC_BUSSUNIT_DECLARATION: 'declarationCert_file_source',
    SRC_BUSSUNIT_BOND_DOC: 'bwflBondDoc_file_source',
    SRC_BUSSUNIT_POWER_OF_ATTORNEY: 'powerOfAttorney_file_source',
    SRC_BUSSUNIT_PHOTO_OF_PREMISES: 'photoOfPremises_file_source',
    SRC_BUSSUNIT_OWN_RENT_LEASE_DOCUMENT: 'ownRentLease_file_source ',
    SRC_BUSSUNIT_ANNUAL_PRODUCTION_CAPACITY_CERT: 'annualProductionCapacity_file_source',
    SRC_BUSSUNIT_BOUNDRY: 'boundries_file_source',
    SRC_BWFL_1: 'bwfl1_file_source',
    SRC_BWFL_3: 'bwfl3_file_source',
    SRC_BUSSUNIT_ITR: 'itr_file_source',
    SRC_BUSSUNIT_FINAL_PRODUCT: 'finalProduct_file_source',

    SRC_FINISHED_PRODUCT_WILL_CONTAIN_ALCOHOL: 'finished_product_will_contain_alcohol_file_source',
    SRC_ARE_THEY_MANUFACTURED_ACCORDING_TO_ISI_SPECIFICATIONS: 'are_they_manufactured_according_to_isi_specifications_file_source',
    SRC_COPY_OF_RELEVANT_EXTRACT_MAY_BE_ATTACHED:'copy_of_relevant_extract_may_be_attached_file_source',
    SRC_DETAIL_OF_DENATURANT:'detail_of_denaturant_file_source',
    SRC_PERCENTAGE_OF_ALCOHOL_RECOVERED: 'percentage_of_alcohol_recovered_file_source',
    SRC_NAME_OF_THE_NATURAL_RESINS_HANDLES: 'name_of_the_natural_resins_handles_file_source',
    SRC_SALES_TAX_FIGURES_OF_LAST_YEAR:'sales_tax_figures_of_last_year_file_source',
    SRC_INCOME_TAX_FIGURES_OF_LAST_YEAR:'income_tax_figures_of_last_year_file_source',
    SRC_LIST_OF_TECHNICAL_STAFF_QUALIFICATION: 'list_of_technical_staff_qualification_file_source',
    SRC_DO_YOU_HAVE_EXPLOSIVE_ACT_LICENSE: 'do_you_have_explosive_act_license_file_source',
    SRC_DO_YOU_HAVE_POISON_ACT_LICENSE: 'do_you_have_poison_act_license_file_source',
    SRC_NO_DUES_CERTIFICATE_FROM_DM:'no_dues_certificate_from_dm_file_source',
    SRC_LIST_OF_RAW_MATERIAL_OTHERS_THAN_ALCOHOL:'list_of_raw_material_others_than_alcohol_file_source',
    SRC_FLOWCHART_OF_PLANT_MACHINERY:'flowchart_of_plant_machinery_file_source',
    SRC_NO_OF_GATES_IN_CHAUHADDI:'no_of_gates_in_chauhaddi_file_source',



    SRC_BUSSUNIT_SPIRIT_STORAGE:'spirit_storage_file_source',
    SRC_BUSSUNIT_PLANT_MACHINERY:'plant_machinery_file_source',
    SRC_BUSSUNIT_ANNUAL_PROD:'annual_prod_file_source',
    SRC_BUSSUNIT_FINAL_PROD:'final_prod_file_source',
    SRC_BUSSUNIT_NOC_FIRE:'noc_fire_dep_file_source',
    SRC_BUSSUNIT_DIC_CERTIFICATE:'dic_certificate_file_source',
    SRC_BUSSUNIT_NOC_UPPCB:'noc_uppcb_file_source',
    SRC_BUSSUNIT_FOREIGN_COLLABRATION:'foreign_collab_file_source',
    SRC_BUSSUNIT_CRIMINAL_CONVICTION:'criminal_conviction_file_source',
    SRC_BUSSUNIT_OTHER_UPED:'other_uped_file_source',
    SRC_BUSSUNIT_EXACT_PORTION:'exact_portion_file_source',
    SRC_BUSSUNIT_FINISHED_PROD:'finished_prod_file_source',
    SRC_BUSSUNIT_ISI_SPECIFICATION:'isi_specification_file_source',
    SRC_BUSSUNIT_THIRD_NEGATIVE:'third_negative_file_source',
    SRC_BUSSUNIT_DENATURED_SPIRIT:'denatured_spirit_file_source',
    SRC_BUSSUNIT_DENAURENT:'denaurent_file_source',
    SRC_BUSSUNIT_PRODUCTION_PROCESS:'production_process_file_source',
    SRC_BUSSUNIT_INTERMEDIATE_STAGE:'intermediate_stage_file_source',
    SRC_BUSSUNIT_EXACT_BRANCH:'exact_branch_file_source',
    SRC_BUSSUNIT_SOLVENT_HANDLED:'solvents_handled_file_source',
    SRC_BUSSUNIT_NATURAL_RESINS:'natural_resins_file_source',
    SRC_BUSSUNIT_MACHINE_GRINDING:'machine_grinding_file_source',
    SRC_BUSSUNIT_PIGMENTS_HANDLED:'pigments_handled_file_source',
    SRC_BUSSUNIT_OILS_HANDLED:'oils_handled_file_source',
    SRC_BUSSUNIT_SITE_PLAN:'site_plan_file_source',
    SRC_BUSSUNIT_SALES_TAX:'sales_tax_file_source',
    SRC_BUSSUNIT_INCOME_TAX:'income_tax_file_source',
    SRC_BUSSUNIT_AUTHORIZED_OFFICER:'authorized_officer_file_source',
    SRC_BUSSUNIT_DISTRICT_OFFICER:'district_officer_file_source',
    SRC_BUSSUNIT_ALCOHOL_RECOVERED:'alcohol_recovered_file_source',
    SRC_BUSSUNIT_TANK_VESSEL: 'tank_vessel_file_source',
    SRC_BUSSUNIT_MACHINERY_DETAILS: 'machinery_details_file_source',
    SRC_BUSSUNIT_COSMETIC_ACT: 'cosmetic_act_file_source',
    SRC_BUSSUNIT_CGST: 'cgst_file_source',

    FN_ADDI_NO_DUES_DOC: 'ADDITIONAL_NO_DUES_DOC',

    // Additional Document for bottling
    Valid_APPLICANT_PHOTOGRAPH: 'Valid Photo',

    // additional Document for wholesale
    FN_ADDI_PAN: 'ADDITIONAL_PAN_DOC',
    FN_ADDI_GSTIN: 'ADDITIONAL_GSTIN_DOC',
    FN_ADDI_REGISTRATION_CERT: 'ADDITIONAL_REGISTRATION_CERTIFICATE_OF_FIRM_DOC',
    FN_VALID_LICENSE_OUTSIDE_UP: 'Valid License Copy',
    FN_IMPORT_PERMIT_OUTSIDE_UP: 'Import Permit of UP',
    FN_EXPORT_PERMIT_CONCERNED_STATE_OUTSIDE_UP: 'Export Permit of Concerned State',
    FN_ADDI_DECLARATION: 'ADDITIONAL_DECLARATION_DOC',
    FN_ADDI_PHOTO_OF_PREMISES: 'ADDITIONAL_PHOTO_OF_GODOWN_AND_PREMISES_DOC',
    FN_ADDI_RESU_OF_AUTH: 'ADDITIONAL_RESOLUTION_OF_AUTHORIZATION_DOC',
    FN_ADDI_OWN_RENT_LEASE_DOC: 'ADDITIONAL_OWN_RENT_LEASE_DOC',
    FN_ADDI_PARTNERSHIPDEED: 'ADDITIONAL_PARTNERSHIP_DEED_DOC',
    FN_ADDI_APPLI_AUTH_LETTER: 'ADDITIONAL_AUTHORIZATION_LETTER_OF_APPLICANT_DOC',
    FN_ADDI_AGREEMENT_DOC: 'ADDITIONAL_AGREEMENT_DOC',
    FN_ADDI_IEC_DOC: 'ADDITIONAL_ICE_DOC',
    FN_ADDI_NOC_DOC: 'ADDITIONAL_NOC_DOC',
    FN_ADDI_OWNERSHIIP_DOC: 'ADDITIONAL_OWNERSHIP_DOC',

    FN_GAUGE_CHART_DOC: 'GAUGE_CHART',
    FN_ARRANGEMENT_OF_TEMPERATURE_CONTROL_DOC : "ATTACHMENT_ARRANGEMENT_OF_TEMPERATURE_CONTROL",
    FN_FLOW_DIAGRAM_DOC : "ATTACHMENT_FLOW_DIAGRAM",
    FN_PLAN_ELEVATION_DOC : "ATTACHMENT_PLAN_ELEVATION",
    FN_DRAWING_DOC : "ATTACHMENT_DRAWINGS",
    FN_BLUEPRINT_DOC : "ATTACHMENT_BLUEPRINT",
    Valid_License_Copy: 'Valid License Copy',
    Export_Permit_of_Concerned_State: 'Export Permit of Concerned State',
    Import_Permit_of_UP: 'Import Permit of UP',

    SRC_ADDI_PAN: 'addiPan_file_source',
    SRC_ADDI_GSTIN: 'addiGSTIN_file_source',
    SRC_ADDI_REGISTRATION_CERT: 'registrationCert_file_source',
    SRC_EXPORT_PERMIT_CONCERNED_STATE_OUTSIDE_UP: 'sm_export_permit_file',
    SRC_IMPORT_PERMIT_OUTSIDE_UP: 'sm_import_permit_file',
    SRC_VALID_LICENSE_OUTSIDE_UP: 'sm_valid_license_copy_file',
    SRC_ADDI_DECLARATION: 'declaration_file_source',
    SRC_ADDI_PHOTO_OF_PREMISES: 'photoOfPremises_file_source',
    SRC_ADDI_RESU_OF_AUTH: 'resoluOfAuth_file_source',
    SRC_ADDI_OWN_RENT_LEASE_DOC: 'ownRentLeaseDoc_file_source',
    SRC_ADDI_PARTNERSHIPDEED: 'partnershipDeed_file_source',
    SRC_ADDI_APPLI_AUTH_LETTER: 'appliAuthLetter_file_source',
    SRC_ADDI_IEC_DOC: 'iec_file_source',
    SRC_ADDI_NOC_DOC: 'noc_file_source',
    SRC_ADDI_AGREEMENT_DOC: 'agreement_file_source',
    SRC_ADDI_NO_DUES_DOC: 'noDues_file_source',

    // Premises Document identifiers for HBR license
    FN_HBR_PREM_OWNERSHIIP_DOC: 'HBR_OWNERSHIP_DOC',
    SRC_HBR_PREM_OWNERSHIIP_DOC: 'ownershipDoc_file_source',

    // EMD Document identifiers for Retail license
    FN_RL_EMD_DOC: 'RETAIL_EMD_DOC',
    FN_SHOP_OWN_RENT_LEASE_DOC: 'SHOP_OWN_RENT_LEASE_DOC',
    FN_SHOP_OWNERSHIP_AFFADAVAIT_DOC: 'SHOP_OWNERSHIP_AFFADAVAIT_DOC',
    FN_SHOP_FRONT_DOC: 'SHOP_FRONT_DOC',
    FN_SHOP_BACK_DOC: 'SHOP_BACK_DOC',
    FN_SHOP_EAST_SIDE_DOC: 'SHOP_EAST_SIDE_DOC',
    FN_SHOP_WEST_SIDE_DOC: 'SHOP_WEST_SIDE_DOC',

    SRC_RL_EMD_DOC: 'emd_doc_file_source',
    SRC_SHOP_OWN_RENT_LEASE_DOC: 'ownRentLeaseDoc_file_source',
    SRC_SHOP_OWNERSHIP_AFFADAVAIT_DOC: 'ownershipDoc_file_source',
    SRC_SHOP_FRONT_DOC: 'shop_photo_front_doc_file_source',
    SRC_SHOP_BACK_DOC: 'shop_photo_back_doc_file_source',
    SRC_SHOP_EAST_SIDE_DOC: 'shop_photo_east_side_doc_file_source',
    SRC_SHOP_WEST_SIDE_DOC: 'shop_photo_west_side_doc_file_source',

    // Secuirty Document identifiers for Retail license
    FN_RL_SECUIRYT_DOC: 'RETAIL_SECURITY_DOC',
    SRC_RL_SECUIRYT_DOC: 'secuirty_doc_file_source',

    FN_SM_PAN: 'SUGARMILL_FIRM_PAN_DOC',
    FN_SM_AADHAR: 'SUGARMILL_FIRM_AADHAR_DOC',
    FN_SM_AFFADAVAIT: 'SUGARMILL_FIRM_AFFADAVAIT_DOC',
    FN_SM_CHARAC_CERT: 'SUGARMILL_FIRM_CHARACTER_CERTIFICATE_DOC',
    FN_SM_CRIMINAL_CASE: 'FIRM_CRIMINAL_CASE_DOC',
    FN_SM_ITR: 'FIRM_ITR',

    SRC_SM_PAN: 'firmcompany_pan_file_source',
    SRC_SM_ITR: 'firmcompany_itr_file_source',
    SRC_SM_AADHAR: 'firmcompany_aadhar_file_source',
    SRC_SM_AFFADAVAIT: 'firmcompany_affadavait_file_source',
    SRC_SM_CHARAC_CERT: 'firmcompany_character_file_source',
    SRC_SM_CRIMINAL_CASE: 'firmcompany_criminal_case_file_source',
    
    FN_SITE_PHOTO: 'SITE_PHOTO_DOC',
    FN_TANK_BLUEPRINT_FILES: 'FN_TANK_BLUEPRINT_FILES',
    FN_TANK_FLOWDIAGRAM_FILES: 'FN_TANK_FLOWDIAGRAM_FILES',
    FN_TANK_ELEVATIONPLAN_FILES: 'FN_TANK_ELEVATIONPLAN_FILES',
    FN_SITE_REPORT: 'SITE_REPORT_DOC',
    FN_EXCISE_INSPECTOR: 'EXCISE_INSPECTOR_DOC',
    FN_REJECT_REPORT: 'REJECT_REPORT_DOC',
    FN_DISTRICT_INDUSTRY: 'DISTRICT_INDUSTRY_DOC',   
    FN_SITE_REPORT_UPLOAD: 'SITE_REPORT_UPLOAD_DOC',    
    FN_SITE_DM_REPORT: 'SITE_DM_REPORT',
    FN_SITE_LEVEL_REPORT: 'SITE_LEVEL_REPORT',
    FN_SALESMAN_GOVT_ID: 'appli_govtid_file',
    FN_ATTACHMENT: 'ATTACHMENT_DOC',

    SRC_SITE_PHOTO: 'site_photo_file_source',
    SRC_SITE_REPORT: 'site_report_file_source',
    SRC_EXCISE_INSPECTOR: 'excise_inspector_report_file_source',
    SRC_REJECT_REPORT: 'reject_report_file_source',
    SRC_DISTRICT_INDUSTRY: 'district_industry_officer_report_file_source', 
    SRC_SITE_REPORT_UPLOAD: 'site_report_upload_file_source',    
    SRC_SITE_DM_REPORT: 'site_dm_report_file_source',
    SRC_SITE_LEVEL_REPORT: 'site_level_report_file_source',
    SRC_SALESMAN_GOVT_ID: 'appli_govtid_file_source',
    SRC_PLAN_ELEVATION: 'plan_elevation_file_source',
    SRC_FLOW_DIAG: 'flow_diagram_file_source',
    SRC_TEMP_CONTROL: 'arrangement_of_temperature_control_file_source',
    SRC_GUAGE_CHART: 'guage_chart_file_source',
    SRC_ATTACHMENT: 'attachment_file_source',

    FN_GAUGECHART: 'firm gaugechart',
    FN_CALIBRATIONDOC: 'calibration document',

    SRC_GAUGECHART: 'firm_gaugatechart_file_source',
    SRC_CALIBRATIONDOC: 'calibration_document_file_source',

    FN_SALESMAN_PHOTO_DOC: 'SALESMAN_PHOTO_DOC',
    FN_SALESMAN_AFFADAVAIT_DOC: 'SALESMAN_AFFADAVAIT_DOC',
    FN_SALESMAN_CHARAC_CERT: 'SALESMAN_CHARAC_CERT_DOC',
    FN_SALESMAN_DOC: 'SALESMAN_DOC',

    SRC_SALESMAN_PHOTO_DOC: 'salesman_photo_file_source',
    SRC_SALESMAN_AFFADAVAIT_DOC: 'salesman_affadavait_file_source',
    SRC_SALESMAN_CHARAC_CERT: 'salesman_character_file_source',
    SRC_SALESMAN_DOC: 'salesman_file_source',

    FN_PHOTO_BOARD_MEMBERS: 'PHOTO_BOARD_MEMBERS',
    SRC_PHOTO_BOARD_MEMBERS: 'photo_board_member_file_source',

    /**
     * License checklist codes
     */
    
    // CODE_CHECKLIST_EVENT_BAR_FL11: 'CHECKLIST_EVENT_BAR_FL11', 
    // CODE_CHECKLIST_BWFL_PARENT_UNIT: 'CHECKLIST_BWFL_PARENT_UNIT'
    CODE_CHECKLIST_IMPORT_UNIT: 'CHECKLIST_IMPORT_UNIT',

    CODE_CHECKLIST_WHOLESALE_FL2: 'CHECKLIST_WHOLESALE_FL2',
    CODE_CHECKLIST_WHOLESALE_FL2A: 'CHECKLIST_WHOLESALE_FL2A',
    CODE_CHECKLIST_WHOLESALE_FL2B: 'CHECKLIST_WHOLESALE_FL2B',
    CODE_CHECKLIST_WHOLESALE_CL2: 'CHECKLIST_WHOLESALE_CL2',
    CODE_CHECKLIST_WHOLESALE_ID3: 'CHECKLIST_WHOLESALE_ID3',

    CODE_CHECKLIST_BOND_WAREHOUSE_2A: 'CHECKLIST_BOND_WAREHOUSE_2A',
    CODE_CHECKLIST_BOND_WAREHOUSE_2B: 'CHECKLIST_BOND_WAREHOUSE_2B',
    CODE_CHECKLIST_BOND_WAREHOUSE_2C: 'CHECKLIST_BOND_WAREHOUSE_2C',
    CODE_CHECKLIST_BOND_WAREHOUSE_2D: 'CHECKLIST_BOND_WAREHOUSE_2D',

    // For DISTILLERY
    CODE_CHECKLIST_DISTI_BOTTL_CL_B1: 'CODE_CHECKLIST_DISTI_BOTTL_CL_B1',
    CODE_CHECKLIST_DISTI_BOTTL_CL_B2: 'CODE_CHECKLIST_DISTI_BOTTL_CL_B2',
    CODE_CHECKLIST_DISTI_BOTTL_DS_1: 'CODE_CHECKLIST_DISTI_BOTTL_DS_1',
    CODE_CHECKLIST_DISTI_BOTTL_FL_1: 'CODE_CHECKLIST_DISTI_BOTTL_FL_1',
    CODE_CHECKLIST_DISTI_BOTTL_FL_1A: 'CODE_CHECKLIST_DISTI_BOTTL_FL_1A',
    CODE_CHECKLIST_DISTI_BOTTL_FL_3: 'CODE_CHECKLIST_DISTI_BOTTL_FL_3',
    CODE_CHECKLIST_DISTI_BOTTL_FL_3A: 'CODE_CHECKLIST_DISTI_BOTTL_FL_3A',
    CODE_CHECKLIST_DISTI_BOTTL_FL_16: 'CODE_CHECKLIST_DISTI_BOTTL_FL_16',
    CODE_CHECKLIST_DISTI_BOTTL_FL_17: 'CODE_CHECKLIST_DISTI_BOTTL_FL_17',
    CODE_CHECKLIST_DISTI_BOTTL_FL_32: 'CODE_CHECKLIST_DISTI_BOTTL_FL_32',
    CODE_CHECKLIST_DISTI_BOTTL_FL_39: 'CODE_CHECKLIST_DISTI_BOTTL_FL_39',
    CODE_CHECKLIST_DISTI_BOTTL_FL_40: 'CODE_CHECKLIST_DISTI_BOTTL_FL_40',
    CODE_CHECKLIST_DISTI_BOTTL_FL_41: 'CODE_CHECKLIST_DISTI_BOTTL_FL_41',
    CODE_CHECKLIST_DISTI_BOTTL_FL_49: 'CODE_CHECKLIST_DISTI_BOTTL_FL_49',
    CODE_CHECKLIST_DISTI_BOTTL_PD_1: 'CODE_CHECKLIST_DISTI_BOTTL_PD_1',
    CODE_CHECKLIST_DISTI_BOTTL_PD_2: 'CODE_CHECKLIST_DISTI_BOTTL_PD_2',
    CODE_CHECKLIST_DISTI_BOTTL_PD_32: 'CODE_CHECKLIST_DISTI_BOTTL_PD_32',
    CODE_CHECKLIST_DISTI_BOTTL_PD_33: 'CODE_CHECKLIST_DISTI_BOTTL_PD_33',
    CODE_CHECKLIST_DISTI_BOTTL_PD_34: 'CODE_CHECKLIST_DISTI_BOTTL_PD_34',

    CODE_CHECKLIST_SUGAR_MILL_WITH_IN_UP: 'CHECKLIST_SUGAR_MILL_WITH_IN_UP',
    CODE_CHECKLIST_SUGAR_MILL_OUT_SIDE_UP: 'CHECKLIST_SUGAR_MILL_OUT_SIDE_UP',
    CODE_CHECKLIST_SUGAR_MILL_INDUS_IN_UP: 'CHECKLIST_SUGAR_MILL_INDUS_IN_UP',
    CODE_CHECKLIST_SUGAR_MILL_INDUS_OUT_SIDE_UP: 'CHECKLIST_SUGAR_MILL_INDUS_OUT_SIDE_UP',
    CODE_CHECKLIST_SUGAR_MILL_MOLASSESS_TRADERS: 'CHECKLIST_SUGAR_MILL_MOLASSESS_TRADERS',
    CODE_CHECKLIST_SUGAR_MILL_BELOW_GRADE: 'CHECKLIST_SUGAR_MILL_BELOW_GRADE',

    /**
     * License category and subcategory for WHOLESALE
     */
    WHOLESALE_LICENSE_CATEGORY: 'WHOLESALE',
    WHOLESALE_LICENSE_CATEGORY_DESC: 'Wholesale',
    WHOLESALE_FL2_SUBCATEGORY: 'FL2',
    WHOLESALE_FL2_SUBCATEGORY_DESC: 'FL-2',
    WHOLESALE_FL2A_SUBCATEGORY: 'FL2A',
    WHOLESALE_FL2A_SUBCATEGORY_DESC: 'FL-2A',
    WHOLESALE_FL2B_SUBCATEGORY: 'FL2B',
    WHOLESALE_FL2B_SUBCATEGORY_DESC: 'FL-2B',
    WHOLESALE_CL2_SUBCATEGORY: 'CL2',
    WHOLESALE_CL2_SUBCATEGORY_DESC: 'CL-2',
    WHOLESALE_ID3_SUBCATEGORY: 'ID3',
    WHOLESALE_ID3_SUBCATEGORY_DESC: 'ID-3',
    WHOLESALE_ID10_SUBCATEGORY: 'ID10',
    WHOLESALE_ID10_SUBCATEGORY_DESC: 'ID-10',

    WHOLESALE_FL2_SUBCATEGORY_TITLE: 'Wholesale Vends of Foregien Liquor in the District',
    WHOLESALE_FL2A_SUBCATEGORY_TITLE: 'License for wholesale sale of foreign liquor, beer, wine and low strength alcoholic beverages(other than denatured spirit) to military/paramilitary forces wholesalers & retailers',
    WHOLESALE_FL2B_SUBCATEGORY_TITLE: 'Wholesale Vends of Beer in the District',
    WHOLESALE_CL2_SUBCATEGORY_TITLE: 'Wholesale Vends of Country Liquor (CL) in the District',
    WHOLESALE_ID3_SUBCATEGORY_TITLE: 'License for the wholesale supply of Bhang to District Wholesale Godowns',
    WHOLESALE_ID10_SUBCATEGORY_TITLE: 'License for the wholesale supply of Bhang under the contract supply system',

    MICRO_BREWERY_MB2_SUBCATEGORY_TITLE: 'Application to Establish a Microbrewery(MB-2)',
    MICRO_BREWERY_MB6_SUBCATEGORY_TITLE: 'Application to Establish a Microbrewery(MB-6)',

    /**
     * License category and subcategory for EVENT_BAR
     */
    EVENT_BAR_LICENSE_CATEGORY: 'EVENT_BAR',
    EVENT_BAR_LICENSE_CATEGORY_DESC: 'Event Bar',
    EVENT_BAR_FL11_SUBCATEGORY: 'FL11',
    EVENT_BAR_FL11_SUBCATEGORY_DESC: 'FL-11',


    EVENT_BAR_FL11_SUBCATEGORY_TITLE: 'EventBar License',
    /**
     * License category and subcategory for HBR
     */
    HBR_LICENSE_CATEGORY: 'HBR',
    HBR_LICENSE_CATEGORY_DESC: 'HBR',
    HBR_FL6_SUBCATEGORY: 'FL6',
    HBR_FL6_SUBCATEGORY_DESC: 'FL-6',
    HBR_FL7_SUBCATEGORY: 'FL7',
    HBR_FL7_SUBCATEGORY_DESC: 'FL-7',
    HBR_FL7A_SUBCATEGORY: 'FL7A',
    HBR_FL7A_SUBCATEGORY_DESC: 'FL-7A',
    HBR_FL8_SUBCATEGORY: 'FL8',
    HBR_FL8_SUBCATEGORY_DESC: 'FL-8',
    HBR_FLAL1_SUBCATEGORY: 'FLAL1',
    HBR_FLAL1_SUBCATEGORY_DESC: 'FLAL-1',

    HBR_FL6_SUBCATEGORY_TITLE: 'Hotel Bar Licence',
    HBR_FL7_SUBCATEGORY_TITLE: 'Restaurant Bar Licence',
    HBR_FL7A_SUBCATEGORY_TITLE: 'Club Bar Licence',
    HBR_FL8_SUBCATEGORY_TITLE: 'License for retail vend of Foreign liquor, Beer, Wine, & LAB in Special  Railway Trains/International Cruise/National Cruise for its Bonafied Travellers',
    HBR_FLAL1_SUBCATEGORY_TITLE: 'Airport Bar Licence',

    /**
     * License category and subcategory for CUSTOMBOND
     */
    CUSTOMBOND_LICENSE_SUBCATEGORY:'CUSTOMBOND',
    CUSTOMBOND_LICENSE_SUBCATEGORY_DESC:'CUSTOMBOND LICENSE',

    /**
     * License category and subcategory for BWFL Parent Unit
     */
    BWFL_PARENT_LICENSE_CATEGORY: 'PARENT_UNIT',
    BWFL_PARENT_LICENSE_CATEGORY_DESC: 'Outside UP(distillery/brewery/winery/bottling) Unit registration',
    BWFL_PARENT_LICENSE_SUBCATEGORY: 'BWFL',
    BWFL_PARENT_LICENSE_SUBCATEGORY_DESC: 'Parent BWFL',

    /**
     * License category and subcategory for BOND_WAREHOUSE
     */
    BOND_WAREHOUSE_LICENSE_CATEGORY: 'WAREHOUSE',
    BOND_WAREHOUSE_LICENSE_CATEGORY_DESC: 'Bond Warehouse',
    BOND_WAREHOUSE_2A_SUBCATEGORY: 'BWFL_2A',
    BOND_WAREHOUSE_2A_SUBCATEGORY_DESC: 'BWFL-2A',
    BOND_WAREHOUSE_2B_SUBCATEGORY: 'BWFL_2B',
    BOND_WAREHOUSE_2B_SUBCATEGORY_DESC: 'BWFL-2B',
    BOND_WAREHOUSE_2C_SUBCATEGORY: 'BWFL_2C',
    BOND_WAREHOUSE_2C_SUBCATEGORY_DESC: 'BWFL-2C',
    BOND_WAREHOUSE_2D_SUBCATEGORY: 'BWFL_2D',
    BOND_WAREHOUSE_2D_SUBCATEGORY_DESC: 'BWFL-2D',

    /**
     * License category and subcategory for DISTILLERY
     */
    DISTI_BOTTL_LICENSE_CATEGORY: 'DISTILLERY',
    DISTI_BOTTL_LICENSE_CATEGORY_DESC: 'Distillery',
    DISTI_BOTTL_CLB1_SUBCATEGORY: 'CLB1',
    DISTI_BOTTL_CLB1_SUBCATEGORY_DESC: 'CL-B1',
    DISTI_BOTTL_CLB2_SUBCATEGORY: 'CLB2',
    DISTI_BOTTL_CLB2_SUBCATEGORY_DESC: 'CL-B2',
    DISTI_BOTTL_DS1_SUBCATEGORY: 'DS1',
    DISTI_BOTTL_DS1_SUBCATEGORY_DESC: 'DS-1',
    DISTI_BOTTL_FL1_SUBCATEGORY: 'FL1',
    DISTI_BOTTL_FL1_SUBCATEGORY_DESC: 'FL-1',
    DISTI_BOTTL_FL1A_SUBCATEGORY: 'FL1A',
    DISTI_BOTTL_FL1A_SUBCATEGORY_DESC: 'FL-1A',
    DISTI_BOTTL_FL3_SUBCATEGORY: 'FL3',
    DISTI_BOTTL_FL3_SUBCATEGORY_DESC: 'FL-3',
    DISTI_BOTTL_FL3A_SUBCATEGORY: 'FL3A',
    DISTI_BOTTL_FL3A_SUBCATEGORY_DESC: 'FL-3A',
    DISTI_BOTTL_FL16_SUBCATEGORY: 'FL16',
    DISTI_BOTTL_FL16_SUBCATEGORY_DESC: 'FL-16',
    DISTI_BOTTL_FL17_SUBCATEGORY: 'FL17',
    DISTI_BOTTL_FL17_SUBCATEGORY_DESC: 'FL-17',
    DISTI_BOTTL_FL32_SUBCATEGORY: 'FL32',
    DISTI_BOTTL_FL32_SUBCATEGORY_DESC: 'FL-32',
    DISTI_BOTTL_FL39_SUBCATEGORY: 'FL39',
    DISTI_BOTTL_FL39_SUBCATEGORY_DESC: 'FL-39',
    DISTI_BOTTL_FL40_SUBCATEGORY: 'FL40',
    DISTI_BOTTL_FL40_SUBCATEGORY_DESC: 'FL-40',
    DISTI_BOTTL_FL41_SUBCATEGORY: 'FL41',
    DISTI_BOTTL_FL41_SUBCATEGORY_DESC: 'FL-41',
    DISTI_BOTTL_FL49_SUBCATEGORY: 'FL49',
    DISTI_BOTTL_FL49_SUBCATEGORY_DESC: 'FL-49',
    DISTI_BOTTL_PD1_SUBCATEGORY: 'PD1',
    DISTI_BOTTL_PD1_SUBCATEGORY_DESC: 'PD-1',
    DISTI_BOTTL_PD2_SUBCATEGORY: 'PD2',
    DISTI_BOTTL_PD2_SUBCATEGORY_DESC: 'PD-2',
    DISTI_BOTTL_PD32_SUBCATEGORY: 'PD32',
    DISTI_BOTTL_PD32_SUBCATEGORY_DESC: 'PD-32',
    DISTI_BOTTL_PD33_SUBCATEGORY: 'PD33',
    DISTI_BOTTL_PD33_SUBCATEGORY_DESC: 'PD-33',
    DISTI_BOTTL_PD34_SUBCATEGORY: 'PD34',
    DISTI_BOTTL_PD34_SUBCATEGORY_DESC: 'PD-34',
    DISTI_BOTTL_FL50_SUBCATEGORY: 'FL50',
    DISTI_BOTTL_FL50_SUBCATEGORY_DESC: 'FL-50',
    DISTI_BOTTL_FL51_SUBCATEGORY: 'FL51',
    DISTI_BOTTL_FL51_SUBCATEGORY_DESC: 'FL-51',

    /**
     * License category and subcategory for RETAIL
     */
    RETAIL_LICENSE_CATEGORY: 'RETAIL',
    RETAIL_LICENSE_CATEGORY_DESC: 'Retail',
    RETAIL_FL4A_SUBCATEGORY: 'FL4A',
    RETAIL_FL4A_SUBCATEGORY_DESC: 'FL-4A',
    RETAIL_FL4C_SUBCATEGORY: 'FL4C',
    RETAIL_FL4C_SUBCATEGORY_DESC: 'FL-4C',
    RETAIL_FL5B_SUBCATEGORY: 'FL5B',
    RETAIL_FL5B_SUBCATEGORY_DESC: 'FL-5B',
    RETAIL_FL5C_SUBCATEGORY: 'FL5C',
    RETAIL_FL5C_SUBCATEGORY_DESC: 'CL-5C',
    RETAIL_FL5D_SUBCATEGORY: 'FL5D',
    RETAIL_FL5D_SUBCATEGORY_DESC: 'FL-5D',
    RETAIL_HM1_SUBCATEGORY: 'HM1',
    RETAIL_HM1_SUBCATEGORY_DESC: 'HM-1',

    RETAIL_FL4A_SUBCATEGORY_TITLE: 'License for retail sale of foreign Liquor/Beer/Wine/LAB in sealed Bottles for consumption on and Off the premises and Draught beer on the premises (Model Shop) - ( F. L - 4A)',
    RETAIL_FL4C_SUBCATEGORY_TITLE: "License for Retail sale of foreign Liquor in sealed Bottles for consumption 'Off' the Premises (Premium Retail Vend) - ( F. L - 4C)",
    RETAIL_FL5B_SUBCATEGORY_TITLE: "License for Retail Sale of Beer , Wine and LAB in sealed Bottles for consumption 'Off' the Premises - ( F. L - 5B)",
    RETAIL_FL5C_SUBCATEGORY_TITLE: "License for Retail sale of country liquor in sealed bottles for consumption 'on' and 'off' premises. - ( C.L - 5C)",
    RETAIL_FL5D_SUBCATEGORY_TITLE: "License for Retail Sale of Foreign Liquor and Wine in Sealed Bottles for consumption 'off' the premises - ( F. L - 5D)",
    RETAIL_HM1_SUBCATEGORY_TITLE: "Licence for Retail Sale of Bhang for consumption 'off' premises.(H.M-1)",

    RETAIL_FL4A_SUBCATEGORY_TITLE_TEXT: 'Application for FL 4A License',
    RETAIL_FL4C_SUBCATEGORY_TITLE_TEXT: 'Application for License F.L 4C',
    RETAIL_FL5B_SUBCATEGORY_TITLE_TEXT: 'Application for FL 5B License',
    RETAIL_FL5C_SUBCATEGORY_TITLE_TEXT: 'Application for CL 5C License',
    RETAIL_FL5D_SUBCATEGORY_TITLE_TEXT: 'Application for FL 5D License',
    RETAIL_HM1_SUBCATEGORY_TITLE_TEXT: 'Application for HM 1 License',

    /**
     * License category and subcategory for RETAIL
     */
     RETAIL_CSD_LICENSE_CATEGORY: 'RETAIL_CSD',
     RETAIL_CSD_LICENSE_CATEGORY_DESC: 'Retail CSD',
     RETAIL_CSD_FL9_SUBCATEGORY: 'FL9',
     RETAIL_CSD_FL9_SUBCATEGORY_DESC: 'FL-9',
     RETAIL_CSD_FL9A_SUBCATEGORY: 'FL9A',
     RETAIL_CSD_FL9A_SUBCATEGORY_DESC: 'FL-9A',


    RETAIL_CSD_FL9A_SUBCATEGORY_TITLE: 'License for retail vend of only concessional rum military and Para-Military Forces',
    RETAIL_CSD_FL9_SUBCATEGORY_TITLE: 'License for retail vend of foreign Liquor to military and Para-Military Forces',
    /**
     * License category and subcategory for SUGAR_MILL
     */
    SUGAR_MILL_LICENSE_CATEGORY: 'SUGAR_MILL',
    SUGAR_MILL_LICENSE_CATEGORY_DESC: 'Sugar Mill',
    SUGAR_MILL_WITH_IN_UP_SUBCATEGORY: 'WITH_IN_UP',
    SUGAR_MILL_WITH_IN_UP_SUBCATEGORY_DESC: 'Sugarmill Within UP',
    SUGAR_MILL_WITH_IN_UP_SUBCATEGORY_DESC_TITLE: 'Sugarmill Within UP / Khandsari',
    SUGAR_MILL_WITH_IN_UP_KHANDASARI_SUBCATEGORY_DESC: 'Khandsari (Sugar Manufacturing Unit)',
    SUGAR_MILL_OUT_SIDE_UP_SUBCATEGORY: 'OUTSIDE_UP',
    SUGAR_MILL_OUT_SIDE_UP_SUBCATEGORY_DESC: 'Sugarmill Outside UP',
    SUGAR_MILL_INDUS_IN_UP_SUBCATEGORY: 'INDUSTRY_INSIDE_UP',
    SUGAR_MILL_INDUS_IN_UP_SUBCATEGORY_DESC: 'Industries Inside UP',
    SUGAR_MILL_INDUS_OUT_SIDE_UP_SUBCATEGORY: 'INDUSTRY_OUTSIDE_UP',
    SUGAR_MILL_INDUS_OUT_SIDE_UP_SUBCATEGORY_DESC: 'Industries Outside UP',
    SUGAR_MILL_MOLASSESS_TRADERS_SUBCATEGORY: 'MOLASESS_TRADER',
    SUGAR_MILL_MOLASSESS_TRADERS_SUBCATEGORY_DESC: 'Molasess Trader Exports',
    SUGAR_MILL_BELOW_GRADE_SUBCATEGORY: 'BELOW_GRADE',
    SUGAR_MILL_BELOW_GRADE_SUBCATEGORY_DESC: 'Registeration for Below Grade',

  /**
   * License category and subcategory for BOTTLING
   */
  BOTTLING_LICENSE_CATEGORY: 'BOTTELING',
  BOTTLING_LICENSE_CATEGORY_DESC: 'Bottling',
  BOTTLING_CLB1_SUBCATEGORY: 'CLB1',
  BOTTLING_CLB1_SUBCATEGORY_DESC: 'CLB-1',
  BOTTLING_CLB2_SUBCATEGORY: 'CLB2',
  BOTTLING_CLB2_SUBCATEGORY_DESC: 'CLB-2',
  BOTTLING_FL3_SUBCATEGORY: 'FL3',
  BOTTLING_FL3_SUBCATEGORY_DESC: 'FL 3',
  BOTTLING_FL3A_SUBCATEGORY: 'FL3A',
  BOTTLING_FL3A_SUBCATEGORY_DESC: 'FL 3A',
  BOTTLING_FL50_SUBCATEGORY_DESC: 'FL 50',
  BOTTLING_FL50_SUBCATEGORY: 'FL50',
  BOTTLING_FL51_SUBCATEGORY_DESC: 'FL 51',
  BOTTLING_FL51_SUBCATEGORY: 'FL51',

  /**
   * License category and subcategory for MICRO_BREWERY
   */
  MICRO_BREWERY_LICENSE_CATEGORY: 'MICRO_BREWERY',
  MICRO_BREWERY_LICENSE_CATEGORY_DESC: 'MICRO BREWERY',
      MICRO_BREWERY_MB6_SUBCATEGORY: 'MB6',
      MICRO_BREWERY_MB6_SUBCATEGORY_DESC: 'MB 6',
      MICRO_BREWERY_MB2_SUBCATEGORY: 'MB2',
      MICRO_BREWERY_MB2_SUBCATEGORY_DESC: 'MB 2',

    /**
    * License category and subcategory for District_Warehouse
    */
    DISTRICT_WAREHOUSE_CATEGORY: 'DISTRICT_WAREHOUSE',
    DISTRICT_WAREHOUSE_CATEGORY_DESC: 'District Ware House',
    DISTRICT_WAREHOUSE_FL1A_SUBCATEGORY: 'FL1A',
    DISTRICT_WAREHOUSE_FL1A_SUBCATEGORY_DESC: 'FL 1A',
    DISTRICT_WAREHOUSE_FL1_SUBCATEGORY: 'FL1',
    DISTRICT_WAREHOUSE_FL1_SUBCATEGORY_DESC: 'FL 1',
    
     /**
     * License category and subcategory for BREWERY-WINERY
     */
     BREWERY_LICENSE_CATEGORY: 'BREWERY',
     BREWERY_LICENSE_CATEGORY_DESC: 'Brewery',
     BREWERY_B1_SUBCATEGORY: 'B1',
     BREWERY_B1_SUBCATEGORY_DESC: 'B-1',
     BREWERY_B19_SUBCATEGORY: 'B19',
     BREWERY_B19_SUBCATEGORY_DESC: 'B-19',
     BREWERY_B20_SUBCATEGORY: 'B20',
     BREWERY_B20_SUBCATEGORY_DESC: 'B-20',
     BREWERY_B21_SUBCATEGORY: 'B21',
     BREWERY_B21_SUBCATEGORY_DESC: 'B-21',
     BREWERY_MB1_SUBCATEGORY: 'MB1',
     BREWERY_MB1_SUBCATEGORY_DESC: 'MB-1',
     BREWERY_MB2_SUBCATEGORY: 'MB2',
     BREWERY_MB2_SUBCATEGORY_DESC: 'MB-2',
     BREWERY_MB3_SUBCATEGORY: 'MB3',
     BREWERY_MB3_SUBCATEGORY_DESC: 'MB-3',
     BREWERY_MB4_SUBCATEGORY: 'MB4',
     BREWERY_MB4_SUBCATEGORY_DESC: 'MB-4',
     BREWERY_MB5_SUBCATEGORY: 'MB5',
     BREWERY_MB5_SUBCATEGORY_DESC: 'MB-5',
     BREWERY_MB6_SUBCATEGORY: 'MB6',
     BREWERY_MB6_SUBCATEGORY_DESC: 'MB-6',
     BREWERY_MB7_SUBCATEGORY: 'MB7',
     BREWERY_MB7_SUBCATEGORY_DESC: 'MB-7',
     BREWERY_V1_SUBCATEGORY: 'V1',
     BREWERY_V1_SUBCATEGORY_DESC: 'V-1',
     BREWERY_V2_SUBCATEGORY: 'V2',
     BREWERY_V2_SUBCATEGORY_DESC: 'V-2',
     VINTINERY_LICENSE_CATEGORY: 'VINTINERY',
     VINTINERY_LICENSE_CATEGORY_DESC: 'Vintinery',
     VINTINERY_V1_SUBCATEGORY: 'V1',
     VINTINERY_V1_SUBCATEGORY_DESC: 'V-1',
     VINTINERY_V2_SUBCATEGORY: 'V2',
     VINTINERY_V2_SUBCATEGORY_DESC: 'V-2',
     VINTINERY_V1_WORKFLOW_NAME:'VINTINERY_V1',
     VINTINERY_V2_WORKFLOW_NAME:'VINTINERY_V2',

    /**
     * Master ware house
     */
    MASTER_WARE_HOUSE_CATEGORY: 'MASTER_WARE_HOUSE',
    MASTER_WARE_HOUSE_CATEGORY_DESC: 'MasterWarehouse',
    MASTER_WARE_HOUSE_SUBCATEGORY:'MWH',
    MASTER_WARE_HOUSE_SUBCATEGORY_DESC:'MasterWarehouse',

    IMPORT_UNIT_CATEGORY: 'IMPORT_UNIT',
    IMPORT_UNIT_CATEGORY_DESC: "Importing Unit ",
    IMPORT_UNIT_SUBCATEGORY: 'IU',
    IMPORT_UNIT_SUBCATEGORY_DESC: 'Import Unit',

    BIO: 'BIO',
    BIO_CATEGORY: 'BIO',
    BIO_CATEGORY_DESC: "BIO 1",
    BIO_SUBCATEGORY: 'BIO',
    BIO_SUBCATEGORY_DESC:  'BIO-1',
    BIO_WORKFLOW_NAME: 'IMPORT_UNIT_BIO',

    BIO1A_WORKFLOW_NAME: 'BIO1A_REGISTRATION',
    BIO1A_CATEGORY: 'BIO1A',
    BIO1A_CATEGORY_DESC: 'BIO-1A',
    BIO1A_SUBCATEGORY: 'BIO-1A',
    BIO1A_SUBCATEGORY_DESC: 'BIO-1A',

    BIO_ZERO_WITHIN_UP_LICENSE_SUBCATEGORY: "BIO_ZERO_WITHIN_UP",
    BIO_ZERO_WITHIN_UP_LICENSE_SUBCATEGORY_DESC: "Bio 0 WithIn UP",

    BIO_ZERO_OUTSIDE_UP_LICENSE_SUBCATEGORY: "BIO_ZERO_OUTSIDE_UP",
    BIO_ZERO_OUTSIDE_UP_LICENSE_SUBCATEGORY_DESC: "Bio 0 OutSide UP",

    BIO_ZERO_LICENSE_CATEGORY: "BIO_ZERO",
    BIO_ZERO_LICENSE_CATEGORY_DESC: "BIO 0",

    BIO_ZERO_LICENSE_WITHIN_UP_WORKFLOW_NAME: 'LICENSE_BIO_ZERO_WITHIN_UP',
    BIO_ZERO_LICENSE_OUTSIDE_UP_WORKFLOW_NAME: 'LICENSE_BIO_ZERO_OUTSIDE_UP',

    HOME_BAR_CATEGORY_CODE: "HOME_BAR",
    HOME_BAR_CATEGORY_DESC: "Home Bar",
    HOME_BAR_SUB_CATEGORY_CODE: "HOME_BAR",
    HOME_BAR_SUB_CATEGORY_DESC:"Home Bar",
    HOME_BAR_LICENSE_WORKFLOW_NAME: "LICENSE_HOME_BAR",

    // custom bond
    CUSTOM_BOND_CATEGORY: 'CUSTOM_BOND',
    CUSTOM_BOND_CATEGORY_DESC: 'Custom Bond',
    WITH_IN_UP_SUBCATEGORY: 'WITH_IN_UP',
    WITH_IN_UP_SUBCATEGORY_DESC: 'Custom Bond Within UP',
    OUTSIDE_UP_SUBCATEGORY: 'OUTSIDE_UP',
    OUTSIDE_UP_SUBCATEGORY_DESC: 'Custom Bond Outside UP',
    CUSTOM_BOND_WITH_IN_UP_WORKFLOW_NAME: 'CUSTOM_BOND_WITH_IN_UP',
    CUSTOM_BOND_OUTSIDE_UP_WORKFLOW_NAME: 'CUSTOM_BOND_OUTSIDE_UP',

    // For BHANG License

    BHANG_CATEGORY: 'BHANG',
    BHANG_CATEGORY_DESC: "Bhang",

    BHANG_ID15_CATEGORY: 'BHANG',
    BHANG_ID15_CATEGORY_DESC: "Bhang",
    BHANG_ID15_SUBCATEGORY: 'ID15',
    BHANG_ID15_SUBCATEGORY_DESC: 'ID 15',
    BHANG_ID15_WORKFLOW_NAME: 'BHANG_ID15',

    BHANG_ID16_SUBCATEGORY: 'ID16', 
    BHANG_ID16_SUBCATEGORY_DESC: 'ID 16',
    BHANG_ID16_WORKFLOW_NAME: 'BHANG_ID16',

    // For INDUSTRIAL ALCOHOL
    INDUSTRIAL_ALCOHOL_LICENSE_CATEGORY: 'INDUSTRIAL_ALCOHOL',
    INDUSTRIAL_ALCOHOL_LICENSE_CATEGORY_DESC: 'Industrial Alcohol',
    INDUSTRIAL_ALCOHOL_DS1_SUBCATEGORY: 'DS1',
    INDUSTRIAL_ALCOHOL_DS1_SUBCATEGORY_DESC: 'DS1',
    INDUSTRIAL_ALCOHOL_FL32_SUBCATEGORY: 'FL32',
    INDUSTRIAL_ALCOHOL_FL32_SUBCATEGORY_DESC: 'FL32',
    INDUSTRIAL_ALCOHOL_FL16_SUBCATEGORY: 'FL16',
    INDUSTRIAL_ALCOHOL_FL16_SUBCATEGORY_DESC: 'FL16',
    INDUSTRIAL_ALCOHOL_FL17_SUBCATEGORY: 'FL17',
    INDUSTRIAL_ALCOHOL_FL17_SUBCATEGORY_DESC: 'FL17',
    INDUSTRIAL_ALCOHOL_FL39_SUBCATEGORY: 'FL39',
    INDUSTRIAL_ALCOHOL_FL39_SUBCATEGORY_DESC: 'FL39',
    INDUSTRIAL_ALCOHOL_FL40_SUBCATEGORY: 'FL40',
    INDUSTRIAL_ALCOHOL_FL40_SUBCATEGORY_DESC: 'FL40',
    INDUSTRIAL_ALCOHOL_FL41_SUBCATEGORY: 'FL41',
    INDUSTRIAL_ALCOHOL_FL41_SUBCATEGORY_DESC: 'FL41',
    INDUSTRIAL_ALCOHOL_FL49_SUBCATEGORY: 'FL49',
    INDUSTRIAL_ALCOHOL_FL49_SUBCATEGORY_DESC: 'FL49',
    INDUSTRIAL_ALCOHOL_FL41_OUTSIDE_UP_SUBCATEGORY: 'FL41_OUTSIDE_UP',
    INDUSTRIAL_ALCOHOL_FL41_OUTSIDE_UP_SUBCATEGORY_DESC: 'FL41 Outside UP',
    INDUSTRIAL_ALCOHOL_FL32_GOVT_MEDICAL_INSTITUTIONS_SUBCATEGORY: 'FL32_GOVT_MEDICAL_INSTITUTIONS',
    INDUSTRIAL_ALCOHOL_FL32_GOVT_MEDICAL_INSTITUTIONS_SUBCATEGORY_DESC: 'FL32 for Government Hospitals/PG/Medical College',
    INDUSTRIAL_ALCOHOL_FL32_NON_GOVT_MEDICAL_INSTITUTIONS_SUBCATEGORY: 'FL32_NON_GOVT_MEDICAL_INSTITUTIONS',
    INDUSTRIAL_ALCOHOL_FL32_NON_GOVT_MEDICAL_INSTITUTIONS_SUBCATEGORY_DESC: 'FL32 for Non-Government Hospitals',
    INDUSTRIAL_ALCOHOL_FL32_EDUCATIONAL_INSTITUTIONS_SUBCATEGORY: 'FL32_GOVT_EDUCATIONAL_INSTITUTIONS',
    INDUSTRIAL_ALCOHOL_FL32_EDUCATIONAL_INSTITUTIONS_SUBCATEGORY_DESC: 'FL32 for Educational Institutions (School/Colleges)',
    INDUSTRIAL_ALCOHOL_FL32_CENTRAL_LABORATORY_SUBCATEGORY: 'FL32_CENTRAL_LABORATORY',
    INDUSTRIAL_ALCOHOL_FL32_CENTRAL_LABORATORY_SUBCATEGORY_DESC: 'FL32 for Central Laboratory',
    INDUSTRIAL_ALCOHOL_FL32_STATE_LABORATORY_SUBCATEGORY: 'FL32_STATE_LABORATORY',
    INDUSTRIAL_ALCOHOL_FL32_STATE_LABORATORY_SUBCATEGORY_DESC: 'FL32 for State Laboratory',
    INDUSTRIAL_ALCOHOL_FL32_GOVT_NON_GOVT_INSTITUTIONS_SUBCATEGORY: 'FL32_GOVT_NON_GOVT_INSTITUTIONS',
    INDUSTRIAL_ALCOHOL_FL32_GOVT_NON_GOVT_INSTITUTIONS_SUBCATEGORY_DESC: 'FL32 for Government & Non-Government Institution',
    INDUSTRIAL_ALCOHOL_FL32_SAFETY_EQUIPMENTS_MANUFACTURE_UNITS_SUBCATEGORY: 'FL32_SAFETY_EQUIPMENTS_MANUFACTURE_UNITS',
    INDUSTRIAL_ALCOHOL_FL32_SAFETY_EQUIPMENTS_MANUFACTURE_UNITS_SUBCATEGORY_DESC: 'FL32 for Safety Equipment Manufacturing Units',
    

    INDUSTRIAL_ALCOHOL_DS1_TITLE: 'License for Denaturation of Rectified Spirit/Absolute Alcohol in the distillery premises',
    INDUSTRIAL_ALCOHOL_FL16_TITLE: 'License for the wholesale vend of denatured spirit.',
    INDUSTRIAL_ALCOHOL_FL17_TITLE: 'License for retail sale of denatured spirit',
    INDUSTRIAL_ALCOHOL_FL32_TITLE: 'Permit granted to a person or institution authorized to obtain rectified spirit or absolute alcohal or both spirit free of duty and to transport the same from a distillary in UP or to import the same from a distillary warehouse or bonded laboratory in other state to his permises/ premises of the institutions',
    INDUSTRIAL_ALCOHOL_FL39_TITLE: 'License for the possession of specially denatured spirit for use in industries in which alcohol is destroyed or converted chemically in the process into other product and the product does not contain alcohal such as Ether, Styrene, Butadien, etc.',
    INDUSTRIAL_ALCOHOL_FL40_TITLE: 'License for the possession of specially denatured spirit for use in industries in which alcohol is used only as a solvent or processing agent and the product does not contain alcohol, which is genarally recovered for re-use such as Cellulose, Pectine, etc.',
    INDUSTRIAL_ALCOHOL_FL41_TITLE: 'License for possession of denatured spirit including specially denatured spirit for use in industries in which alcohol is used as solvent or vehicle directly and appears to some extent in the final product such as lacquers, varnishes etc. (Petroleum Depot)',
    INDUSTRIAL_ALCOHOL_FL49_TITLE: 'License for possession of Undenatured spirit, Bhang and other Excise articles for Medical purposes (Form No - 48)',

    /**
     * for tara sendhi
    */
    TARA_SENDHI_LICENSE_CATEGORY: 'TARA_SENDHI',
    TARA_SENDHI_LICENSE_CATEGORY_DESC: 'Tara Sendhi',
    TARA_SENDHI_CL7_SUBCATEGORY: 'CL7',
    TARA_SENDHI_CL7_SUBCATEGORY_DESC: 'CL 7',
    TARA_SENDHI_CL10_SUBCATEGORY: 'CL10',
    TARA_SENDHI_CL10_SUBCATEGORY_DESC: 'CL 10',
    TARA_SENDHI_CL10A_SUBCATEGORY: 'CL10A',
    TARA_SENDHI_CL10A_SUBCATEGORY_DESC: 'CL 10A',
    TARA_SENDHI_CL11_SUBCATEGORY: 'CL11',
    TARA_SENDHI_CL11_SUBCATEGORY_DESC: 'CL 11',
    TARA_SENDHI_CL8_SUBCATEGORY: 'CL8',
    TARA_SENDHI_CL8_SUBCATEGORY_DESC: 'CL 8',
    TARA_SENDHI_CL9_SUBCATEGORY: 'CL9',
    TARA_SENDHI_CL9_SUBCATEGORY_DESC: 'CL 9',
    TARA_SENDHI_CL12_SUBCATEGORY: 'CL12',
    TARA_SENDHI_CL12_SUBCATEGORY_DESC: 'CL 12',

    /**
     * Methyl Alcohol
    */

    METHYL_ALCOHOL_LICENSE_CATEGORY: 'METHYL_ALCOHOL',
    METHYL_ALCOHOL_LICENSE_CATEGORY_DESC: 'Methyl Alcohol',
    METHYL_ALCOHOL_WORKFLOW_NAME: 'METHYL_ALCOHOL_REGISTRATION',
    SCREEN_NAME_METHYL_ALCOHOL: 'METHYL_ALCOHOL_REGISTRATION',
    /**
     * for sacramental-narcotics
    */
    SACRAMENTAL_NARCOTICS_LICENSE_CATEGORY: 'SCRAMENTAL',
    SACRAMENTAL_NARCOTICS_LICENSE_CATEGORY_DESC: 'Sacramental wine',
    SACRAMENTAL_NARCOTICS_SW1_SUBCATEGORY: 'SW1',
    SACRAMENTAL_NARCOTICS_SW1_SUBCATEGORY_DESC: 'SW1',
    SACRAMENTAL_NARCOTICS_NDLC_EXPORT_SUBCATEGORY: 'NDLC_EXPORT',
    SACRAMENTAL_NARCOTICS_NDLC_EXPORT_SUBCATEGORY_DESC: 'NDLC Export',
    SACRAMENTAL_NARCOTICS_NDLC_IMPORT_SUBCATEGORY: 'NDLC_IMPORT',
    SACRAMENTAL_NARCOTICS_NDLC_IMPORT_SUBCATEGORY_DESC: 'NDLC License',

    NARCOTICS_LICENSE_CATEGORY: 'NARCOTIC',
    NARCOTICS_LICENSE_CATEGORY_DESC: 'Narcotic',
    NARCOTICS_NDLD_LICENSE_SUBCATEGORY: 'NDLD',
    NARCOTICS_NDLD_LICENSE_SUBCATEGORY_DESC: 'NDLD',

    SACRAMENTAL_NARCOTICS_SW1_TITLE: 'Application for issuance of Import Permit for Sacramental Wine. By this license , Religious institutions, Churches or trusts can get the sacramental wine.',
    SACRAMENTAL_NARCOTICS_NDLC_EXPORT_TITLE: 'The license of Export Permit for Narcotic Drugs (Under Rule 51, 52 and 53 of the UP Narcotic and Psychotropic substances Rule 1986). The Applicant must be minimum 21 years old to apply for the License.',
    SACRAMENTAL_NARCOTICS_NDLC_IMPORT_TITLE: 'The license of Import Permit for Narcotic Drugs (Under Rule 48(1) (2) of the UP Narcotic and Psychotropic substances Rule 1986). The Applicant must be minimum 21 years old to apply for the License.',
    
    CSD_SUPPLY_LICENSE_CATEGORY: 'CSD_SUPPLY',
    CSD_SUPPLY_LICENSE_CATEGORY_DESC: 'Csd Supply',
    CSD_SUPPLY_SUBCATEGORY: 'CSD_SUPPLY',
    CSD_SUPPLY_SUBCATEGORY_DESC: 'Csd Supply',

    /**
     * Workflow api call module and sub module codes are here
     */
    MODULE_NAME_CODE: 'LICENSE_MANAGEMENT',

    // For WHOLESALE
    WHOLESALE_FL2_WORKFLOW_NAME: 'WHOLESALE_FL2',
    WHOLESALE_FL2A_WORKFLOW_NAME: 'WHOLESALE_FL2A',
    WHOLESALE_FL2B_WORKFLOW_NAME: 'WHOLESALE_FL2B',
    WHOLESALE_CL2_WORKFLOW_NAME: 'WHOLESALE_CL2',
    WHOLESALE_ID3_WORKFLOW_NAME: 'WHOLESALE_ID3',

    // For EVENT_BAR
    EVENT_BAR_FL11_WORKFLOW_NAME: 'EVENT_BAR_FL11',
    CSD_SUPPLY_WORKFLOW_NAME: 'CSD_SUPPLY',

    // For HBR
    HBR_FL6_WORKFLOW_NAME: 'HBR_FL6',
    HBR_FL7_WORKFLOW_NAME: 'HBR_FL7',
    HBR_FL7A_WORKFLOW_NAME: 'HBR_FL7A',
    HBR_FL8_WORKFLOW_NAME: 'HBR_FL8',
    HBR_FLAL1_WORKFLOW_NAME: 'HBR_FLAL1',

    // For BWFL Parent
    BWFL_PARENT_UNIT_WORKFLOW_NAME: 'LICENSE_BWFL_PARENT_UNIT',

    // For BOND_WAREHOUSE
    BOND_WAREHOUSE_2A_WORKFLOW_NAME: 'BWFL_2A',
    BOND_WAREHOUSE_2B_WORKFLOW_NAME: 'BWFL_2B',
    BOND_WAREHOUSE_2C_WORKFLOW_NAME: 'BWFL_2C',
    BOND_WAREHOUSE_2D_WORKFLOW_NAME: 'BWFL_2D',

    // For DISTILLERY
    DISTI_BOTTL_CL_B1_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_CLB1',
    DISTI_BOTTL_CL_B2_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_CLB2',
    DISTI_BOTTL_DS_1_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_DS1',
    DISTI_BOTTL_FL_1_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_FL1',
    DISTI_BOTTL_FL_1A_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_FL1A',
    DISTI_BOTTL_FL_3_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_FL3',
    DISTI_BOTTL_FL_3A_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_FL3A',
    DISTI_BOTTL_FL_16_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_FL16',
    DISTI_BOTTL_FL_17_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_FL17',
    DISTI_BOTTL_FL_32_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_FL32',
    DISTI_BOTTL_FL_39_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_FL39',
    DISTI_BOTTL_FL_40_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_FL40',
    DISTI_BOTTL_FL_41_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_FL41',
    DISTI_BOTTL_FL_49_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_FL49',
    DISTI_BOTTL_PD_1_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_PD1',
    DISTI_BOTTL_PD_2_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_PD2',
    DISTI_BOTTL_PD_32_WORKFLOW_NAME: 'DISTILLERY_PD32',
    DISTI_BOTTL_PD_33_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_PD33',
    DISTI_BOTTL_PD_34_WORKFLOW_NAME: 'DISTILLERY_PD34',
    DISTI_BOTTL_FL_50_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_FL50',
    DISTI_BOTTL_FL_51_WORKFLOW_NAME: 'DISTILLERY_BOTTLING_FL51',

    // For RETAIL
    RETAIL_FL4A_WORKFLOW_NAME: 'RETAIL_FL4A',
    RETAIL_FL4C_WORKFLOW_NAME: 'RETAIL_FL4C',
    RETAIL_FL5B_WORKFLOW_NAME: 'RETAIL_FL5B',
    RETAIL_FL5C_WORKFLOW_NAME: 'RETAIL_FL5C',
    RETAIL_FL5D_WORKFLOW_NAME: 'RETAIL_FL5D',
    RETAIL_HM1_WORKFLOW_NAME: 'RETAIL_HM1',

    // For Retail CSD
    RETAIL_CSD_FL9_WORKFLOW_NAME: 'RETAIL_CSD_FL9',
    RETAIL_CSD_FL9A_WORKFLOW_NAME: 'RETAIL_CSD_FL9A',

    // For SUGAR_MILL
    SUGAR_MILL_WITH_IN_UP_WORKFLOW_NAME: 'SUGARMILL_WITH_IN_UP',
    SUGAR_MILL_OUT_SIDE_UP_WORKFLOW_NAME: 'SUGARMILL_OUTSIDE_UP',
    SUGAR_MILL_INDUS_IN_UP_WORKFLOW_NAME: 'SUGARMILL_INDUSTRY_INSIDE_UP',
    SUGAR_MILL_INDUS_OUT_SIDE_UP_WORKFLOW_NAME: 'SUGARMILL_INDUSTRY_OUTSIDE_UP',
    SUGAR_MILL_MOLASSESS_TRADERS_WORKFLOW_NAME: 'SUGARMILL_MOLASESS_TRADER',
    SUGAR_MILL_BELOW_GRADE_WORKFLOW_NAME: 'SUGARMILL_BELOW_GRADE',

    // For BOTTLING
    BOTTLING_CLB1_WORKFLOW_NAME:'BOTTELING_CLB1', 
    BOTTLING_CLB2_WORKFLOW_NAME:'BOTTELING_CLB2',  
    BOTTLING_FL3_WORKFLOW_NAME:'BOTTELING_FL3', 
    BOTTLING_FL3A_WORKFLOW_NAME:'BOTTELING_FL3A', 
    BOTTLING_FL50_WORKFLOW_NAME:'BOTTELING_FL50', 
    BOTTLING_FL51_WORKFLOW_NAME:'BOTTELING_FL51', 

    // For MICRO_BREWERY
    MICRO_BREWERY_MB6_WORKFLOW_NAME: 'MICRO_BREWERY_MB6',
    MICRO_BREWERY_MB2_WORKFLOW_NAME: 'MICRO_BREWERY_MB2',

    // For DISTRICT_WAREHOUSE
    DISTRICT_WAREHOUSE_FL1A_WORKFLOW_NAME: 'DISTRICT_WAREHOUSE_FL1A',
    DISTRICT_WAREHOUSE_FL1_WORKFLOW_NAME: 'DISTRICT_WAREHOUSE_FL1',

    // For BREWERY
    BREWERY_B1_WORKFLOW_NAME: 'BREWERY_B1',
    BREWERY_B19_WORKFLOW_NAME: 'BREWERY_B19',
    BREWERY_B20_WORKFLOW_NAME: 'BREWERY_B20',
    BREWERY_B21_WORKFLOW_NAME: 'BREWERY_B21',
    BREWERY_MB1_WORKFLOW_NAME: 'BREWERY_MB1',
    BREWERY_MB2_WORKFLOW_NAME: 'BREWERY_MB2',
    BREWERY_MB3_WORKFLOW_NAME: 'BREWERY_MB3',
    BREWERY_MB4_WORKFLOW_NAME: 'BREWERY_MB4',
    BREWERY_MB5_WORKFLOW_NAME: 'BREWERY_MB5',
    BREWERY_MB6_WORKFLOW_NAME: 'BREWERY_MB6',
    BREWERY_MB7_WORKFLOW_NAME: 'BREWERY_MB7',
    BREWERY_V1_WORKFLOW_NAME: 'BREWERY_V1',
    BREWERY_V2_WORKFLOW_NAME: 'BREWERY_V2',

    //FOR MASTERWAREHOUSE
    MASTER_WARE_HOUSE_WORKFLOW_NAME: 'LICENSE_MASTER_WAREHOISE_REGISTRATION',

    // For IMPORT_UNIT
    IMPORT_UNIT: 'IMPORT_UNIT',

    // For CUSTOM_BOND
    CUSTOM_BOND: 'CUSTOM_BOND',
    
    //For Industrial Alcohol
    INDUSTRIAL_ALCOHOL_DS1_WORKFLOW_NAME : 'INDUSTRIAL_ALCOHOL_DS1',
    INDUSTRIAL_ALCOHOL_FL32_WORKFLOW_NAME: 'INDUSTRIAL_ALCOHOL_FL32',
    INDUSTRIAL_ALCOHOL_FL16_WORKFLOW_NAME: 'INDUSTRIAL_ALCOHOL_FL16',
    INDUSTRIAL_ALCOHOL_FL17_WORKFLOW_NAME: 'INDUSTRIAL_ALCOHOL_FL17',
    INDUSTRIAL_ALCOHOL_FL39_WORKFLOW_NAME: 'INDUSTRIAL_ALCOHOL_FL39',
    INDUSTRIAL_ALCOHOL_FL40_WORKFLOW_NAME: 'INDUSTRIAL_ALCOHOL_FL40',
    INDUSTRIAL_ALCOHOL_FL41_WORKFLOW_NAME: 'INDUSTRIAL_ALCOHOL_FL41',
    INDUSTRIAL_ALCOHOL_FL49_WORKFLOW_NAME: 'INDUSTRIAL_ALCOHOL_FL49',
    INDUSTRIAL_ALCOHOL_FL41_OUTSIDE_WORKFLOW_NAME: 'INDUSTRIAL_ALCOHOL_FL41_OUTSIDE',
    INDUSTRIAL_ALCOHOL_FL32_GOVT_MEDICAL_INSTITUTIONS_WORKFLOW_NAME : 'INDUSTRIAL_ALCOHOL_FL32_GOVT_MEDICAL_INSTITUTIONS',
    INDUSTRIAL_ALCOHOL_FL32_NON_GOVT_MEDICAL_INSTITUTIONS_WORKFLOW_NAME: 'INDUSTRIAL_ALCOHOL_FL32_NON_GOVT_MEDICAL_INSTITUTIONS',
    INDUSTRIAL_ALCOHOL_FL32_EDUCATIONAL_INSTITUTIONS_WORKFLOW_NAME: 'INDUSTRIAL_ALCOHOL_FL32_EDUCATIONAL_INSTITUTIONS',
    INDUSTRIAL_ALCOHOL_FL32_CENTRAL_LABORATORY_WORKFLOW_NAME: 'INDUSTRIAL_ALCOHOL_FL32_CENTRAL_LABORATORY',
    INDUSTRIAL_ALCOHOL_FL32_STATE_LABORATORY_WORKFLOW_NAME: 'INDUSTRIAL_ALCOHOL_FL32_STATE_LABORATORY',
    INDUSTRIAL_ALCOHOL_FL32_GOVT_NON_GOVT_INSTITUTIONS_WORKFLOW_NAME: 'INDUSTRIAL_ALCOHOL_FL32_GOVT_NON_GOVT_INSTITUTIONS',
    INDUSTRIAL_ALCOHOL_FL32_SAFETY_EQUIPMENT_MANUFACTURE_WORKFLOW_NAME: 'INDUSTRIAL_ALCOHOL_FL32_SAFETY_EQUIPMENT_MANUFACTURING',

    // for tara sendhi
    TARA_SENDHI_CL10_WORKFLOW_NAME: 'TARA_SENDHI_CL10',
    TARA_SENDHI_CL10A_WORKFLOW_NAME: 'TARA_SENDHI_CL10A',

    // for sacramental narcotics
    SACRAMENTAL_NARCOTICS_NDLC_IMPORT_WORKFLOW_NAME: 'SCRAMENTAL_NDLC_IMPORT',
    SACRAMENTAL_NARCOTICS_NDLC_EXPORT_WORKFLOW_NAME: 'SCRAMENTAL_NDLC_EXPORT',
    SACRAMENTAL_NARCOTICS_SW1_WORKFLOW_NAME: 'SCRAMENTAL_SW1',
    SACRAMENTAL_NARCOTICS_NDLD_WORKFLOW_NAME: 'NARCOTIC_NDLD_LICENSE',
    /**
     * application code for all modules listed here
     */
    APPLI_CODE_WHOLESALE: 'WL',
    APPLI_CODE_EVENT_BAR: 'EBL',
    APPLI_CODE_HBR: 'HBRL',
    APPLI_CODE_BWFL: 'BWFL',
    APPLI_CODE_BONDWAREHOUSE: 'BWHL',
    APPLI_CODE_DISTILLERY: 'DL',
    APPLI_CODE_RETAIL: 'RL',
    APPLI_CODE_SUGAR_MILL: 'SM',
    APPLI_CODE_BOTTLING: 'BOTTELING',
    APPLI_CODE_BREWERY: 'BREW',
    APPLI_CODE_VINTINERY: 'VINTINERY',
    APPLI_CODE_MICRO_BREWERY: 'MBREW',
    APPLI_CODE_DISTRICT_WAREHOUSE: 'DISTRICT_WAREHOUSE',

    SCREEN_NAME_VENDOR: 'vendor-registration',
    SCREEN_NAME_MODULE: 'licence-mangement',
    SCREEN_NAME_WHOLESALE: 'whole-sale',
    SCREEN_NAME_EVENT_BAR: 'Event-bar',
    SCREEN_NAME_HBR: 'hbr',
    SCREEN_NAME_BWFL_PARENT: 'bwfl-parent-unit',
    SCREEN_NAME_BWFL: 'bwfl',
    SCREEN_NAME_BONDWAREHOUSE: 'bond-warehouse',
    SCREEN_NAME_DISTILLERY: 'distillery-bottling',
    SCREEN_NAME_RETAIL: 'retail',
    SCREEN_NAME_SUGAR_MILL: 'sugar-mill',
    SCREEN_NAME_BOTTLING: 'Bottling',
    SCREEN_NAME_BREWERY: 'Brewery',
    SCREEN_NAME_MICRO_BREWERY: 'Micro-Brewery',
    SCREEN_NAME_DISTRICT_WAREHOUSE: 'District-Warehouse',
    SCREEN_NAME_CUSTOM:'Custom Bond',
    SCREEN_NAME_MASTER_WARE_HOUSE:'master-ware-house',
    SCREEN_NAME_IMPORT_UNIT: 'import unit',
    SCREEN_NAME_BIO: 'bio',
    SCREEN_NAME_BIO_ZERO: 'BIO_ZERO',
    SCREEN_NAME_BIO_ZERO_WITHIN_UP: 'LICENSE_BIO_ZERO_WITHIN_UP',
    SCREEN_NAME_BIO_ZERO_OUTSIDE_UP: 'LICENSE_BIO_ZERO_OUTSIDE_UP',
    SCREEN_NAME_HOME_BAR: 'LICENSE_HOME_BAR',
    SCREEN_NAME_MASTER_WAREHOUSE: 'master ware house',
    SCREEN_NAME_VINTINERY: 'VINTINERY',
    SCREEN_NAME_INDUSTRIAL_ALCOHOL: 'Industrial-alcohol',
    SCREEN_NAME_TARA_SENDHI: 'Tara-sendhi',
    SCREEN_NAME_BHANG: 'bhang',
    SCREEN_NAME_SACRAMENTAL_NARCOTICS: 'scramental',
    SCREEN_NAME_NARCOTICS: 'NARCOTIC_NDLD_LICENSE',
    SCREEN_NAME_SUSPEND: 'suspend',
    SCREEN_NAME_CUSTOM_BOND: 'CustomBond',

    LICENSE_URL: {
        VIEW_LICENSE_STATUS: '/licencemgnt/viewLicenseStatus/',
        VIEW_LICENSE_STATUS_BWFL: '/licencemgnt/viewStatusBwfl/',
        ACKNOWLEDGEMENT: '/licencemgnt/acknowledgement/',
        PAYMENT: '/licencemgnt/payment/',
        PAYMENT_FAILURE: '/licencemgnt/payment-failure/',
        SUGAR_MILL_OLD: '/licencemgnt/sugarmill-license/',
        SUGAR_MILL: '/licencemgnt/sugar-mill/',
        RETAIL: '/licencemgnt/retailmodel/',
        RETAIL_CSD: '/licencemgnt/retailcsdmodel/',
        WHOLESALE: '/licencemgnt/wholesale/',
        IMPORT_UNIT: '/licencemgnt/impliquor/',
        EVENT_BAR: '/licencemgnt/eventbarlicense/',
        DISTILLERY: '/licencemgnt/distillery-bottling/',
        HBR: '/licencemgnt/hbr/',
        BHANG:'licencemgnt/bhang',
        BWFL: '/licencemgnt/bondwarehouse/',
        PARENT_UNIT: '/licencemgnt/bwflparentunit/',
        WAREHOUSE: '/licencemgnt/bondwarehouse/',
        SECURITY_AMOUNT: '/licencemgnt/securityAmount/',
        BOTTLING: '/licencemgnt/bottling-license/',
        BIO: '/licencemgnt/bio/',
        BIO1A: 'licencemgnt/bio1A',
        BIO_ZERO: '/licencemgnt/bio-zero/',
        HOME_BAR: '/licencemgnt/home-bar-license/',
        Home_Bar_License:'licencemgnt/hom-bar-license',
        BOTTELING: '/licencemgnt/bottling-new-license/',
        MICRO_BREWERY: '/licencemgnt/micro-brewery/',
        METHYL_ALCOHOL:'/licencemgnt/methyl-alcohol/',
        DISTRICT_WAREHOUSE: '/licencemgnt/district-warehouse-license/',
        MASTER_WARE_HOUSE: '/licencemgnt/masterwarehouse/',
        BREWERY: '/licencemgnt/brewery-license/',
        INDUSTRIAL_ALCOHOL: '/licencemgnt/industrial-alcohol/',
		VINTINERY: '/licencemgnt/vintinery/',
        TARA_SENDHI: '/licencemgnt/tara-sendhi/',
        SCRAMENTAL: '/licencemgnt/scramental/',
        NARCOTIC: '/licencemgnt/ndld-license/',
        ELOTTERY_PAYMENT: '/licencemgnt/elottery-payment/',
        ELOTTERY_ACKNOWLEDGEMENT: '/licencemgnt/elottery-acknowledgement/',
        CUSTOM_BOND: '/licencemgnt/custom-bond/',
        CSD_SUPPLY: '/licencemgnt/csdsupply',
        OTHER_ACKNOWLEDGEMENT: '/licencemgnt/other-acknowledgement/',
        PERMIT_STATUS: '/licencemgnt/beerPermitRoom',
        PERMIT_PAYMENT: '/licencemgnt/permit-payment',
        PERMIT_ACKNOWLEDGEMENT: '/licencemgnt/permit-acknowledgement/',
    },
    VENDOR_URL: {
        VIEW_VENDOR_REGISTRATION_LIST: 'master/vehicletracking/list',
    },
    docuPropObj: {
        applicantDetails: 'applicantDocumentList',
        addressList: 'addressDocumentList',
        bankList: 'bankDocumentList',
        attachmentDetails: 'attachmentDocumentList',
        licenseBankDetails: 'bankDocumentList',
        businessUnitDetails: 'businessUnitDocumentList',
        securityAmountList: 'securityDocumentList',
        licenseOfficersList:'officerDocumentList',
        spaceAllocationDocuments: 'spaceAllocationDocuments',

        // for WHOLESALE
        wholeSaleAdditionalDetails: 'additionalDocumentList',

        // for HBR
        hbrAuthorizePerson: 'authorizePersonDocumentList',
        hbrSeftAttestation: 'attestationDocumentList',
        hbrAdditionalDetails: 'additionalDocumentList',
        hbrPremisesDetails: 'premisesDocumentList',
        hbrFirmCompanyDetailList: 'firmCompanyDocumentList',

        // for DISTILLERY
        distilleryAdditionalDetails: 'additionalDocumentList',

        // for RETAIL
        retailAdditionalDetails: 'additionalDocumentList',
        retailEMDDetails: 'emdDocumentList',
        retailShopDetails: 'retailShopDocumentList',
        salesManDetail: 'salesManDocumentList',
        salesManDetailList: 'salesManDocumentList',

        // for SUGARMILL
        sugarMillAdditionalDetails: 'additionalDocumentList',

        // for BOTTLING
        bottlingAdditionalDetails: 'additionalDocumentList',

        // for INDUSTRIAL ALCOHOL
        industrialAdditionalDetails: 'additionalDocumentList',
    },

    /**
     * License category and subcategory for Bottling
     */
     BOTTLING_CL_B1_SUBCATEGORY: 'CLB1',
     BOTTLING_CL_B1_SUBCATEGORY_DESC: 'CL B1',
     BOTTLING_CL_B2_SUBCATEGORY: 'CLB2',
     BOTTLING_CL_B2_SUBCATEGORY_DESC: 'CL B2',
 
     /**
      * DISTILLERY
      */
      UPLOAD_BRIEF_PROCESS_MANUFACTURE_DOC:'DISTILLERY_PROCESS_DOC',
      BLUEPRINT_UNIT_FILE_DOC:'BLUEPRINT_UNIT_FILE_DOC',
      LAND_DOCUMENT_FILE_DOC:'LAND_DOCUMENT_FILE_DOC',
      MOA_COMPANY_DIRECTORS_FILE_DOC:'MOA_COMPANY_DIRECTORS_DOC',
      GST_REGISTRATION_CERTIFICATE_FILE_DOC:'GST_REGISTRATION_CERTIFICATE_FILE_DOC',
      APPLICATION_SUBMITTED_FIRE_DEPARTMENT_FILE_DOC:'APPLICATION_SUBMITTED_FIRE_DEPARTMENT_FILE_DOC',
      ETHANOL_STORAGE_LICENSE_PESO_FILE_DOC:'ETHANOL_STORAGE_LICENSE_PESO_FILE_DOC',
      IEM_MINISTRY_INDUSTRIES_GOI_FILE_DOC:'IEM_MINISTRY_INDUSTRIES_GOI_FILE_DOC',
      APPLICATION_SUBMITTED_CENTRAL_POLLUTION_CONTROL_BOARD_FILE_DOC:'APPLICATION_SUBMITTED_CENTRAL_POLLUTION_CONTROL_BOARD_FILE_DOC',
      APPLICATION_SUBMITTED_POLLUTION_CONTROL_BOARD_FILE_DOC:'APPLICATION_SUBMITTED_POLLUTION_CONTROL_BOARD_FILE_DOC',
      ENVIRONMENT_FOREST_DEPARTMENT_FILE_DOC:'ENVIRONMENT_FOREST_DEPARTMENT_FILE_DOC',
      DETAILS_MOLASSES_STORAGE_FILE_DOC:'DETAILS_MOLASSES_STORAGE_FILE_DOC',
      DETAILS_PLANTS_MACHINERIES_FORM_FILE_DOC:'DETAILS_PLANTS_MACHINERIES_FORM_FILE_DOC',
      INFORMATION_NOTIFICATION_FILE_DOC:'INFORMATION_NOTIFICATION_FILE_DOC',
      DOCUMENT_CALCULATION_CAPACITY_DISTILLERY_FERMENTATION_HOUSE_FILE_DOC:'DOCUMENT_CALCULATION_CAPACITY_DISTILLERY_FERMENTATION_HOUSE_FILE_DOC',
      DOCUMENT_DEPICTING_FLOW_DIAGRAM_SECTION_FILE_DOC:'DOCUMENT_DEPICTING_FLOW_DIAGRAM_SECTION_FILE_DOC',
      DETAIL_ALCOHOL_RECEIVER_TANK_DIMENSION_FILE_DOC:'DETAIL_ALCOHOL_RECEIVER_TANK_DIMENSION_FILE_DOC',
      DETAIL_STORAGE_TANK_DIMENSION_FILE_DOC:'DETAIL_STORAGE_TANK_DIMENSION_FILE_DOC',
      BLUEPRINT_FERMENTATION_DISTILLATION_SECTION_FILE_DOC: 'BLUEPRINT_FERMENTATION_DISTILLATION_SECTION_FILE_DOC',
      DOCUMENT_DETAILING_UTILITY_DISTILLERY_BLUEPRINT_FILE_DOC:'DOCUMENT_DETAILING_UTILITY_DISTILLERY_BLUEPRINT_FILE_DOC',
      NOTARIZED_AFFIDAVIT_DECLARING_COGNIZANT_OFFENSE_FILE_DOC:'NOTARIZED_AFFIDAVIT_DECLARING_COGNIZANT_OFFENSE_FILE_DOC',
      NO_DUES_CERTIFICATE_DISTRICT_COLLECTOR_CONCERNED_FILE_DOC:'NO_DUES_CERTIFICATE_DISTRICT_COLLECTOR_CONCERNED_FILE_DOC',
      PRODUCTION_LIQUOR_DETAILS_FILE_DOC:'PRODUCTION_LIQUOR_DETAILS_FILE_DOC',
      GAUGE_CHART_FILE_DOC:'GAUGE_CHART_FILE_DOC',
      POLLUTION_NOC_FILE_DOC:'POLLUTION_NOC_FILE_DOC',
      PESO_NOC_FILE_DOC:'PESO_NOC_FILE_DOC',
      APPLICATION_TECHNICAL_INSPECTION_FILE_DOC:'APPLICATION_TECHNICAL_INSPECTION_FILE_DOC',
      MOLASSES_STORAGE_TANK_FILE_DOC:'MOLASSES_STORAGE_TANK_FILE_DOC',
      PLANS_AND_MACHINERIES_FILE_DOC:'PLANS_AND_MACHINERIES_FILE_DOC',
      ALCOHOL_RECEIVER_TANK_FILE_DOC:'ALCOHOL_RECEIVER_TANK_FILE_DOC',
      STORAGE_TANK_DIMENSION_FILE_DOC:'STORAGE_TANK_DIMENSION_FILE_DOC',
      BLUEPRINT_FERMENTATION_DISTILLATION_FILE_DOC:'BLUEPRINT_FERMENTATION_DISTILLATION_FILE_DOC',
      PRODUCTION_LIQUOR_BLENDING_TANK_FILE_DOC:'PRODUCTION_LIQUOR_BLENDING_TANK_FILE_DOC',
      TANK_BLUEPRINT_FILE_DOC:'TANK_BLUEPRINT_FILE_DOC',
      TANK_FLOW_DIAGRAM_FILE_DOC:'TANK_FLOW_DIAGRAM_FILE_DOC',
      TANK_ELEVATION_PLAN_FILE_DOC:'TANK_ELEVATION_PLAN_FILE_DOC',
      BUSINESS_UNIT_DOC:'BUSINESS_UNIT_DOC',
      MOA_COMPANY_DIRECTORS_DOC:'MOA_COMPANY_DIRECTORS_DOC',
      DRAWINGS_HEIGHT_DIAMETER_FILE_DOC:'DRAWINGS_HEIGHT_DIAMETER_FILE_DOC',
      FF_MANUFACTURER_DOCUMENT:'FF_MANUFACTURER_DOCUMENT',

      /**
      * VINTINERY
      */
    ENVIRONMENTAL_CLEARANCE_FORECAST_CLIMATE:'ENVIRONMENTAL_CLEARANCE_FORECAST_CLIMATE_DOC',
    BLUE_PRINT_BUILDING_PLANT_MACHINERY:'BLUE_PRINT_BUILDING_PLANT_MACHINERY_DOC',
    BLUEPRINT_OF_PLAN_ELEVATION:'BLUEPRINT_OF_PLAN_ELEVATION_DOC',
    GST_REGISTRATION_CERTIFICATE:'GST_REGISTRATION_CERTIFICATE_DOC',
    MOA_OF_COMPANY:'MOA_OF_COMPANY_DOC',
    PLANTS_AND_MACHINERIES:'PLANTS_AND_MACHINERIES_DOC',
    DEPICTING_FLOW_DIAGRAM:'DEPICTING_FLOW_DIAGRAM_DOC',
    WINERY_BLUE_PRINT:'WINERY_BLUE_PRINT_DOC',
    CAPACITY_WINERY_FERMENTATION_HOUSE:'CAPACITY_WINERY_FERMENTATION_HOUSE_DOC',
    NOTARIZED_AFFIDAVIT_DECLARING:'NOTARIZED_AFFIDAVIT_DECLARING_DOC',
    CHARACTER_CERTIFICATE_POLICE:'CHARACTER_CERTIFICATE_POLICE_DOC',
    DETAILS_BOTTLING_TANKS_BOTTLING:'DETAILS_BOTTLING_TANKS_BOTTLING_DOC',
    NO_DUES_CERTIFICATE_DISTRICT_COLLECTOR:'NO_DUES_CERTIFICATE_DISTRICT_COLLECTOR_DOC',
    DETAIL_ALL_TANKS_DIMENSION:'DETAIL_ALL_TANKS_DIMENSION_DOC',
    DETAIL_GRAPE_OTHER_FOOD:'DETAIL_GRAPE_OTHER_FOOD_DOC',
    APPLICATION_SUBMITTED_FIRE_DEPARTMENT:'APPLICATION_SUBMITTED_FIRE_DEPARTMENT_DOC',
    IEM_MINISTRY_INDUSTRIES_GOI:'IEM_MINISTRY_INDUSTRIES_GOI_DOC',
    CENTRAL_POLLUTION_CONTROL_BOARD:'CENTRAL_POLLUTION_CONTROL_BOARD_DOC',
    POLLUTION_CONTROL_BOARD_UP:'POLLUTION_CONTROL_BOARD_UP_DOC',
    PAN_NO_OF_UNIT:'PAN_NO_OF_UNIT_DOC',
    UNIT_PAN_DOCUMENT:'UNIT_PAN_DOCUMENT_DOC',
    ATTACHMENT_AFFIDAVIT:'ATTACHMENT_AFFIDAVIT_DOC',
    LAND_PLANT_INSTALLED:'LAND_PLANT_INSTALLED_DOC',
    CALIBRATION_DOCUMENT_FILE_DOC:'CALIBRATION_DOCUMENT_FILE_DOC',
    MUNICIPALITY_CERTIFICATE_FILE_DOC:'MUNICIPALITY_CERTIFICATE_FILE_DOC',
};


export const SubCategoryDescConstants = {
    WHOLESALE: {
        [LicenseConstants.WHOLESALE_FL2_SUBCATEGORY]: LicenseConstants.WHOLESALE_FL2_SUBCATEGORY_DESC,
        [LicenseConstants.WHOLESALE_FL2A_SUBCATEGORY]: LicenseConstants.WHOLESALE_FL2A_SUBCATEGORY_DESC,
        [LicenseConstants.WHOLESALE_FL2B_SUBCATEGORY]: LicenseConstants.WHOLESALE_FL2B_SUBCATEGORY_DESC,
        [LicenseConstants.WHOLESALE_CL2_SUBCATEGORY]: LicenseConstants.WHOLESALE_CL2_SUBCATEGORY_DESC,
        [LicenseConstants.WHOLESALE_ID3_SUBCATEGORY]: LicenseConstants.WHOLESALE_ID3_SUBCATEGORY_DESC,
    },
    HBR: {
        [LicenseConstants.HBR_FL6_SUBCATEGORY]: LicenseConstants.HBR_FL6_SUBCATEGORY_DESC,
        [LicenseConstants.HBR_FL7_SUBCATEGORY]: LicenseConstants.HBR_FL7_SUBCATEGORY_DESC,
        [LicenseConstants.HBR_FL7A_SUBCATEGORY]: LicenseConstants.HBR_FL7A_SUBCATEGORY_DESC,
        [LicenseConstants.HBR_FL8_SUBCATEGORY]: LicenseConstants.HBR_FL8_SUBCATEGORY_DESC,
        [LicenseConstants.HBR_FLAL1_SUBCATEGORY]: LicenseConstants.HBR_FLAL1_SUBCATEGORY_DESC,
    },
    BWFL: {
        [LicenseConstants.BWFL_PARENT_LICENSE_SUBCATEGORY]: LicenseConstants.BWFL_PARENT_LICENSE_SUBCATEGORY_DESC
    },
    WAREHOUSE: {
        [LicenseConstants.BOND_WAREHOUSE_2A_SUBCATEGORY]: LicenseConstants.BOND_WAREHOUSE_2A_SUBCATEGORY_DESC,
        [LicenseConstants.BOND_WAREHOUSE_2B_SUBCATEGORY]: LicenseConstants.BOND_WAREHOUSE_2B_SUBCATEGORY_DESC,
        [LicenseConstants.BOND_WAREHOUSE_2C_SUBCATEGORY]: LicenseConstants.BOND_WAREHOUSE_2C_SUBCATEGORY_DESC,
        [LicenseConstants.BOND_WAREHOUSE_2D_SUBCATEGORY]: LicenseConstants.BOND_WAREHOUSE_2D_SUBCATEGORY_DESC,
    },
    DISTILLERY: {
        [LicenseConstants.DISTI_BOTTL_CLB1_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_CLB1_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_CLB2_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_CLB2_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_DS1_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_DS1_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_FL1_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL1_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_FL1A_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL1A_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_FL3_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL3_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_FL3A_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL3A_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_FL16_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL16_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_FL17_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL17_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_FL32_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL32_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_FL39_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL39_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_FL40_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL40_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_FL41_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL41_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_FL49_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL49_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_PD1_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_PD1_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_PD2_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_PD2_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_PD32_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_PD32_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_PD33_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_PD33_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_PD34_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_PD34_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_FL50_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL50_SUBCATEGORY_DESC,
        [LicenseConstants.DISTI_BOTTL_FL51_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL51_SUBCATEGORY_DESC
    },
    RETAIL: {
        [LicenseConstants.RETAIL_FL4A_SUBCATEGORY]: LicenseConstants.RETAIL_FL4A_SUBCATEGORY_DESC,
        [LicenseConstants.RETAIL_FL4C_SUBCATEGORY]: LicenseConstants.RETAIL_FL4C_SUBCATEGORY_DESC,
        [LicenseConstants.RETAIL_FL5B_SUBCATEGORY]: LicenseConstants.RETAIL_FL5B_SUBCATEGORY_DESC,
        [LicenseConstants.RETAIL_FL5C_SUBCATEGORY]: LicenseConstants.RETAIL_FL5C_SUBCATEGORY_DESC,
        [LicenseConstants.RETAIL_FL5D_SUBCATEGORY]: LicenseConstants.RETAIL_FL5D_SUBCATEGORY_DESC,
        [LicenseConstants.RETAIL_HM1_SUBCATEGORY]: LicenseConstants.RETAIL_HM1_SUBCATEGORY_DESC,
    },
    RETAIL_CSD: {
        [LicenseConstants.RETAIL_CSD_FL9_SUBCATEGORY]: LicenseConstants.RETAIL_CSD_FL9_SUBCATEGORY_DESC,
        [LicenseConstants.RETAIL_CSD_FL9A_SUBCATEGORY]: LicenseConstants.RETAIL_CSD_FL9A_SUBCATEGORY_DESC,
    },
    SUGAR_MILL: {
        [LicenseConstants.SUGAR_MILL_WITH_IN_UP_SUBCATEGORY]: LicenseConstants.SUGAR_MILL_WITH_IN_UP_SUBCATEGORY_DESC,
        [LicenseConstants.SUGAR_MILL_OUT_SIDE_UP_SUBCATEGORY]: LicenseConstants.SUGAR_MILL_OUT_SIDE_UP_SUBCATEGORY_DESC,
        [LicenseConstants.SUGAR_MILL_INDUS_IN_UP_SUBCATEGORY]: LicenseConstants.SUGAR_MILL_INDUS_IN_UP_SUBCATEGORY_DESC,
        [LicenseConstants.SUGAR_MILL_INDUS_OUT_SIDE_UP_SUBCATEGORY]: LicenseConstants.SUGAR_MILL_INDUS_OUT_SIDE_UP_SUBCATEGORY_DESC,
        [LicenseConstants.SUGAR_MILL_MOLASSESS_TRADERS_SUBCATEGORY]: LicenseConstants.SUGAR_MILL_MOLASSESS_TRADERS_SUBCATEGORY_DESC,
        [LicenseConstants.SUGAR_MILL_BELOW_GRADE_SUBCATEGORY]: LicenseConstants.SUGAR_MILL_BELOW_GRADE_SUBCATEGORY_DESC,
    },
    BREWERY: {
        [LicenseConstants.BREWERY_B1_SUBCATEGORY]: LicenseConstants.BREWERY_B1_SUBCATEGORY_DESC,
        [LicenseConstants.BREWERY_B19_SUBCATEGORY]: LicenseConstants.BREWERY_B19_SUBCATEGORY_DESC,
        [LicenseConstants.BREWERY_B20_SUBCATEGORY]: LicenseConstants.BREWERY_B20_SUBCATEGORY_DESC,
        [LicenseConstants.BREWERY_B21_SUBCATEGORY]: LicenseConstants.BREWERY_B21_SUBCATEGORY_DESC,
        [LicenseConstants.BREWERY_MB1_SUBCATEGORY]: LicenseConstants.BREWERY_MB1_SUBCATEGORY_DESC,
        [LicenseConstants.BREWERY_MB2_SUBCATEGORY]: LicenseConstants.BREWERY_MB2_SUBCATEGORY_DESC,
        [LicenseConstants.BREWERY_MB3_SUBCATEGORY]: LicenseConstants.BREWERY_MB3_SUBCATEGORY_DESC,
        [LicenseConstants.BREWERY_MB4_SUBCATEGORY]: LicenseConstants.BREWERY_MB4_SUBCATEGORY_DESC,
        [LicenseConstants.BREWERY_MB5_SUBCATEGORY]: LicenseConstants.BREWERY_MB5_SUBCATEGORY_DESC,
        [LicenseConstants.BREWERY_MB6_SUBCATEGORY]: LicenseConstants.BREWERY_MB6_SUBCATEGORY_DESC,
        [LicenseConstants.BREWERY_MB7_SUBCATEGORY]: LicenseConstants.BREWERY_MB7_SUBCATEGORY_DESC,
        [LicenseConstants.BREWERY_V1_SUBCATEGORY]: LicenseConstants.BREWERY_V1_SUBCATEGORY_DESC,
        [LicenseConstants.BREWERY_V2_SUBCATEGORY]: LicenseConstants.BREWERY_V2_SUBCATEGORY_DESC,
    },
    MASTER_WARE_HOUSE: {
        [LicenseConstants.MASTER_WARE_HOUSE_CATEGORY]: LicenseConstants.MASTER_WARE_HOUSE_SUBCATEGORY_DESC
    },
    IMPORT_UNIT: {
        [LicenseConstants.IMPORT_UNIT_CATEGORY]: LicenseConstants.IMPORT_UNIT_CATEGORY_DESC,
    },
    BOLLINGLICENSE: {
        [LicenseConstants.BOTTLING_CLB1_SUBCATEGORY]: LicenseConstants.BOTTLING_CLB1_SUBCATEGORY_DESC,
        [LicenseConstants.BOTTLING_CLB2_SUBCATEGORY]: LicenseConstants.BOTTLING_CLB2_SUBCATEGORY_DESC,
        [LicenseConstants.BOTTLING_FL3_SUBCATEGORY]: LicenseConstants.BOTTLING_FL3_SUBCATEGORY_DESC,
        [LicenseConstants.BOTTLING_FL3A_SUBCATEGORY]: LicenseConstants.BOTTLING_FL3A_SUBCATEGORY_DESC,
        [LicenseConstants.BOTTLING_FL50_SUBCATEGORY]: LicenseConstants.BOTTLING_FL50_SUBCATEGORY_DESC,
        [LicenseConstants.BOTTLING_FL51_SUBCATEGORY]: LicenseConstants.BOTTLING_FL51_SUBCATEGORY_DESC
    },
    INDUSTRIAL_ALCOHOL: {
        [LicenseConstants.INDUSTRIAL_ALCOHOL_DS1_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_DS1_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL16_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL16_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL17_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL17_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL39_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL39_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL40_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL40_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL41_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL41_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL49_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL49_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL41_OUTSIDE_UP_SUBCATEGORY]:LicenseConstants.INDUSTRIAL_ALCOHOL_FL41_OUTSIDE_UP_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_NON_GOVT_MEDICAL_INSTITUTIONS_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_NON_GOVT_MEDICAL_INSTITUTIONS_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_GOVT_MEDICAL_INSTITUTIONS_SUBCATEGORY]:LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_GOVT_MEDICAL_INSTITUTIONS_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_EDUCATIONAL_INSTITUTIONS_SUBCATEGORY]:LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_EDUCATIONAL_INSTITUTIONS_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_CENTRAL_LABORATORY_SUBCATEGORY]:LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_CENTRAL_LABORATORY_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_STATE_LABORATORY_SUBCATEGORY]:LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_STATE_LABORATORY_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_GOVT_NON_GOVT_INSTITUTIONS_SUBCATEGORY]:LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_GOVT_NON_GOVT_INSTITUTIONS_SUBCATEGORY_DESC,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_SAFETY_EQUIPMENTS_MANUFACTURE_UNITS_SUBCATEGORY]:LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_SAFETY_EQUIPMENTS_MANUFACTURE_UNITS_SUBCATEGORY_DESC
    },
    TARA_SENDHI: {
        [LicenseConstants.TARA_SENDHI_CL10_SUBCATEGORY]: LicenseConstants.TARA_SENDHI_CL10_SUBCATEGORY_DESC,
    },
    SACRAMENTAL_NARCOTICS: {
        [LicenseConstants.SACRAMENTAL_NARCOTICS_SW1_SUBCATEGORY]: LicenseConstants.SACRAMENTAL_NARCOTICS_SW1_SUBCATEGORY_DESC,
        [LicenseConstants.SACRAMENTAL_NARCOTICS_NDLC_IMPORT_SUBCATEGORY]: LicenseConstants.SACRAMENTAL_NARCOTICS_NDLC_IMPORT_SUBCATEGORY_DESC
    },
    NARCOTIC: {
        [LicenseConstants.NARCOTICS_NDLD_LICENSE_SUBCATEGORY]: LicenseConstants.NARCOTICS_NDLD_LICENSE_SUBCATEGORY_DESC
    },
    BIO_ZERO: {
        [LicenseConstants.BIO_ZERO_WITHIN_UP_LICENSE_SUBCATEGORY]: LicenseConstants.BIO_ZERO_WITHIN_UP_LICENSE_SUBCATEGORY_DESC,
        [LicenseConstants.BIO_ZERO_OUTSIDE_UP_LICENSE_SUBCATEGORY]: LicenseConstants.BIO_ZERO_OUTSIDE_UP_LICENSE_SUBCATEGORY_DESC
    },
    HOME_BAR: {
        [LicenseConstants.HOME_BAR_SUB_CATEGORY_CODE]: LicenseConstants.HOME_BAR_SUB_CATEGORY_DESC,
    },
    DISTRICT_WAREHOUSE: {
        [LicenseConstants.DISTRICT_WAREHOUSE_FL1A_SUBCATEGORY]: LicenseConstants.DISTRICT_WAREHOUSE_FL1A_SUBCATEGORY_DESC,
        [LicenseConstants.DISTRICT_WAREHOUSE_FL1_SUBCATEGORY]: LicenseConstants.DISTRICT_WAREHOUSE_FL1_SUBCATEGORY_DESC
    },
    MICRO_BREWERY: {
        [LicenseConstants.MICRO_BREWERY_MB6_SUBCATEGORY]: LicenseConstants.MICRO_BREWERY_MB6_SUBCATEGORY_DESC,
        [LicenseConstants.MICRO_BREWERY_MB2_SUBCATEGORY]: LicenseConstants.MICRO_BREWERY_MB2_SUBCATEGORY_DESC
    },

};

export const WorkFlowNameListConstants = {
    WHOLESALE: {
        [LicenseConstants.WHOLESALE_FL2_SUBCATEGORY]: LicenseConstants.WHOLESALE_FL2_WORKFLOW_NAME,
        [LicenseConstants.WHOLESALE_FL2A_SUBCATEGORY]: LicenseConstants.WHOLESALE_FL2A_WORKFLOW_NAME,
        [LicenseConstants.WHOLESALE_FL2B_SUBCATEGORY]: LicenseConstants.WHOLESALE_FL2B_WORKFLOW_NAME,
        [LicenseConstants.WHOLESALE_CL2_SUBCATEGORY]: LicenseConstants.WHOLESALE_CL2_WORKFLOW_NAME,
        [LicenseConstants.WHOLESALE_ID3_SUBCATEGORY]: LicenseConstants.WHOLESALE_ID3_WORKFLOW_NAME,
    },
    HBR: {
        [LicenseConstants.HBR_FL6_SUBCATEGORY]: LicenseConstants.HBR_FL6_WORKFLOW_NAME,
        [LicenseConstants.HBR_FL7_SUBCATEGORY]: LicenseConstants.HBR_FL7_WORKFLOW_NAME,
        [LicenseConstants.HBR_FL7A_SUBCATEGORY]: LicenseConstants.HBR_FL7A_WORKFLOW_NAME,
        [LicenseConstants.HBR_FL8_SUBCATEGORY]: LicenseConstants.HBR_FL8_WORKFLOW_NAME,
        [LicenseConstants.HBR_FLAL1_SUBCATEGORY]: LicenseConstants.HBR_FLAL1_WORKFLOW_NAME,
    },
    EVENT_BAR: {
        [LicenseConstants.EVENT_BAR_FL11_SUBCATEGORY]: LicenseConstants.EVENT_BAR_FL11_WORKFLOW_NAME
    },
    CSD_SUPPLY: {
        [LicenseConstants.CSD_SUPPLY_SUBCATEGORY]: LicenseConstants.CSD_SUPPLY_WORKFLOW_NAME
    },
    BWFL: {
        [LicenseConstants.BWFL_PARENT_LICENSE_SUBCATEGORY]: LicenseConstants.BWFL_PARENT_UNIT_WORKFLOW_NAME
    },
    WAREHOUSE: {
        [LicenseConstants.BOND_WAREHOUSE_2A_SUBCATEGORY]: LicenseConstants.BOND_WAREHOUSE_2A_WORKFLOW_NAME,
        [LicenseConstants.BOND_WAREHOUSE_2B_SUBCATEGORY]: LicenseConstants.BOND_WAREHOUSE_2B_WORKFLOW_NAME,
        [LicenseConstants.BOND_WAREHOUSE_2C_SUBCATEGORY]: LicenseConstants.BOND_WAREHOUSE_2C_WORKFLOW_NAME,
        [LicenseConstants.BOND_WAREHOUSE_2D_SUBCATEGORY]: LicenseConstants.BOND_WAREHOUSE_2D_WORKFLOW_NAME,
    },
    DISTILLERY: {
        [LicenseConstants.DISTI_BOTTL_CLB1_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_CL_B1_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_CLB2_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_CL_B2_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_DS1_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_DS_1_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_FL1_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL_1_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_FL1A_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL_1A_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_FL3_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL_3_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_FL3A_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL_3A_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_FL16_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL_16_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_FL17_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL_17_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_FL32_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL_32_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_FL39_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL_39_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_FL40_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL_40_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_FL41_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL_41_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_FL49_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL_49_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_PD1_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_PD_1_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_PD2_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_PD_2_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_PD32_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_PD_32_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_PD33_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_PD_33_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_PD34_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_PD_34_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_FL50_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL_50_WORKFLOW_NAME,
        [LicenseConstants.DISTI_BOTTL_FL51_SUBCATEGORY]: LicenseConstants.DISTI_BOTTL_FL_51_WORKFLOW_NAME
    },
    RETAIL: {
        [LicenseConstants.RETAIL_FL4A_SUBCATEGORY]: LicenseConstants.RETAIL_FL4A_WORKFLOW_NAME,
        [LicenseConstants.RETAIL_FL4C_SUBCATEGORY]: LicenseConstants.RETAIL_FL4C_WORKFLOW_NAME,
        [LicenseConstants.RETAIL_FL5B_SUBCATEGORY]: LicenseConstants.RETAIL_FL5B_WORKFLOW_NAME,
        [LicenseConstants.RETAIL_FL5C_SUBCATEGORY]: LicenseConstants.RETAIL_FL5C_WORKFLOW_NAME,
        [LicenseConstants.RETAIL_FL5D_SUBCATEGORY]: LicenseConstants.RETAIL_FL5D_WORKFLOW_NAME,
        [LicenseConstants.RETAIL_HM1_SUBCATEGORY]: LicenseConstants.RETAIL_HM1_WORKFLOW_NAME,
    },
    RETAIL_CSD: {
        [LicenseConstants.RETAIL_CSD_FL9_SUBCATEGORY]: LicenseConstants.RETAIL_CSD_FL9_WORKFLOW_NAME,
        [LicenseConstants.RETAIL_CSD_FL9A_SUBCATEGORY]: LicenseConstants.RETAIL_CSD_FL9A_WORKFLOW_NAME,
    },
    SUGAR_MILL: {
        [LicenseConstants.SUGAR_MILL_WITH_IN_UP_SUBCATEGORY]: LicenseConstants.SUGAR_MILL_WITH_IN_UP_WORKFLOW_NAME,
        [LicenseConstants.SUGAR_MILL_OUT_SIDE_UP_SUBCATEGORY]: LicenseConstants.SUGAR_MILL_OUT_SIDE_UP_WORKFLOW_NAME,
        [LicenseConstants.SUGAR_MILL_INDUS_IN_UP_SUBCATEGORY]: LicenseConstants.SUGAR_MILL_INDUS_IN_UP_WORKFLOW_NAME,
        [LicenseConstants.SUGAR_MILL_INDUS_OUT_SIDE_UP_SUBCATEGORY]: LicenseConstants.SUGAR_MILL_INDUS_OUT_SIDE_UP_WORKFLOW_NAME,
        [LicenseConstants.SUGAR_MILL_MOLASSESS_TRADERS_SUBCATEGORY]: LicenseConstants.SUGAR_MILL_MOLASSESS_TRADERS_WORKFLOW_NAME,
        [LicenseConstants.SUGAR_MILL_BELOW_GRADE_SUBCATEGORY]: LicenseConstants.SUGAR_MILL_BELOW_GRADE_WORKFLOW_NAME,
    },
    BOTTELING: {
        [LicenseConstants.BOTTLING_CLB1_SUBCATEGORY]: LicenseConstants.BOTTLING_CLB1_WORKFLOW_NAME,
        [LicenseConstants.BOTTLING_CLB2_SUBCATEGORY]: LicenseConstants.BOTTLING_CLB2_WORKFLOW_NAME,
        [LicenseConstants.BOTTLING_FL3_SUBCATEGORY]: LicenseConstants.BOTTLING_FL3_WORKFLOW_NAME,
        [LicenseConstants.BOTTLING_FL3A_SUBCATEGORY]: LicenseConstants.BOTTLING_FL3A_WORKFLOW_NAME,
        [LicenseConstants.BOTTLING_FL50_SUBCATEGORY]: LicenseConstants.BOTTLING_FL50_WORKFLOW_NAME,
        [LicenseConstants.BOTTLING_FL51_SUBCATEGORY]: LicenseConstants.BOTTLING_FL51_WORKFLOW_NAME
    },
    BREWERY: {
        [LicenseConstants.BREWERY_B1_SUBCATEGORY]: LicenseConstants.BREWERY_B1_WORKFLOW_NAME,
        [LicenseConstants.BREWERY_B19_SUBCATEGORY]: LicenseConstants.BREWERY_B19_WORKFLOW_NAME,
        [LicenseConstants.BREWERY_B20_SUBCATEGORY]: LicenseConstants.BREWERY_B20_WORKFLOW_NAME,
        [LicenseConstants.BREWERY_B21_SUBCATEGORY]: LicenseConstants.BREWERY_B21_WORKFLOW_NAME,
        [LicenseConstants.BREWERY_MB1_SUBCATEGORY]: LicenseConstants.BREWERY_MB1_WORKFLOW_NAME,
        [LicenseConstants.BREWERY_MB2_SUBCATEGORY]: LicenseConstants.BREWERY_MB2_WORKFLOW_NAME,
        [LicenseConstants.BREWERY_MB3_SUBCATEGORY]: LicenseConstants.BREWERY_MB3_WORKFLOW_NAME,
        [LicenseConstants.BREWERY_MB4_SUBCATEGORY]: LicenseConstants.BREWERY_MB4_WORKFLOW_NAME,
        [LicenseConstants.BREWERY_MB5_SUBCATEGORY]: LicenseConstants.BREWERY_MB5_WORKFLOW_NAME,
        [LicenseConstants.BREWERY_MB6_SUBCATEGORY]: LicenseConstants.BREWERY_MB6_WORKFLOW_NAME,
        [LicenseConstants.BREWERY_MB7_SUBCATEGORY]: LicenseConstants.BREWERY_MB7_WORKFLOW_NAME,
        [LicenseConstants.BREWERY_V1_SUBCATEGORY]: LicenseConstants.BREWERY_V1_WORKFLOW_NAME,
        [LicenseConstants.BREWERY_V2_SUBCATEGORY]: LicenseConstants.BREWERY_V2_WORKFLOW_NAME,
    },
    MASTER_WARE_HOUSE: {
        [LicenseConstants.MASTER_WARE_HOUSE_SUBCATEGORY]: LicenseConstants.MASTER_WARE_HOUSE_WORKFLOW_NAME
    },
    IMPORT_UNIT: {
        [LicenseConstants.IMPORT_UNIT_SUBCATEGORY]: LicenseConstants.IMPORT_UNIT,
    },
    BIO: {
        [LicenseConstants.BIO_SUBCATEGORY]: LicenseConstants.BIO_WORKFLOW_NAME,
    },
    BIO1A: {
        [LicenseConstants.BIO1A_SUBCATEGORY]: LicenseConstants.BIO1A_WORKFLOW_NAME,
    },
    BIO_ZERO: {
        [LicenseConstants.BIO_ZERO_WITHIN_UP_LICENSE_SUBCATEGORY]: LicenseConstants.BIO_ZERO_LICENSE_WITHIN_UP_WORKFLOW_NAME,
        [LicenseConstants.BIO_ZERO_OUTSIDE_UP_LICENSE_SUBCATEGORY]: LicenseConstants.BIO_ZERO_LICENSE_OUTSIDE_UP_WORKFLOW_NAME
    },
    HOME_BAR: {
        [LicenseConstants.HOME_BAR_SUB_CATEGORY_CODE]: LicenseConstants.HOME_BAR_LICENSE_WORKFLOW_NAME
    },
    MICRO_BREWERY: {
        [LicenseConstants.MICRO_BREWERY_MB6_SUBCATEGORY]: LicenseConstants.MICRO_BREWERY_MB6_WORKFLOW_NAME,
        [LicenseConstants.MICRO_BREWERY_MB2_SUBCATEGORY]: LicenseConstants.MICRO_BREWERY_MB2_WORKFLOW_NAME
    },
    DISTRICT_WAREHOUSE: {
        [LicenseConstants.DISTRICT_WAREHOUSE_FL1A_SUBCATEGORY]: LicenseConstants.DISTRICT_WAREHOUSE_FL1A_WORKFLOW_NAME,
        [LicenseConstants.DISTRICT_WAREHOUSE_FL1_SUBCATEGORY]: LicenseConstants.DISTRICT_WAREHOUSE_FL1_WORKFLOW_NAME
    },
    VINTINERY: {
        [LicenseConstants.VINTINERY_V1_SUBCATEGORY]: LicenseConstants.VINTINERY_V1_WORKFLOW_NAME,
        [LicenseConstants.VINTINERY_V2_SUBCATEGORY]: LicenseConstants.VINTINERY_V2_WORKFLOW_NAME
    },
    INDUSTRIAL_ALCOHOL: {
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL39_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL39_WORKFLOW_NAME,  
        [LicenseConstants.INDUSTRIAL_ALCOHOL_DS1_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_DS1_WORKFLOW_NAME,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_WORKFLOW_NAME,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL16_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL16_WORKFLOW_NAME,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL17_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL17_WORKFLOW_NAME,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL40_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL40_WORKFLOW_NAME,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL41_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL41_WORKFLOW_NAME,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL49_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL49_WORKFLOW_NAME,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL41_OUTSIDE_UP_SUBCATEGORY]:LicenseConstants.INDUSTRIAL_ALCOHOL_FL41_OUTSIDE_WORKFLOW_NAME,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_NON_GOVT_MEDICAL_INSTITUTIONS_SUBCATEGORY]: LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_NON_GOVT_MEDICAL_INSTITUTIONS_WORKFLOW_NAME,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_GOVT_MEDICAL_INSTITUTIONS_SUBCATEGORY]:LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_GOVT_MEDICAL_INSTITUTIONS_WORKFLOW_NAME,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_EDUCATIONAL_INSTITUTIONS_SUBCATEGORY]:LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_EDUCATIONAL_INSTITUTIONS_WORKFLOW_NAME,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_CENTRAL_LABORATORY_SUBCATEGORY]:LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_CENTRAL_LABORATORY_WORKFLOW_NAME,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_STATE_LABORATORY_SUBCATEGORY]:LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_STATE_LABORATORY_WORKFLOW_NAME,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_GOVT_NON_GOVT_INSTITUTIONS_SUBCATEGORY]:LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_GOVT_NON_GOVT_INSTITUTIONS_WORKFLOW_NAME,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_SAFETY_EQUIPMENTS_MANUFACTURE_UNITS_SUBCATEGORY]:LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_SAFETY_EQUIPMENT_MANUFACTURE_WORKFLOW_NAME
    },
    TARA_SENDHI: {
        [LicenseConstants.TARA_SENDHI_CL10_SUBCATEGORY]: LicenseConstants.TARA_SENDHI_CL10_WORKFLOW_NAME,
    },
    BHANG: {
        [LicenseConstants.BHANG_ID16_SUBCATEGORY]: LicenseConstants.BHANG_ID16_WORKFLOW_NAME,
        [LicenseConstants.BHANG_ID15_SUBCATEGORY]: LicenseConstants.BHANG_ID15_WORKFLOW_NAME, 
    },
    SCRAMENTAL: {
        [LicenseConstants.SACRAMENTAL_NARCOTICS_SW1_SUBCATEGORY]: LicenseConstants.SACRAMENTAL_NARCOTICS_SW1_WORKFLOW_NAME,
        [LicenseConstants.SACRAMENTAL_NARCOTICS_NDLC_IMPORT_SUBCATEGORY]: LicenseConstants.SACRAMENTAL_NARCOTICS_NDLC_IMPORT_WORKFLOW_NAME,
        [LicenseConstants.SACRAMENTAL_NARCOTICS_NDLC_EXPORT_SUBCATEGORY]: LicenseConstants.SACRAMENTAL_NARCOTICS_NDLC_EXPORT_WORKFLOW_NAME,
        [LicenseConstants.NARCOTICS_NDLD_LICENSE_SUBCATEGORY]: LicenseConstants.SACRAMENTAL_NARCOTICS_NDLD_WORKFLOW_NAME,
    },
    NARCOTIC: {
        [LicenseConstants.NARCOTICS_NDLD_LICENSE_SUBCATEGORY]: LicenseConstants.SACRAMENTAL_NARCOTICS_NDLD_WORKFLOW_NAME,
    },
    CUSTOM_BOND: {
        [LicenseConstants.WITH_IN_UP_SUBCATEGORY]: LicenseConstants.CUSTOM_BOND_WITH_IN_UP_WORKFLOW_NAME,
        [LicenseConstants.OUTSIDE_UP_SUBCATEGORY]: LicenseConstants.CUSTOM_BOND_OUTSIDE_UP_WORKFLOW_NAME
    }
};

export const CheckListCodeConstants = {
    WHOLESALE: {
        [LicenseConstants.WHOLESALE_FL2_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_WHOLESALE_FL2,
        [LicenseConstants.WHOLESALE_FL2A_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_WHOLESALE_FL2A,
        [LicenseConstants.WHOLESALE_FL2B_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_WHOLESALE_FL2B,
        [LicenseConstants.WHOLESALE_CL2_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_WHOLESALE_CL2,
        [LicenseConstants.WHOLESALE_ID3_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_WHOLESALE_ID3,
    },
    HBR: {
        [LicenseConstants.HBR_FL6_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_WHOLESALE_FL2,
        [LicenseConstants.HBR_FL7_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_WHOLESALE_FL2A,
        [LicenseConstants.HBR_FL7A_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_WHOLESALE_FL2B,
        [LicenseConstants.HBR_FL8_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_WHOLESALE_CL2,
        [LicenseConstants.HBR_FLAL1_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_WHOLESALE_ID3,
    },
    EVENT_BAR: {
        [LicenseConstants.EVENT_BAR_FL11_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_EVENT_BAR_FL11
    },
    CSD_SUPPLY: {
        [LicenseConstants.CSD_SUPPLY_SUBCATEGORY]: LicenseConstants.CSD_SUPPLY_WORKFLOW_NAME
    },
    BWFL: {
        [LicenseConstants.BWFL_PARENT_LICENSE_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_BWFL_PARENT_UNIT
    },
    WAREHOUSE: {
        [LicenseConstants.BOND_WAREHOUSE_2A_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_BOND_WAREHOUSE_2A,
        [LicenseConstants.BOND_WAREHOUSE_2B_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_BOND_WAREHOUSE_2B,
        [LicenseConstants.BOND_WAREHOUSE_2C_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_BOND_WAREHOUSE_2C,
        [LicenseConstants.BOND_WAREHOUSE_2D_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_BOND_WAREHOUSE_2D,
    },
    DISTILLERY: {
        [LicenseConstants.DISTI_BOTTL_CLB1_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_CL_B1,
        [LicenseConstants.DISTI_BOTTL_CLB2_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_CL_B2,
        [LicenseConstants.DISTI_BOTTL_DS1_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_DS_1,
        [LicenseConstants.DISTI_BOTTL_FL1_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_FL_1,
        [LicenseConstants.DISTI_BOTTL_FL1A_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_FL_1A,
        [LicenseConstants.DISTI_BOTTL_FL3_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_FL_3,
        [LicenseConstants.DISTI_BOTTL_FL3A_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_FL_3A,
        [LicenseConstants.DISTI_BOTTL_FL16_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_FL_16,
        [LicenseConstants.DISTI_BOTTL_FL17_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_FL_17,
        [LicenseConstants.DISTI_BOTTL_FL32_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_FL_32,
        [LicenseConstants.DISTI_BOTTL_FL39_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_FL_39,
        [LicenseConstants.DISTI_BOTTL_FL40_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_FL_40,
        [LicenseConstants.DISTI_BOTTL_FL41_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_FL_41,
        [LicenseConstants.DISTI_BOTTL_FL49_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_FL_49,
        [LicenseConstants.DISTI_BOTTL_PD1_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_PD_1,
        [LicenseConstants.DISTI_BOTTL_PD2_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_PD_2,
        [LicenseConstants.DISTI_BOTTL_PD32_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_PD_32,
        [LicenseConstants.DISTI_BOTTL_PD33_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_PD_33,
        [LicenseConstants.DISTI_BOTTL_PD34_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_DISTI_BOTTL_PD_34
    },
    SUGAR_MILL: {
        [LicenseConstants.SUGAR_MILL_WITH_IN_UP_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_SUGAR_MILL_WITH_IN_UP,
        [LicenseConstants.SUGAR_MILL_OUT_SIDE_UP_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_SUGAR_MILL_OUT_SIDE_UP,
        [LicenseConstants.SUGAR_MILL_INDUS_IN_UP_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_SUGAR_MILL_INDUS_IN_UP,
        [LicenseConstants.SUGAR_MILL_INDUS_OUT_SIDE_UP_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_SUGAR_MILL_INDUS_OUT_SIDE_UP,
        [LicenseConstants.SUGAR_MILL_MOLASSESS_TRADERS_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_SUGAR_MILL_MOLASSESS_TRADERS,
        [LicenseConstants.SUGAR_MILL_BELOW_GRADE_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_SUGAR_MILL_BELOW_GRADE,
    },
    MASTER_WARE_HOUSE: {
        [LicenseConstants.MASTER_WARE_HOUSE_CATEGORY]: LicenseConstants.CODE_MASTER_WARE_HOUSE
    },
    IMPORT_UNIT: {
        [LicenseConstants.IMPORT_UNIT_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_IMPORT_UNIT,
    },
    INDUSTRIAL_ALCOHOL: {
        [LicenseConstants.INDUSTRIAL_ALCOHOL_DS1_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_DS1,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL32_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_FL32,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL16_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_FL16,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL17_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_FL17,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL39_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_FL39,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL40_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_FL40,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL41_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_FL41,
        [LicenseConstants.INDUSTRIAL_ALCOHOL_FL49_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_INDUSTRIAL_ALCOHOL_FL49
    },
    TARA_SENDHI: {
        [LicenseConstants.TARA_SENDHI_CL7_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_TARA_SENDHI_CL7,
        [LicenseConstants.TARA_SENDHI_CL8_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_TARA_SENDHI_CL8,
        [LicenseConstants.TARA_SENDHI_CL9_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_TARA_SENDHI_CL9,
        [LicenseConstants.TARA_SENDHI_CL10_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_TARA_SENDHI_CL10,
        [LicenseConstants.TARA_SENDHI_CL10A_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_TARA_SENDHI_CL10A,
        [LicenseConstants.TARA_SENDHI_CL11_SUBCATEGORY]: LicenseConstants.CODE_CHECKLIST_TARA_SENDHI_CL11,
    }
};

export const LAT_LONG_RANGE = {
    
	LATITUDE: {
		MINUTES: {
			MIN: '20',
			MAX: '32'
		},
		SECONDS: {
			MIN: '0', // used with minutes minimum
			MAX: '0000000' // used with minutes maximum
		}
	},
	LONGITUDE: {
		MINUTES: {
			MIN: '75',
			MAX: '85'
		},
		SECONDS: {
			MIN: '0', // used with minutes minimum
			MAX: '0000000' // used with minutes maximum
		}
	}
};

