import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { isArray } from 'util';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  modalOptions: NgbModalOptions = {};
  modalReference: NgbModalRef;
  closeResult: string;
  constructor(private datePipe: DatePipe, private modalService: NgbModal) { }

  downloadExcel(excelData, excelName: String, objKeys = [], type?: any) {
    var dwnldExcelData = excelData;
    if (objKeys.length == 2) {
      if (isArray(objKeys[0]) && isArray(objKeys[1])) {
        dwnldExcelData = this.convertDatas(excelData, objKeys, type);
      }
    }
    dwnldExcelData.forEach(function (item) {
      delete item.Action;
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dwnldExcelData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, excelName + '.xlsx');
    return 'success';
  }
  convertDatas(excelData: Array<{}>, objKeys = [], type?: any) {
    let excelArray = [];
    let excelHeaders = objKeys[0];

    let excelKeys = objKeys[1];
    if (excelData.length <= 0) {
      excelArray = [];
      let objKey = {
        'S.NO': ''
      };
      for (let idx = 0; idx < excelKeys.length; idx++) {
        objKey[excelHeaders[idx]] = '';
      }
      excelArray.push(objKey)
    }
    for (let index = 0; index < excelData.length; index++) {
      const element: any = excelData[index];
      var objKey = {
        'S.NO': (index + 1)
      };
      for (let idx = 0; idx < excelKeys.length; idx++) {
        const innerElement = excelKeys[idx];
        if (innerElement.data == "paymentType") {
          objKey[excelHeaders[idx]] = element["feesPenalityMappingResponse"][innerElement.data];
        }
        else if (innerElement.data == "feesPenalityName") {
          objKey[excelHeaders[idx]] = element["feesPenalityMappingResponse"][innerElement.data];
        }
        else if (innerElement.data == "subFeesPenalityName") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? element[innerElement.data] : "-";
        }
        else if (innerElement.data == "brewVesselToUniTank") {
          console.log('sdsd', element)
          objKey[excelHeaders[idx]] = element.brewVesselToUniTank ? element.brewVesselToUniTank['batchNo'] : "-";
        }
        else if (innerElement.data == "veh") {
          objKey[excelHeaders[idx]] = element.transportVehicleEntity.vehicleNumber ? element.transportVehicleEntity.vehicleNumber : "-";
        }

        else if (innerElement.data == "createdDate1") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss a') : "-";
        }
        // else if (innerElement.data == "bankProcessedDate") {
        //   objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy') : "-";
        // }
        else if (innerElement.data == "createdDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss a') : "-";
        }
        else if (innerElement.data == "valdityPeriod") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss a') : "-";
        }
        else if (innerElement.data == "validUpto") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss a') : "-";
        }
        else if (innerElement.data == "blendingStartDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MMM-yyyy') : "-";
        }

        else if (innerElement.data == "date") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MMM-yyyy') : "-";
        }
        else if (innerElement.data == "fortnightStartDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss') : "-";
        }
        else if (innerElement.data == "fromDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss a') : "-";
        }
        else if (innerElement.data == "toDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss a') : "-";
        }
        else if (innerElement.data == "financialYearValue") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'yyyy') : "-";
        }
        else if (innerElement.data == "fortnightEndDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss') : "-";
        }
        else if (innerElement.data == "mf4GatePassGenerationDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy') : "-";
        }
        else if (innerElement.data == "consentagreementValidity") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy') : "-";
        }
        else if (innerElement.data == "indentDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss') : "-";
        }
        else if (innerElement.data == "dateOfInspection") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy') : "-";
        }
        else if (innerElement.data == "inspectorDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss') : "-";
        }
        else if (innerElement.data == "mf6date") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy') : "-";
        }
        else if (innerElement.data == "dateOfPackaging") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy') : "-";
        }
        else if (innerElement.data == "transportPassDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy') : "-";
        }
        else if (innerElement.data == "arrivalDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy') : "-";
        }
        else if (innerElement.data == "startDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy') : "-";
        }
        else if (innerElement.data == "inspectionDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy') : "-";
        }
        else if (innerElement.data == "endDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy') : "-";
        }
        else if (innerElement.data == "applicationDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss') : "-";
        }
        else if (innerElement.data == "tenderDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss') : "-";
        }
        else if (innerElement.data == "otStartDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss') : "-";
        }
        else if (innerElement.data == "applicationNumber1") {
          objKey[excelHeaders[idx]] = element.transferOfBeer && element.transferOfBeer.applicationNumber ? element.transferOfBeer.applicationNumber : "-";
        }
        else if (innerElement.data == "batchref") {
          objKey[excelHeaders[idx]] = element.transferOfBeer && element.transferOfBeer.brewVesselToUniTank.batchNo ? element.transferOfBeer.brewVesselToUniTank.batchNo : "-";
        }
        else if (innerElement.data == "bbtref") {
          objKey[excelHeaders[idx]] = element.transferOfBeer && element.transferOfBeer.bbtNo ? element.transferOfBeer.bbtNo : "-";
        }
        else if (innerElement.data == "tankDetailList") {
          objKey[excelHeaders[idx]] = element.tankDetailList[0] ? element.tankDetailList[0].quantity : '';
        }
        else if (innerElement.data == "requestedForCancellation") {
          objKey[excelHeaders[idx]] = element.requestedForCancellation == true ? 'Yes' : 'No';

        } else if (innerElement.data == "blendingVatNoCoded") {
          objKey[excelHeaders[idx]] = element.blendingVatNoCode && element.blendingVatNoValue ? element.blendingVatNoCode + '(' + element.blendingVatNoValue + ')' : element.blendingVatNoCode;

        } else if (innerElement.data == "fromTankId") {
          objKey[excelHeaders[idx]] = element.fromTankId && element.fromTankName ? element.fromTankId + '(' + element.fromTankName + ')' : element.fromTankId;
        }
        else if (innerElement.data == "toTankId") {
          objKey[excelHeaders[idx]] = element.toTankId && element.toTankName ? element.toTankId + '(' + element.toTankName + ')' : element.toTankId;
        }
        else if (innerElement.data == "requestedForModification") {
          objKey[excelHeaders[idx]] = element.requestedForModification == true ? 'Yes' : 'No';
        }
        else if (innerElement.data == "stageDesc") {
          objKey[excelHeaders[idx]] = (element.status == 'APPROVED' || element.status == 'DRAFT' || element.status == 'SUBMITTED' || element.stageDesc == 'Completed') ? '-' : element.stageDesc;
        }
        else if (innerElement.data == "mf7SugarmillReportList[0].tankNo") {
          objKey[excelHeaders[idx]] = element.mf7SugarmillReportList[0] ? element.mf7SugarmillReportList[0].tankNo : '';
        }
        else if (innerElement.data == "mf7SugarmillReportList[0].brix") {
          objKey[excelHeaders[idx]] = element.mf7SugarmillReportList[0] ? element.mf7SugarmillReportList[0].brix : '';
        }
        else if (innerElement.data == "mf7SugarmillReportList[0].trs") {
          objKey[excelHeaders[idx]] = element.mf7SugarmillReportList[0] ? element.mf7SugarmillReportList[0].trs : '';
        }
        else if (innerElement.data == "mf7SugarmillReportList[0].toplayer") {
          objKey[excelHeaders[idx]] = element.mf7SugarmillReportList[0] ? element.mf7SugarmillReportList[0].toplayer : '';
        }
        else if (innerElement.data == "mf7SugarmillReportList[0].middlelayer") {
          objKey[excelHeaders[idx]] = element.mf7SugarmillReportList[0] ? element.mf7SugarmillReportList[0].middlelayer : '';
        }
        else if (innerElement.data == "mf7SugarmillReportList[0].bottomlayer") {
          objKey[excelHeaders[idx]] = element.mf7SugarmillReportList[0] ? element.mf7SugarmillReportList[0].bottomlayer : '';
        }

        // else if (innerElement.data == "modifiedBy") {
        //   objKey[excelHeaders[idx]] = element[innerElement.data] ? element[innerElement.data] : "-";
        // }else if (innerElement.data == "modifiedDate") {
        //   objKey[excelHeaders[idx]] = element[innerElement.data] ? element[innerElement.data] : "-";
        // }

        else if (innerElement.data == "active") {
          objKey[excelHeaders[idx]] = element["feesPenalityMappingResponse"][innerElement.data] ? "Active" : "Inactive";
        }
        else if (innerElement.data == "otNumber" && (type == 'yeast' || type == 'prefermenter' || type == 'fermenter' || type == 'senttostill')) {
          objKey[excelHeaders[idx]] = element.otProductionPlan.otNumber ? element.otProductionPlan.otNumber : "-";
        }
        else if (innerElement.data == "yeastVessels" && type == 'yeast') {
          objKey[excelHeaders[idx]] = element.otProductionPlan.yeastVessels ? element.otProductionPlan.yeastVessels : "-";
        }
        else if (innerElement.data == "preFermenterCount" && type == 'prefermenter') {
          objKey[excelHeaders[idx]] = element.otProductionPlan.preFermenterCount ? element.otProductionPlan.preFermenterCount : "-";
        }
        else if (innerElement.data == "fermenterCount" && (type == 'fermenter' || type == 'senttostill')) {
          objKey[excelHeaders[idx]] = element.otProductionPlan.fermenterCount ? element.otProductionPlan.fermenterCount : "-";
        }
        else if (innerElement.data == "fermenterCompleted" && type == 'senttostill') {
          objKey[excelHeaders[idx]] = element.fermentationRawMaterials.length ? element.fermentationRawMaterials.length : "-";
        }
        else if (innerElement.data == "otEndDate") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? this.datePipe.transform(element[innerElement.data], 'dd-MM-yyyy HH:mm:ss') : "-";
        } else if (innerElement.data == 'curwith') {
          objKey[excelHeaders[idx]] = (element.status == 'APPROVED' || element.status == 'DRAFT' || element.status == 'SUBMITTED' || element.stageDesc == 'Completed') ? '-' : element.stageDesc;
        }

        else if (innerElement.data == "duration" && type == 'senttostill') {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? ((element.otEndDate - element.otStartDate) / (1000 * 3600 * 24)).toFixed(2) : 0;
        }
        else if (innerElement.data == "otNumber1" && type == 'cot') {
          objKey[excelHeaders[idx]] = element.otProductionPlans[0] != undefined ? element.otProductionPlans[0].otNumber : ''
        }
        else if (innerElement.data == "otNumber2" && type == 'cot') {
          objKey[excelHeaders[idx]] = element.otProductionPlans[1] != undefined ? element.otProductionPlans[1].otNumber : ''
        }
        else if (innerElement.data == "otNumber3" && type == 'cot') {
          objKey[excelHeaders[idx]] = element.otProductionPlans[2] != undefined ? element.otProductionPlans[2].otNumber : ''
        }
        else if (innerElement.data == "blendingProcessEndDate" && type == 'blendingprocess') {
          objKey[excelHeaders[idx]] = element.blendingProcessRawMaterial.length != 0 ? this.datePipe.transform(element.blendingProcessRawMaterial[0].blendingProcessEndDate, 'dd-MM-yyyy HH:mm:ss') : "-";
        }
        else if (innerElement.data == "finalVolume" && type == 'blendingprocess') {
          objKey[excelHeaders[idx]] = element.blendingProcessRawMaterial.length != 0 ? element.blendingProcessRawMaterial[0].finalVolume : "-";
        }
        else if (innerElement.data == "consignerUnitName" && type == 'pd17') {
          objKey[excelHeaders[idx]] = element.pd18.consignerUnitName ? element.pd18.consignerUnitName : "-";
        }
        else if (innerElement.data == "brandNameValue" && type == 'pd17') {
          objKey[excelHeaders[idx]] = element.pd18.brandNameValue ? element.pd18.brandNameValue : "-";
        }
        else if (innerElement.data == "requestedQuantityBl" && type == 'pd17') {
          objKey[excelHeaders[idx]] = element.pd18.requestedQuantityBl ? element.pd18.requestedQuantityBl : "-";
        }
        else if (innerElement.data == "passNumber" && type == 'pd17') {
          objKey[excelHeaders[idx]] = element.pd26.passNumber ? element.pd26.passNumber : "-";
        }
        else if (innerElement.data == "consigneeBottlingName" && type == 'pd25') {
          objKey[excelHeaders[idx]] = element.pd26 && element.pd26.pd18.consignerUnitName ? element.pd26.pd18.consigneeBottlingName : "-";
        }
        else if (innerElement.data == "brandName" && type == 'pd25') {
          objKey[excelHeaders[idx]] = element.pd26 && element.pd26.pd18.brandNameValue ? element.pd26.pd18.brandNameValue : "-";
        }
        else if (innerElement.data == "requestedQuantityBl" && type == 'pd25') {
          objKey[excelHeaders[idx]] = element.pd26 && element.pd26.pd18.requestedQuantityBl ? element.pd26.pd18.requestedQuantityBl : "-";
        }
        else if (innerElement.data == "dispatchQty" && type == 'pd25') {
          objKey[excelHeaders[idx]] = element.pd26 && element.pd26.pd18.dispatchQty ? element.pd26.pd18.dispatchQty : "-";
        }
        else if (innerElement.data == "spiritTypeValue" && type == 'pd25') {
          objKey[excelHeaders[idx]] = element.pd26 && element.pd26.pd18.spiritTypeValue ? element.pd26.pd18.spiritTypeValue : "-";
        }
        else if (innerElement.data == "consignerUnitName" && type == 'pd25') {
          objKey[excelHeaders[idx]] = element.pd26 && element.pd26.pd18.consignerUnitName ? element.pd26.pd18.consignerUnitName : "-";
        }

        else if (innerElement.data == "passNumber" && type == 'pd25') {
          objKey[excelHeaders[idx]] = element.pd26 && element.pd26.passNumber ? element.pd26.passNumber : "-";
        }
        else if (innerElement.data == "passNumber" && type == 'pd26') {
          objKey[excelHeaders[idx]] = element.passNumber ? element.passNumber : "-";
        }
        else if (innerElement.data == "consigneeBottlingName" && type == 'pd26') {
          objKey[excelHeaders[idx]] = element.pd18.consigneeBottlingName ? element.pd18.consigneeBottlingName : "-";
        }
        else if (innerElement.data == "brandName" && type == 'pd26') {
          objKey[excelHeaders[idx]] = element.pd18.brandNameValue ? element.pd18.brandNameValue : "-";
        }
        else if (innerElement.data == "requestedQuantityBl" && type == 'pd26') {
          objKey[excelHeaders[idx]] = element.pd18.requestedQuantityBl ? element.pd18.requestedQuantityBl : "-";
        }
        else if (innerElement.data == "enaPurchaseOrderNumber" && type == 'pd25') {
          objKey[excelHeaders[idx]] = element.pd26 && element.pd26.pd18.enaPurchaseOrderNumber ? element.pd26.pd18.enaPurchaseOrderNumber : "-";
        }
        else if (innerElement.data == "enaPurchaseOrderNumber" && type == 'pd26') {
          objKey[excelHeaders[idx]] = element.pd18.enaPurchaseOrderNumber ? element.pd18.enaPurchaseOrderNumber : "-";
        }
        else if (innerElement.data == "consignerUnitName" && type == 'pd26') {
          objKey[excelHeaders[idx]] = element.pd18.consignerUnitName ? element.pd18.consignerUnitName : "-";
        }
        else if (innerElement.data == "spiritTypeValue" && type == 'pd26') {
          objKey[excelHeaders[idx]] = element.pd18.spiritTypeValue ? element.pd18.spiritTypeValue : "-";
        }
        else if (innerElement.data == "dispatchQty" && type == 'pd26') {
          objKey[excelHeaders[idx]] = element.pd18.dispatchQty ? element.pd18.dispatchQty : "-";
        }
        else if (innerElement.data == "amountBondValue" && type == 'pd26') {
          objKey[excelHeaders[idx]] = element.pd18.amountBondValue ? element.pd18.amountBondValue : "-";
        } 
        else if (innerElement.data == "openingBalance" || innerElement.data == "amount" || innerElement.data == "closingBalance") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? parseFloat(element[innerElement.data]).toFixed(2) : "0.00";
        }
        else if (innerElement.data == "tankAvailableQuantity") {
          objKey[excelHeaders[idx]] = element[innerElement.data] ? (Math.floor(element[innerElement.data] * 100) / 100):'';
        }
        else {
          objKey[excelHeaders[idx]] = element[innerElement.data];
        }

      }
      excelArray.push(objKey);
    }
    return excelArray;
  }
  openDialogModal(content) {
    this.modalOptions.backdrop = 'static';
    this.modalOptions.keyboard = true;
    this.modalOptions.centered = true;
    this.modalReference = this.modalService.open(content, this.modalOptions);
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  closeDialogModal() {
    this.modalReference.close();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
