import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
import { PdCommonService } from '../pd';

@Injectable({
  providedIn: 'root'
})
export class DistileryService {

  dislryURL = environment.distilleryBaseURL;
  userManagementURL = environment.baseURLUser;
  masterURL = environment.baseURLMaster;
  parentunitURL = environment.parentUnit;
  masterurl = environment.baseURLMasterIpAddr
  baseURLLM = environment.licneseBaseUrl;
  setPreview: any;
  masterBaseURL = environment.baseURLL;
  workflowbaseURL = 'http://30060c9f59ef.ngrok.io/workflow/'
  devisionUrl = environment.devisionUrl
  // masterswagerurl ='http://65.0.150.210:3503/masterData/'
  baseURL = environment.scmproduction;
  domainUrl = environment.domainUrl;

  constructor(
    private http: HttpClient,
    private pdCommonService: PdCommonService
  ) {

  }
  // tank reg
  getnewtankreg(): Observable<any> {
    return this.http.get(this.masterURL + apiUrls.newtankreg)

  }
  gettankmetirelmoc(): Observable<any> {
    return this.http.get(this.masterURL + apiUrls.gettankmetirecalMoc)

  }
  gettankusage(id): Observable<any> {
    return this.http.get(this.masterURL + apiUrls.tankusage + id)
  }
  gettankrawmetirel(id): Observable<any> {
    return this.http.get(this.masterURL + apiUrls.tankrawmetireal + id)
  }
  // export order api's
  getExportorder(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.exportgetAllIndentImport);
  }

  getExportDownload(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.exceldownload, { responseType: 'blob' });
  }

  getExportorderbyid(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.exportorderbyid + id);
  }

  addExportorder(data): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.exportaddorder, data);
  }

  UpdateExportorder(data): Observable<any> {
    return this.http.put(this.dislryURL + apiUrls.exportupdateexportorder, data);
  }

  searchExportOrder(data): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.exportLazySearch, data);
  }

  //Allotment Api's
  getAllotment(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.allotmentrequestList);
  }

  getAllotmentById(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.allotmentrequestGetById + id);
  }

  addAllotment(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.allotmentrequestCreate, postdata);
  }

  updateAllotment(postdata): Observable<any> {
    return this.http.put(this.dislryURL + apiUrls.updateallotmentrequest, postdata);
  }

  approvalAllotment(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.approvelallotmentreq, postdata);
  }

  searchAllotment(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.LazySearch, postdata);
  }

  getAllotmentDownload(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.allotmentexportExcel, { responseType: 'blob' });
  }

  getentitype(): Observable<any> {
    return this.http.get(this.userManagementURL + apiUrls.getallentitytypes);
  }

  // dispatch-pd22 api's
  getPd22(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.pd22registrationGetAll);
  }

  getPd22ById(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.pd22registrationGetbyId + id);
  }

  addPd22(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.pd22registrationAdd, postdata);
  }

  updatePd22(postdata): Observable<any> {
    return this.http.put(this.dislryURL + apiUrls.pd22registrationUpdate, postdata);
  }

  searchPd22(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.pd22lazySearch, postdata);
  }

  // dispatch-pd24 api's
  getPd24(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.pd24IssuanceGetAll);
  }

  getPd24ById(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.pd24IssuanceGetbyId + id);
  }

  addPd24(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.pd24IssuanceAdd, postdata);
  }

  updatePd24(postdata): Observable<any> {
    return this.http.put(this.dislryURL + apiUrls.pd24IssuanceUpdate, postdata);
  }

  searchPd24(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.pd24lazySearch, postdata);
  }

  //dispatch-pd25 api's
  getPd25(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.PD25getAll);
  }

  getPd25ById(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.PD25GetbyId + id);
  }

  addPd25(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.PD25Add, postdata);
  }

  updatePd25(postdata): Observable<any> {
    return this.http.put(this.dislryURL + apiUrls.PD25Update, postdata);
  }

  searchPd25(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.PD25lazySearch, postdata);
  }

  // dispatch-pd26 api's
  getPd26(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.PD26getAll);
  }

  getPd26ById(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.PD26GetbyId + id);
  }

  addPd26(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.PD26Add, postdata);
  }

  updatePd26(postdata): Observable<any> {
    return this.http.put(this.dislryURL + apiUrls.PD26Update, postdata);
  }

  searchPd26(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.PD26lazySearch, postdata);
  }

  // exportpermit api's
  getExportorderprimit(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.exportpermitlist);
  }

  downloadExportPrmt(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.excelpermtdownload, { responseType: 'blob' });
  }

  getExportorderprmtbyid(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.exportpermitview + id);
  }

  addexportprmt(data) {
    return this.http.post(this.dislryURL + apiUrls.exportaddpermit, data);
  }

  updateexportprmt(data) {
    return this.http.put(this.dislryURL + apiUrls.exportpermitedit, data);
  }

  searchExportPrmt(data): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.exportsearchapi, data);
  }

  // fermentation api's
  getotnumber(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getotnumber);
  }

  getotmumberdetails(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.otdetails + id);
  }

  createfermentation(data): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.fermentationcreate, data);
  }

  updatefermentation(data): Observable<any> {
    return this.http.put(this.dislryURL + apiUrls.fermentationcreate, data);
  }

  getlist(data): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.getlist, data);
  }

  getviewdetails(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getview + id);
  }

  getprefermetor(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getfermetornumber + id);
  }

  getfermetordetails(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.otfermenterdetails + id);
  }

  fermenterdetails(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.fermentationvessels + id);
  }

  getvessaldata(data): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.vesselcapacity + data);
  }

  getotmumberdetailss(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.otdetails + id);
  }

  getvessalnodata(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getvessaldata + id)
  }

  // importpermtrequest api's
  getallimportpermitreq(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getAllImportPermit);
  }
  getAccpd25Ack(payload){
     const url = this.parentunitURL + 'exportAccidentalRequest/acknowledgment/search'
        // const url = 'http://localhost:3501/scmProduction/exportAccidentalRequest/acknowledgment/search';

    return this.http.post(url, payload);
  }

  getdownloadexcel(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.imppermitexceldownload);
  }

  getimportprtbyid(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getByIdImportPermit + id);
  }

  addimportprtreq(data): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.AddImportPermit, data);
  }

  updateimportprmtreq(data): Observable<any> {
    return this.http.put(this.dislryURL + apiUrls.UpdateImportPermit, data);
  }

  searchimportprmtreq(data): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.importpermitlazySearch, data);
  }

  // importrequest api's
  getImportreq(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getAllIndentImport);
  }

  getexceldownload(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.importexceldownload);
  }

  searchImportreq(data): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.Importsearch, data);
  }

  getImportreqbyid(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getIndentImportById + id);
  }

  addImportreq(data): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.addIndentImport, data)
  }

  updateImportreq(data): Observable<any> {
    return this.http.put(this.dislryURL + apiUrls.updateIndentImport, data);
  }

  // indentrequest api's
  getindentrequest(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getAllIndent);
  }

  downloadIndentRequests(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.indentexceldownload, { responseType: 'blob' });
  }

  getindentbyid(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getIndentById + id);
  }

  addindentreq(data): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.addIndent, data);
  }

  updatereq(data): Observable<any> {
    return this.http.put(this.dislryURL + apiUrls.updateIndent, data);
  }

  approvalindent(data): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.approveIndent, data);
  }

  searchindent(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.indentlazysearch, postdata);
  }

  getautopopulate(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getautopopulate);
  }

  getautoresrveddata(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getreserddetails + id);
  }

  getallotmentdata(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getallotmentids);
  }

  gettankexistingcapacitydata(data): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getTankExistingCapacity + data);
  }

  // mf4acknowledgemt api's
  getgatedata(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getgatepass + id);
  }

  getMF4Acklist(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getmf4acklist);
  }

  update(id): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.updateMf4ack + '?id=' + id, id);
  }

  // mf4recived api's
  getmf4alldata(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.mf4getall);
  }

  exceldownload(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.mf4exceldownload, { responseType: 'blob' });
  }

  getmf4byid(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.mf4getbyid + id);
  }

  addmf4data(data): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.distilleryinwardmollasees, data);
  }

  updatemf4recived(data): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.distilleryinwardmollasees, data);
  }

  searchapi(data): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.mf4lazysearch, data);
  }

  getautodata(data): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.mf4autodata + data);
  }

  // mf6partone api's
  getMF6Partone(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.mf6part1List);
  }

  getMF6PartoneById(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.mf6part1GetById + id);
  }

  addMF6Partone(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.mf6part1Create, postdata);
  }

  updateMF6Partone(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.mf6part1Update, postdata);
  }

  searchMF6Partone(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.mf6part1LazySearch, postdata);
  }

  getautopopulatedata(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.mf6part1gatpassdetail + id);
  }

  getcurrentStockBytankid(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.currentstockbytank + id);
  }

  getopenBalance(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.currentStock);
  }

  getdesignationbyofficer() {
    return this.http.get(this.userManagementURL + apiUrls.userdesignation);
  }

  // mf6parttwo api's
  getMf6parttwo(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.mf6part2List);
  }

  getMf6parttwoById(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.mf6part2GetById + id);
  }

  addMf6parttwo(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.mf6part2Create, postdata);
  }

  updateMf6parttwo(postdata): Observable<any> {
    return this.http.put(this.dislryURL + apiUrls.mf6part2Update, postdata);
  }

  searchMf6parttwo(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.mf6part2LazySearch, postdata);
  }

  // mf10-entry api's
  getMf10entrys(): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getAllmf10MolassesEntry);
  }

  getMf10entrysById(id): Observable<any> {
    return this.http.get(this.dislryURL + apiUrls.getmf10MolassesEntryById + id);
  }

  addMf10entrys(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.mf10add, postdata);
  }

  updateMf10entrys(postdata): Observable<any> {
    return this.http.put(this.dislryURL + apiUrls.mf10update, postdata);
  }

  searchMf10entrys(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.mf10LazySearch, postdata);
  }

  //mf12-register api's
  getMf12register(): Observable<any> {
    const url = this.dislryURL + apiUrls.mf12registerList;
    return this.http.get(url);
  }

  getMf12registerById(id): Observable<any> {
    const url = this.dislryURL + apiUrls.mf12registerGetById + id;
    return this.http.get(url);
  }

  addMf12register(postdata): Observable<any> {
    const url = this.dislryURL + apiUrls.mf12registerCreate;
    return this.http.post(url, postdata);
  }

  updateMf12register(postdata): Observable<any> {
    const url = this.dislryURL + apiUrls.mf12registerUpdate;
    return this.http.put(url, postdata);
  }

  searchMf12register(postdata): Observable<any> {
    const url = this.dislryURL + apiUrls.mf12azySearch;
    return this.http.post(url, postdata);
  }

  //otproductionplan api's
  searchotProddata(data): Observable<any> {
    const url = this.parentunitURL + apiUrls.otProdlazySearch;
    return this.http.post(url, data);
  }

  getotProdbyid(id): Observable<any> {
    const url = this.parentunitURL + apiUrls.otProdgetById + id;
    return this.http.get(url);
  }

  addotProddata(data): Observable<any> {
    const url = this.parentunitURL + apiUrls.otProdcreate;
    return this.http.post(url, data);
  }

  updateotProddata(data): Observable<any> {
    const url = this.dislryURL + apiUrls.otProdupdate;
    return this.http.put(url, data);
  }

  getdistilleytank(): Observable<any> {
    const url = this.dislryURL + apiUrls.disttank;
    return this.http.get(url);
  }

  getusagetanks(): Observable<any> {
    const url = this.dislryURL + apiUrls.vesseltanks;
    return this.http.get(url);
  }

  gettanksbyid(params) {
    // const ids = `?gradeId=${id}`;
    // const url = this.parentunitURL + `${apiUrls.gettanksbyid}${ids}`;
    const url = this.parentunitURL + `${apiUrls.gettanksbyid}`;
    return this.http.post(url, params);
  }
  getrawmaterialtanksbyid(params) {
    const url = this.parentunitURL + `${apiUrls.gettanksbyid}`;
    return this.http.post(url, params);
  }

  // receiver methods :: ss

  getrecerot() {
    const url = this.dislryURL + apiUrls.receivergetotnumber;
    return this.http.get(url)
  }

  getrecvreferenceno(data) {
    const url = this.dislryURL + apiUrls.recvgetrefno + data;
    return this.http.get(url);
  }
  getrecivertank(data) {
    const url = this.dislryURL + apiUrls.recvtank + data;
    return this.http.get(url);
  }

  recvcreate(data) {
    const url = this.dislryURL + apiUrls.recvcreate;
    return this.http.post(url, data);
  }
  recvupdate(data) {
    const url = this.dislryURL + apiUrls.recvupdate;
    return this.http.put(url, data);
  }
  getrevautopopulate(id) {
    const url = this.dislryURL + apiUrls.getrecvrautopopulate + id;
    return this.http.get(url);
  }

  getsprint() {
    const url = this.masterURL + apiUrls.getsprint;
    return this.http.get(url);
  }


  /// sent to still//

  createSentToStill(data): Observable<any> {
    const url = this.parentunitURL + apiUrls.createsenttostill;
    return this.http.post(url, data);

  }
  updateSentToStill(data): Observable<any> {

    const url = this.dislryURL + apiUrls.updatesenttostill;
    return this.http.put(url, data);

  }
  getSentToStill(data): Observable<any> {
    const url = this.parentunitURL + apiUrls.senttostilllazySearch;
    return this.http.post(url, data);

  }

  getsenttostillbyid(id): Observable<any> {
    const url = this.parentunitURL + apiUrls.getsenttostillbyId + id;
    return this.http.get(url);
  }

  getsenttostillOtnumber() {
    const url = this.dislryURL + apiUrls.senttostillgetotnumber;
    return this.http.get(url)
  }

  getsenttostillfermentrenceno(data) {
    const url = this.dislryURL + apiUrls.senttostillfermentrefno + data;
    return this.http.get(url);
  }





  /////
  searchrecver(data) {
    const url = this.dislryURL + apiUrls.searchrecever;
    return this.http.post(url, data);
  }

  getrecverviewdata(id) {
    const url = this.dislryURL + apiUrls.getviewdata + id;
    return this.http.get(url);
  }

  getconsumptiontype() {
    const url = this.dislryURL + apiUrls.consumptiontype;
    return this.http.get(url);
  }

  getworkflowbydesignation(data, data2) {
    const ids = `?moduleNameCode=${data}&workFlowName=${data2}`;
    const url = this.workflowbaseURL + `${apiUrls.getworkflowbydesignation}${ids}`;
    return this.http.get(url);
  }


  // tanks transfer api's ::::

  tanktranscreate(data) {
    const url = this.dislryURL + apiUrls.tanktransfercreate;
    return this.http.post(url, data);
  }

  tankgetall(data) {
    const url = this.dislryURL + apiUrls.tankgetalldata;
    return this.http.post(url, data);
  }

  getbyiddata(id) {
    const url = this.dislryURL + apiUrls.tankgetbyiddata + id;
    return this.http.post(url, id);
  }

  getByRawMaterialId(id) {
    const url = this.dislryURL + apiUrls.getbyrawmetiraldata + id;
    return this.http.get(url);
  }

  searchTankTransfer(postdata): Observable<any> {
    return this.http.post(this.dislryURL + apiUrls.searchTankTransfer, postdata);
  }

  updateTanTransfer(postdata): Observable<any> {
    const url = this.dislryURL + apiUrls.tanktransfercreate;
    return this.http.post(url, postdata);
  }

  getAllTanReg(): Observable<any> {
    const url = this.dislryURL + apiUrls.getdistAllTankReg;
    return this.http.get(url);
  }
  getallfromtanks(): Observable<any> {
    const url = this.dislryURL + apiUrls.getfromtanks;
    return this.http.get(url);
  }
  getAllTankRegis(): Observable<any> {
    const url = this.dislryURL + apiUrls.getAllTankRegistration;
    return this.http.get(url);
  }
  getAllTankbasedOnTankRawmaterial(id): Observable<any> {
    const url = this.dislryURL + apiUrls.getTankListByRawMaterialId + id;
    return this.http.get(url);
  }

  getAllTankRawMaterialbasedOnTankuasagetype(id): Observable<any> {
    const url = this.masterURL + apiUrls.getRawMaterialByTankUsageGroupId + id;
    return this.http.get(url);
  }

  getapplicationnumbers(data) {
    const url = this.parentunitURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {});
  }
  getmetirealmoc(): Observable<any> {
    const url = this.masterURL + apiUrls.gettankmetirecalMoc;
    return this.http.get(url);
  }


  getTankUsageall(): Observable<any> {
    const url = this.masterURL + apiUrls.tankusagegeAll;
    return this.http.get(url);
  }

  getproducationlist() {
    const url = this.masterURL + apiUrls.producationplan;
    return this.http.get(url);
  }

  ////dipbook apis ///////

  addDipbook(postParam): Observable<any> {
    const url = this.dislryURL + apiUrls.createUpdateDipBook;
    return this.http.post(url, postParam);
  }

  searchDipbook(postParam): Observable<any> {
    const url = this.parentunitURL + 'dipBook/lazySearch';
    return this.http.post(url, postParam);
  }

  getDipBookById(id): Observable<any> {
    const url = this.dislryURL + apiUrls.findDipBookById + id;
    return this.http.get(url);
  }

  getOpeningBalance(id): Observable<any> {
    const url = this.dislryURL + apiUrls.getOpeningBalance + id;
    return this.http.get(url);
  }

  searchTankInsp(postParam): Observable<any> {
    const url = this.dislryURL + apiUrls.searchTankInspec;
    return this.http.post(url, postParam);
  }


  // Indent for Blend apis //

  addIndent(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.createIndent;
    return this.http.post(url, postParam);
  }

  getAppNumber(data): Observable<any> {
    const url = this.parentunitURL + apiUrls.getAppNumber + data;
    return this.http.post(url, data);
  }

  getTempLicense(): Observable<any> {
    const url = this.parentunitURL + apiUrls.getTempLicense;
    return this.http.get(url);
  }

  getBollingPlan(data, data2) {
    const ids = `?licenseNumber=${data}&bottlingPlanNumber=${data2}`;
    const url = this.parentunitURL + `${apiUrls.getBottlingPlan}${ids}`;
    return this.http.get(url);
  }

  searchIndentBlend(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.searchIndentBlend;
    return this.http.post(url, postParam);
  }

  getIndentBlendById(id): Observable<any> {
    const url = this.parentunitURL + apiUrls.findIndentById + id;
    return this.http.get(url);
  }
  // Create BPP //

  addBpp(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.createUpdateBottlingProductionPlan;
    return this.http.post(url, postParam);
  }

  searchBPP(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.searchBottlingProductionPlan;
    return this.http.post(url, postParam);
  }

  getPBBById(id): Observable<any> {
    const url = this.parentunitURL + apiUrls.findBottlingProductionPlan + id;
    return this.http.get(url);
  }

  getBrandDetails(id): Observable<any> {
    const url = this.parentunitURL + apiUrls.findBrandDetails + id;
    return this.http.get(url);
  }

  getallmeasurement(id): Observable<any> {
    const url = this.parentunitURL + apiUrls.findPackageSizeByBrandDetailId + id;
    return this.http.get(url);
  }

  allPackageTypes(id): Observable<any> {
    const url = this.parentunitURL + apiUrls.findPackageTypeByBrandDetailId + id;
    return this.http.get(url);
  }

  findBrandDropdowns(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.findBrandDropdown;
    //const url = 'http://localhost:3501/scmProduction/findBrandDetails/dropDown';
    return this.http.post(url, postParam);
  }

  findBrand(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.postBrandDetails;
    return this.http.post(url, postParam);
  }

  findLabelDetails(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.findLabelDetails;
    return this.http.post(url, postParam);
  }

  getTankDetailsbyApplicatioin(id): Observable<any> {
    const url = this.dislryURL + apiUrls.findtankDetails + id;
    return this.http.post(url, {});
  }

  searchQrCOde(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.searchQrCOde;
    return this.http.post(url, postParam);
  }
  getQrById(id): Observable<any> {
    const url = this.parentunitURL + apiUrls.findQrCode + id;
    return this.http.get(url);
  }
  addQR(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.addQr;
    return this.http.post(url, postParam);
  }

  getLiquorType() {
    const url = this.masterURL + apiUrls.getAllLiquorType;
    return this.http.get(url).toPromise();
  }
  addprintStatus(postParam, id): Observable<any> {
    const url = this.parentunitURL + apiUrls.addprintStatus + id;
    return this.http.post(url, postParam);
  }

  // Boxing Process apis
  searchBoxing(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.searchBoxinglist;
    return this.http.post(url, postParam);
  }

  getboxingProcessId(id): Observable<any> {
    const url = this.parentunitURL + apiUrls.getBoxingProcess + id;
    return this.http.get(url);
  }

  updateWastage(data, data2): Observable<any> {
    const ids = `?wastageQuantity=${data}&bottlingPlanId=${data2}`;
    const url = this.parentunitURL + `${apiUrls.updateWastage}${ids}`;
    return this.http.post(url, data, data2);
  }

  transferWarehouse(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.transferWarehouse;
    return this.http.post(url, postParam);
  }

  // Mannual Scanning apis
  searchManualScan(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.searchManualScan;
    return this.http.post(url, postParam);
  }

  getManualScanById(id): Observable<any> {
    const url = this.parentunitURL + apiUrls.findPrintedDetails + id;
    return this.http.get(url);
  }

  scanBarcodeQr(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.addScanQrcodeBarcode;
    return this.http.post(url, postParam);
  }


  scanStatus(postParam, data): Observable<any> {
    const url = this.parentunitURL + apiUrls.scanQrcodeBarcodeMapping + data;
    return this.http.post(url, postParam);
  }

  //////Bottling Process ///

  getbotllingPlanId(): Observable<any> {
    const url = this.parentunitURL + apiUrls.findProductionPlan;
    return this.http.get(url);
  }

  addBottlingProcess(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.addBottlingProcess;
    return this.http.post(url, postParam);
  }

  searchBottlingProcess(postParam): Observable<any> {
    const url = this.parentunitURL + apiUrls.searchBottlingProcess;
    return this.http.post(url, postParam);
  }

  getBottlingProcess(id): Observable<any> {
    const url = this.parentunitURL + apiUrls.findBottlingProcess + id;
    return this.http.get(url);
  }

  // stockinhand ///

  getstockinlistdata(postParam) {
    const url = this.parentunitURL + apiUrls.getstockinhandlist;
    return this.http.post(url, postParam);
  }

  getRawMaterialTankType(postParam) {
    const url = this.masterBaseURL + apiUrls.getRawMaterialTankType;
    return this.http.get(url, { params: postParam });
  }
  getusageTank(postParam) {
    const url = this.parentunitURL + apiUrls.getusageTank;
    return this.http.post(url, postParam);
  }
  getLicenceDetails() {
    const url = this.baseURLLM + `${apiUrls.getLicenseUniName}/PD`;
    return this.http.get(url);
  }
  getrawmaterialTypes(key): Observable<any> {
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=' + key
    return this.http.get(url);
  }
  getMolassesTypesList(): Observable<any> {
    // const url = environment.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=RAW_MOLASSES_TYPE'
    const url = environment.devisionUrl + 'api/masterValues/findDependentValuesMasterOnMaster?filterCode=RAW_MATERIALS&filterSubCode=RM001'
    return this.http.get(url);
  }
  getMolassesTypesList1(): any{
    // const url = environment.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=RAW_MOLASSES_TYPE'
    const url = environment.devisionUrl + 'api/masterValues/findDependentValuesMasterOnMaster?filterCode=RAW_MATERIALS&filterSubCode=RM001'
    return this.http.get(url).toPromise();
  }
  getrawmaterialTanks(): Observable<any> {
    // &additionalFilter2=PD34,PD33
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=RAW_MATERIAL_TANK_TYPE&additionalFilter2=PD34'
    return this.http.get(url);
  }

  getTankUsages(): Observable<any> {
    // &additionalFilter2=PD34,PD33
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=TANK_USAGE&additionalFilter2=PD34'
    return this.http.get(url);
  }
  getmolassesTypes(): Observable<any> {
    // &additionalFilter2=PD34,PD33
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=MOLASSES_TYPE'
    return this.http.get(url);
  }

  //Transfer to storage
  createTransferToStorage(postParam) {
    const url = this.parentunitURL + apiUrls.transferToStorage;
    return this.http.post(url, postParam);
  }

  getTransferToStorage(postParam) {
    const url = this.parentunitURL + apiUrls.transferToStorageSearch;
    return this.http.post(url, postParam);
  }

  getByidtransferToStorage(id): Observable<any> {
    const url = this.parentunitURL + apiUrls.getByidtransferToStorage + id;
    return this.http.get(url);
  }

  getspiritTypes(): Observable<any> {
    // &additionalFilter2=PD34,PD33
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=SPIRIT_TYPE'
    return this.http.get(url);
  }
  getmaterialTank(payload) {
    // const url = environment.parentUnit + 'materialTank/dropDown'
    // const url = environment.parentUnit + 'findTankRegistration/dropDown'
    const url = environment.parentUnit + 'breweryTankRegistration/tankRegistrationLazySearch'
    return this.http.post(url, payload);

  }
  getRawMaterial2(key, addi?, add2?): Observable<any> {

    let url = this.masterurl + apiUrls.getDropDownBykey + '?dropDownKey=' + key;
    url += (addi) ? '&additionalFilter2=' + addi : '';
    if (add2) {
      url += (add2) ? '&additionalFilter1=' + add2 : '';
    }
    return this.http.get(url);

  }
  otTanks(payload): Observable<any> {
    const url = this.parentunitURL + 'otProductionPlan/otTanks';
    return this.http.get(url, { params: payload });
  }
  getcompletedFermenterDetail(payload): Observable<any> {
    const url = this.parentunitURL + 'fermentationProcess/completedFermenterDetail';
    return this.http.get(url, { params: payload });
  }
  getCOTDeclration(payload): Observable<any> {
    const url = this.parentunitURL + 'cotDeclaration/lazySearch';
    return this.http.post(url, payload);
  }
  saveCOTDeclration(payload): Observable<any> {
    const url = this.parentunitURL + 'cotDeclaration';
    //const url = 'http://localhost:3501/scmProduction/cotDeclaration';
    return this.http.post(url, payload);
  }
  getCOTById(id): Observable<any> {
    const url = this.parentunitURL + 'cotDeclaration/' + id;
    return this.http.get(url);
  }
  getfermenterReceiver(payload): Observable<any> {
    const url = this.parentunitURL + 'cotDeclaration/fermenterReceiver';
    return this.http.get(url, { params: payload });
  }
  getBottlingTanks(params): Observable<any> {
    const url = this.parentunitURL + 'blendTransfer/bottlingTanks';
    return this.http.get(url, { params: params });
  }
  getBottlingAllTanks(params): Observable<any> {
    const url = this.parentunitURL + 'blendTransfer/bottlingTanksFl';
    return this.http.get(url, { params: params });
  }
  getBottlingAllTanks1(params): any {
    const url = this.parentunitURL + 'blendTransfer/bottlingTanksFl';
    return this.http.get(url, { params: params }).toPromise();
  }
  getDropdownValues(params): Observable<any> {
    const url = this.devisionUrl + apiUrls.findDropDownMasterOnMaster
    return this.http.get(url, { params: params });
  }
  getApplicationNo(payload): Observable<any> {
    const url = this.parentunitURL + 'pd18/lazySearch';
    return this.http.post(url, payload);
  }
  blendTransferPD18LazySearchAsync(payload): any {
    const url = this.parentunitURL + 'pd18/lazySearch';
    return this.http.post(url, payload).toPromise();
  }
  searchApplicationNoForIDPD26(payload): Observable<any> {
    const url = this.parentunitURL + 'omdIndentRequest/lazySearch';
    return this.http.post(url, payload);
  }
  createPD26(payload): Observable<any> {
    const url = this.parentunitURL + 'pd26';
    //const url = 'http://localhost:3501/scmProduction/pd26';
    return this.http.post(url, payload);
  }
  createFl39(payload): Observable<any> {
    const url = this.parentunitURL + 'fl39';
    return this.http.post(url, payload);
  }
  createFl16(payload): Observable<any> {
    const url = this.parentunitURL + 'fl16';
    return this.http.post(url, payload);
  }
  createSchool(payload): Observable<any> {
    const url = this.parentunitURL + 'dispatchToSchools';
    return this.http.post(url, payload);
  }

  getPD26(params): Observable<any> {
    const url = this.parentunitURL + 'pd26/lazySearch';
    // const url = 'http://localhost:3501/scmProduction/pd26/lazySearch';

    return this.http.post(url, params);
  }
  getFl39List(params): Observable<any> {
    const url = this.parentunitURL + 'fl39/lazySearch';
    // const url = 'http://localhost:3501/scmProduction/fl39/lazySearch';
    return this.http.post(url, params);
  }
  getFl39ListAsync(params): Observable<any> {
    const url = this.parentunitURL + 'fl39/lazySearch';
    return this.http.post(url, params);
  }
  getFl16List(params): Observable<any> {
    const url = this.parentunitURL + 'fl16/lazySearch';
        // const url = 'http://localhost:3501/scmProduction/fl16/lazySearch';

    return this.http.post(url, params);
  }
  getSchoolList(params): Observable<any> {
    const url = this.parentunitURL + 'dispatchToSchools/lazySearch';
    return this.http.post(url, params);
  }
  getSchoolListAsync(params): any {
    const url = this.parentunitURL + 'dispatchToSchools/lazySearch';
    return this.http.post(url, params).toPromise();
  }

  getPD26ById(id): Observable<any> {
    const url = this.parentunitURL + 'pd26/' + id;
    return this.http.get(url);
  }
  getPD26ByIdAsync(id): any {
    const url = this.parentunitURL + 'pd26/' + id;
    return this.http.get(url).toPromise();
  }
  getFl39ById(id): Observable<any> {
    const url = this.parentunitURL + 'fl39/' + id;
    return this.http.get(url);
  }
  getFl16ById(id): Observable<any> {
    const url = this.parentunitURL + 'fl16/' + id;
    return this.http.get(url);
  } 
  getRadarDetails(vatCode,requestId,unitCode): Observable<any>{
    const url = this.baseURL + 'tankRaidorBasedLog/getRedorDetail?vatCode=' + vatCode +
    '&requestId=' +requestId + '&unitCode=' +unitCode
    return this.http.get(url);
  }
  getRequestId(key,vatCode): Observable<any>{
    const url = this.baseURL + 'tankRaidorBasedLog/getDropDown?type=' + key
    +'&tankRedorVatCode=' +vatCode 
    return this.http.get(url);
  }
  getRadarRequestId(key,vatCode,upordown): Observable<any>{
    const url = this.baseURL + 'tankRaidorBasedLog/getDropDown?type=' + key
    +'&tankRedorVatCode=' +vatCode  +'&upDown=' +upordown
    return this.http.get(url);
  }
  getSchoolById(id): Observable<any> {
    const url = this.parentunitURL + 'dispatchToSchools/' + id;
    return this.http.get(url);
  }
  getSchoolByIdAsync(id): any {
    const url = this.parentunitURL + 'dispatchToSchools/' + id;
    return this.http.get(url).toPromise();
  }
  findpaymentdetailsFl16(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'fl16/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  findpaymentdetailsSchool(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'dispatchToSchools/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  saveupdatepaymentdetailsfl16(payload): Observable<any> {
    const url = this.baseURL + 'fl16/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }
  saveupdatepaymentdetailsschool(payload): Observable<any> {
    const url = this.baseURL + 'dispatchToSchools/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }
  getOTCOTDropdown(params): Observable<any> {
    const url = this.parentunitURL + 'cotDeclaration/otDropDown';
    return this.http.get(url, { params: params });
  }

  createPD25(payload): Observable<any> {
    const url = this.parentunitURL + 'pd25';
    //const url = 'http://localhost:3501/scmProduction/pd25';
    return this.http.post(url, payload);
  }
  createPamr(payload): Observable<any> {
    const url = this.parentunitURL + 'pamr';
    return this.http.post(url, payload);
  }
  getPamrById(id): Observable<any> {
    const url = this.parentunitURL + 'pamr/' + id;
    return this.http.get(url);
  }
  getPD25(params): Observable<any> {
    const url = this.parentunitURL + 'pd25/lazySearch';
    // const url = 'http://localhost:3501/scmProduction/pd25/lazySearch';

    return this.http.post(url, params);
  }
  getPamr(params): Observable<any> {
    const url = this.parentunitURL + 'pamr/lazySearch';
    return this.http.post(url, params);
  }
  getPD25ack(params): Observable<any> {
    const url = this.parentunitURL + 'pd25/acknowledgment/search';
    return this.http.post(url, params);
  }
  getPD25ById(id): Observable<any> {
    const url = this.parentunitURL + 'pd25/' + id;
    //const url = 'http://localhost:3501/scmProduction/pd25/' + id;
    return this.http.get(url);
  }

 

  createMSTIST(params): Observable<any> {
    const url = this.parentunitURL + 'mstIstSpirit';
    return this.http.post(url, params);
  }
  getMSTISTSpirit(params): Observable<any> {
    const url = this.parentunitURL + 'mstIstSpirit/lazySearch';
    return this.http.post(url, params);
  }
  getMSTISTSpiritById(id): Observable<any> {
    const url = this.parentunitURL + 'mstIstSpirit/' + id;
    return this.http.get(url);
  }


  createmstIstMolasses(params): Observable<any> {
    const url = this.parentunitURL + 'mstIstMolasses';
    return this.http.post(url, params);
  }
  getmstIstMolasses(params): Observable<any> {
    const url = this.parentunitURL + 'mstIstMolasses/lazySearch';
    return this.http.post(url, params);
  }
  getmstIstMolassesById(id): Observable<any> {
    const url = this.parentunitURL + 'mstIstMolasses/' + id;
    return this.http.get(url);
  }

  getOpenBalance(param): Observable<any> {
    const url = this.parentunitURL + 'mf6part1/getOpeningBalance';
    return this.http.get(url, { params: param });
  }
  getPDModeOfTransfer(): Observable<any> {
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMaster?dropDownKey=MODE_OF_TRANSFER&additionalFilter2=PD34'
    return this.http.get(url);
  }

  getIssuedQty(params): Observable<any> {
    const url = this.parentunitURL + 'mstIstMolasses/getIssuedQuantityByTankId';
    return this.http.post(url, params);
  }

  getrawMaterialPd(entityType, unitcode, licenseType, rawCode): Observable<any> {
    const url = this.parentunitURL + 'stockActivity/rawMaterial?entityType=' + entityType + '&unitCode=' + unitcode + '&additionalFilter=' + licenseType + '&rawMaterial=' + rawCode;
    return this.http.get(url);
  }

  downloadData(payload) {
    const url = this.parentunitURL + 'reportReceipt/downloadReceipt';
    //const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt'
    return this.http.get(url, { responseType: 'blob', params: payload });
  }
  downloadenavalue(payload) {
    const url = this.parentunitURL + 'reportReceipt/downloadReceipt'
    return this.http.get(url, { responseType: 'blob', params: payload });
  }
  /*  getOtProductionPlanCount(entityType?) {
      const url = this.baseURL + 'otProductionPlan/getOtProductionPlanCount?entityType=' + entityType;
      return this.http.get(url);
    }
    getOtSendtoStillCount(entityType?) {
      const url = this.baseURL + 'otSendToStill/getOtSendtoStillCount?entityType=' + entityType;
      return this.http.get(url);
    }*/


  // payment related api's
  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'otSendToStill/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    let url = this.baseURL + 'otSendToStill/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }


  licenceAdd(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd';
    return this.http.post(url, payload);
  }
  downloadDocument(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadSampleFeeReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType;
    return this.http.get(url, { responseType: 'blob' });
  }
  getPaymentDetail(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search';//'config/sub/search';
    return this.http.post(url, payload);
  }

  downloadPayment(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType + '&receiptType=SENT_TO_STILL_PENALTY';
    return this.http.get(url, { responseType: 'blob' });
  }

  // download template 

  download(payload) {
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt'
    return this.http.get(url, { responseType: 'blob', params: payload });
  }

  getCOTDeclrationwithplan(payload): Observable<any> {
    const url = this.parentunitURL + 'cotDeclaration/lazySearch';
    return this.http.post(url, payload);
  }
  searchotProddatawithplan(data): Observable<any> {
    const url = this.parentunitURL + apiUrls.otProdlazySearch;
    return this.http.post(url, data);
  }
  saveCOTDeclrationwithplan(payload): Observable<any> {
    const url = this.parentunitURL + 'cotDeclaration';
    return this.http.post(url, payload);
  }
  getCOTByIdwithplan(id): Observable<any> {
    const url = this.parentunitURL + 'cotDeclaration/' + id;
    return this.http.get(url);
  }
  getfermenterReceiverwithplan(payload): Observable<any> {
    const url = this.parentunitURL + 'cotDeclaration/fermenterReceiver';
    return this.http.get(url, { params: payload });
  }
  getSentToStillwithplan(data): Observable<any> {
    const url = this.parentunitURL + apiUrls.senttostilllazySearch;
    return this.http.post(url, data);

  }
  getOTCOTDropdownwithplan(params): Observable<any> {
    const url = this.parentunitURL + 'otDeclarationWithoutPlan/lazySearch';
    //const url = 'http://localhost:3501/scmProduction/otDeclarationWithoutPlan/lazySearch';
    return this.http.post(url, params);
  }
  getOTCOTDropdownwithplan1(params): any {
    const url = this.parentunitURL + 'otDeclarationWithoutPlan/lazySearch';
    return this.http.post(url, params).toPromise();
  }
  getProcessTypes(): Observable<any> {
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMaster?dropDownKey=OT_PROCESS_TYPE'
    return this.http.get(url);
  }
  getOtProductionplan(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    //const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }
  getCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }

  saveAccidentalOmd(payload): Observable<any>{
    const url = this.parentunitURL + 'accidentalRequestOmd'
    return this.http.post(url,payload);
  }

  getAccidentalOmd(id): Observable<any> {
    const url = this.parentunitURL + 'accidentalRequestOmd/' + id;
    return this.http.get(url);
  }
  getListOfAccidentalOmd(payload): Observable<any> {
    const url = this.parentunitURL + 'accidentalRequestOmd/lazySearch';
    return this.http.post(url, payload);
  }
  //Fl16 accident req 
  saveAccFl16(payload): Observable<any>{
    const url = this.parentunitURL + 'accidentalRequestFl16'
    return this.http.post(url,payload);
  }
  getListOfAccFl16(payload): Observable<any> {
    const url = this.parentunitURL + 'accidentalRequestFl16/lazySearch';
    return this.http.post(url, payload);
  }
  getAccFl16(id): Observable<any> {
    const url = this.parentunitURL + 'accidentalRequestFl16/' + id;
    return this.http.get(url);
  }
  saveStockinOmd(payload): Observable<any>{
    const url = this.parentunitURL + 'omdStockIn'
    return this.http.post(url,payload);
  }

  getStockinOmd(id): Observable<any> {
    const url = this.parentunitURL + 'omdStockIn/' + id;
    return this.http.get(url);
  }
  getListOfStockIn(payload): Observable<any> {
    const url = this.parentunitURL + 'omdStockIn/lazySearch';
    return this.http.post(url, payload);
  }

  //accidental Request For FL39 And FL49
  saveAccidentalFl39(payload): Observable<any>{
    const url = this.parentunitURL + 'accidentalRequestFL39';
    return this.http.post(url, payload);
  }
  LazySearchFL39(paylod): Observable<any>{
    const url = this.parentunitURL + 'accidentalRequestFL39/lazySearch';
    return this.http.post(url, paylod);
  }
  getByIdFL39(id): Observable<any> {
    const url = this.parentunitURL + 'accidentalRequestFL39/' + id;
    return this.http.get(url);
  }
  getFl39Lazy(payload): any {
    const url = this.parentunitURL + 'fl39/lazySearch';
    return this.http.post(url, payload).toPromise();
  }
  getPD26Lazy(payload): any {
    const url = this.parentunitURL + 'pd26/lazySearch';
    return this.http.post(url, payload).toPromise();
  }
  // export spirit accdent req
  saveAcciEs(payload): Observable<any>{
    const url = this.parentunitURL + 'exportAccidentalRequest';
    return this.http.post(url, payload);
  }
  getListOfAcc(payload): Observable<any> {
    const url = this.parentunitURL + 'exportAccidentalRequest/lazySearch';
    return this.http.post(url, payload);
  }
  getAccEs(id): Observable<any> {
    const url = this.parentunitURL + 'exportAccidentalRequest/' + id;
    return this.http.get(url);
  }
  omdreturntpcreate(payload): Observable<any> {
    const url = this.parentunitURL + 'omdReturnTp';
    return this.http.post(url, payload);
  }
  omdreturntplazySearch(payload): Observable<any> {
    const url = this.parentunitURL + 'omdReturnTp/lazySearch';
    return this.http.post(url, payload);
  }
  omdreturntpupdateWorkFlow(payload): Observable<any> {
    const url = this.parentunitURL + 'omdReturnTp/updateWorkFlow';
    return this.http.post(url, payload);
  }
  omdreturntpbyid(id): Observable<any> {
    const url = this.parentunitURL + 'omdReturnTp/'+ id;
    return this.http.get(url);
  }
  saveReturnTpForOmd(payload): Observable<any>{
    const url = this.parentunitURL + 'returnTp'
    return this.http.post(url,payload);
  }

  getReturnTpForOmd(id): Observable<any> {
    const url = this.parentunitURL + 'returnTp/' + id;
    return this.http.get(url);
  }
  getListOfReturnTpForOmd(payload): Observable<any> {
    const url = this.parentunitURL + 'returnTp/lazySearch';
    return this.http.post(url, payload);
  }

  returntpdownload(payload){
    const url = this.parentunitURL + 'reportReceipt/downloadReceipt';
    return this.http.get(url, { responseType: 'blob', params: payload });
  }
  getstatuscount(payload):Observable<any> {
    const url = this.baseURL + 'sugarMill/getStatusCount';
    return this.http.post(url,payload);
  }
}
