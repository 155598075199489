import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrls } from '@appEnv/apiurls';
import { environment } from '@appEnv/environment';
import { Observable } from 'rxjs';
import * as fileSaver from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class WholesaleIndentRequestService {

  constructor(private http: HttpClient) { }

  baseURL = environment.wholesaleURL;
  baseURLLM = environment.licencemanagementURL;
  masterURL = environment.devisionUrl;
  retailURL = environment.retailURL;
  wholesaleNewURL = environment.wholesaleNewURL;
  domainUrl = environment.domainUrl;
  trackandtrace = environment.trackandtraceUrl;
  nmUrl = environment.distcmintegrationUrl;


  getLiquorList(postParam): Observable<any> {
    const url = this.retailURL + 'customdata/getdata';
    return this.http.post(url, postParam);
  }
  brandMappingList(postParam): Observable<any> {
    const url = this.retailURL + 'customdata/getdata';
    return this.http.post(url, postParam);
  }
  addIndent(postData): Observable<any> {
    const url = this.baseURL + 'indentRequest/addIndentRequest';
    return this.http.post(url, postData)
  }
  searchIndentRequest(postParam): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, postParam);
  }
  IndentRequest(postParam): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, postParam);
  }
  getRecordCount(postParam): any {
    const url = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(url, postParam).toPromise();
  }
  getApprovalFilterListCount(postParam): any {
    const url = this.wholesaleNewURL + 'departmentapproval/getapprovalfilterlistcount'
    // const url = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(url, postParam).toPromise();
  }
  getApprovalFilterListCount1(postParam): any {
    const url = this.wholesaleNewURL + 'departmentapproval/getapprovalfilterlistcount'
    return this.http.post(url, postParam);
  }
  getApprovalFilterList(postdata): Observable<any> {
    const url = this.wholesaleNewURL + 'departmentapproval/getapprovalfilterlist';
    return this.http.post(url, postdata);
  }
  getIndent(id): Observable<any> {
    const url = this.baseURL + 'indentRequest/getIndentRequest?id=' + id;
    return this.http.get(url)
  }
  getIndentReqById(id): Observable<any> {
    const url = this.baseURL + 'indentRequest/getIndentRequest?id=' + id;
    return this.http.get(url);
  }

  downloadIndent(indetNumber): Observable<any> {
    // let indType = "";
    // if (localStorage.getItem('unitType')) {
    //   indType = (localStorage.getItem('unitType') == "PARENT_UNIT") ? '/OUTSIDE_INDENT' : '/INSIDE_INDENT';
    // }
    // const url = this.baseURL + 'indentfile/' + indetNumber + indType;
    const url = this.wholesaleNewURL + 'indentfile/' + indetNumber + '/indent';
    return this.http.get(url, { responseType: 'blob' });
  }
  downloadImport(indetNumber): Observable<any> {
    const url = this.wholesaleNewURL + 'indentfile/' + indetNumber + '/Import';
    return this.http.get(url, { responseType: 'blob' });
  }

  fileSaver(resp, documentName) {
    return fileSaver.saveAs(resp, documentName);
  }

  unitLicenseTypes(): Observable<any> {
    const url = this.baseURLLM + "license/category/WHOLESALE";
    return this.http.get(url)
  }
  reatilType(): Observable<any> {
    const url = this.baseURLLM + "license/category/RETAIL";
    return this.http.get(url)
  }

  retailLicenseNumber(licenseNum): Observable<any> {
    const url = this.baseURLLM + "license/category/RETAIL?licenseNumber=" + licenseNum;
    return this.http.get(url)
  }
  unitLicenseTypesByOfficer(lmmnumber): Observable<any> {
    const url = this.baseURLLM + "license/category/WHOLESALE?licenseNumber=" + lmmnumber;
    return this.http.get(url)
  }
  getIndtReqStatusList() {
    const url = this.baseURL + apiUrls.acceptanceStatusList;
    return this.http.get(url)
  }
  getStatusMasters() {
    const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=WHOLESALE_STATUS_INDENTREQ"
    return this.http.get(url)
  }

  daterange(fromDate, toDate) {
    console.log(fromDate)
    let url = '';
    if (fromDate == "Invalid date" && toDate == "Invalid date") {
      url = this.baseURL + "indentRequest/getIndentStatusCount";
    } else {

      url = this.baseURL + "indentRequest/getIndentStatusCount?fromDate=" + fromDate + "&toDate=" + toDate;
    }
    return this.http.get(url)
  }

  getUnitName(postParam) {
    const data = `/${postParam.category}/subCategory/${postParam.subCategory}?size=${postParam.size}`;
    const url = this.baseURLLM + `${apiUrls.getLicenseUniName}${data}`;
    return this.http.get(url);
  }

  findLicenseMapping(data, data2) {
    const ids = `?licenseCategoryCode=${data}&licenseType=${data2}`;
    const url = this.masterURL + `${apiUrls.findLicenseMapping}${ids}`;
    return this.http.get(url);
  }
  retailAddIndent(postData): Observable<any> {
    const url = this.retailURL + 'indentRequest/addIndentRequest';
    return this.http.post(url, postData)
  }

  addIndentNew(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'indentrequestcontroller/createindentrequest'
    return this.http.post(url, postData)
  }
  addSubIndentNew(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'indentrequestcontroller/createsubindentrequest'
    return this.http.post(url, postData)
  }

  saveIndentAccept(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'indentrequestcontroller/saveindentacceptance'
    return this.http.post(url, postData)
  }

  getIndentReqByIdNew(id): any {
    const payload = {
      "dataCode": "INDENT_REQUEST_DETAILS_VIEW",
      "placeholderKeyValueMap": {
        "${indentRequestId}": id
      }
    };
    const url = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(url, payload).toPromise();
  }

  getIndentDetailReqByIdNew(id): Observable<any> {
    const payload = {
      "dataCode": "INDENT_REQUEST_DETAILS_ITEM_VIEW",
      "placeholderKeyValueMap": {
        "${indentRequestId}": id
      }
    };
    const url = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(url, payload)
  }
  getIndentDetailItemViewReqByIdNew(id): Observable<any> {
    const payload = {
      "dataCode": "INDENT_REQUEST_DETAILS_ITEM_VIEW_SUBINDENT",
      "placeholderKeyValueMap": {
        "indentNumber": id
      }
    };
    const url = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(url, payload)
  }
  cancelIndent(indentNumber, remarks) {
    const payload = {
      "customDataDTOList": [
        {
          "dataCode": "APPROVAL_LOG_CANCEL",
          "entityCode": "'" + localStorage.getItem('entityCode') + "'",
          "placeholderKeyValueMap": {
            "cancelRemarks": "'" + remarks + "'",
            "createdBy": "'" + localStorage.getItem('USER_ID') + "'",
            "indentNumber": "'" + indentNumber + "'",
            "modifiedBy": "'" + localStorage.getItem('USER_ID') + "'",
            "tableName": "'INDENT_REQUEST'"
          }
        },
        {
          "dataCode": "INDENT_REQUEST_CANCEL",
          "entityCode": "'" + localStorage.getItem('entityCode') + "'",
          "placeholderKeyValueMap": {
            "cancelRemarks": "'" + remarks + "'",
            "indentNumber": "'" + indentNumber + "'",
          }
        }
      ]
    }
    const url = this.wholesaleNewURL + 'customdata/saverecord'
    return this.http.post(url, payload)
  }

  rejectIndent(indentRequestID, remarks) {
    const payload = {
      "customDataDTOList": [
        {
          "dataCode": "INDENT_REQUEST_LOG_REJECT",
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "INDENT_REQUEST_NOTE_REJECT",
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "INDENT_REQUEST_REJECT",
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'"
          }
        }
      ]
    }
    const url = this.wholesaleNewURL + 'customdata/saverecord'
    return this.http.post(url, payload)
  }

  rejectIndentAcceptance(indentRequestID, indentNumber, remarks, entitycode) {
    const payload = {
      "customDataDTOList": [
        {
          "dataCode": "INDENT_REQUEST_LOG_REJECT",
          "entityCode": "'" + entitycode + "'",
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'",
          }
        },
        {
          "dataCode": "INDENT_REQUEST_NOTE_REJECT",
          "entityCode": "'" + entitycode + "'",
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'",
          }
        },
        {
          "dataCode": "INDENT_REQUEST_REJECT",
          "entityCode": "'" + entitycode + "'",
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'",
          }
        },
        {
          "dataCode": "APPROVAL_LOG",
          "entityCode": "'" + entitycode + "'",
          "placeholderKeyValueMap": {
            "stage": "'REJECTED'",
            "createdBy": "'" + localStorage.getItem('USER_ID') + "'",
            "indentNumber": "'" + indentNumber + "'",
            "modifiedBy": "'" + localStorage.getItem('USER_ID') + "'",
            "remarks": "'" + remarks + "'",
            "tableName": "'INDENT_REQUEST'"
          }
        }
      ]
    }
    const url = this.wholesaleNewURL + 'customdata/saverecord'
    return this.http.post(url, payload)
  }

  approveIndent(indentRequestID, fromEntityCode, remarks) {
    const payload = {
      "fromEntityCode": fromEntityCode,
      "createdBy": 1,
      "approvalLogDTO": {
        "recordId": parseInt(indentRequestID, 10),
        "functionCode": "INDENT_REQUEST",
        "stage": "APPROVED",
        "remarks": "'" + remarks + "'"
      }
      // SUBMITTED
      // APPROVED
      // FINAL_APPROVED
      // CLARIFICATION REQUESTED
      // REJECTED
    }
    const url = this.wholesaleNewURL + 'approvalflow/process'
    return this.http.post(url, payload)
  }

  acceptIndent(indentRequestID, indentNumber, remarks, indentType?) {
    const payload = {
      "customDataDTOList": [
        {
          "dataCode": "INDENT_REQUEST_LOG_ACCEPT",
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "INDENT_REQUEST_NOTE_ACCEPT",
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "INDENT_REQUEST_ACCEPT",
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "APPROVAL_LOG",
          "placeholderKeyValueMap": {
            "stage": "'ACCEPTED'",
            "indentNumber": "'" + indentNumber + "'",
            "remarks": "'" + remarks + "'",
            "createdBy": localStorage.getItem('USER_ID'),
            "modifiedBy": localStorage.getItem('USER_ID'),
            "tableName": indentType == 'INDENT_BY_ITEM' ? "'INDENT_REQUEST_BY_ITEM'" : "'INDENT_REQUEST'"
          }
        }
      ]
    }
    const url = this.wholesaleNewURL + 'customdata/saverecord'
    return this.http.post(url, payload)
  }


  reqforclar(indentRequestID, indentNumber, remarks, entitycode) {
    const payload = {
      "customDataDTOList": [
        {
          "dataCode": "INDENT_REQUEST_LOG_WAITING_FOR_REQUEST_CLARIFICATION",
          "entityCode": localStorage.getItem('entityCode'),
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "INDENT_REQUEST_NOTE_WAITING_FOR_REQUEST_CLARIFICATION",
          "entityCode": localStorage.getItem('entityCode'),
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "INDENT_REQUEST_WAITING_FOR_REQUEST_CLARIFICATION",
          "entityCode": localStorage.getItem('entityCode'),
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "APPROVAL_LOG",
          "entityCode": localStorage.getItem('entityCode'),
          "placeholderKeyValueMap": {
            "stage": "'WAITING FOR REQUEST CLARIFICATION'",
            "indentNumber": "'" + indentNumber + "'",
            "remarks": "'" + remarks + "'",
            "tableName": "'INDENT_REQUEST'",
            "createdBy": "'" + localStorage.getItem('USER_ID') + "'",
            "modifiedBy": "'" + localStorage.getItem('USER_ID') + "'",
          }
        }
      ]
    }
    const url = this.wholesaleNewURL + 'customdata/saverecord'
    return this.http.post(url, payload)
  }
  modifiedRequest(indentRequestID, indentNumber, remarks, entitycode, moduleCode, submoduleCode) {
    const payload = {
      "customDataDTOList": [
        {
          "dataCode": "INDENT_REQUEST_LOG_MODIFICATION_SUBMITTED",
          "entityCode": localStorage.getItem('entityCode'),
          "placeholderKeyValueMap": {
            "indentRequestID": parseInt(indentRequestID),
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "INDENT_REQUEST_NOTE_MODIFICATION_SUBMITTED",
          "entityCode": localStorage.getItem('entityCode'),
          "placeholderKeyValueMap": {
            "indentRequestID": parseInt(indentRequestID),
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "INDENT_REQUEST_MODIFICATION_SUBMITTED",
          "entityCode": localStorage.getItem('entityCode'),
          "placeholderKeyValueMap": {
            "indentRequestID": parseInt(indentRequestID),
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "APPROVAL_LOG_INSERT",
          "entityCode": localStorage.getItem('entityCode'),
          "placeholderKeyValueMap": {
            "stage": "'MODIFICATION_SUBMITTED'",
            "recordId": parseInt(indentRequestID),
            "remarks": "'" + remarks + "'",
            "tableName": "'INDENT_REQUEST'",
            "createdBy": "'" + localStorage.getItem('USER_ID') + "'",
            "modifiedBy": "'" + localStorage.getItem('USER_ID') + "'",
            "moduleCode": "'" + moduleCode + "'",
            "subModuleCode": "'" + submoduleCode + "'",
            "designationCode": "'CUSTOMER'",
            "levelCode": "'Level 1'",
          },
        }
      ]
    }
    const url = this.wholesaleNewURL + 'customdata/saverecord'
    return this.http.post(url, payload)
  }
  foreClose(indentNumber, remarks) {
    const payload = {
      "customDataDTOList": [
        {
          "dataCode": "INDENT_FORECLOSURE_REQUEST",
          "entityCode": localStorage.getItem('entityCode'),
          "placeholderKeyValueMap": {
            "indentNumber": "'" + indentNumber + "'",
            "foreclosureRemarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "APPROVAL_LOG",
          "entityCode": localStorage.getItem('entityCode'),
          "placeholderKeyValueMap": {
            "stage": "'INDENT FORECLOSED'",
            "createdBy": localStorage.getItem('USER_ID'),
            "indentNumber": "'" + indentNumber + "'",
            "modifiedBy": localStorage.getItem('USER_ID'),
            "remarks": "'" + remarks + "'",
            "tableName": "'INDENT_REQUEST'"
          }
        }
      ]
    }
    const url = this.wholesaleNewURL + 'customdata/saverecord'
    return this.http.post(url, payload)
  }

  changePriority(indentRequestID, indentNumber, remarks, entitycode) {
    const payload = {
      indentRequestID: indentRequestID,
      fromEntityCode: entitycode,
      indentNumber: indentNumber,
      remarks: remarks,
      createdBy: localStorage.getItem('USER_ID'),
    }
    const url = this.wholesaleNewURL + 'indentrequestcontroller/indentpriority'
    return this.http.post(url, payload)
  }

  getFeesDetails(postParam): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/config/new/search'
    // const url = 'https://testapigateway.upexciseonline.co/payandrecon/1.0/config/new/search'
    return this.http.post(url, postParam);
  }

  addImportPermit(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'indentrequestcontroller/createimportpermit'
    return this.http.post(url, postData)
  }

  submitNMImportPermit(postData): Observable<any> {
    const url = this.nmUrl + 'add/niveshmithraindentrequest'
    return this.http.post(url, postData)
  }

  indentCancellition(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/saverecord';
    return this.http.post(url, payload)
  }

  dropDown(postParam): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, postParam);
  }

  savePenalty(payload): Observable<any> {
    // const url = this.wholesaleNewURL + 'penalty/savePenaltyDetails';
    const url = this.trackandtrace + "penalty/savePenaltyDetails";
    return this.http.post(url, payload);
  }

  acceptQuota(indentRequestID, indentNumber, remarks) {
    const payload = {
      "customDataDTOList": [
        {
          "dataCode": "INDENT_REQUEST_LOG_ACCEPT",
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "INDENT_REQUEST_NOTE_ACCEPT",
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "INDENT_REQUEST_ACCEPT",
          "placeholderKeyValueMap": {
            "indentRequestID": indentRequestID,
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "APPROVAL_LOG",
          "placeholderKeyValueMap": {
            "stage": "'ACCEPTED'",
            "indentNumber": "'" + indentNumber + "'",
            "remarks": "'" + remarks + "'",
            "createdBy": localStorage.getItem('USER_ID'),
            "modifiedBy": localStorage.getItem('USER_ID'),
            "tableName": "'QUOTA_TRANSFER_REQUEST'"
          }
        }
      ]
    }
    const url = this.wholesaleNewURL + 'customdata/saverecord'
    return this.http.post(url, payload)
  }

  quotareqforclar(indentRequestID, indentNumber, remarks, entitycode) {
    const payload = {
      "customDataDTOList": [
        {
          "dataCode": "INDENT_REQUEST_LOG_WAITING_FOR_REQUEST_CLARIFICATION",
          "entityCode": localStorage.getItem('entityCode'),
          "placeholderKeyValueMap": {
            "indentRequestID": "'" + indentRequestID + "'",
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "INDENT_REQUEST_NOTE_WAITING_FOR_REQUEST_CLARIFICATION",
          "entityCode": localStorage.getItem('entityCode'),
          "placeholderKeyValueMap": {
            "indentRequestID": "'" + indentRequestID + "'",
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "INDENT_REQUEST_WAITING_FOR_REQUEST_CLARIFICATION",
          "entityCode": localStorage.getItem('entityCode'),
          "placeholderKeyValueMap": {
            "indentRequestID": "'" + indentRequestID + "'",
            "remarks": "'" + remarks + "'"
          }
        },
        {
          "dataCode": "APPROVAL_LOG",
          "entityCode": localStorage.getItem('entityCode'),
          "placeholderKeyValueMap": {
            "stage": "'WAITING FOR REQUEST CLARIFICATION'",
            "indentNumber": "'" + indentNumber + "'",
            "remarks": "'" + remarks + "'",
            "tableName": "'QUOTA_TRANSFER_REQUEST'",
            "createdBy": localStorage.getItem('USER_ID'),
            "modifiedBy": localStorage.getItem('USER_ID'),
          }
        }
      ]
    }
    const url = this.wholesaleNewURL + 'customdata/saverecord'
    return this.http.post(url, payload)
  }

  rejectQuotaacceptance(indentRequestID, indentNumber, remarks, entitycode) {
    const payload = {
      "customDataDTOList": [
        {
          "dataCode": "INDENT_REQUEST_LOG_REJECT",
          "entityCode": "'" + entitycode + "'",
          "placeholderKeyValueMap": {
            "indentRequestID": "'" + indentRequestID + "'",
            "remarks": "'" + remarks + "'",
          }
        },
        {
          "dataCode": "INDENT_REQUEST_NOTE_REJECT",
          "entityCode": "'" + entitycode + "'",
          "placeholderKeyValueMap": {
            "indentRequestID": "'" + indentRequestID + "'",
            "remarks": "'" + remarks + "'",
          }
        },
        {
          "dataCode": "INDENT_REQUEST_REJECT",
          "entityCode": "'" + entitycode + "'",
          "placeholderKeyValueMap": {
            "indentRequestID": "'" + indentRequestID + "'",
            "remarks": "'" + remarks + "'",
          }
        },
        {
          "dataCode": "APPROVAL_LOG",
          "entityCode": "'" + entitycode + "'",
          "placeholderKeyValueMap": {
            "stage": "'REJECTED'",
            "createdBy": "'" + localStorage.getItem('USER_ID') + "'",
            "indentNumber": "'" + indentNumber + "'",
            "modifiedBy": "'" + localStorage.getItem('USER_ID') + "'",
            "remarks": "'" + remarks + "'",
            "tableName": "'QUOTA_TRANSFER_REQUEST'"
          }
        }
      ]
    }
    const url = this.wholesaleNewURL + "customdata/saverecord";
    return this.http.post(url, payload)
  }

  summeryDetails(postParam) {
    const url = this.wholesaleNewURL + "customdata/getdata";
    return this.http.post(url, postParam);
  }

  mstBarCodeScanner(payload): Observable<any> {
    const url = this.wholesaleNewURL + "stockverificationcase/updatestockverificationcase"
    return this.http.put(url, payload)
  }

  payandrecon(postData): Observable<any> {
    const url = this.domainUrl + "payandrecon/1.0/config/sub/search";
    return this.http.post(url, postData)
  }

  subSearchPromise(postData): any {
    const url = this.domainUrl + "payandrecon/1.0/config/sub/search";
    return this.http.post(url, postData).toPromise();
  }

  edpregister(postData): Observable<any> {
    const url = this.domainUrl + "payandrecon/1.0/EdpRegistration/particularpackagesearch";
    return this.http.post(url, postData)
  }

  edpregister1(postData): Observable<any> {
    const url = this.domainUrl + "payandrecon/1.0/EcbRegistration/particularpackagesearch";
    return this.http.post(url, postData)
  }

  packagesearchPromise(postData): any {
    const url = this.domainUrl + "payandrecon/1.0/EcbRegistration/particularpackagesearch";
    return this.http.post(url, postData).toPromise();
  }


  dutyAmountGet(postData): Observable<any> {
    const url = this.domainUrl + "payandrecon/1.0/advanceDuty/get";
    return this.http.post(url, postData)
  }

  paymentReduce(postData): Observable<any> {
    const url = this.domainUrl + "payandrecon/1.0/license/multipleadd";
    return this.http.post(url, postData)
  }

  updatePaymentIndent(postData): Observable<any> {
    const url = this.wholesaleNewURL + "indentrequestcontroller/updateindentpayment";
    return this.http.post(url, postData)
  }

  indentDutyFeesEnabled(postData): Observable<any> {
    const url = this.wholesaleNewURL + "appconfig/getappconfigvalue";
    return this.http.post(url, postData)
  }
  indentDutyFeesEnabled1(postData): any {
    let request = {
      "entityCode": postData,
      "appKey": "CL_INDENT_DUTY_FEE_ENABLED"
    }
    const url = this.wholesaleNewURL + "appconfig/getappconfigvalue";
    return this.http.post(url, request).toPromise();
  }

  approve(postData): Observable<any> {
    const url = this.wholesaleNewURL + "approvalflow/process";
    return this.http.post(url, postData)
  }

  amountRefund(postData): Observable<any> {
    const url = this.domainUrl + "payandrecon/1.0/license/walletrefund";
    return this.http.post(url, postData)
  }

  update(postData): Observable<any> {
    const url = this.wholesaleNewURL + "indentrequestcontroller/updateindentrequestfundstatus";
    return this.http.post(url, postData)
  }

  appconfigvalue(postData): any {
    let request = {
      "entityCode": postData,
      "appKey": "CL_INDENT_DUTY_FEE_ENABLED"
    }
    const url = this.wholesaleNewURL + "appconfig/getappconfigvalue";
    return this.http.post(url, request).toPromise()
  }
  cancelForCL2(postData): Observable<any> {
    const url = this.wholesaleNewURL + "customdata/saverecord";
    return this.http.post(url, postData)
  }
  approveindentPriority(postData): Observable<any> {
    const url = this.wholesaleNewURL + "indentrequestcontroller/approveindentpriority";
    return this.http.post(url, postData)
  }
  indentOnHold(postData): Observable<any> {
    const url = this.wholesaleNewURL + "indentrequestcontroller/submitholdindentrequest";
    return this.http.post(url, postData)
  }
  holdApprove(postData): Observable<any> {
    const url = this.wholesaleNewURL + "indentrequestcontroller/approveholdindentrequest";
    return this.http.post(url, postData)
  }

  indentOnRelese(postData): Observable<any> {
    const url = this.wholesaleNewURL + "indentrequestcontroller/releaseindentrequest";
    return this.http.post(url, postData)
  }

  addentityproductmapping(postData): Observable<any> {
    const url = this.wholesaleNewURL + "entityproductmapping/addentityproductmapping";
    return this.http.post(url, postData)
  }


  digitalSign(indetNumber): Observable<any> {
    const url = this.retailURL + 'getsignedindentfile/' + indetNumber;
    return this.http.get(url, { responseType: 'blob' });
  }

  exportDigitalSign(exportPermitNumber): Observable<any> {
    const url = this.retailURL + 'getsignedexportpermitfile/' + exportPermitNumber;
    return this.http.get(url, { responseType: 'blob' });
  }

  getCancelImportPermit(postParam): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, postParam);
  }
  validateGPSIDCheck(validateGPSIDRequst): Observable<any> {
    const url = this.domainUrl + 'vehicleTracking/v1.0.0/api/transportpass/validateGpsIdAndVendor';
    return this.http.post(url, validateGPSIDRequst)
  }
  updateHbrPaymentIndent(postData): Observable<any> {
    const url = this.wholesaleNewURL + "indentrequestcontroller/hbrupdateindentrequestpayment";
    return this.http.post(url, postData)
  }
  payandreconPromise(postData): any {
    const url = this.domainUrl + "payandrecon/1.0/config/sub/search";
    return this.http.post(url, postData).toPromise();
  }

  dutyAmountGetPromise(postData): any {
    const url = this.domainUrl + "payandrecon/1.0/advanceDuty/get";
    return this.http.post(url, postData).toPromise();
  }

  getPaymentConfig(postParam): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, postParam);
  }
  getAppConfigValue(postParam): Observable<any> {
    const url = this.wholesaleNewURL + 'appconfig/getappconfigvalue';
    return this.http.post(url, postParam);
  }
  getAppConfigValueByEntityCode(postParam): Observable<any> {
    const url = this.wholesaleNewURL + 'appconfig/getentityappconfigvalue';
    return this.http.post(url, postParam);
  }
  consignorDetails(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, payload);
  }
  fromLicenseType(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, payload);
  }
  toLicenseType(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, payload);
  }

  getFeesDetailsPerticularSearch(postData): any {
    const url = this.domainUrl + "payandrecon/1.0/EdpRegistration/particularpackagesearch";
    return this.http.post(url, postData).toPromise();
  }
  dutyAmountGetting(postData): any {
    const url = this.domainUrl + "payandrecon/1.0/advanceDuty/get";
    return this.http.post(url, postData).toPromise();
  }
  savePaymentDetailsRecord(payload): Observable<any> {
    const url = this.trackandtrace + 'penalty/savePaymentDetails';
    return this.http.post(url, payload);
  }
  importValidityExtension(payload): Observable<any> {
    const url = this.retailURL + 'indentrequestcontroller/validityextension';
    return this.http.post(url, payload);
  }
  approveCL2(postData, licenceType): Observable<any> {
    let url: any;
    url = this.wholesaleNewURL + "approvalflow/savevalidationandprocess";
    return this.http.post(url, postData)
  }

  updateTransPaymentStatus(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'indentrequestcontroller/updateimportpermitpayment';
    return this.http.post(url, payload);
  }
  updateTransPayment(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'indentrequestcontroller/updateimportpermitpayment';
    return this.http.post(url, payload);
  }
  getMWHAuthoraizedUnit(data) {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, data)
  }
  getMWHEntityConfig(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'appconfig/getentityappconfigvalue';
    return this.http.post(url, payload);
  }
  addIndentRequestByItems(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'indentrequestcontroller/indentrequestbyitem ';
    return this.http.post(url, payload);
  }
  indentReject(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'paymentrefund/indentrequest';
    return this.http.post(url, payload);
  }

  importPaymentGettingDetails(postData): any {
    const url = this.wholesaleNewURL + "approvalflow/importpermitsearchpaymentdetails";
    return this.http.post(url, postData).toPromise();
  }

  caseChangeAfterApprove(payload): Observable<any> {
    const url = this.wholesaleNewURL + 'paymentrefund/indentrequestapprove';
    return this.http.post(url, payload);
  }
  getAppConfigByRevenue(postParam): Observable<any> {
    const url = this.wholesaleNewURL + 'appconfig/getentityappconfigvalue';
    return this.http.post(url, postParam);
  }

  getAppConfigByQuota(postParam): Observable<any> {
    const url = this.wholesaleNewURL + 'appconfig/getentityappconfigvalue';
    return this.http.post(url, postParam);
  }
  getEntiyAppConfig(postParam): any {
    const url = this.wholesaleNewURL + 'appconfig/getentityappconfigvalue';
    return this.http.post(url, postParam).toPromise();
  }

  addIndentReqCL2(postParam): Observable<any> {
    const url = this.wholesaleNewURL + 'add/clindentrequest';
    return this.http.post(url, postParam);
  }
  downloadRecieptForCLIndent(indetNumber, type): Observable<any> {
    const url = this.wholesaleNewURL + 'paymentreceiptfile/' + indetNumber + '/' + type;
    return this.http.get(url, { responseType: 'blob' });
  }
  downloadRecieptForHBRIndent(indetNumber, type): Observable<any> {
    const url = this.wholesaleNewURL + 'paymentreceiptfile/' + indetNumber + '/' + type;
    return this.http.get(url, { responseType: 'blob' });
  }
  downloadReciept(indetNumber, type): Observable<any> {
    const url = this.wholesaleNewURL + 'paymentreceiptfile/' + indetNumber + '/' + type;
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadRecieptForExportPermit(exportPermitNumber): Observable<any> {
    const url = this.wholesaleNewURL + 'paymentreceiptfile/' + exportPermitNumber + '/EXPORT_PERMIT';
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadRecieptForFLB11WithoutIndent(tpReferenceNumber, type): Observable<any> {
    const url = this.wholesaleNewURL + 'paymentreceiptfile/' + tpReferenceNumber + '/' + type;
    return this.http.get(url, { responseType: 'blob' });
  }
  downloadRecieptForPD20FL1(TpRefNum, type): Observable<any> {
    const url = this.wholesaleNewURL + 'paymentreceiptfile/' + TpRefNum + '/' + type;
    return this.http.get(url, { responseType: 'blob' });
  }
  cancelPermitRequest(requestParams): Observable<any> {
    const url = this.wholesaleNewURL + 'indent/cancelrequest';
    return this.http.post(url, requestParams);
  }
  importPermitServerSidePaymentDetuction(importPermitPaymentDetionRequest): Observable<any> {
    const url = this.wholesaleNewURL + 'approvalflow/processwithindentrequestpaymentupdate';
    return this.http.post(url, importPermitPaymentDetionRequest);
  }
  importPermitServerSidePaymentRefund(importPermitPaymentRefundRequest): Observable<any> {
    const url = this.wholesaleNewURL + 'paymentrefund/importpermit';
    return this.http.post(url, importPermitPaymentRefundRequest);
  }
  wholesaleTPCancelApproval(tpCancelRequest): Observable<any> {
    const url = this.wholesaleNewURL + 'orderpickuprelease/saveorderpickupreleasewithtpCancellation';
    return this.http.post(url, tpCancelRequest);
  }

  hbrCreateIndentAndServerDeduction(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'indentrequestcontroller/createhbrindentrequest'
    return this.http.post(url, postData)
  }
  bioSubIndentRequest(postData): Observable<any> {
    const url = this.wholesaleNewURL + 'indentrequestcontroller/createimportpermitbio'
    return this.http.post(url, postData)
  }
  bioDigitalSignDoc(tpNumber): Observable<any> {
    const url = this.retailURL + 'getsignedtransportpassfile/' + tpNumber;
    return this.http.get(url, { responseType: 'blob' });
  }
  getBio1AIndentReqByIdNew(id): any {
    const payload = {
      "dataCode": "BIO1A_INDENT_REQUEST_DETAILS_VIEW",
      "placeholderKeyValueMap": {
        "${indentRequestId}": id
      }
    };
    const url = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(url, payload).toPromise();
  }
  cancelBio1APermitRequest(requestParams): Observable<any> {
    const url = this.wholesaleNewURL + 'indent/cancelimportpermitbio1a';
    return this.http.post(url, requestParams);
  }
  importPermitPaymentRefund(postData): Observable<any> {
    const url = this.wholesaleNewURL + "indentrequestcontroller/updatebioindentrequestfundstatus";
    return this.http.post(url, postData)
  }


  updateTransactionNumber(postData): Observable<any> {
    const url = this.wholesaleNewURL + "indentrequestcontroller/updatebioindentrequestfundstatus";
    return this.http.post(url, postData)
  }

  bioServerRefundDeduction(postData): Observable<any> {
    const url = this.wholesaleNewURL + "paymentrefund/hbrindenttobioindentacceptance";
    return this.http.post(url, postData)
  }

  indentCancelNew(postData): Observable<any> {
    const url = this.wholesaleNewURL + "indent/cancelrequest";
    return this.http.post(url, postData)
  }

  getBeerFeeDetails(postData): any {
    const url = this.wholesaleNewURL + "approvalflow/searchpaymentdetails";
    return this.http.post(url, postData).toPromise();
  }

  beerIndentAcceptance(postData): Observable<any> {
    const url = this.wholesaleNewURL + "beerindent/savebeerindentacceptance";
    return this.http.post(url, postData)
  }

}

