import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from '@appThemeLayout/admin/admin.component';
import { PageNotFoundComponent } from './containers/page-not-found/page-not-found.component';
import { WelcomeComponent } from './containers/welcome/welcome.component';
import { AuthGuardService } from '@appServices/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./containers/authentication/authentication.module')
      .then(m => m.AuthenticationModule)
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard/analytics',
        pathMatch: 'full'
      },
      {
        path: 'welcome',
        canActivate: [AuthGuardService],
        component: WelcomeComponent
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'location',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/locationmaster/locationmasters.module')
          .then(m => m.LocationMastersModule)
      },
      {
        path: 'master',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/master/masters.module').then(m => m.MastersModule)
      },
      {
        path: 'usermanagement',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/usermanagement/usermanagement.module')
          .then(m => m.UsermanagementModule)
      },
      {
        path: 'register',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/registermanagement/registermanagement.module')
          .then(m => m.RegistermanagementModule)
      },
      {
        path: 'wholesale',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/wholesale/wholesale.module')
          .then(m => m.WholesaleModule)
      },
      {
        path: 'payment-master',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/payment-master/payment-master.module')
          .then(m => m.PaymentMasterModule)
      },
      // {
      //   path: 'inventory',
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import('./containers/inventorymaster/inventorymaster.module')
      //     .then(m => m.InventorymasterModule)
      // },
      // {
      //   path: 'alertandcase-management',
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import('./containers/alertandcase-management/alertandcase-management.module')
      //     .then(m => m.AlertandcaseManagementModule)
      // },
      // {
      //   path: 'payandreconcillation',
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import('./containers/payandreconcillation/payandreconcillation.module')
      //     .then(m => m.PayandreconcillationModule)
      // },
      // {
      //   path: 'passandpermit',
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import('./containers/passandpermit/passandpermit.module')
      //     .then(m => m.PassandpermitModule)
      // },
      // {
      //   path: 'qr-generation',
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import('./containers/qr-generation/qr-generation.module')
      //     .then(m => m.QrGenerationModule)
      // },
      // {
      //   path: 'controlregulation',
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import('./containers/controlregulation/controlregulation.module')
      //     .then(m => m.ControlregulationModule)
      // },
      // {
      //   path: 'vigilenceenforcement',
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import('./containers/vigilenceenforcement/vigilenceenforcement.module')
      //     .then(m => m.VigilenceenforcementModule)
      // },
      // {
      //   path: 'grievance',
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import('./containers/grievance/grievance.module').then(m => m.GrievanceModule)
      // },
      {
        path: 'exiselabel',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/exiselabels/exiselabels.module')
          .then(m => m.ExiselabelsModule)
      },
      {
        path: 'indentmanagement',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/indentmanagement/indentmanagement.module')
          .then(m => m.LiquorIndentmanagementModule)
      },
      {
        path: 'report',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'helpdesk',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/helpdesk/helpdesk.module').then(m => m.HelpdeskModule)
      },
      {
        path: 'asset-management',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/asset-management/asset-management.module').then(m => m.AssetManagementModule)
      },
      // {
      //   path: 'helpdeskmaster',
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import('./containers/helpdeskmaster/helpdeskmaster.module').then(m => m.HelpDeskMastersModule)
      // },
      {
        path: 'licencemgnt',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/licencemgnt/licencemgnt.module')
          .then(m => m.LicencemgntModule)
      },
      {
        path: 'chief-minister-dashboard',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/chief-minister-dashboard/chief-minister-dashboard.module')
          .then(m => m.ChiefMinisterDashboardModule)
      },
      {
        path: 'brewery',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/brewery/brewery.module')
          .then(m => m.BreweryModule)
      },
      {
        path: 'department',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/department/department.module')
        .then(m => m.DepartmentModule)
          
      },
      {
        path: 'pd',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/pd/pd.module')
          .then(m => m.PdModule)
      },
      {
        path: 'vintinery',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/pd/vintinery.module')
          .then(m => m.VintineryModule)
      },
      {
        path: 'ind',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/pd/pd.module')
          .then(m => m.PdModule)
      },
      {
        path: 'mixed',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/pd/pd.module')
          .then(m => m.PdModule)
      },
      {
        path: 'returnfiling',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/returnfilling/returnfilling.module')
          .then(m => m.returnfillingModule)
      },
      {
        path: 'chemicallab',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/chemicallab/chemicallab.module')
          .then(m => m.ChemicallabModule)
      },
      {
        path: 'auditManagement',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/auditManagement/audit.module')
          .then(m => m.AuditModule)
      },
      {
        path: 'vigilance',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/vigilance/vigilance.module')
          .then(m => m.VigilanceModule)
      },
      {
        path: 'controlregulation',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/controlregulation/controlregulation.module')
          .then(m => m.ControlregulationModule)
      },
      {
        path: 'sugarmill',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/sugarmill/sugarmill.module')
          .then(m => m.SugarmillModule)
      },
      {
        path: 'masterwarehouse',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/masterwarehouse/masterwarehouse.module').then(m => m.MasterWarehouseModule)
      },
      {
        path: 'notification',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/notification/notification.module').then(m => m.NotificationModule)
      },
      {
        path: 'customer',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/customer/customer.module').then(m => m.CustomerModule)
      },
      {
        path: 'registration',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/registration/registration.module').then(m => m.RegistrationModule)
      },
      {
        path: 'entity-users',
        loadChildren: () => import('./containers/entity-users/entity-users.module').then(m => m.EntityUsersModule)
      },
      {
        path: 'pos-asset',
        loadChildren: () => import('./containers/pos-asset/pos-asset.module').then(m => m.PosAssetModule)
      },
      {
        path: 'payment-and-reconciliation',
        loadChildren: () => import('./containers/payment-and-reconciliation/payment-and-reconciliation.module').then(m => m.PaymentAndReconciliationModule)
      },
      {
        path: 'parentunit',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/parentunit/parentunit.module')
          .then(m => m.ParentunitModule)
      },
      {
        path: 'csdsupply',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/csdsupply/csdsupply.module')
          .then(m => m.csdsupplyModule)
      },
      {
        path: 'importunit',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/importunit/importunit.module')
          .then(m => m.ImportunitModule)
      },

      {
        path: 'portabledistillery',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/portable-distillery/portable-distillery.module')
          .then(m => m.PortableDistilleryModule)
      },
      {
        path: 'districtwarehouse',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/districtwarehouse/districtwarehouse.module')
          .then(m => m.DistWareHouseModule)
      },
      {
        path: 'grievance',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/grievance/grievance.module')
          .then(m => m.GrievanceModule)
      },
      {
        path: 'retailpos',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/retailpos/retailpos.module')
          .then(m => m.RetailposModule)
      },
      {
        path: 'hbr',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/hbr/hbr.module')
          .then(m => m.HelpdeskModule)
      },
      {
        path: 'audit',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/auditManagement/audit.module')
          .then(m => m.AuditModule)
      }, {
        path: 'alertandcasemanagement',
        loadChildren: () => import('./containers/alertandcasemanagement/alertandcasemanagement.module').then(m => m.AlertandcasemanagementModule)
      },

      {
        path: 'sugarmill/casemanagement',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/pd/casemanagement/casemanagement.module').then(m => m.CasemanagementModule)
        
      },
      {
        path: 'ndlcpermit',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/ndlc/ndlc.module')
          .then(m => m.NdlcModule)
      },
      {
        path: 'sacramental',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/sacramental/sacramental.module')
          .then(m => m.SacramentalModule)
      },
      {
        path: 'fl39',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/Fl39/fl39.module')
          .then(m => m.Fl39Module)
      },
      {
        path: 'ndo2',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/ndo2/ndo2.module')
          .then(m => m.Ndo2Module)
      },
      {
        path: 'fl50',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/fl50/fl50.module')
          .then(m => m.Fl50Module)
      },
      {
        path: 'IA',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/industrialalcohol/industrialalcohol.module')
          .then(m => m.IndustrialalcoholModule)
      },
      {
        path: 'beerindentpayment',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./containers/beer-indent-with-payment/beer-indent-with-payment.module')
          .then(m => m.BeerIndentWithPaymentModule)
      }
    ]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
  { path: 'complaintagainstshoporlicensee', loadChildren: () => import('./containers/vigilance/complaintagainstshoporlicensee/complaintagainstshoporlicensee.module').then(m => m.ComplaintagainstshoporlicenseeModule) }

  // Wildcard route for a 404 page

  // {
  //   path: '',
  //   loadChildren: () => import('./demo/pages/authentication/authentication.module').then(m => m.AuthenticationModule)
  //   // component: AuthComponent,
  //   // children: [
  //   //   {
  //   //     path: 'auth',
  //   //     loadChildren: () => import('./demo/pages/authentication/authentication.module').then(module => module.AuthenticationModule)
  //   //   },
  //   //   // {
  //   //   //   path: 'maintenance',
  //   //   //   loadChildren: () => import('./demo/pages/maintenance/maintenance.module').then(module => module.MaintenanceModule)
  //   //   // }
  //   // ]
  // },
  // {
  //   path: 'auth',
  //   loadChildren: () => import('./demo/pages/authentication/authentication.module').then(m => m.AuthenticationModule)
  // },
  // {
  //   path: 'basic',
  //   loadChildren: () => import('./demo/ui-elements/ui-basic/ui-basic.module').then(module => module.UiBasicModule)
  // },
  // {
  //   path: 'forms',
  //   loadChildren: () => import('./demo/pages/form-elements/form-elements.module').then(module => module.FormElementsModule)
  // },
  // {
  //   path: 'tbl-bootstrap',
  //   loadChildren: () => import('./demo/pages/tables/tbl-bootstrap/tbl-bootstrap.module').then(module => module.TblBootstrapModule)
  // },
  // {
  //   path: 'charts',
  //   loadChildren: () => import('./demo/pages/core-chart/core-chart.module').then(module => module.CoreChartModule)
  // },
  // {
  //   path: 'sample-page',
  //   loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
  // },

  // {
  //   path: 'layout',
  //   canActivate: [ AuthGuardService ],
  //   loadChildren: () => import('./demo/pages/layout/layout.module').then(m => m.LayoutModule)
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuardService]
})
export class AppRoutingModule { }
