
const pdParfix = "PD";
const parentunitParfix = "PARENT_UNIT";
const parentBrewery = "BREWERY";
const vintineryParfix = "VINTINERY";
const indParfix = "ID";
const mixedParfix = "MIXED";


export class LocalUrl {
  public static brewery = {
    registrationType: 'BR_',
    entityType: 'BREWERY',
    moduleName: 'brewery',
    url: 'brewery',
    workflowModule: 'BREWERY',
    workflowSubModule: 'BREWERY_BRAND_LABEL',
  };

  public static breweryoverview = {
    registrationType: 'BR_',
    entityType: 'BREWERY',
    moduleName: 'brewery',
    url: 'brewery',
    workflowModule: 'BREWERY',
    workflowSubModule: 'BREWERY_TANK_OVERVIEW',
  };

  public static brewerytanklog = {
    registrationType: 'BTL_',
    entityType: 'BREWERY',
    moduleName: 'brewery',
    url: 'brewery',
    workflowModule: 'BREWERY',
    workflowSubModule: 'BREWERY_TANK_OVERVIEW',
  };

  public static brewerydismentle = {
    registrationType: 'BTD_',
    entityType: 'BREWERY',
    moduleName: 'brewery',
    url: 'brewery',
    workflowModule: 'BREWERY',
    workflowSubModule: 'BREWERY_TANK_DISMANT',
  };

  public static brewerymaintenance = {
    registrationType: 'BTM_',
    entityType: 'BREWERY',
    moduleName: 'brewery',
    url: 'brewery',
    workflowModule: 'BREWERY',
    workflowSubModule: 'BREWERY_BOTLINE_MAINTAIN',
  };

  public static breweryregistration = {
    registrationType: 'BTR_',
    entityType: 'BREWERY',
    moduleName: 'brewery',
    url: 'brewery',
    workflowModule: 'BREWERY',
    workflowSubModule: 'BREWERY_TANK_REG',
  };

  public static breweryrepair = {
    registrationType: 'BTRP_',
    entityType: 'BREWERY',
    moduleName: 'brewery',
    url: 'brewery',
    workflowModule: 'BREWERY',
    workflowSubModule: 'BREWERY_TANK_REPAIR',
  };

  public static brewerytransfer = {
    registrationType: 'BTT_',
    entityType: 'BREWERY',
    moduleName: 'brewery',
    url: 'brewery',
    workflowModule: 'BREWERY',
    workflowSubModule: 'BREWERY_TANK_REPAIR',
  };

  public static brewerydipbook = {
    registrationType: 'BTDB_',
    entityType: 'BREWERY',
    moduleName: 'brewery',
    url: 'brewery',
    workflowModule: 'BREWERY',
    workflowSubModule: 'BREWERY_TANK_DIPBOOK',
  };
  public static brewerydipbookmix = {
    registrationType: 'BTDB_',
    entityType: 'BREWERY',
    moduleName: 'brewery',
    url: 'brewery',
    workflowModule: 'BREWERY',
    workflowSubModule: 'BREWERY_TANK_DIPBOOK',
  };

  public static brewerypredeclaration = {
    registrationType: 'PDL_',
    entityType: 'BREWERY',
    moduleName: 'brewery',
    url: 'brewery',
    workflowModule: 'BREWERY',
    workflowSubModule: 'BREWERY_PLAN_PREDECLARATION',
  };

  public static pdpredeclaration = {
    registrationType: 'PDL_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: 'PD_PLAN_PREDECLARATION',
  };
  public static fl39spiritimportfl39 = {
    registrationType: 'PDL_',
    entityType: 'INDUSTRIAL_ALCOHOL',  
    moduleName: 'industrialalcohol',
    url: 'fl39',
    workflowModule: 'INDUSTRIAL_ALCOHOL',
    workflowSubModule: 'INDUSTRIAL_ALCOHOL_FL39_IMPORT_PERMIT',
  };

  public static mixedpredeclaration = {
    registrationType: 'PDL_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: 'MIXED',
    workflowSubModule: 'MIXED_PLAN_PREDECLARATION',
  };

  public static breweryconversion = {
    registrationType: 'TC',
    entityType: 'BREWERY',
    moduleName: 'brewery',
    url: 'brewery',
    workflowModule: 'BREWERY',
    workflowSubModule: 'BREWERY_TANK_CONV',
  };
  public static vintinery = {
    registrationType: 'BR_',
    entityType: vintineryParfix,
    moduleName: 'vintinery',
    url: 'vintinery',
    workflowModule: vintineryParfix,
    workflowSubModule: vintineryParfix + '_BRAND_LABEL',
  };
  // Pd Section config
  public static pd = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_BRAND_LABEL',
  };

  public static pdoverview = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: 'PD_TANK_OVERVIEW',
  };
  public static pdtranstoginplant = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: 'PD_GIN_TRANSFER_APPROVAL',
  };
  public static indtranstoginplant = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: 'ID',
    workflowSubModule: 'ID_GIN_TRANSFER_APPROVAL',
  };
  public static mixedtranstoginplant = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: 'MIXED',
    workflowSubModule: 'MIXED_GIN_TRANSFER_APPROVAL',
  };
  public static pddismentle = {
    registrationType: 'BTD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: 'PD_TANK_DISMANT',
  };
  public static pdaccidentrequest = {
    registrationType: 'BTD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: 'PD_ACCIDENTAL_REQUEST',
  };

  public static indaccidentrequest = {
    registrationType: 'BTD_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: 'ID',
    workflowSubModule: 'ID_ACCIDENTAL_REQUEST',
  };
  public static mixedaccidentrequest = {
    registrationType: 'BTD_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: 'MIXED',
    workflowSubModule: 'MIXED_ACCIDENTAL_REQUEST',
  };

  public static pdgeneratemf4accidental = {
    registrationType: 'BTD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: 'PD_GENERATE_MF4_ACCIDENTAL',
  };

  public static indgeneratemf4accidental = {
    registrationType: 'BTD_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: 'ID',
    workflowSubModule: 'ID_GENERATE_MF4_ACCIDENTAL',
  };
  public static mixedgeneratemf4accidental = {
    registrationType: 'BTD_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: 'MIXED',
    workflowSubModule: 'MIXED_GENERATE_MF4_ACCIDENTAL',
  };

  public static pdmaintenance = {
    registrationType: 'BTM_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: 'PD_BOTLINE_MAINTAIN',
  };

  public static pdregistration = {
    registrationType: 'BTR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: 'PD_TANK_REG',
  };

  public static pdrepair = {
    registrationType: 'BTRP_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: 'PD_TANK_REPAIR',
  };

  public static pdtransfer = {
    registrationType: 'BTT_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: 'PD_TANK_TRANSFER',
  };

  public static pddipbook = {
    registrationType: 'BTDB_',
    entityType: 'PD',
    moduleName: 'PD',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: 'PD_TANK_DIPBOOK',
  };

  public static pdconversion = {
    registrationType: 'BTC',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: 'PD_TANK_CONVERSION',
  };
  ///parent unit section config 
  public static parentunit = {
    registrationType: 'BR_',
    entityType: 'PARENT_UNIT',
    moduleName: 'PARENT_UNIT',
    url: 'parentunit',
    workflowModule: 'PARENT_UNIT',
    workflowSubModule: parentunitParfix + '_BRAND_LABEL',
  };

  public static pdadd = {
    registrationType: 'BTC',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: 'PD_PD5',
  };

  public static parentunitbottlingplan = {
    registrationType: 'BR_',
    entityType: 'PARENT_UNIT',
    moduleName: 'PARENT_UNIT',
    url: 'parentunit',
    workflowModule: 'PARENT_UNIT',
    workflowSubModule: parentunitParfix + '_BOTTLING_PLAN',
  };

  //PD bottling plan workflow

  public static pdbottlingplan = {
    registrationType: 'BPL_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: pdParfix + '_BOTTLING_PLAN',
  };

  //mixed bottling plan workflow

  public static mixedbottlingplan = {
    registrationType: 'BPL_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: 'MIXED',
    workflowSubModule: mixedParfix + '_BOTTLING_PLAN',
  };

  //ID Sub Bottling Plan Workflow

  public static indbottlingplan = {
    registrationType: 'BPL_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'id',
    workflowModule: 'ID',
    workflowSubModule: indParfix + '_BOTTLING_PLAN',
  };
  public static vintinerybottlingplan = {
    registrationType: 'VBPL_',
    entityType: vintineryParfix,
    moduleName: 'vintinery',
    url: 'vintinery',
    workflowModule: vintineryParfix,
    workflowSubModule: vintineryParfix + '_BOTTLING_PLAN',
  };

  public static parentunitsubbottlingplan = {
    registrationType: 'PUSBPL_',
    entityType: 'PARENT_UNIT',
    moduleName: 'PARENT_UNIT',
    url: 'parentunit',
    workflowModule: 'PARENT_UNIT',
    workflowSubModule: parentunitParfix + '_SUB_BOTTLING_PLAN',
  };

  //PD Sub bottling plan workflow

  public static pdsubbottlingplan = {
    registrationType: 'PDSBPL_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: pdParfix + '_SUB_BOTTLING_PLAN',
  };

  //mixed Sub  bottling plan workflow

  public static mixedsubbottlingplan = {
    registrationType: 'MISBPL_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: 'MIXED',
    workflowSubModule: mixedParfix + '_SUB_BOTTLING_PLAN',
  };

  //ID Sub  Bottling Plan Workflow

  public static indsubbottlingplan = {
    registrationType: 'IDSBPL_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'id',
    workflowModule: 'ID',
    workflowSubModule: indParfix + '_SUB_BOTTLING_PLAN',
  };

  public static vintinerysubbottlingplan = {
    registrationType: 'PDSBPL_',
    entityType: vintineryParfix,
    moduleName: 'vintinery',
    url: 'vintinery',
    workflowModule: vintineryParfix,
    workflowSubModule: vintineryParfix + '_SUB_BOTTLING_PLAN',
  };


  ///sugarmill   section config 
  public static "sugarmillaccidental-request-outsideup" = {
    registrationType: 'BR_',
    entityType: 'SUGAR_MILL',
    moduleName: 'sugarmill',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_INDUSTRIES_OUTSIDE_UP_ACCIDENTAL_REQUEST'
  }

  public static "sugarmillaccidental-request-insideup" = {
    registrationType: 'BR_',
    entityType: 'SUGAR_MILL',
    moduleName: 'sugarmill',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_INDUSTRIES_INSIDE_UP_ACCIDENTAL_REQUEST'
  }
  public static "sugarmillaccidental-request-sugarmill-outsideup" = {
    registrationType: 'BR_',
    entityType: 'SUGAR_MILL',
    moduleName: 'sugarmill',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_OUTSIDE_UP_ACCIDENTAL_REQUEST'
  }
  public static sugarmill = {
    registrationType: 'BR_',
    entityType: 'SUGAR_MILL',
    moduleName: 'sugarmill',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_BRAND_LABEL'
  };


  public static sugarmilloverview = {
    registrationType: 'BR_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'TANK_OVERVIEW',
  };

  public static sugarmillregistration = {
    registrationType: 'BTR_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_TANK_REG',
  };

  public static sugarmillrepair = {
    registrationType: 'BTRP_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_TANK_REPAIR',
  };


  public static sugarmilldismentle = {
    registrationType: 'BTD_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_TANK_DISMANT',
  };

  public static sugarmilltransfer = {
    registrationType: 'BTT_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_TANK_TRANSFER'
  };

  public static sugarmillconversion = {
    registrationType: 'BTC',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_TANK_CONVERSION',
  };

  public static sugarmilldipbook = {
    registrationType: 'BTDB_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_TANK_DIPBOOK',
  };

  public static "sugarmilltankmixing" = {
    registrationType: 'BTTM_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_TANK_MIXING_APPROVAL',
  };
  public static "sugarmillstockactivity" = {
    registrationType: 'SMSA_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_STOCK_APPROVAL',
  };
  public static "sugarmillind-stockavailability" = {
    registrationType: 'SMSA_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_STOCK_AVAILABILITY',
  };
  public static sugarmillapplicationforexportofmolasses = {
    registrationType: 'BTTM_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_MOLASSES_EXPORT',
  };
  public static sugarmillapplicationforexportofmolassesindustryoutsideup = {
    registrationType: 'BTTM_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_MOLASSES_EXPORT',
  };
  public static "sugarmillindent-acceptance-inside-up" = {
    registrationType: 'BTTM_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_PERMIT_ACCEPTANCE',
  };
  public static "sugarmillmolasses-export-permit-acceptance" = {
    registrationType: 'BTTM_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
  };
  public static "sugarmilltank-cleaning" = {
    registrationType: 'BTTC_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_TANK_CLEANING_APPROVAL',
  };
  public static "sugarmilldeclarationofmolasses" = {
    registrationType: 'BTDM_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_DECLARE_MOLASSES',
  };
  // public static sugarmillmaintenance = {
  //   registrationType: 'BTM_',
  //   entityType: 'SUGAR_MILL',
  //   moduleName: 'SUGAR_MILL',
  //   url: 'sugarmill',
  //   workflowModule: 'SUGAR_MILL',
  //   workflowSubModule: 'BOTLINE_MAINTAIN',
  // };
  public static sugarmillallotherpenalties = {
    registrationType: 'SMAOP_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL' + '_ALL_OTHER_PENALTIES',
  };
  public static "sugarmillsheeranidhi-register" = {
    registrationType: 'SMSNR_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL' + '_SHEERA_NIDHI',
  };
  public static "sugarmillindustry-insideup-passbook" = {
    registrationType: 'INPB_',
    entityType: 'SUGAR_MILL',
    moduleName: 'sugarmill',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_PASS_BOOK_APPROVAL',
  };


  public static importunitbio = {
    registrationType: 'BR_',
    entityType: 'BIO',
    moduleName: 'bio',
    url: 'importunit/bio',
    workflowModule: 'BIO',
    workflowSubModule: 'BIO_BRAND_LABEL',
  };

  public static importunitcbw = {
    registrationType: 'BR_',
    entityType: 'CBW',
    moduleName: 'cbw',
    url: 'importunit/cbw',
    workflowModule: 'CBW',
    workflowSubModule: 'CBW_BRAND_LABEL',
  };
  public static importunitbiozero = {
    registrationType: 'BR_',
    entityType: 'CBW',
    moduleName: 'cbw',
    url: 'importunit/biozero',
    workflowModule: 'CBW',
    workflowSubModule: 'CBW_BRAND_LABEL',
  };


  // ind 

  public static ind = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_BRAND_LABEL',
  };

  public static pdapplicationforexportofmolasses = {
    registrationType: 'PDEM_',
    entityType: 'PD',
    moduleName: 'PD',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_MOLASSES_EXPORT',
  };

  public static pdapplicationforexportofmolassescancellation = {
    registrationType: 'PDEM_',
    entityType: 'PD',
    moduleName: 'PD',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_MOLASSES_EXPORT',
  };

  public static indapplicationforexportofmolassescancellation = {
    registrationType: 'PDEM_',
    entityType: 'ID',
    moduleName: 'ID',
    url: 'ind',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_MOLASSES_EXPORT',
  };

  public static indapplicationforexportofmolasses = {
    registrationType: 'IDEM_',
    entityType: 'ID',
    moduleName: 'ID',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_MOLASSES_EXPORT',
  };

  public static mixedapplicationforexportofmolasses = {
    registrationType: 'MIXEDEM_',
    entityType: 'MIXED',
    moduleName: 'MIXED',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_MOLASSES_EXPORT',
  };
  public static "sugarmillapplicationforreleaseofsheeranidhi" = {
    registrationType: 'BTTM_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_SHEERA_NIDHI_RELEASE',
  };

  //sugarmill molasses tender
  public static molassestender = {
    registrationType: 'SMT_',
    entityType: 'SUGAR_MILL',
    moduleName: 'SUGAR_MILL',
    url: 'sugarmill',
    workflowModule: 'SUGAR_MILL',
    workflowSubModule: 'SUGAR_MILL_MOLASSES_TENDER',
  };



  public static "alertandcasemanagementexciseappeal" = {
    registrationType: 'SMSNR_',
    entityType: 'CASE_MANAGEMENT',
    moduleName: 'CASE_MANAGEMENT',
    url: 'alertandcasemanagement',
    workflowModule: 'CASE_MANAGEMENT',
    workflowSubModule: 'CASE_MANAGEMENT_EXCISE_APPEAL_APPROVAL',
  };
  public static "alertandcasemanagementuppublicservicetribunal" = {
    registrationType: 'SMSNR_',
    entityType: 'CASE_MANAGEMENT',
    moduleName: 'CASE_MANAGEMENT',
    url: 'alertandcasemanagement',
    workflowModule: 'CASE_MANAGEMENT',
    workflowSubModule: 'CASE_MANAGEMENT_UPPUBLIC_TRIBUNAL',
  };
  public static "alertandcasemanagementsupremecourtcaseform" = {
    registrationType: 'SMSNR_',
    entityType: 'CASE_MANAGEMENT',
    moduleName: 'CASE_MANAGEMENT',
    url: 'alertandcasemanagement',
    workflowModule: 'CASE_MANAGEMENT',
    workflowSubModule: 'CASE_MANAGEMENT_SUPREME_COURT_CASE_APPROVAL',
  };

  public static pddeclarationofstock = {
    registrationType: 'BTD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: 'PD',
    workflowSubModule: 'PD_DECLARATION_OF_STOCK',
  }

  public static inddeclarationofstock = {
    registrationType: 'BTD_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: 'ID',
    workflowSubModule: 'ID_DECLARATION_OF_STOCK',
  };

  public static mixeddeclarationofstock = {
    registrationType: 'PDL_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: 'MIXED',
    workflowSubModule: 'MIXED_DECLARATION_OF_STOCK',
  };

  public static brewerydeclarationofstock = {
    registrationType: 'BTD_',
    entityType: 'BREWERY',
    moduleName: 'brewery',
    url: 'brewery',
    workflowModule: 'BREWERY',
    workflowSubModule: 'BREWERY_DECLARATION_OF_STOCK',
  };
  // public static pdoverview = {
  //   registrationType: 'BR_',
  //   entityType: 'PD',
  //   moduleName: 'pd',
  //   url: 'pd',
  //   workflowModule: 'PD',
  //   workflowSubModule: 'PD_TANK_OVERVIEW',
  // };

  // public static pddismentle = {
  //   registrationType: 'BTD_',
  //   entityType: 'PD',
  //   moduleName: 'pd',
  //   url: 'pd',
  //   workflowModule: 'PD',
  //   workflowSubModule: 'PD_TANK_DISMANT',
  // };

  // public static pdmaintenance = {
  //   registrationType: 'BTM_',
  //   entityType: 'PD',
  //   moduleName: 'pd',
  //   url: 'pd',
  //   workflowModule: 'PD',
  //   workflowSubModule: 'PD_BOTLINE_MAINTAIN',
  // };

  // public static pdregistration = {
  //   registrationType: 'BTR_',
  //   entityType: 'PD',
  //   moduleName: 'pd',
  //   url: 'pd',
  //   workflowModule: 'PD',
  //   workflowSubModule: 'PD_TANK_REG',
  // };

  // public static pdrepair = {
  //   registrationType: 'BTRP_',
  //   entityType: 'PD',
  //   moduleName: 'pd',
  //   url: 'pd',
  //   workflowModule: 'PD',
  //   workflowSubModule: 'PD_TANK_REPAIR',
  // };

  // public static pdtransfer = {
  //   registrationType: 'BTT_',
  //   entityType: 'PD',
  //   moduleName: 'pd',
  //   url: 'pd',
  //   workflowModule: 'PD',
  //   workflowSubModule: 'PD_TANK_TRANSFER',
  // };

  // public static pddipbook = {
  //   registrationType: 'BTDB_',
  //   entityType: 'PD',
  //   moduleName: 'PD',
  //   url: 'pd',
  //   workflowModule: 'PD',
  //   workflowSubModule: 'PD_TANK_DIPBOOK',
  // };

  // public static pdconversion = {
  //   registrationType: 'BTC',
  //   entityType: 'PD',
  //   moduleName: 'pd',
  //   url: 'pd',
  //   workflowModule: 'PD',
  //   workflowSubModule: 'PD_TANK_CONVERSION',
  // };
  //  Status list
  public static ndo2permit = {
    registrationType: 'ND_',
    entityType: 'NDO2',
    moduleName: 'NDO2',
    url: 'ndo2',
    workflowModule: 'NDO2',
    workflowSubModule: 'NDO2_PERMIT',
  };

  // maxed
  public static mixed = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_BRAND_LABEL',
  };

  //sacramental fl22
  public static fl22importpermit = {
    registrationType: 'SAIM_',
    entityType: 'SCRAMENTAL',
    moduleName: 'SCRAMENTAL',
    url: 'sacramental',
    workflowModule: 'SCRAMENTAL',
    workflowSubModule: 'SCRAMENTAL_FL22_IMPORT_PERMIT',
  };

  public static csdsupplybrandlabelregistrationcsd = {
    registrationType: 'BR_',
    entityType: 'CSD_SUPPLY',
    moduleName: 'CSD_SUPPLY',
    url: 'csdsupply',
    workflowModule: 'CSD_SUPPLY',
    workflowSubModule: 'CSD_SUPPLY_BRAND_LABEL',
  };

  public static csdsupplybrandlabelregistrationparentcsd = {
    registrationType: 'BR_',
    entityType: 'CSD_PARENT_UNIT',
    moduleName: 'CSD_PARENT_UNIT',
    url: 'csdsupply',
    workflowModule: 'CSD_PARENT_UNIT',
    workflowSubModule: 'CSD_PARENT_UNIT_BRAND_LABEL',
  };

  public static csdsupply = {
    registrationType: 'BR_',
    entityType: 'CSD_SUPPLY',
    moduleName: 'CSD_SUPPLY',
    url: 'csdsupply',
    workflowModule: 'CSD_SUPPLY',
    workflowSubModule: 'CSD_SUPPLY_BRAND_LABEL',
  };

  public static ndtpermit = {
    registrationType: 'NDT_',
    entityType: 'NDLC',
    moduleName: 'NDLC',
    url: 'ndlcpermit',
    workflowModule: 'NDLC',
    workflowSubModule: 'NDLC_NDT_PERMIT',
  }

  public static ndepermit = {
    registrationType: 'NDE_',
    entityType: 'NDLC',
    moduleName: 'NDLC',
    url: 'ndlcpermit',
    workflowModule: 'NDLC',
    workflowSubModule: 'NDLC_NDEPERMIT',
  }
  public static exportpermit = {
    registrationType: 'NDTND_',
    entityType: 'NDLC',
    moduleName: 'NDLC',
    url: 'ndlcpermit',
    workflowModule: 'NDLC',
    workflowSubModule: 'NDLC_EXPORT_PERMIT_FOR_NARCOTICS',
  }
  public static importpermitnarcoticdrug = {
    registrationType: 'NDTND_',
    entityType: 'NDLC',
    moduleName: 'NDLC',
    url: 'ndlcpermit',
    workflowModule: 'NDLC',
    workflowSubModule: 'NDLC_IMPORT_PERMIT_FOR_NARCOTICS',
  }
  public static "pdbrandlabelregistrationfl50" = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_BRAND_LABEL_FL50',
  };
  public static "indbrandlabelregistrationfl50" = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_BRAND_LABEL_FL50',
  };
  public static "mixedbrandlabelregistrationfl50" = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_BRAND_LABEL_FL50',
  };

  public static "pdaccidentalomd" = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_ACCIDENTAL_REQUEST_OMD',
  };
  public static "indaccidentalomd" = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_ACCIDENTAL_REQUEST_OMD',
  };
  public static "mixedaccidentalomd" = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_ACCIDENTAL_REQUEST_OMD',
  };
  public static "pdomd-stock-in" = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_OMD_STOCK_IN',
  };
  public static "indomd-stock-in" = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_OMD_STOCK_IN',
  };
  public static "mixedomd-stock-in" = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_OMD_STOCK_IN',
  };
  public static "pdaccidental-dispatch-to-school" = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_FL32_ACCIDENTAL_REQUEST',
  };
  public static "indaccidental-dispatch-to-school" = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_FL32_ACCIDENTAL_REQUEST',
  };
  public static "mixedaccidental-dispatch-to-school" = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_FL32_ACCIDENTAL_REQUEST',
  };
  
  public static 'IAreturn-tp-for-omd-Within' = {
    registrationType: 'BR_',
    entityType: 'INDUSTRIAL_ALCOHOL',
    moduleName: 'INDUSTRIAL_ALCOHOL',
    url: 'IA',
    workflowModule: 'INDUSTRIAL_ALCOHOL',
    workflowSubModule: 'INDUSTRIAL_ALCOHOL_RETURN_TP_PROCESS',
  };
  public static 'IAreturn-tp-for-omd-Outside' = {
    registrationType: 'BR_',
    entityType: 'INDUSTRIAL_ALCOHOL',
    moduleName: 'INDUSTRIAL_ALCOHOL',
    url: 'IA',
    workflowModule: 'INDUSTRIAL_ALCOHOL',
    workflowSubModule: 'INDUSTRIAL_ALCOHOL_RETURN_TP_PROCESS',
  };
  public static statusList = [
    {
      code: 'INITIATE',
      value: "Submit"
    },
    {
      code: 'IN_PROGRESS',
      value: "In Progress"
    },
    {
      code: 'DRAFT',
      value: "Draft"
    },
    {
      code: 'SUBMITTED',
      value: "Submitted"
    },
    {
      code: 'APPROVED',
      value: "Approved"
    },
    {
      code: 'ACCEPTED',
      value: "Accepted"
    },
    {
      code: 'REJECTED',
      value: "Rejected"
    },
    {
      code: 'PENDING',
      value: "Pending"
    },
    {
      code: 'INACTIVE',
      value: "InActive"
    },
    {
      code: 'SENDBACK',
      value: "Clarification"
    },
    {
      code: 'EXPIRED',
      value: "Expired"
    },
    {
      code: 'HISTORY',
      value: "History"
    },
    {
      code: 'CANCELLED',
      value: "Cancelled"
    },
    {
      code: 'MAINTENANCE',
      value: "Maintenance"
    },
    {
      code: 'DISMANTLED',
      value: "Dismantled"
    },
    {
      code: 'CANCELL_IN_PROGRESS',
      value: "Cancellation InProgress"
    },
    {
      code: 'MODIFY_IN_PROGRESS',
      value: "Modify InProgress"
    },
    {
      code: 'COMPLETED',
      value: "Completed"
    },
    {
      code: 'ACKNOWLEDGED',
      value: "acknowledged"
    },


  ]

  


}
