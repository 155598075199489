var GqUrl = 'https://apigateway.upexciseonline.co/';

export const environment = {
  name: 'prod',
  production: true,
  baseURLMaster: GqUrl + 'master/v1.0.0/',
  baseURL: GqUrl + 'sugarmill/v1.0.0/',
  localDBUrl: 'https://json.upexciseonline.co/',
  baseURLL: GqUrl + 'master/v1.0.0/',
  sugarmillBaseURL: GqUrl + 'sugarmill/v1.0.0/',
  licencemanagementURL: GqUrl + 'licensemanagement/v1.0.0/',
  vehicleTracking: GqUrl + 'vehicleTracking/v1.0.0/api/',
  wholesaleURL: GqUrl + 'wholesale/1.0.0/',
  warehouseURL: GqUrl + 'warehouse/1.0.0/',
  warehouseurl: GqUrl + 'warehouse/1.0.0/',
  distilleryBaseURL: GqUrl + 'distillery/v1.0.0/',
  helpdeskURL: GqUrl + 'helpdesk/v1.0.0/',
  retailURL: GqUrl + 'retailpos/v1.0.0/',
  workflowconfig: GqUrl + 'workflow/v1.0.0/',
  devisionUrl: GqUrl + 'master/v1.0.0/',
  documentUrl: GqUrl + 'docManagement/v1.0.0/',
  documentIPUrl: GqUrl + 'docManagement/v1.0.0/',
  licencemanagementIPURL: GqUrl + 'licensemanagement/v1.0.0/',
  userManagerUrl: GqUrl + 'user-managment/v1.0.0/',
  worflowIPURL: GqUrl + 'workflow/v1.0.0/',
  parentunit: GqUrl + 'parentUnit/1.0/',
  helpdesk: GqUrl + 'helpdesk/v1.0.0/',
  notificationURL: GqUrl + 'notification/v1.0.0/',
  baseURLMasterIpAddr: GqUrl + 'master/v1.0.0/',
  scmproduction: GqUrl + 'scmProduction/v1.0.0/',
  licneseBaseUrl: GqUrl + 'licensemanagement/v1.0.0/',
  paymentDomain: GqUrl + 'payment/1.0',
  payuRequest: '/payu/request',
  payuStatusCheck: '/payu/statusCheck',
  payuAction: 'https://test.payu.in/_payment',
  //paymentSurl: "https://uat.upexciseonline.co/JSP/index.jsp",
  //paymentFurl: "https://uat.upexciseonline.co/JSP/index.jsp",
  paymentSurl: "https://upexciseonline.co/JSP/index.jsp",
  paymentFurl: "https://upexciseonline.co/JSP/index.jsp",
  paymentBaseUrl: GqUrl,
  licenseTypeBaseUrl: GqUrl + 'scmProduction/v1.0.0/',
  baseURLUser: GqUrl + 'user-managment/v1.0.0/',
  parentUnit: GqUrl + 'scmProduction/v1.0.0/',
  wholesaleNewURL: GqUrl + 'retailpos/v1.0.0/',
  trackandtraceUrl: GqUrl + 'trackandtrace/v1.0.0/',
  payandreconBaseUrl: GqUrl,
  domainUrl: GqUrl,
  licenseManagement: GqUrl + 'licensemanagement/v1.0.0/' + 'license/',
  SSOLoginUrl: 'https://mis.upexciseonline.co/',
  SSODashoardUrl : 'https://mis.upexciseonline.co/dashboard/omisystem/customreport',
  info: 'pTYsweyJhdXRoVG9rZW4iOiI3YWFhNGQzZi03NmM4LTMwZGItOGY4Yi03Yzc1NzI5NzU3YWIiLCJwYXltZW50U2FsdCI6IjlWSnRMWWlKIiwiZW5jcnlwdGlvbktleSI6Im9hc3lzVVBleGNpc2VLZXlAMTIzISEifQ=='
};
