import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { apiUrls } from '@appEnv/apiurls';
import { Observable } from 'rxjs';
import { data } from 'jquery';
import * as fileSaver from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class WholesaleStockinService {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.wholesaleURL;
  baseURLRETAIL = environment.retailURL;
  wholesaleNewURL = environment.wholesaleNewURL;

  getAllstcokin(obj): Observable<any> {
    const url = this.baseURL + apiUrls.getallstockinbysearch;
    return this.http.post(url, obj);
  }
  searchStockIn(postParam): Observable<any> {
    const url = this.baseURL + apiUrls.getallstockinbysearch;
    return this.http.post(url, postParam);
  }
  getStockbyid(id): Observable<any> {
    const url = this.baseURL + apiUrls.getallstockinbyID + id;
    return this.http.get(url);
  }
  addstockin(postData) {
    const url = this.baseURL + 'stockIn/addStockIn';
    return this.http.post(url, postData)
  }
  getallDrafttorderpickup(obj) {
    const url = this.baseURL + apiUrls.getallDraftorderpickup;
    return this.http.post(url, obj);
  }
  getallSubmittedorderpickup(obj) {
    const url = this.baseURL + apiUrls.getSubmittedrecords;
    return this.http.post(url, obj);
  }
  getallAcceptedorderpickup(obj) {
    const url = this.baseURL + apiUrls.acceptedIndent;
    return this.http.post(url, obj);
  }
  getbyorderIdd(id) {
    const url = this.baseURL + apiUrls.getinprogressRecordsbyIndentNo + id;
    return this.http.get(url);
  }
  getbyorderbyindentIdd(id) {
    const url = this.baseURLRETAIL + apiUrls.getindentbyindentNumm + id;
    return this.http.get(url);
  }
  addorderpickup(postdata) {
    const url = this.baseURL + apiUrls.addorderpickupp;
    return this.http.post(url, postdata)
  }
  getorderpickuplogs(data) {
    const ids = `?indentNo=${data}`;
    const url = this.baseURL + `${apiUrls.getorderpickupllogs}${ids}`;
    return this.http.get(url);
  }
  getorderpickupByIdforAllLists(data) {
    const ids = `?indentNo=${data}`;
    const url = this.baseURL + `${apiUrls.getorderpickupAlllists}${ids}`;
    return this.http.get(url);
  }
  getorderpickuplogsReset(data) {
    const ids = `?indentNo=${data}`;
    const url = this.baseURL + `${apiUrls.orderpickupreset}${ids}`;
    return this.http.get(url);
  }
  getTransportPassGenByTransportNo(transportNo) {
    const url = this.baseURL + apiUrls.getStockinByTransportNo + '?transportNo=' + transportNo;
    return this.http.get(url);
  }

  getProductDetailByIndentNo(transportNo, indentNo) {
    let param = '?transportNo=' + transportNo + '&indentNo=' + indentNo;
    const url = this.baseURL + apiUrls.getDispatchedProductDetailByIndentNo + param;
    return this.http.get(url).toPromise();
  }

  getProductDetByIndentNo(transportNo, indentNo) {
    let param = '?transportNo=' + transportNo + '&indentNo=' + indentNo;
    const url = this.baseURL + apiUrls.getDispatchedProductDetailByIndentNo + param;
    return this.http.get(url);
  }

  searchStockInNew(postParam): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, postParam);
  }
  getStockbyidNew(postParam): Observable<any> {
    let url = "";
    if (localStorage.getItem('unitType') == "DISTRICT_WAREHOUSE") {
      url = this.wholesaleNewURL + 'transportpass/getflb11transportpassitems';
    } else {
      url = this.wholesaleNewURL + 'transportpass/viewtransportpassitems';
    }
    return this.http.post(url, postParam);
  }
  getstockbyid(postdata) {
    let url = "";
    if (localStorage.getItem('unitType') == "DISTRICT_WAREHOUSE") {
      url = this.wholesaleNewURL + 'transportpass/getflb11transportpassitems';
    } else if (localStorage.getItem('unitType') == "IMPORT_UNIT") {
      url = this.wholesaleNewURL + 'transportpass/gettransportpassitemsforbio';
    } else {
      url = this.wholesaleNewURL + 'transportpass/gettransportpassitems';
    }
    return this.http.post(url, postdata);
  }
  validateTransport(postdata) {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, postdata);
  }
  getTransportPassGenByTransportNoNew(postParam) {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, postParam);
  }
  submitdata(data) {
    const url = this.wholesaleNewURL + 'transportpass/acktransportpass';
    return this.http.post(url, data);
  }
  stockinscan(data) {
    const url = this.wholesaleNewURL + 'transportpass/getflb11transportpassitems';
    return this.http.post(url, data)
  }
  getTransportPassItemsBio(data): Observable<any> {
    const url = this.wholesaleNewURL + 'transportpass/gettransportpassitemsforbio';
    return this.http.post(url, data)
  }
  stockinsubmit(data) {
    const url = this.wholesaleNewURL + 'transportpass/acktransportpassforbio';
    return this.http.post(url, data)
  }
  biolist(data) {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, data)
  }
  invalidateTransport(postdata) {
    const url = this.wholesaleNewURL + 'transportpass/gettransportpassitems';
    return this.http.post(url, postdata);
  }
  downloadBill(transportNumber:number){
    const url = this.wholesaleNewURL + 'filedownload/cbwfile';
    return this.http.get(`${url}/${transportNumber}`,{ responseType: 'blob'});
  }
  fileSaver(resp, documentName) {
    return fileSaver.saveAs(resp, documentName);
  }
  billofEntryApproval(data) {
    const url = this.wholesaleNewURL + 'departmentapproval/getapprovalfilterlist';
    return this.http.post(url, data)
  }
  getStockinHandList(data) {
    const url = this.wholesaleNewURL + 'stockdata/stockinhandbyentitycode';
    return this.http.post(url, data);
  }
};
