import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class StockActivityService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;  
  parentunitURL = environment.parentUnit;

  addetails(data): Observable<any> {
     const url = this.baseURL + 'stockActivity';
      // const url = 'http://localhost:3501/scmProduction/stockActivity'
    return this.http.post(url, data);
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'stockActivity/lazySearch';
    return this.http.post(url, payload);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'stockActivity/' + id;
    return this.http.get(url);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getLicanceData(category): Observable<any> {
    const url = this.licenseURL + 'license/category/' + category;
    return this.http.get(url);
  }
  getbrewNo(category): Observable<any> {
    const url = this.baseURL + 'brewIntimation/brewPlanNo?licenseNo=' + category;
    return this.http.get(url);
  }
  getrawMaterial(val): Observable<any> {
    const url = this.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=RAW_MATERIALS&additionalFilter2=' + val;
    return this.http.get(url);
  }
  getrawMaterial1(entityType, unitcode): Observable<any> {
    const url = this.baseURL + 'findBrandDetails/stockActivity/unitCode/rawMaterial?entityType=' + entityType + '&unitCode=' + unitcode;
    return this.http.get(url);
  }
  getrawMaterialPd(entityType, unitcode, licenseType, rawCode): Observable<any> {
    const url = this.baseURL + 'stockActivity/rawMaterial?entityType=' + entityType + '&unitCode=' + unitcode + '&additionalFilter=' + licenseType + '&rawMaterial=' + rawCode;
    return this.http.get(url);
  }
  getTankList(payLoad): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, payLoad);
  }
  getDrumList(payload) :Observable<any>{
    const url = this.baseURL + 'breweryTankRegistration/drumRegistration/lazySearch';
    return this.http.post(url,payload);
  }
  getoldQuantity(licanceNum, rawCode, entityType): Observable<any> {
    const url = this.baseURL + 'stockActivity/oldQuantity/' + rawCode + '?licenseNo=' + licanceNum + '&entityType=' + entityType;
    return this.http.get(url);
  }
  approveStock(postdata): Observable<any> {
    const url = this.baseURL + 'stockActivity/updateWorkFlowDetails';
    return this.http.post(url, postdata);
  }
  updateStock(postdata): Observable<any> {
    const url = this.baseURL + 'stockActivity/updateWorkFlowDetails';
    return this.http.post(url, postdata);
  }
  getMolassesTypesList(): Observable<any> {
    // const url = environment.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=RAW_MOLASSES_TYPE'
    const url = environment.devisionUrl + 'api/masterValues/findDependentValuesMasterOnMaster?filterCode=RAW_MATERIALS&filterSubCode=RM001'
    return this.http.get(url);
  }
  getMolassesTypesListPd(unitCode, entityType): Observable<any> {
    // const url = environment.devisionUrl + 'api/masterValues/findDropDownMasterOnMaster?dropDownKey=RAW_MOLASSES_TYPE'
    const url = this.baseURL + 'stockActivity/rawMaterial?unitCode=' + unitCode + '&entityType=' + entityType + '&additionalFilter=RM001&rawMaterial=molasses'
    return this.http.get(url);
  }

 
  getPD25(params): Observable<any> {
    const url = this.parentunitURL + 'pd25/lazySearch';
    return this.http.post(url, params);
  }
  getPD25acknowledge(params): Observable<any> {
    const url = this.parentunitURL + 'pd25/acknowledgment/search';
    return this.http.post(url, params);
  }
  spiritimport(params): Observable<any> {
    const url = this.parentunitURL + 'spiritImportPermit/lazySearch';
    return this.http.post(url, params);
  }
  spiritImportPermitOrderLazySearch(payload):Observable<any>{
    const url = this.parentunitURL + 'SIPOrderProcessing/lazySearch';
    return this.http.post(url, payload);
  }
  getByIdImpMol(id): Observable<any> {
    const url = this.baseURL + 'importMolasses?id=' + id;
    return this.http.get(url);
  }
  addetailsImpMol(data): Observable<any> {
    const url = this.baseURL + 'importMolasses';
    return this.http.post(url, data);
  }
  getImpMolList(payload) :Observable<any>{
    const url = this.baseURL + 'importMolasses/lazySearch';
    return this.http.post(url,payload);
  }
  updateImpMOl(postdata): Observable<any> {
    const url = this.baseURL + 'importMolasses/updateImportMolassesWorkFlow';
    return this.http.post(url, postdata);
  }
  validityExtension(payload):Observable<any>{
    const url = this.baseURL + 'importMolasses/sub/updateValidityExtension';
    return this.http.post(url, payload);
  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }

}
