import { DatePipe, formatDate, Location } from '@angular/common';
import { EventEmitter, Injectable }       from '@angular/core';

import {
  LicenseConstants,
  SubCategoryDescConstants,
  WorkFlowNameListConstants,
}                                               from '@app/containers/licencemgnt/license.constants';
import { AlertService, DocumentUploadService, } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import * as fileSaver                           from 'file-saver';
import * as _                                   from 'lodash';

import * as moment         from 'moment';
import { BehaviorSubject } from 'rxjs';

interface Post {
  startDate: Date;
}

const {
  BOTTLING_CLB1_SUBCATEGORY,
  BOTTLING_CLB1_SUBCATEGORY_DESC,
  BOTTLING_CLB2_SUBCATEGORY_DESC,
  BOTTLING_FL3_SUBCATEGORY_DESC,
  BOTTLING_FL3_SUBCATEGORY,
  BOTTLING_FL3A_SUBCATEGORY_DESC,
  BOTTLING_FL3A_SUBCATEGORY,
  BOTTLING_FL50_SUBCATEGORY_DESC,
  BOTTLING_FL50_SUBCATEGORY,
  BOTTLING_FL51_SUBCATEGORY_DESC,
  BOTTLING_FL51_SUBCATEGORY,
  BOTTLING_CLB2_SUBCATEGORY,
  BREWERY_B19_SUBCATEGORY_DESC,
  BREWERY_B19_SUBCATEGORY,
  BREWERY_B21_SUBCATEGORY,
  BREWERY_B21_SUBCATEGORY_DESC,
  EVENT_ASSIGMENT_FOR_SITE_VISIT_OFFICER,
  STATUS_REJECTED_SITE_VISIT_INFO,
  DISTRICT_WAREHOUSE_FL1A_SUBCATEGORY,
  DISTRICT_WAREHOUSE_FL1A_SUBCATEGORY_DESC,
  DISTRICT_WAREHOUSE_FL1_SUBCATEGORY,
  DISTRICT_WAREHOUSE_FL1_SUBCATEGORY_DESC,
  MICRO_BREWERY_MB6_SUBCATEGORY_DESC,
  MICRO_BREWERY_MB6_SUBCATEGORY,
  MICRO_BREWERY_MB2_SUBCATEGORY_DESC,
  MICRO_BREWERY_MB2_SUBCATEGORY,
  LICENSE_URL,SUGAR_MILL_WITH_IN_UP_SUBCATEGORY_DESC_TITLE,
  SUGAR_MILL_WITH_IN_UP_SUBCATEGORY_DESC,
  SUGAR_MILL_WITH_IN_UP_SUBCATEGORY,
  SUGAR_MILL_OUT_SIDE_UP_SUBCATEGORY_DESC,
  SUGAR_MILL_OUT_SIDE_UP_SUBCATEGORY,
  SUGAR_MILL_INDUS_IN_UP_SUBCATEGORY_DESC, SUGAR_MILL_INDUS_IN_UP_SUBCATEGORY, SUGAR_MILL_INDUS_OUT_SIDE_UP_SUBCATEGORY_DESC, SUGAR_MILL_INDUS_OUT_SIDE_UP_SUBCATEGORY,
  SUGAR_MILL_MOLASSESS_TRADERS_SUBCATEGORY_DESC, SUGAR_MILL_MOLASSESS_TRADERS_SUBCATEGORY, STATUS_PENDING_SITE_VISIT_APPROVAL, PENDING_SITE_VISIT_APPROVAL_NEXT,
  docuPropObj, EVENT_INITIATE, STATUS_PENDING_SITE_VISIT, EVENT_SENDBACK_SITE_INFO, EVENT_SITE_VISIT_OFFICER_ASSIGMENT, SENDBACK_SITE_APPROVAL_CLARIFICATION_INFO_NEXT,
  STATUS_DRAFT, STATUS_SAVE, STATUS_SUBMITTED, STATUS_SENDBACK, STATUS_APPROVED, STATUS_PENDING_SITE_INFO, SENDBACK_SITE_APPROVAL_CLARIFICATION_INFO,
  STATUS_SENDBACK_SITE_INFO, CUSTOMER, STATUS_PENDING_LICENSE_FEE, STATUS_REJECTED, STATUS_APPROVED_SITE_VISIT,
  FF_SITE_DOCUMENT, COMMENT_SENDBACK, COMMENT_ACTION_REJECTED, COMMENT_SENDBACK_SITE_INFO, STATUS_REJECTED_SITE_VISIT,
  STATUS_SEND_BACK_REJECTED_SITE_VISIT, STATUS_RECONSIDER_REJECTED_SITE_VISIT, STATUS_FORWARD_REJECTED_SITE_VISIT,
  WHOLESALE_LICENSE_CATEGORY, EVENT_BAR_LICENSE_CATEGORY, MASTER_WARE_HOUSE_CATEGORY, HBR_LICENSE_CATEGORY, BWFL_PARENT_LICENSE_CATEGORY,
  BOND_WAREHOUSE_LICENSE_CATEGORY, DISTI_BOTTL_LICENSE_CATEGORY, RETAIL_LICENSE_CATEGORY, PREVIOUSYEAR_ITR,STATUS_PENDING_SITE_VISIT_APPROVAL_ONE_RECORD,
  MSG_ERR_FILE_DOWNLOAD, MSG_SUCC_FILE_DOWNLOAD, MSG_ERR_NO_FILE, MSG_ERR_NO_FILE_DET, DATE_FORMAT, STR_LEVEL_ONE, EVENT_PENDING_SITE_INFO, BTN_NAME_RECOMMAND
} = LicenseConstants;

@Injectable({
  providedIn: "root",
})
export class LicenseCommonService {
  selectedState = new EventEmitter();
  selectedAutoState = new EventEmitter();
  selectedTahsilDistrict = new EventEmitter();
  selectedPinCodeDistrict = new EventEmitter();
  viewSelectedData = new EventEmitter();
  selectedGrpName = new EventEmitter();
  selectedIndustryType = new EventEmitter();
  selectedOfficerType = new EventEmitter();
  selectedModelUpdate = new EventEmitter();
  selectedGrpView = new EventEmitter();
  selectedExistingLicenseType = new EventEmitter();
  selectedLicenseNoSearch = new EventEmitter();
  sendTankShape = new BehaviorSubject(null);

  parentApplicationNumber = new EventEmitter();

  onParentApplicationNumberChange(applicationNumber: any) {
    this.parentApplicationNumber.emit(applicationNumber);
  }

  onSendTankShape(item: any) {
    this.sendTankShape.next(item);
  }

  onExistingLicenseType(item: any) {
    this.selectedExistingLicenseType.emit(item);
  }

  onLicenseNoSearch(item: any) {
    this.selectedLicenseNoSearch.emit(item);
  }

  onUpdateModel(item: any) {
    this.selectedModelUpdate.emit(item);
  }

  onOfficerType(item: any) {
    this.selectedOfficerType.emit(item);
  }

  onViewDoc(item: any) {
    this.viewSelectedData.emit(item);
  }

  onSelectedAutoState(item: any) {
    this.selectedAutoState.emit(item);
  }

  onStateSelected(item: any) {
    this.selectedState.emit(item);
  }

  onDistrictTahsilSelected(item: any) {
    this.selectedTahsilDistrict.emit(item);
  }

  onDistrictPincodeSelected(item: any) {
    this.selectedPinCodeDistrict.emit(item);
  }

  onGrpNameSelected(item: any) {
    this.selectedGrpName.emit(item);
  }

  onViewGrpSelected(item: any) {
    this.selectedGrpView.emit(item);
  }

  onIndustryTypeSelected(item: any) {
    this.selectedIndustryType.emit(item);
  }

  licenseConsts = LicenseConstants;
  workFlowConsts = WorkFlowNameListConstants;
  subCatDescConsts = SubCategoryDescConstants;
  docuPropObj = docuPropObj;

  post: Post = { startDate: new Date(Date.now()) };
  applicationDate = formatDate(this.post.startDate, "yyyy-MM-dd", "en");
  importUnitApplicationDate = formatDate(
    this.post.startDate,
    "dd/MM/yyyy",
    "en"
  );

  maxDOBDate = moment(new Date()).subtract(21, "years").format("YYYY-MM-DD");
  minDOBDate = moment(this.maxDOBDate)
    .subtract(85, "years")
    .format("YYYY-MM-DD");
  curDate = moment(new Date()).format("YYYY-MM-DD");
  emdDate = moment(new Date()).subtract(1, "day").format("YYYY-MM-DD");
  emdExpiryDate = moment(new Date()).add(1, "day").format("YYYY-MM-DD");
  eventExpiryDate = moment(new Date()).add(0, "day").format("YYYY-MM-DD");
  maxEmdDate = new Date().getFullYear();
  maxDate = moment(this.curDate).add(5, "months").format("YYYY-MM-DD");
  dateAft6Month = moment(this.curDate).add(5, "months").format("YYYY-MM-DD");
  dateof6Month = moment(this.curDate).add(6, "months").format("YYYY-MM-DD");
  maxOneyear = moment(this.curDate).add(12, "months").format("YYYY-MM-DD");

  curDateNew = moment();
  curDateFormatNew = moment().format(DATE_FORMAT);
  maxDOBDateNew = moment().subtract(21, "years");
  minDOBDateNew = moment(this.maxDOBDate).subtract(85, "years");
  emdExpiryDateMinNew = moment().add(1, "day");
  emdExpiryDateMaxNew = moment().endOf("year");
  dateof6MonthNew = moment().add(6, "months");
  datebef6MonthNew = moment().subtract(6, "months");
  dateAft3YearNew = moment().add(3, "year");
  dateAft5YearNew = moment().add(5, "year");
  bef10dayNew = moment().subtract(10, "day");
  eventDateNew = moment().subtract(1, "day");

  viewFileArr = {
    [FF_SITE_DOCUMENT]: [],
  };

  constructor(
    // private stateSer: StatemasterService,
    // private districtSer: DistrictMasterService,
    // private dropdownConfigSer: DropdownConfigService,
    // private talukSer: TalukMasterService,
    // private authService: AuthService,
    private alert: AlertService,
    private location: Location,
    private docUplSer: DocumentUploadService,
    private datePipe: DatePipe, 
    private translate: TranslateService
  ) { }

  back(): void {
    this.location.back();
  }
  randomNum(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  randomStr() {
    const alpha = "abcdefghijklmnopqrstuvwxyz";
    return alpha[Math.floor(Math.random() * alpha.length)];
  }
  mergeWith(obj1, obj2) {
    return _.mergeWith(obj1, obj2);
  }
  fileSaver(resp, documentName) {
    return fileSaver.saveAs(resp, documentName);
  }
  stringToUpperCase(string) {
    return _.toUpper(string).replace(/ /g, "_");
  }
  getPatchDate(dateStr) {
    return dateStr ? moment(dateStr, DATE_FORMAT) : null;
  }
  getFormatDate(dateStr) {
    return dateStr ? dateStr.format(DATE_FORMAT) : null;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 9 || charCode > 18)
    ) {
      return false;
    }
    // if (charCode == 48 && event.target.value.trim() == "") {
    //   return false;
    // }
    return true;
  }

  decimalNumberOnly(event: any) {
    // const inp = String.fromCharCode(event.keyCode);
    // if (/^[0-9.]/.test(inp)) {
    //   return true;
    // } else {
    //   event.preventDefault();
    //   return false;
    // }
    // return true;
    return String.fromCharCode(event.charCode).match(/[^0-9.]/g) === null
  }

  extraSpaceremoval(str): boolean {
    if (/\s+/g.test(str)) {
      return false;
    }
  }

  alphabetOnly(event) {
    const inp = String.fromCharCode(event.keyCode);
    if (/^[a-zA-Z]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  alphabetSpaceOnly(event) {
    const inp = String.fromCharCode(event.keyCode);
    if (/^[a-zA-Z _]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  alphabetWithCommaOnly(event) {
    const inp = String.fromCharCode(event.keyCode);
    if (/^[a-zA-Z,\s]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  alphanumbericSplCharOnly(event) {
    const inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9\s]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  alphanumericOnly(event) {
    const inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9\s]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  alphanumericSpaceOnly(event) {
    const inp = String.fromCharCode(event.keyCode);
    if (/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i.test(inp) || /^[a-zA-Z,\s]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  alphanumericSpaceSpecialOnly(event) {
    const inp = String.fromCharCode(event.keyCode);
    if (/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i.test(inp) || /^[a-zA-Z,\s]*$/.test(inp) || /^[ A-Za-z0-9.,'%_@\/#&-]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  alphanumericSpaceWithbracesOnly(event) {
    const inp = String.fromCharCode(event.keyCode);
    if (/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i.test(inp) || /^[a-zA-Z,\s]*$/.test(inp) || /^[A-z0-9()]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }


  alphanumericSomeSplChar(event) {
    const inp = String.fromCharCode(event.keyCode);
    // if (/^[ A-Za-z0-9_.,-\/]*$/.test(inp)) {
    if (/^[ A-Za-z0-9.,'%_@\/#&-]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  alphanumericStreetSomeSplChar(event) {
    const inp = String.fromCharCode(event.keyCode);
    if (/^[ A-Za-z0-9.,'%_@\/#&-]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  alphanumericMandate(event) {
    const inp = String.fromCharCode(event.keyCode);
    if (/[^A-Za-z0-9]+/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  combineArr(oldArr, newArr) {
    // console.log('oldArr::', oldArr);
    // console.log('newArr::', newArr);

    if (newArr.length > 0) {
      newArr.forEach((element) => {
        const ind = oldArr.findIndex((item) => item.name === element.name);
        if (ind !== -1) {
          oldArr[ind] = element;
        } else {
          oldArr.push(element);
        }
      });
    }
    return oldArr;
  }

  combineArrDoc(oldArr, newArr) {
    // console.log('oldArr::', oldArr);
    // console.log('newArr::', newArr);

    if (newArr.length > 0) {
      newArr.forEach((element) => {
        oldArr.forEach((elemnt,ids) => {
        const ind = oldArr.findIndex((item) => item.name === element[0].name);
        if (ind !== -1) {
          oldArr[ind] = element;
        } else if(elemnt.name === element[0].name){  
            console.log(elemnt)
              oldArr[ids].name = element[0].name;
              oldArr[ids].documentName = element[0].documentName;
              oldArr[ids].uuid = element[0].uuid;
              console.log(oldArr[ids])
              oldArr.push(oldArr[ids]);
        }else{
          oldArr.push(element);
        }
      })
      });
    }
    return oldArr;
  }

  combineArrLiquourDoc(oldArr, newArr) {
    // console.log('oldArr::', oldArr);
    // console.log('newArr::', newArr);

    if (newArr.length > 0) {
      newArr.forEach((element) => {
        oldArr.forEach((elemnt,ids) => {
        const ind = oldArr.findIndex((item) => item.name === element[0].name);
        if (ind !== -1) {
          oldArr[ind] = element;
        } 
        else if(elemnt.name === null && oldArr.length==1){  
          console.log(elemnt)
            oldArr[ids].name = element[0].name;
            oldArr[ids].documentName = element[0].documentName;
            oldArr[ids].uuid = element[0].uuid;
            console.log(oldArr[ids])
            oldArr.push(oldArr[ids]);
      }
        // else if(elemnt.name === element[0].name){  
        //     console.log(elemnt)
        //       oldArr[ids].name = element[0].name;
        //       oldArr[ids].documentName = element[0].documentName;
        //       oldArr[ids].uuid = element[0].uuid;
        //       console.log(oldArr[ids])
        //       oldArr.push(oldArr[ids]);
        // }
        else{
          oldArr.push(element);
        }
      })
      });
    }
    return oldArr;
  }

  invertDate(date) {
    if (!date) {
      return date;
    }
    date = date.split("-");
    return date[2] + "-" + date[1] + "-" + date[0];
  }

  getDateStr(dateObj, delimiter) {
    return dateObj
      ? dateObj.day + delimiter + dateObj.month + delimiter + dateObj.year
      : "";
  }

  getDateObj(dateStr, delimiter) {
    if (dateStr) {
      const date = dateStr.split(delimiter);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  stringToDate(strDate) {
    let res;
    if (strDate) {
      const dt = strDate.split("-");
      res = formatDate(dt[2] + "-" + dt[1] + "-" + dt[0], "yyyy-MM-dd", "en");
    }
    return res;
  }

  setDateFormat(date) {
    if (date) {
      const dt = date.split("/");
      let res = formatDate(
        dt[2] + "-" + dt[1] + "-" + dt[0],
        "yyyy-MM-dd",
        "en"
      );
      return new Date(res);
    }
  }

  unsetValidator(formControl, fileArr) {
    console.log(formControl);
    console.log(fileArr);
    if (!fileArr) {
      return false;
    }

    fileArr.forEach((ele) => {
      const eleSource = ele + "_source";
      if (formControl.get(ele) && formControl.get(eleSource)) {
        formControl.get(ele).setValidators([]);
        formControl.get(ele).updateValueAndValidity();
        formControl.get(eleSource).setValidators([]);
        formControl.get(eleSource).updateValueAndValidity();
      } else {
        // console.log('eleSource::', ele + '_file_source');
      }
    });
    return true;
  }

  addValidation(instControl, validation) {
    instControl.setValidators(validation);
    instControl.updateValueAndValidity();
    return true;
  }

  getArr(namedArr) {
    const arr = [];
    for (let key in namedArr) {
      let value = namedArr[key];
      value ? arr.push(value) : "";
    }
    return arr;
  }

  consoleForm(formName, form) {
    console.log(formName + ".valid::", form.valid);
    console.log(formName + ".value::", form.value);
    console.log(formName + "::", form);
  }

  customerCommentFilter(res) {
    if (res.actionKey === COMMENT_SENDBACK && res.sendBackTo === "Level 1") {
      return true;
    } else if (
      res.actionKey === COMMENT_ACTION_REJECTED ||
      res.actionKey === COMMENT_SENDBACK_SITE_INFO
    ) {
      return true;
    } else {
      return false;
    }

    // (res.actionKey === COMMENT_SENDBACK || res.sendBackTo === '')
  }

  getLicenseData(licAppliData): any {
    const {
      licenseCategory,
      financialYear,
      applicationDate,
      licenseType,
      addressList,
      applicantDetails,
      bankList,
      status,
      commentList,
      checkList,
    } = licAppliData;

    let mergeObj;
    let licenseObj;

    switch (licenseCategory) {
      case WHOLESALE_LICENSE_CATEGORY:
        const { businessUnitDetails, wholeSaleLicenses } = licAppliData;
        licenseObj = wholeSaleLicenses ? wholeSaleLicenses : [];
        mergeObj = {
          addressList,
          applicantDetails,
          bankList,
          businessUnitDetails,
        };
        break;

      case MASTER_WARE_HOUSE_CATEGORY:
        const { masterwarehouseDetails } = licAppliData;
        licenseObj = masterwarehouseDetails ? masterwarehouseDetails : [];
        mergeObj = {
          addressList,
          applicantDetails,
          bankList,
          businessUnitDetails,
        };
        break;
      case EVENT_BAR_LICENSE_CATEGORY:
        const { eventBarLicenses } = licAppliData;
        licenseObj = eventBarLicenses ? eventBarLicenses : [];
        mergeObj = { addressList, applicantDetails, bankList };
        break;
      case RETAIL_LICENSE_CATEGORY:
        const { retailLicenses, securityAmountList } = licAppliData;
        licenseObj = retailLicenses ? retailLicenses : [];
        mergeObj = {
          addressList,
          applicantDetails,
          bankList,
          securityAmountList,
        };
        break;
    }

    this.mergeWith(licenseObj, mergeObj);

    return {
      financialYear,
      applicationDate,
      licenseType,
      licenseObj,
      status,
      commentList,
    };
  }

  isEditFormIsReadOnly(isCustomer, status, stage, licenseCategory) {
    console.log("status::", status);
    console.log("stage::", stage);
    console.log("licenseCategory::", licenseCategory);

    let readOnly;
    let showActionFor;
    if (isCustomer) {
      console.log("CUSTOMER_ACTION_BUTTONS_WITH_READONLY_YES_OR_NO");
      const { condition } = this.getCondition(licenseCategory, status);
      if (condition) {
        if (stage == null || stage == "null" || stage === "customer") {
          console.log(
            "NEED_CUSTOMER_ACTION_BUTTONS_BECAUSE_ITS_IN_CUSTOMER_HAND"
          );
          readOnly = false;
          showActionFor = CUSTOMER;
          // display save, submit cancel
        } else {
          console.log(
            "NO_NEED_CUSTOMER_ACTION_BUTTONS_BECAUSE_ITS_NOT_IN_CUSTOMER_HAND"
          );
          readOnly = true;
          showActionFor = "";
        }
      } else {
        console.log("NO_NEED_CUSTOMER_ACTION_BUTTONS_AFTER_APPROVED");
        readOnly = true;
        showActionFor = "";
      }
    }
    return { readOnly, showActionFor };
  }

  getCondition(licenseCategory, status) {
    let condition;
    switch (licenseCategory) {
      case WHOLESALE_LICENSE_CATEGORY:
        condition =
          status !== STATUS_APPROVED && status !== STATUS_PENDING_SITE_INFO; //  && status !== STATUS_SENDBACK_SITE_INFO
        break;
      case MASTER_WARE_HOUSE_CATEGORY:
        condition =
          status !== STATUS_APPROVED && status !== STATUS_PENDING_SITE_INFO; //  && status !== STATUS_SENDBACK_SITE_INFO
        break;
      case BOND_WAREHOUSE_LICENSE_CATEGORY:
        condition = (status !== STATUS_APPROVED &&
          status !== STATUS_PENDING_SITE_INFO); // && status !== STATUS_SENDBACK_SITE_INFO
        break;
      case RETAIL_LICENSE_CATEGORY:
        condition =
          status !== STATUS_APPROVED && status !== STATUS_PENDING_SITE_INFO; // && status !== STATUS_SENDBACK_SITE_INFO
        break;

      default:
        condition = status !== STATUS_APPROVED;
        break;
    }
    return { condition };
  }

  onSiteDocumentFileChange(event, idx, siteDocumentCntrl, sourceName, fileFor) {
    const secName = FF_SITE_DOCUMENT;
    const fileUpRes = this.onFileChange(
      event,
      sourceName,
      fileFor,
      siteDocumentCntrl
    );
    if (!fileUpRes) {
      return false;
    }

    if (this.viewFileArr[secName][idx]) {
      this.viewFileArr[secName][idx].push(sourceName);
    } else {
      this.viewFileArr[secName][idx] = [];
      this.viewFileArr[secName][idx].push(sourceName);
    }
  }

  onFileChange(event, sourceName, fileFor, formControl) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      file.fileFor = fileFor;
      formControl.patchValue({ [sourceName]: file });
      return true;
    }
    return false;
  }

  getCheckListLogInfo(checkList, comment, idx) {
    const { level: commentOfficerLevel, designation } = comment;
    let officerCheckListApiData = checkList
      ? checkList.find((resp: any) => resp.level === commentOfficerLevel)
      : {};
    officerCheckListApiData =
      officerCheckListApiData && officerCheckListApiData.length > 1
        ? officerCheckListApiData[idx]
        : officerCheckListApiData;

    let isCustomerComment = true;
    let totCheckListCount = 0;
    let apprCheckListCount = 0;
    const unApprDocCodeArr = [];

    if (checkList && officerCheckListApiData) {
      isCustomerComment = false;
      officerCheckListApiData.checkListDocument.forEach((element) => {
        totCheckListCount++;
        if (element.isVerified === true) {
          apprCheckListCount++;
        } else {
          unApprDocCodeArr.push(element.documentDesc);
        }
      });
    }
    return {
      isCustomerComment,
      totCheckListCount,
      apprCheckListCount,
      unApprDocCodeArr,
    };
  }

  getSiteDoc(
    isCustomer,
    licenseData,
    customerSiteDetails,
    officierSiteDetails,
    propertyName,
    idx
  ) {
    const propObj = isCustomer ? customerSiteDetails : officierSiteDetails;
    return propObj &&
      propObj[propertyName] &&
      propObj[propertyName][idx] &&
      propObj[propertyName][idx].documentName !== null
      ? propObj[propertyName][idx]
      : false;
  }

  isSiteDocFileExists(
    isCustomer,
    licenseData,
    customerSiteDetails,
    officierSiteDetails,
    propertyName,
    idx
  ) {
    return !licenseData
      ? false
      : this.getSiteDoc(
        isCustomer,
        licenseData,
        customerSiteDetails,
        officierSiteDetails,
        propertyName,
        idx
      );
  }

  downloadSiteDocument(
    isCustomer,
    licenseData,
    customerSiteDetails,
    officierSiteDetails,
    propertyName,
    idx
  ) {
    if (licenseData) {
      const fileObj = this.getSiteDoc(
        isCustomer,
        licenseData,
        customerSiteDetails,
        officierSiteDetails,
        propertyName,
        idx
      );

      const { uuid, documentName } = fileObj;

      if (uuid && documentName) {
        this.docUplSer.downloadDocument(uuid, documentName).subscribe(
          (resp: any) => fileSaver.saveAs(resp, documentName),
          (error: any) => this.alert.showError("Error downloading the file"),
          () => console.info("File downloaded successfully")
        );
      } else {
        this.alert.showError("File not available");
      }
    } else {
      this.alert.showError("File details not found");
    }
  }

  selectITRchange(event): boolean {
    return event === PREVIOUSYEAR_ITR ? true : false;
  }

  getFileArr(licenseData, propertyName, idx = null) {
    const { licenseObj } = this.getLicenseData(licenseData);
    console.log("licenseObj::", licenseObj);
    console.log("propertyName::", propertyName);
    const propObj = licenseObj[propertyName];
    console.log("propObj::", propObj);
    const docProp = this.docuPropObj[propertyName];
    console.log("docProp::", docProp);
    const fileArr = idx !== null ? propObj[idx][docProp] : propObj[docProp];
    return fileArr;
  }

  isFileExists(licenseData, propertyName, fileFor, idx = null) {
    console.log("idx::", idx);
    return licenseData
      ? this.getFileArr(licenseData, propertyName, idx).find(
        (resp: any) => resp.name === fileFor
      )
      : false;
  }

  downloadDocument(licenseData, propertyName, fileFor) {
    if (licenseData) {
      const fileArr = this.getFileArr(licenseData, propertyName);
      const { uuid, documentName } = fileArr.find(
        (resp: any) => resp.name === fileFor
      );

      if (uuid && documentName) {
        this.docUplSer.downloadDocument(uuid, documentName).subscribe(
          (resp: any) => this.fileSaver(resp, documentName),
          (error: any) => this.alert.showError(MSG_ERR_FILE_DOWNLOAD),
          () => console.info(MSG_SUCC_FILE_DOWNLOAD)
        );
      } else {
        this.alert.showError(MSG_ERR_NO_FILE);
      }
    } else {
      this.alert.showError(MSG_ERR_NO_FILE_DET);
    }
  }

  downloadFile(uuid, documentName) {
    if (uuid && documentName) {
      this.docUplSer.downloadDocument(uuid, documentName).subscribe(
        (resp: any) => this.fileSaver(resp, documentName),
        (error: any) => this.alert.showError(MSG_ERR_FILE_DOWNLOAD),
        () => console.info(MSG_SUCC_FILE_DOWNLOAD)
      );
    } else {
      this.alert.showError(MSG_ERR_NO_FILE);
    }
  }

  fileDocObj(v, doc) {
    let obj = {
      fileType: v.type,
      formName: doc.formGroupName,
      key: v.key,
      submitJsonKey: v.submitJsonKey,
      submitSubJsonKey: v.submitSubJsonKey,
      submitSubDocJsonKey: v.submitSubDocJsonKey,
      arrayListName: v.arrayListName,
    };

    return obj;
  }

  dateFormateChange(val) {
    let dateVal = val;
    if (val) {
      dateVal = this.datePipe.transform(new Date(val), "dd/MM/yyyy");
    }
    return dateVal;
  }

  getStatus(type, isCustomer, isOfficer, model) {
    let val = {
      status: type,
      event: '',
      level: '',
    };
    switch ( type ) {
      case STATUS_DRAFT:
      case STATUS_SAVE:
      case this.licenseConsts.STATUS_PENDING_SECURITY_FEE:
        val.event = '';
        val.level = '';
        break;
      case STATUS_SUBMITTED:
        val.event = EVENT_INITIATE;
        val.level = STR_LEVEL_ONE;
        break;
      case STATUS_PENDING_LICENSE_FEE:
      case STATUS_APPROVED:
      case STATUS_REJECTED:
      case STATUS_REJECTED_SITE_VISIT_INFO:
      case EVENT_ASSIGMENT_FOR_SITE_VISIT_OFFICER:
      case STATUS_APPROVED_SITE_VISIT:
      case STATUS_PENDING_SITE_VISIT_APPROVAL:
      case STATUS_REJECTED_SITE_VISIT:
      case STATUS_SEND_BACK_REJECTED_SITE_VISIT:
      case STATUS_FORWARD_REJECTED_SITE_VISIT:
      case STATUS_RECONSIDER_REJECTED_SITE_VISIT:
      case SENDBACK_SITE_APPROVAL_CLARIFICATION_INFO:
      case PENDING_SITE_VISIT_APPROVAL_NEXT:
      case SENDBACK_SITE_APPROVAL_CLARIFICATION_INFO_NEXT:
      case this.licenseConsts.STATUS_SENDBACK_SITE_APPROVAL_CLARIFICATION_INFO:
      case this.licenseConsts.STATUS_REJECTED_SITE_INFO:
        val.event = type;
        val.level = '';
        break;
      case STATUS_SENDBACK_SITE_INFO:
        val.event = (isOfficer) ? STATUS_SENDBACK_SITE_INFO : EVENT_PENDING_SITE_INFO;
        val.level = STR_LEVEL_ONE;
        break;
      case STATUS_SENDBACK:
        val.event =
          model.status === STATUS_PENDING_SITE_VISIT
            ? EVENT_SENDBACK_SITE_INFO
            : STATUS_SENDBACK;
        val.level = '';
        break;
      case STATUS_PENDING_SITE_INFO:
        val.event = isOfficer
          ? EVENT_SITE_VISIT_OFFICER_ASSIGMENT
          : STATUS_PENDING_SITE_INFO;
        val.level = "";
        break;
        case STATUS_PENDING_SITE_VISIT_APPROVAL_ONE_RECORD:
          val.event = STATUS_PENDING_SITE_VISIT_APPROVAL_ONE_RECORD
          val.level = "";
          break;
      default:
        val.event = "";
        val.level = "";
        break;
    }

    return val;
  }

  panelWithinList() {
    let obj = [
      {
        title: "Suger Mill Registration Form",
        formGroupName: "sugerMillRegistrationForm",
        docList: [
          {
            type: this.licenseConsts.FN_ADDI_REGISTRATION_CERT,
            key: "uploadValidSugerMillLicenseRegistrationCertificate",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillDocumentList",
            arrayListName: null,
          },
        ],
      },
      {
        title: "Registered Head Office",
        formGroupName: "registeredHeadOffice",
        docList: [],
      },
      {
        title: "Unit Office Details",
        formGroupName: "unitOfficeDetails",
        docList: [],
      },
      {
        title: "Occupier Detail",
        formGroupName: "occupierDetail",
        docList: [],
      },
      {
        title: "General Manager Detail",
        formGroupName: "generalManagerDetail",
        docList: [],
      },
      {
        title: "Sales Manager Detail",
        formGroupName: "salesManagerDetail",
        docList: [],
      },
      {
        title:
          "Sugar and Molasses Production Achieved During Previous Molasses Year",
        formGroupName:
          "sugarAndMolassesProductionAchievedDuringPreviousMolassesYear",
        docList: [],
      },
      {
        title: "Registered Crushing Capacity",
        formGroupName: "registeredCrushingCapacity",
        docList: [],
      },
      {
        title: "Quintal/Counter (In Case Pipeline Supply on MF4 Gate Pass)",
        formGroupName: "quintalCounter",
        docList: [],
      },
      {
        title: "Molasses Storage Detail",
        formGroupName: "molassesStorageDetail",
        docList: [
          {
            type: this.licenseConsts.FN_GAUGE_CHART_DOC,
            key: "gaugeChart",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "molassesList",
            arrayListName: "molassessDocumentList",
            submitSubDocJsonKey: "molassessDocumentList",
          },
          {
            type: this.licenseConsts.FN_PLAN_ELEVATION_DOC,
            key: "planElevation",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "molassesList",
            arrayListName: "molassessDocumentList",
            submitSubDocJsonKey: "molassessDocumentList",
          }, {
            type: this.licenseConsts.FN_FLOW_DIAGRAM_DOC,
            key: "flowDiagram",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "molassesList",
            arrayListName: "molassessDocumentList",
            submitSubDocJsonKey: "molassessDocumentList",
          },
          {
            type: 'ATTACHMENT_DRAWINGS',
            key: "drawings",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "molassesList",
            arrayListName: "molassessDocumentList",
            submitSubDocJsonKey: "molassessDocumentList",
          },
          {
            type: 'ATTACHMENT_BLUEPRINT',
            key: "blueprintOfTank",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "molassesList",
            arrayListName: "molassessDocumentList",
            submitSubDocJsonKey: "molassessDocumentList",
          }
        ],
      },
      {
        title: "Employment Details",
        formGroupName: "employmentDetails",
        docList: [],
      },
      {
        title: "Site Details - Geographical Info",
        formGroupName: "siteDetailsGeographicalInfo",
        docList: [],
      },
      {
        title: "Attachments",
        formGroupName: "attachments",
        docList: [],
      },
    ];

    return obj;
  }

  panelOutsideList() {
    let obj = [
      {
        title: "Suger Mill Registration Form",
        formGroupName: "sugerMillRegistrationForm",
        docList: [
          {
            type: this.licenseConsts.Valid_License_Copy,
            key: "validLicenseCopy",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.Export_Permit_of_Concerned_State,
            key: "exportPermitOfConcernedState",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.Import_Permit_of_UP,
            key: "importPermitOfUp",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillDocumentList",
            arrayListName: null,
          },
        ],
      },
      {
        title: "Registered Head Office",
        formGroupName: "registeredHeadOffice",
        docList: [],
      },
      {
        title: "Unit Office Details",
        formGroupName: "unitOfficeDetails",
        docList: [],
      },
      {
        title: "General Manager Detail",
        formGroupName: "generalManagerDetail",
        docList: [],
      },
      {
        title: "Sales Manager Detail",
        formGroupName: "salesManagerDetail",
        docList: [],
      },
      {
        title:
          "Sugar and Molasses Production Achieved During Previous Molasses Year",
        formGroupName:
          "sugarAndMolassesProductionAchievedDuringPreviousMolassesYear",
        docList: [],
      },
      {
        title: "Registered Crushing Capacity",
        formGroupName: "registeredCrushingCapacity",
        docList: [],
      },
      {
        title: "Attachments",
        formGroupName: "attachments",
        docList: [],
      },
    ];

    return obj;
  }

  panelIndustriesInsideUpList() {
    let obj = [
      {
        title: "Suger Mill Registration Form",
        formGroupName: "sugerMillRegistrationForm",
        docList: [
          {
            type: this.licenseConsts.FN_ATTACH_COPY_OF_BLUEPRINT_OF_PLAN,
            key: "copyOfBluePrintOfPlan",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.FN_GAUGE_CHART_DOC,
            key: "gaugeChart",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillDocumentList",
            arrayListName: null,
          }
        ],
      },
      {
        title: "Registered Head Office",
        formGroupName: "registeredHeadOffice",
        docList: [],
      },
      {
        title: "Working Address Details",
        formGroupName: "workingAddressDetail",
        docList: [],
      },
      {
        title: "General Manager Detail",
        formGroupName: "generalManagerDetail",
        docList: [],
      },
      {
        title: "Sales Manager Detail",
        formGroupName: "salesManagerDetail",
        docList: [],
      },
      {
        title:
          "Sugar and Molasses Production Achieved During Previous Molasses Year",
        formGroupName:
          "sugarAndMolassesProductionAchievedDuringPreviousMolassesYear",
        docList: [],
      },
      {
        title: "Registered Crushing Capacity",
        formGroupName: "registeredCrushingCapacity",
        docList: [],
      },
      {
        title: "Attachments",
        formGroupName: "attachments",
        docList: [],
      },
    ];

    return obj;
  }

  panelIndustriesOutsideUpList() {
    let obj = [
      {
        title: "Suger Mill Registration Form",
        formGroupName: "sugerMillRegistrationForm",
        docList: [
          {
            type: this.licenseConsts.FN_ATTACH_COPY_OF_BLUEPRINT_OF_PLAN,
            key: "copyOfBluePrintOfPlan",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.FN_GAUGE_CHART_DOC,
            key: "gaugeChart",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillDocumentList",
            arrayListName: null,
          }
        ],
      },
      {
        title: "Registered Head Office",
        formGroupName: "registeredHeadOffice",
        docList: [],
      },
      {
        title: "Working Address Details",
        formGroupName: "workingAddressDetail",
        docList: [],
      },
      {
        title: "General Manager Detail",
        formGroupName: "generalManagerDetail",
        docList: [],
      },
      {
        title: "Sales Manager Detail",
        formGroupName: "salesManagerDetail",
        docList: [],
      },
      {
        title:
          "Sugar and Molasses Production Achieved During Previous Molasses Year",
        formGroupName:
          "sugarAndMolassesProductionAchievedDuringPreviousMolassesYear",
        docList: [],
      },
      {
        title: "Registered Crushing Capacity",
        formGroupName: "registeredCrushingCapacity",
        docList: [],
      },
      {
        title: "Attachments",
        formGroupName: "attachments",
        docList: [],
      },
    ];

    return obj;
  }

  panelMolasessTraderList() {
    let obj = [
      {
        title: "Application for Traders for Export",
        formGroupName: "sugerMillRegistrationForm",
        docList: [],
      },
      {
        title: "Applicant Details",
        formGroupName: "applicantDetailsForm",
        docList: [
          {
            type: this.licenseConsts.FN_APPLI_PHOTO,
            key: "photographOfTheApplicant",
            submitJsonKey: "applicantDetails",
            submitSubJsonKey: "applicantDocumentList",
            arrayListName: null,
          },

          {
            type: this.licenseConsts.SRC_APPLI_PAN,
            key: "uploadPan",
            submitJsonKey: "applicantDetails",
            submitSubJsonKey: "applicantDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.SRC_APPLI_ITR,
            key: "uploadItr",
            submitJsonKey: "applicantDetails",
            submitSubJsonKey: "applicantDocumentList",
            arrayListName: null,
          },
        ],
      },
      {
        title: "Applicant Address",
        formGroupName: "registeredHeadOffice",
        docList: [],
      },
      {
        title: "Premises Details",
        formGroupName: "sugarMillPremisesDetails",
        docList: [
          {
            type: this.licenseConsts.SRC_SM_PAN,
            key: "firmPanDocument",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillPremisesDetails",
            submitSubDocJsonKey: "premisesDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.SRC_SM_ITR,
            key: "itrDocument",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillPremisesDetails",
            submitSubDocJsonKey: "premisesDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.SRC_ATTACH_CERT_OF_INCORP,
            key: "incorporationDocument",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillPremisesDetails",
            submitSubDocJsonKey: "premisesDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.SRC_ATTACH_MOM_OF_ASSO,
            key: "memorandumOfAssociationDocument",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillPremisesDetails",
            submitSubDocJsonKey: "premisesDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.SRC_ATTACH_ART_OF_ASSOC,
            key: "articleOfAssociationDocument",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillPremisesDetails",
            submitSubDocJsonKey: "premisesDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.SRC_ATTACH_RESU_OF_AUTH,
            key: "resolutionOfAuthorizationDocument",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillPremisesDetails",
            submitSubDocJsonKey: "premisesDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.SRC_ATTACH_OWNERSHIIP_DOC,
            key: "ownershipDocument",
            submitJsonKey: "sugarMillLicenses",
            submitSubJsonKey: "sugarMillPremisesDetails",
            submitSubDocJsonKey: "premisesDocumentList",
            arrayListName: null,
          },
        ],
      },
      {
        title: "Premises Address",
        formGroupName: "premisesAddressDetail",
        docList: [],
      },
      {
        title: "Attachments",
        formGroupName: "attachmentDetails",
        docList: [
          {
            type: this.licenseConsts.SRC_ATTACH_SOLVENCY_CERTIFICATE_OF_PROPERTY_DOC,
            key: "solvencyCertificateOfProperty",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.SRC_ATTACH_AFFADAVAIT,
            key: "affidavitForEligibility",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.FN_ATTACH_LTTRFRMEMBASSY,
            key: "letterFromEmbassy",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.FN_ATTACH_IMPORTEXPORTCRT,
            key: "exportLicense",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.FN_ATTACH_CONSTENT_LETTER_OF_IMPORTER,
            key: "consentLetterOfImporter",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          }
        ],
      },
      {
        title: "Bank Details",
        formGroupName: "licenseBankDetails",
        docList: [
          {
            type: this.licenseConsts.FN_BK_CAN_CHEQUE,
            key: "cancelCheque",
            submitJsonKey: "licenseBankDetails",
            submitSubJsonKey: "bankDocumentList",
            arrayListName: null,
          },
        ],
      },
      {
        title: "Attachments",
        formGroupName: "attachments",
        docList: [],
      },
    ];

    return obj;
  }

  autoPopulateVal(isEdit) {
    let list = [
      {
        keysList: [
          {
            key: "husbandFatherName",
            fieldKey: "applicantFatherHusbandName",
            subFieldKey: "",
            fieldType: "input",
          },
          {
            key: "salutationCode",
            fieldKey: "salutation",
            subFieldKey: "",
            fieldType: "ddl",
          },
          {
            key: "salutationDesc",
            fieldKey: "salutationDesc",
            subFieldKey: "",
            fieldType: "ddl",
          },
          {
            key: "firstName",
            fieldKey: "firstName",
            subFieldKey: "",
            fieldType: "input",
          },
          {
            key: "middleName",
            fieldKey: "middleName",
            subFieldKey: "",
            fieldType: "input",
          },
          {
            key: "lastName",
            fieldKey: "lastName",
            subFieldKey: "",
            fieldType: "input",
          },
          {
            key: "mobileNumber",
            fieldKey: "mobileNumber",
            subFieldKey: "",
            fieldType: "input",
          },
          {
            key: "emailId",
            fieldKey: "email",
            subFieldKey: "",
            fieldType: "input",
          },
          {
            key: "genderCode",
            fieldKey: "genderCode",
            subFieldKey: "",
            fieldType: "ddl",
          },
          {
            key: "genderDesc",
            fieldKey: "genderDesc",
            subFieldKey: "",
            fieldType: "ddl",
          },
          {
            key: "dob",
            fieldKey: "dob",
            subFieldKey: "",
            fieldType: "date",
          },
          {
            key: "panNumber",
            fieldKey: "panNumber",
            subFieldKey: "",
            fieldType: "input",
          },
          {
            key: "uploadPan",
            fieldType: "file",
            fieldKey: "uploadPan",
            modelObjkey: "applicantDetails",
            respkey: "documentList",
            modelfileName: "APPLICANT_PAN_DOC",
            fileName: "appli_pan_file_source",
          },
          {
            key: "photographOfTheApplicant",
            fieldType: "file",
            modelObjkey: "applicantDetails",
            respkey: "documentList",
            modelfileName: "APPLICANT_PHOTO_DOC",
            fileName: "APPLICANT_PHOTO_DOC",
          },
        ],
        formName: "applicantDetailsForm",
      },
      {
        keysList: [
          {
            key: "country",
            fieldKey: "country",
            subFieldKey: "userAddressVO",
            fieldType: "ddl",
          },
          {
            key: "pinCode",
            fieldKey: "pinCode",
            subFieldKey: "userAddressVO",
            fieldType: "input",
          },
          {
            key: "stateCode",
            fieldKey: "stateCode",
            subFieldKey: "userAddressVO",
            fieldType: "ddl",
          },
          {
            key: "stateDesc",
            fieldKey: "stateDesc",
            subFieldKey: "userAddressVO",
            fieldType: "ddl",
          },
          {
            key: "districtCode",
            fieldKey: "districtCode",
            subFieldKey: "userAddressVO",
            fieldType: "ddl",
          },
          {
            key: "districtDesc",
            fieldKey: "districtDesc",
            subFieldKey: "userAddressVO",
            fieldType: "ddl",
          },
          {
            key: "tahsilCode",
            fieldKey: "tahsilCode",
            subFieldKey: "userAddressVO",
            fieldType: "ddl",
          },
          {
            key: "tahsilDesc",
            fieldKey: "tahsilDesc",
            subFieldKey: "userAddressVO",
            fieldType: "ddl",
          },
          {
            key: "localityType",
            fieldKey: "localityType",
            subFieldKey: "userAddressVO",
            fieldType: "ddl",
          },
          {
            key: "localityTypeDesc",
            fieldKey: "localityTypeDesc",
            subFieldKey: "userAddressVO",
            fieldType: "ddl",
          },
          {
            key: "ward",
            fieldKey: "ward",
            subFieldKey: "userAddressVO",
            fieldType: "input",
          },
          {
            key: "municipalityName",
            fieldKey: "municipalityName",
            subFieldKey: "userAddressVO",
            fieldType: "input",
          },
          {
            key: "block",
            fieldKey: "block",
            subFieldKey: "userAddressVO",
            fieldType: "input",
          },
          {
            key: "village",
            fieldKey: "village",
            subFieldKey: "userAddressVO",
            fieldType: "input",
          },
          {
            key: "locality",
            fieldKey: "locality",
            subFieldKey: "userAddressVO",
            fieldType: "input",
          },
          {
            key: "street",
            fieldKey: "street",
            subFieldKey: "userAddressVO",
            fieldType: "input",
          },
          {
            key: "policeStationName",
            fieldKey: "policeStationName",
            subFieldKey: "userAddressVO",
            fieldType: "input",
          },
        ],
        formName: "registeredHeadOffice",
      },
    ];
    let obj = {};
    let localStore = JSON.parse(localStorage.getItem("USER_DET"));
    list.forEach((v) => {
      obj[v.formName] = {};
      v.keysList.forEach((h) => {
        if (h.fieldType == "file") {
          let findVal = localStore[h.respkey].find(
            (p) => p.name == h.modelfileName
          );
          if (findVal) {
            findVal.name = h.fileName;
            obj[v.formName][h.key] = findVal;
          }
          console.log(obj[v.formName][h.key])
        } else if (h.fieldKey && h.subFieldKey) {
          obj[v.formName][h.key] = localStore[h.subFieldKey][h.fieldKey];
        } else if (h.fieldKey) {
          obj[v.formName][h.key] = localStore[h.fieldKey];
        }
      });
    });
    if(!isEdit){
      this.alert.showSuccess("Customer details fetched successfully.")
    }
    return obj;
  }
  autoPopulateValParent(parentApplicationData) {
    const list = [
      {
        keysList: [
          {
            key: "licenseNumber",
            fieldKey: "licenseNumber",
            subFieldKey: "",
            fieldType: "input",
            modelObjkey: "bottlingParentUnitDetails"
          },
          {
            fieldKey: "applicationNumber",
            key: "applicationNumber",
            subFieldKey: "",
            fieldType: "input",
            modelObjkey: "bottlingParentUnitDetails"
          },
          {
            key: "licenseCopyUuid",
            fieldKey: "uploadLicense",
            subFieldKey: "",
            fieldType: "fileType",
            fieldModelObjkey: "bottlingParentUnitDetails",
            fieldRespkey: "parentUnitDocumentList",
          },
          {
            key: "licenseExpireDate",
            fieldKey: "licenseValidityDate",
            subFieldKey: "",
            fieldType: "date",
            modelObjkey: "bottlingParentUnitDetails"
          },
          {
            key: "panNumber",
            fieldKey: "panNumber",
            subFieldKey: "attachmentDetails",
            fieldType: "input",
          },
          {
            key: "panCertificate",
            fieldType: "file",
            fieldKey: "panCertificate",
            modelObjkey: "attachmentDetails",
            respkey: "attachmentDocumentList",
            modelfileName: "APPLICANT_PAN_DOC",
            fileName: "APPLICANT_PAN_DOC",
            fieldModelObjkey: "bottlingParentUnitDetails",
            fieldRespkey: "parentUnitDocumentList"
          }, {
            key: "gstNumber",
            fieldKey: "gstNumber",
            subFieldKey: "attachmentDetails",
            fieldType: "input",
          },
          {
            key: "gstinCertificate",
            fieldType: "file",
            fieldKey: "gstinCertificate",
            modelObjkey: "attachmentDetails",
            respkey: "attachmentDocumentList",
            modelfileName: "ADDITIONAL_GSTIN_DOC",
            fileName: "ADDITIONAL_GSTIN_DOC",
            fieldModelObjkey: "bottlingParentUnitDetails",
            fieldRespkey: "parentUnitDocumentList"
          },
          {
            key: "itrAssesmentYearCode",
            fieldKey: "itrAssesmentYearCode",
            subFieldKey: "attachmentDetails",
            fieldType: "ddl",
          },
          {
            key: "itrAssesmentYearDesc",
            fieldKey: "itrAssesmentYearDesc",
            subFieldKey: "attachmentDetails",
            fieldType: "ddl",
          },
          {
            key: "uploadItr",
            fieldType: "file",
            fieldKey: "uploadItr",
            modelObjkey: "attachmentDetails",
            respkey: "attachmentDocumentList",
            modelfileName: "APPLICANT_ITR_DOC",
            fileName: "APPLICANT_ITR_DOC",
            fieldModelObjkey: "bottlingParentUnitDetails",
            fieldRespkey: "parentUnitDocumentList"
          },
          {
            key: "previousItrNotFiled",
            fieldKey: "previousItrNotFiled",
            subFieldKey: "applicantDetails",
            fieldType: "input",
          },
          {
            key: "unitName",
            fieldKey: "unitName",
            subFieldKey: "businessUnitDetails",
            fieldType: "input",
          }
        ],
        formName: "bottlingParentUnitDetails",
      },  {
        keysList: [{
            key: "country",
            fieldKey: "country",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "pinCode",
            fieldKey: "pinCode",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
          {
            key: "stateCode",
            fieldKey: "stateCode",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"

          },
          {
            key: "stateDesc",
            fieldKey: "stateDesc",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "districtCode",
            fieldKey: "districtCode",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "districtDesc",
            fieldKey: "districtDesc",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "tahsilCode",
            fieldKey: "tahsilCode",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "tahsilDesc",
            fieldKey: "tahsilDesc",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "localityType",
            fieldKey: "localityType",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "localityTypeDesc",
            fieldKey: "localityTypeDesc",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "ward",
            fieldKey: "ward",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
          {
            key: "municipalityName",
            fieldKey: "municipalityName",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
          {
            key: "block",
            fieldKey: "block",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
          {
            key: "village",
            fieldKey: "village",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
          {
            key: "locality",
            fieldKey: "locality",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
          {
            key: "street",
            fieldKey: "street",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
          {
            key: "policeStationName",
            fieldKey: "policeStationName",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
        ],
        formName: "parentDistilleryHeadAddress",
      }
    ];
    let obj = {};
    let localStore = parentApplicationData
    list.forEach((v) => {
      console.log("Sfa")
      obj[v.formName] = {};
      v.keysList.forEach((h) => {
        if(localStore){
          if (h.fieldType == "file") {
            if(localStore[h.modelObjkey][h.respkey]){
              let findVal = localStore[h.modelObjkey][h.respkey].find(
                (p) => p.name == h.modelfileName
              );
              if (findVal) {
                findVal.name = h.fileName;
                if(!obj[h.fieldModelObjkey][h.fieldRespkey]){
                  obj[h.fieldModelObjkey][h.fieldRespkey] = []
                }
                delete findVal["id"]
                obj[h.fieldModelObjkey][h.fieldRespkey].push(findVal)
              }
            }
          } if (h.fieldType == "fileType") {
            let findVal =  null
            if(!h.subFieldKey){
              findVal = {
                "documentName" : "licenseCopy.pdf",
                "name": "appli_license_file_source",
                "uuid": localStore[h.fieldKey]
              }
              if(!obj[h.fieldModelObjkey][h.fieldRespkey]){
                obj[h.fieldModelObjkey][h.fieldRespkey] = []
              }
              obj[h.fieldModelObjkey][h.fieldRespkey].push(findVal)
            }
          }  else if (h.fieldKey && h.subFieldKey && h.addressIdentifier) {
            let findVal = localStore[h.subFieldKey].find(
              (p) => p.addressIdentifier == h.addressIdentifier
            );
            if(findVal[h.key]){
              obj[v.formName][h.key] = findVal[h.key];
            }
          } else if (h.fieldKey && h.subFieldKey) {
            if(localStore[h.subFieldKey][h.fieldKey]){
              obj[v.formName][h.key] = localStore[h.subFieldKey][h.fieldKey];
            }
          } else if (h.fieldKey) {
            // if(localStore[h.fieldKey]){
              if(h.fieldType == "date"){
                const myDateArray = localStore[h.fieldKey].split("/");
                if(myDateArray.length==3){
                  if(parseInt(myDateArray[1])< 10){
                    myDateArray[1] = "0"+myDateArray[1]
                  }
                  if(parseInt(myDateArray[0])< 10){
                    myDateArray[0] = "0"+ myDateArray[0]
                  }
                  let dateVal = myDateArray[2] + "-" + myDateArray[1] + "-" + myDateArray[0]
                  obj[v.formName][h.key] = dateVal;
                }
              } else {
                obj[v.formName][h.key] = localStore[h.fieldKey];
              }
            // }
          }
        } else {
          obj[v.formName][h.key] = ""
        }
      });
    });
    return obj;
  }

  autoPopulateAddress(){
    let obj = [
      "country","stateCode","stateDesc","districtCode","districtDesc","pinCode","tahsilCode","tahsilDesc","municipalityName","localityType","localityTypeDesc","ward","block","village","locality","street","policeStationName"
    ]

    return obj
  }

  sugarMillDescription() {
    let LicUrl = LICENSE_URL.SUGAR_MILL;
    let data = [
      {
        titleText: SUGAR_MILL_WITH_IN_UP_SUBCATEGORY_DESC_TITLE,
        descText: this.translate.instant('licenseMgnt.sugarmillWithinUPTitleDesc'),
        url: LicUrl + SUGAR_MILL_WITH_IN_UP_SUBCATEGORY,
        subCategory: SUGAR_MILL_WITH_IN_UP_SUBCATEGORY,
        renewLicense: false,
      },
      {
        titleText: SUGAR_MILL_OUT_SIDE_UP_SUBCATEGORY_DESC,
        descText: this.translate.instant('licenseMgnt.sugarmillOutsideUPTitleDesc'),
        url: LicUrl + SUGAR_MILL_OUT_SIDE_UP_SUBCATEGORY,
        subCategory: SUGAR_MILL_OUT_SIDE_UP_SUBCATEGORY,
        renewLicense: false,
      },
      {
        titleText: SUGAR_MILL_INDUS_IN_UP_SUBCATEGORY_DESC,
        descText: this.translate.instant('licenseMgnt.smIndInsideUPTitleDesc'),
        url: LicUrl + SUGAR_MILL_INDUS_IN_UP_SUBCATEGORY,
        subCategory: SUGAR_MILL_INDUS_IN_UP_SUBCATEGORY,
        renewLicense: false,
      },
      {
        titleText: SUGAR_MILL_INDUS_OUT_SIDE_UP_SUBCATEGORY_DESC,
        descText: this.translate.instant('licenseMgnt.smIndOutsideUPTitleDesc'),
        url: LicUrl + SUGAR_MILL_INDUS_OUT_SIDE_UP_SUBCATEGORY,
        subCategory: SUGAR_MILL_INDUS_OUT_SIDE_UP_SUBCATEGORY,
        renewLicense: false,
      },
      {
        titleText: SUGAR_MILL_MOLASSESS_TRADERS_SUBCATEGORY_DESC,
        descText: this.translate.instant('licenseMgnt.smMolassesTraderDesc'),
        url: LicUrl + SUGAR_MILL_MOLASSESS_TRADERS_SUBCATEGORY,
        subCategory: SUGAR_MILL_MOLASSESS_TRADERS_SUBCATEGORY,
        renewLicense: false,
      },
    ];

    return data;
  }

  panelMicroBreweryMb6List() {
    let obj = [
      {
        title: "Micro Brewery",
        formGroupName: "microBreweryForm",
        docList: [],
      },
      {
        title: "Applicant Details",
        formGroupName: "applicantDetailsForm",
        docList: [
          {
            type: this.licenseConsts.FN_APPLI_PHOTO,
            key: "photographOfTheApplicant",
            submitJsonKey: "applicantDetails",
            submitSubJsonKey: "applicantDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.SRC_APPLI_PAN,
            key: "uploadPan",
            submitJsonKey: "applicantDetails",
            submitSubJsonKey: "applicantDocumentList",
            arrayListName: null,
          },
        ],
      },
      {
        title: "Applicant Address",
        formGroupName: "registeredHeadOffice",
        docList: [],
      },
      {
        title: "Attachments",
        formGroupName: "attachmentDetails",
        docList: [
          {
            type: this.licenseConsts
              .FN_ATTACH_ATTACHMENT_PLANT_MACHINERY_DETAILS_DOC,
            key: "plantMachineryDetails",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.FN_ATTACH_RAW_MATERIAL_DETAILS_DOC,
            key: "rawMaterialDetails",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts
              .FN_ATTACH_CAPACITY_OF_THE_PLANT_PER_DAY_DOC,
            key: "capacityOfThePlantPerDay",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.FN_ATTACH_NO_DUES_DOC,
            key: "noDues",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts
              .FN_ATTACH_INTIMATION_LETTER_FOR_INSPECTION_DOC,
            key: "intimationLetterForInspection",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts
              .FN_ATTACH_BLUEPRINTS_LAYOUT_OF_MICRO_BREWERY_DOC,
            key: "blueprintsayoutOfMicroBrewery",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.FN_ATTACH_DECLARATION_IN_FORM_MB3_DOC,
            key: "declarationInFormMB3",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts
              .FN_ATTACH_NON_JUDICIAL_STAMP_OF_UNDERTAKING_DOC,
            key: "nonJudicialStampOfUndertaking",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.FN_ATTACH_EXISTING_BAR_LICENSE_DOC,
            key: "existingBarLicense",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.FN_ATTACH_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_DOC,
            key: "nocFromUpPollutionControlBoard",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.FN_ATTACH_NOC_FROM_LOCAL_WATER_UTILITY_DOC,
            key: "nocFromLocalWaterUtility",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.FN_ATTACH_FSSAI_CERTIFICATE_DOC,
            key: "fssaiCertificate",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts
              .FN_ATTACH_FURTHER_PLANS_ESTIMATES_INFORMATION_TO_BE_FURNISHED_DOC,
            key: "furtherPlansEstimatesInformationToBeFurnished",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
          {
            type: this.licenseConsts.FN_ATTACH_CHARACTER_CERTIFICATE_DOC,
            key: "characterCertificate",
            submitJsonKey: "attachmentDetails",
            submitSubJsonKey: "attachmentDocumentList",
            arrayListName: null,
          },
        ],
      },
      {
        title: "Bank Details",
        formGroupName: "licenseBankDetails",
        docList: [
          {
            type: this.licenseConsts.FN_BK_CAN_CHEQUE,
            key: "cancelCheque",
            submitJsonKey: "licenseBankDetails",
            submitSubJsonKey: "bankDocumentList",
            arrayListName: null,
          },
        ],
      },
      {
        title: "Attachments",
        formGroupName: "attachments",
        docList: [],
      },
    ];

    return obj;
  }

  panelMicroBreweryMb2List() {
    let obj = [{
      "title": "Micro Brewery",
      "formGroupName": "microBreweryForm",
      "docList": []
    }, {
      "title": "Applicant Details",
      "formGroupName": "applicantDetailsForm",
      "docList": [{
        "type": this.licenseConsts.FN_APPLI_PHOTO,
        "key": "photographOfTheApplicant",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_APPLI_PAN,
        "key": "uploadPan",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Applicant Address",
      "formGroupName": "registeredHeadOffice",
      "docList": []
    }, {
      "title": "Attachments",
      "formGroupName": "attachmentDetails",
      "docList": [{
        "type": this.licenseConsts.FN_ATTACH_ATTACHMENT_PLANT_MACHINERY_DETAILS_DOC,
        "key": "plantMachineryDetails",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_RAW_MATERIAL_DETAILS_DOC,
        "key": "rawMaterialDetails",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_CAPACITY_OF_THE_PLANT_PER_DAY_DOC,
        "key": "capacityOfThePlantPerDay",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_BLUEPRINTS_LAYOUT_OF_MICRO_BREWERY_DOC,
        "key": "blueprintsayoutOfMicroBrewery",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DECLARATION_IN_FORM_MB3_DOC,
        "key": "declarationInFormMB3",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_NO_DUES_DOC,
        "key": "noDues",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_NON_JUDICIAL_STAMP_OF_UNDERTAKING_DOC,
        "key": "nonJudicialStampOfUndertaking",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_EXISTING_BAR_LICENSE_DOC,
        "key": "existingBarLicense",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_CHARACTER_CERTIFICATE_DOC,
        "key": "characterCertificate",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Bank Details",
      "formGroupName": "licenseBankDetails",
      "docList": [{
        "type": this.licenseConsts.FN_BK_CAN_CHEQUE,
        "key": "cancelCheque",
        "submitJsonKey": "licenseBankDetails",
        "submitSubJsonKey": "bankDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Attachments",
      "formGroupName": "attachments",
      "docList": []
    }]

    return obj
  }

  panelDistrictWarehouseFL1AList() {
    let obj = [{
      "title": "Application Details",
      "formGroupName": "districtWarehouseForm",
      "docList": []
    }, {
      "title": "Applicant Details",
      "formGroupName": "applicantDetailsForm",
      "docList": [{
        "type": this.licenseConsts.FN_APPLI_PHOTO,
        "key": "photographOfTheApplicant",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_APPLI_PAN,
        "key": "uploadPan",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_APPLI_ITR,
        "key": "uploadItr",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Applicant Address",
      "formGroupName": "registeredHeadOffice",
      "docList": []
    }, {
      "title": "Premises Address",
      "formGroupName": "premisesAddressDetail",
      "docList": []
    }, {
      "title": "Attachments",
      "formGroupName": "attachmentDetails",
      "docList": [{
        "type": this.licenseConsts.FN_ATTACH_BOUNDRY,
        "key": "boundaries",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_FIRESAFTEYNOC,
        "key": "fireSafetyNoc",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_AFFADAVAIT,
        "key": "affadavait",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_LAY_PLAN,
        "key": "uploadLayoutPlan",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_NO_DUES_DOC,
        "key": "noDues",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Bank Details",
      "formGroupName": "licenseBankDetails",
      "docList": [{
        "type": this.licenseConsts.FN_BK_CAN_CHEQUE,
        "key": "cancelCheque",
        "submitJsonKey": "licenseBankDetails",
        "submitSubJsonKey": "bankDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Attachments",
      "formGroupName": "attachments",
      "docList": []
    }]

    return obj
  }

  panelDistrictWarehouseFL1List() {
    let obj = [{
      "title": "Application Details",
      "formGroupName": "districtWarehouseForm",
      "docList": []
    }, {
      "title": "Applicant Details",
      "formGroupName": "applicantDetailsForm",
      "docList": [{
        "type": this.licenseConsts.FN_APPLI_PHOTO,
        "key": "photographOfTheApplicant",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_APPLI_PAN,
        "key": "uploadPan",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_APPLI_ITR,
        "key": "uploadItr",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Applicant Address",
      "formGroupName": "registeredHeadOffice",
      "docList": []
    }, {
      "title": "Attachments",
      "formGroupName": "attachmentDetails",
      "docList": [{
        "type": this.licenseConsts.FN_ATTACH_BOUNDRY,
        "key": "boundaries",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_FIRESAFTEYNOC,
        "key": "fireSafetyNoc",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_AFFADAVAIT,
        "key": "affadavait",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_LAY_PLAN,
        "key": "uploadLayoutPlan",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_NO_DUES_DOC,
        "key": "noDues",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Bank Details",
      "formGroupName": "licenseBankDetails",
      "docList": [{
        "type": this.licenseConsts.FN_BK_CAN_CHEQUE,
        "key": "cancelCheque",
        "submitJsonKey": "licenseBankDetails",
        "submitSubJsonKey": "bankDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Attachments",
      "formGroupName": "attachments",
      "docList": []
    }]

    return obj
  }

  microBreweryDescription() {
    let LicUrl = LICENSE_URL.MICRO_BREWERY;
    let obj = [
      {
        titleText: MICRO_BREWERY_MB2_SUBCATEGORY_DESC,
        descText: "Application to establish a Microbrewery",
        url: LicUrl + MICRO_BREWERY_MB2_SUBCATEGORY,
        renewLicense: false,
        subCategory: MICRO_BREWERY_MB2_SUBCATEGORY,
      },
      {
        titleText: MICRO_BREWERY_MB6_SUBCATEGORY_DESC,
        descText: "Application for grant of a license to work a brewery in Form MB-1",
        url: LicUrl + MICRO_BREWERY_MB6_SUBCATEGORY,
        renewLicense: false,
        subCategory: MICRO_BREWERY_MB6_SUBCATEGORY,
      },
    ];

    return obj;
  }

  bottlingDescription() {

    let LicUrl = LICENSE_URL.BOTTELING;
    let obj = [
      {
        titleText: BOTTLING_CLB1_SUBCATEGORY_DESC,
        descText: 'License for Bottling of Country Liquor. (Bottling in the same premise)',
        url: LicUrl + BOTTLING_CLB1_SUBCATEGORY,
        renewLicense: false,
        subCategory: BOTTLING_CLB1_SUBCATEGORY,
      },
      {
        titleText: BOTTLING_CLB2_SUBCATEGORY_DESC,
        descText: "CLB2 is the licence for Country liquor bottling in the premises of another disllery.",
        url: LicUrl + BOTTLING_CLB2_SUBCATEGORY,
        renewLicense: false,
        subCategory: BOTTLING_CLB2_SUBCATEGORY
      },
      {
        titleText: BOTTLING_FL3_SUBCATEGORY_DESC,
        descText: 'License for the bottling of Indian made/overseas spirit/beer/wine in bond without payment of duty/after payment of duty',
        url: LicUrl + BOTTLING_FL3_SUBCATEGORY,
        renewLicense: false,
        subCategory: BOTTLING_FL3_SUBCATEGORY
      },
      {
        titleText: BOTTLING_FL3A_SUBCATEGORY_DESC,
        descText: "License for the bottling of IMFL in the premise FL3 license holder(Other's Premise)",
        url: LicUrl + BOTTLING_FL3A_SUBCATEGORY,
        renewLicense: false,
        subCategory: BOTTLING_FL3A_SUBCATEGORY
      },
      {
        titleText: BOTTLING_FL50_SUBCATEGORY_DESC,
        descText: "License for processing and manufacture of Analytical Grade Absolute and HPLC Grade Absolute Alcohol",
        url: LicUrl + BOTTLING_FL50_SUBCATEGORY,
        renewLicense: false,
        subCategory: BOTTLING_FL50_SUBCATEGORY
      },
      {
        titleText: BOTTLING_FL51_SUBCATEGORY_DESC,
        descText: "Application to establish Analytical Grade Absolute Alcohol & HPLC Grade Absolute Alcohol Plant",
        url: LicUrl + BOTTLING_FL51_SUBCATEGORY,
        renewLicense: false,
        subCategory: BOTTLING_FL51_SUBCATEGORY
      }
    ]

    return obj
  }

  breweryDescription() {
    let LicUrl = LICENSE_URL.BREWERY;
    let obj = [
      {
        titleText: BREWERY_B19_SUBCATEGORY_DESC,
        descText: this.translate.instant('licenseMgnt.breweryB19DescText'),
        url: LicUrl + BREWERY_B19_SUBCATEGORY,
        renewLicense: false,
        subCategory: BREWERY_B19_SUBCATEGORY,
      },
      {
        titleText: BREWERY_B21_SUBCATEGORY_DESC,
        descText: this.translate.instant('licenseMgnt.breweryB21DescText'),
        url: LicUrl + BREWERY_B21_SUBCATEGORY,
        renewLicense: false,
        subCategory: BREWERY_B21_SUBCATEGORY,
      }
    ]

    return obj
  }

  vintineryDescription() {
    let LicUrl = LICENSE_URL.VINTINERY;
    let obj = [
      {
        titleText: this.licenseConsts.VINTINERY_V1_SUBCATEGORY_DESC,
        descText: this.translate.instant('licenseMgnt.vinitineryV1description'),
        url: LicUrl + this.licenseConsts.VINTINERY_V1_SUBCATEGORY,
        renewLicense: false,
        subCategory: this.licenseConsts.VINTINERY_V1_SUBCATEGORY,
      },
      {
        titleText: this.licenseConsts.VINTINERY_V2_SUBCATEGORY_DESC,
        descText: this.translate.instant('licenseMgnt.vinitineryV2description'),
        url: LicUrl + this.licenseConsts.VINTINERY_V2_SUBCATEGORY,
        renewLicense: false,
        subCategory: this.licenseConsts.VINTINERY_V2_SUBCATEGORY,
      }
    ]

    return obj
  }

  panelBreweryB19List() {
    let obj = [{
      "title": "Application to Establish a Brewery in U.P",
      "formGroupName": "breweryForm",
      "docList": []
    }, {
      "title": "Applicant Details",
      "formGroupName": "applicantDetailsForm",
      "docList": [{
        "type": this.licenseConsts.SRC_APPLI_PAN,
        "key": "uploadPan",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_APPLI_PHOTO,
        "key": "photographOfTheApplicant",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_CHARACTER_CERTIFICATE_DOC,
        "key": "characterCertificate",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_AFFADAVAIT,
        "key": "affidavitEligibility",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_APPLI_ITR,
        "key": "uploadItr",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Applicant Address",
      "formGroupName": "registeredHeadOffice",
      "docList": []
    }, {
      "title": "Unit Details",
      "formGroupName": "businessUnitDetails",
      "docList": [{
        "type": this.licenseConsts.SRC_ADDR_OWNERSHIIP_DOC,
        "key": "ownershipDocument",
        "submitJsonKey": "businessUnitDetails",
        "submitSubJsonKey": "businessUnitDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Unit Address",
      "formGroupName": "premisesAddressDetail",
      "docList": []
    }, {
      "title": "Name of the Undertaking",
      "formGroupName": "breweryUndertakingDetails",
      "docList": [{
        "type": this.licenseConsts.SRC_APPLI_PAN,
        "key": "uploadPan",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "breweryUndertakingDetails",
        "submitSubDocJsonKey": "undertakingDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_APPLI_ITR,
        "key": "uploadItr",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "breweryUndertakingDetails",
        "submitSubDocJsonKey": "undertakingDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Address of Undertaking",
      "formGroupName": "addressOfUndertaking",
      "docList": []
    }, {
      "title": "Capital Structure",
      "formGroupName": "breweryCapitalDetails",
      "docList": []
    }, {
      "title": "Plant and Machinery",
      "formGroupName": "breweryPlanMachineryDetails",
      "docList": []
    }, {
      "title": "Raw Material Required for Production / Quantity and Value of Raw Materials Including Hops",
      "formGroupName": "breweryRawMaterial",
      "docList": []
    }, {
      "title": "Water and Power Requirements",
      "formGroupName": "breweryWaterPowerDetails",
      "docList": []
    }, {
      "title": "Process",
      "formGroupName": "breweryProcess",
      "docList": []
    }, {
      "title": "Forecast Assistance",
      "formGroupName": "breweryForcastAssitance",
      "docList": []
    }, {
      "title": "Employment Potential",
      "formGroupName": "breweryEmploymentDetails",
      "docList": []
    }, {
      "title": "Attachments",
      "formGroupName": "attachmentDetails",
      "docList": [{
        "type": this.licenseConsts.FN_ATTACH_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_DOC,
        "key": "nocFromUpPollutionControlBoard",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_NOC_FROM_MINISTRY_OF_ENVIRONMENT_AND_CLIMATE_CHANGE_DOC,
        "key": "nocFromMinistryOfEnvironmentAndClimateChange",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },
      {
        "type": this.licenseConsts.FN_ATTACH_NO_DUES_CERTIFICATE_BY_DM_OFFICE_DOC,
        "key": "noDuesCertificateByDMOffice",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_MINISTRY_OF_INDUSTRIES_REGISTRATION_CERTIFICATE_DOC,
        "key": "MinistryOfIndustriesRegistrationCertificate",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_OTHERS,
        "key": "otherDocument",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_FIRESAFTEYNOC,
        "key": "fireSafetyNoc",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_BLUEPRINT,
        "key": "bluePrintsDoc",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_COPY_OF_BLUEPRINT_OF_PLAN,
        "key": "copyOfBluePrintOfPlan",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_ATTACHMENT_BLUEPRINT_OF_PLAN_ELEVATION,
        "key": "bluePrintOfPlanElevation",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ADDI_GSTIN,
        "key": "gstRegistrationCertificate",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_COPY_OF_MOA_OF_THE_COMPANY,
        "key": "copyOfMoaOfTheCompany",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DETAILED_DOCUMENT_OF_PLANTS_AND_MACHINERIES,
        "key": "detailedDocumentOfPlantsAndMachineries",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DEPICTING_FLOW_DIAGRAM,
        "key": "documentDepictingFlowDiagram",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DETAILING_THE_LAND_WHERE_PLANT_INSTALLED,
        "key": "documentDetailingTheLandWherePlantInstalled",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DETAILING_UTILITY_OF_BREWERY_WITH_BLUE_PRINT,
        "key": "documentDetailingUtilityOfBreweryWithBluePrint",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_FOR_CALCULATION_OF_CAPACITY_OF_BREWERY,
        "key": "documentForCalculationOfCapacityOfBrewery",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_NOTARIZED_AFFIDAVIT,
        "key": "notarizedAffidavit",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_APPLI_PAN,
        "key": "uploadPan",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_AFFADAVAIT,
        "key": "affidavitEligibility",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_APPLI_ITR,
        "key": "uploadItr",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Bank Details",
      "formGroupName": "licenseBankDetails",
      "docList": [{
        "type": this.licenseConsts.FN_BK_CAN_CHEQUE,
        "key": "cancelCheque",
        "submitJsonKey": "licenseBankDetails",
        "submitSubJsonKey": "bankDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Attachments",
      "formGroupName": "attachments",
      "docList": []
    }]
    return obj
  }

  panelVintineryV1List() {
    let obj = [{
      "title": "Application to Establish a Brewery in U.P",
      "formGroupName": "breweryForm",
      "docList": []
    }, {
      "title": "Applicant Details",
      "formGroupName": "applicantDetailsForm",
      "docList": [{
        "type": this.licenseConsts.SRC_APPLI_PAN,
        "key": "uploadPan",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_APPLI_PHOTO,
        "key": "photographOfTheApplicant",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_CHARACTER_CERTIFICATE_DOC,
        "key": "characterCertificate",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_AFFADAVAIT,
        "key": "affidavitEligibility",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_APPLI_ITR,
        "key": "uploadItr",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Applicant Address",
      "formGroupName": "registeredHeadOffice",
      "docList": []
    }, {
      "title": "Unit Details",
      "formGroupName": "businessUnitDetails",
      "docList": [{
        "type": this.licenseConsts.SRC_ADDR_OWNERSHIIP_DOC,
        "key": "ownershipDocument",
        "submitJsonKey": "businessUnitDetails",
        "submitSubJsonKey": "businessUnitDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Unit Address",
      "formGroupName": "premisesAddressDetail",
      "docList": []
    }, {
      "title": "Name of the Undertaking",
      "formGroupName": "breweryUndertakingDetails",
      "docList": [{
        "type": this.licenseConsts.SRC_APPLI_PAN,
        "key": "uploadPan",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "breweryUndertakingDetails",
        "submitSubDocJsonKey": "undertakingDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_APPLI_ITR,
        "key": "uploadItr",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "breweryUndertakingDetails",
        "submitSubDocJsonKey": "undertakingDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_BUSSUNIT_POWER_OF_ATTORNEY,
        "key": "attorney",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "breweryUndertakingDetails",
        "submitSubDocJsonKey": "undertakingDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Address of Undertaking",
      "formGroupName": "addressOfUndertaking",
      "docList": []
    }, {
      "title": "Capital Structure",
      "formGroupName": "breweryCapitalDetails",
      "docList": []
    }, {
      "title": "Plant and Machinery",
      "formGroupName": "breweryPlanMachineryDetails",
      "docList": []
    }, {
      "title": "Raw Material Required for Production / Quantity and Value of Raw Materials Including Hops",
      "formGroupName": "breweryRawMaterial",
      "docList": []
    }, {
      "title": "Water and Power Requirements",
      "formGroupName": "breweryWaterPowerDetails",
      "docList": []
    }, {
      "title": "Process",
      "formGroupName": "breweryProcess",
      "docList": []
    }, {
      "title": "Forecast Assistance",
      "formGroupName": "breweryForcastAssitance",
      "docList": []
    }, {
      "title": "Employment Potential",
      "formGroupName": "breweryEmploymentDetails",
      "docList": []
    }, {
      "title": "Attachments",
      "formGroupName": "attachmentDetails",
      "docList": [
        {
          "type": this.licenseConsts.FN_ATTACH_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_UP_DOC,
          "key": "nocFromUpPollutionControlBoardUP",
          "submitJsonKey": "attachmentDetails",
          "submitSubJsonKey": "attachmentDocumentList",
          "arrayListName": null
        },{
          "type": this.licenseConsts.FN_ATTACH_ATTACHMENT_BLUEPRINT_OF_PLAN_ELEVATION,
          "key": "bluePrintOfPlanElevation",
          "submitJsonKey": "attachmentDetails",
          "submitSubJsonKey": "attachmentDocumentList",
          "arrayListName": null
        },{
        "type": this.licenseConsts.ENVIRONMENTAL_CLEARANCE_FORECAST_CLIMATE,
        "key": "applicantRegardingEnvironmentalClearanceForEnvironment",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_NOC_FROM_MINISTRY_OF_ENVIRONMENT_AND_CLIMATE_CHANGE_DOC,
        "key": "nocFromMinistryOfEnvironmentAndClimateChange",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },
      {
        "type": this.licenseConsts.FN_ATTACH_NO_DUES_CERTIFICATE_BY_DM_OFFICE_DOC,
        "key": "noDuesCertificateByDMOffice",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_MINISTRY_OF_INDUSTRIES_REGISTRATION_CERTIFICATE_DOC,
        "key": "MinistryOfIndustriesRegistrationCertificate",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_OTHERS,
        "key": "otherDocument",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_FIRESAFTEYNOC,
        "key": "fireSafetyNoc",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },
      {
        "type": this.licenseConsts.CHARACTER_CERTIFICATE_POLICE,
        "key": "characterCertificateByPolice",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.DETAILS_BOTTLING_TANKS_BOTTLING,
        "key": "detailsOfBottlingTanksBottlingLineFinishedGoodsGoDown",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.NO_DUES_CERTIFICATE_DISTRICT_COLLECTOR,
        "key": "noDuesCertificateFromDistrict",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.DETAIL_ALL_TANKS_DIMENSION,
        "key": "detailOfAllTanks",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.DETAIL_GRAPE_OTHER_FOOD,
        "key": "detailOfGrapeOtherFood",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.IEM_MINISTRY_INDUSTRIES_GOI,
        "key": "industrialEntrepreneurMemorandumToMinistry",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.CENTRAL_POLLUTION_CONTROL_BOARD,
        "key": "applicationSubmittedForPollutionControlBoardOfUP",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.APPLICATION_SUBMITTED_FIRE_DEPARTMENT,
        "key": "applicationSubmittedForFireDepartment",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_DOC,
        "key": "nocFromUpPollutionControlBoard",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_BLUEPRINT,
        "key": "bluePrintsDoc",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_COPY_OF_BLUEPRINT_OF_PLAN,
        "key": "copyOfBluePrintOfPlan",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ADDI_GSTIN,
        "key": "gstRegistrationCertificate",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_COPY_OF_MOA_OF_THE_COMPANY,
        "key": "copyOfMoaOfTheCompany",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DETAILED_DOCUMENT_OF_PLANTS_AND_MACHINERIES,
        "key": "detailedDocumentOfPlantsAndMachineries",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DEPICTING_FLOW_DIAGRAM,
        "key": "documentDepictingFlowDiagram",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DETAILING_THE_LAND_WHERE_PLANT_INSTALLED,
        "key": "documentDetailingTheLandWherePlantInstalled",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DETAILING_UTILITY_OF_BREWERY_WITH_BLUE_PRINT,
        "key": "documentDetailingUtilityOfBreweryWithBluePrint",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_FOR_CALCULATION_OF_CAPACITY_OF_BREWERY,
        "key": "documentForCalculationOfCapacityOfBrewery",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_NOTARIZED_AFFIDAVIT,
        "key": "notarizedAffidavit",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_APPLI_PAN,
        "key": "uploadPan",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_AFFADAVAIT,
        "key": "affidavitEligibility",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_APPLI_ITR,
        "key": "uploadItr",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Bank Details",
      "formGroupName": "licenseBankDetails",
      "docList": [{
        "type": this.licenseConsts.FN_BK_CAN_CHEQUE,
        "key": "cancelCheque",
        "submitJsonKey": "licenseBankDetails",
        "submitSubJsonKey": "bankDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Attachments",
      "formGroupName": "attachments",
      "docList": []
    }]
    return obj
  }

  panelBreweryB21List() {
    let obj = [{
      "title": "Application to Run a Brewery in U.P",
      "formGroupName": "breweryForm",
      "docList": []
    }, {
      "title": "Applicant Details",
      "formGroupName": "applicantDetailsForm",
      "docList": [{
        "type": this.licenseConsts.SRC_APPLI_PAN,
        "key": "uploadPan",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_APPLI_PHOTO,
        "key": "photographOfTheApplicant",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_CHARACTER_CERTIFICATE_DOC,
        "key": "characterCertificate",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_AFFADAVAIT,
        "key": "affidavitEligibility",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_APPLI_ITR,
        "key": "uploadItr",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Applicant Address",
      "formGroupName": "registeredHeadOffice",
      "docList": []
    }, {
      "title": "Brewery Unit Details",
      "formGroupName": "businessUnitDetails",
      "docList": [{
        "type": this.licenseConsts.SRC_ADDR_OWNERSHIIP_DOC,
        "key": "ownershipDocument",
        "submitJsonKey": "businessUnitDetails",
        "submitSubJsonKey": "businessUnitDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Brewery Premises Address",
      "formGroupName": "premisesAddressDetail",
      "docList": []
    }, {
      "title": "Vessel Detail",
      "formGroupName": "vessalDetailList",
      "arrayListName": "vessalDocumentList",
      "docList": [{
        "type": this.licenseConsts.FN_PLAN_ELEVATION_DOC,
        "key": "planElevation",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "vessalList",
        "arrayListName": "vessalDocumentList"
      }, {
        "type": this.licenseConsts.FN_FLOW_DIAGRAM_DOC,
        "key": "flowDiagram",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "vessalList",
        "arrayListName": "vessalDocumentList"
      }, {
        "type": this.licenseConsts.FN_ARRANGEMENT_OF_TEMPERATURE_CONTROL_DOC,
        "key": "arrangementOfTemperatureControl",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "vessalList",
        "arrayListName": "vessalDocumentList"
      }, {
        "type": this.licenseConsts.FN_GAUGE_CHART_DOC,
        "key": "gaugeChart",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "vessalList",
        "arrayListName": "vessalDocumentList"
      },
      {
        "type": 'ATTACHMENT_DRAWINGS',
        "key": "drawings",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "vessalList",
        "arrayListName": "vessalDocumentList"
      },
      {
        "type": 'ATTACHMENT_BLUEPRINT',
        "key": "blueprintOfTank",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "vessalList",
        "arrayListName": "vessalDocumentList"
      }]
    }, {
      "title": "Attachments",
      "formGroupName": "attachmentDetails",
      "docList": [{
        "type": this.licenseConsts.FN_ATTACH_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_DOC,
        "key": "nocFromUpPollutionControlBoard",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_DOC_21,
        "key": "nocFromUpPollutionControlBoard21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_NOC_FROM_MINISTRY_OF_ENVIRONMENT_AND_CLIMATE_CHANGE_DOC,
        "key": "nocFromMinistryOfEnvironmentAndClimateChange",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_NO_DUES_CERTIFICATE_BY_DM_OFFICE_DOC,
        "key": "noDuesCertificateByDMOffice",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_OTHERS,
        "key": "otherDocument",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_MINISTRY_OF_INDUSTRIES_REGISTRATION_CERTIFICATE_DOC,
        "key": "MinistryOfIndustriesRegistrationCertificate",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_ATTACHMENT_BLUEPRINT_OF_PLAN_ELEVATION,
        "key": "bluePrintOfPlanElevation",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },
      {
        "type": this.licenseConsts.FN_ATTACH_FIRESAFTEYNOC,
        "key": "fireSafetyNoc",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_FIRESAFTEYNOC_21,
        "key": "fireSafetyNoc21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_BLUEPRINT,
        "key": "bluePrintsDoc",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_BLUEPRINT_21,
        "key": "bluePrintsDoc21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_COPY_OF_BLUEPRINT_OF_PLAN,
        "key": "copyOfBluePrintOfPlan",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_COPY_OF_BLUEPRINT_OF_PLAN_21,
        "key": "copyOfBluePrintOfPlan21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ADDI_GSTIN,
        "key": "gstRegistrationCertificate",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_COPY_OF_MOA_OF_THE_COMPANY,
        "key": "copyOfMoaOfTheCompany",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DETAILED_DOCUMENT_OF_PLANTS_AND_MACHINERIES,
        "key": "detailedDocumentOfPlantsAndMachineries",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DETAILED_DOCUMENT_OF_PLANTS_AND_MACHINERIES_21,
        "key": "detailedDocumentOfPlantsAndMachineries21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DEPICTING_FLOW_DIAGRAM,
        "key": "documentDepictingFlowDiagram",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DEPICTING_FLOW_DIAGRAM_21,
        "key": "documentDepictingFlowDiagram21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DETAILING_THE_LAND_WHERE_PLANT_INSTALLED,
        "key": "documentDetailingTheLandWherePlantInstalled",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DETAILING_THE_LAND_WHERE_PLANT_INSTALLED_21,
        "key": "documentDetailingTheLandWherePlantInstalled21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DETAILING_UTILITY_OF_BREWERY_WITH_BLUE_PRINT,
        "key": "documentDetailingUtilityOfBreweryWithBluePrint",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DETAILING_UTILITY_OF_BREWERY_WITH_BLUE_PRINT_21,
        "key": "documentDetailingUtilityOfBreweryWithBluePrint21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_FOR_CALCULATION_OF_CAPACITY_OF_BREWERY,
        "key": "documentForCalculationOfCapacityOfBrewery",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_FOR_CALCULATION_OF_CAPACITY_OF_BREWERY_21,
        "key": "documentForCalculationOfCapacityOfBrewery21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_NOTARIZED_AFFIDAVIT,
        "key": "notarizedAffidavit",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },  {
        "type": this.licenseConsts.FN_ATTACH_NOTARIZED_AFFIDAVIT_21,
        "key": "notarizedAffidavit21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_APPLI_PAN,
        "key": "uploadPan",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_AFFADAVAIT,
        "key": "affidavitEligibility",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_APPLI_ITR,
        "key": "uploadItr",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_APPLICATION_FOR_SITE_INSPECTION,
        "key": "applicationForSiteInspection",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_ATTACHMENT_NOC_FROM_LOCAL_AUTH,
        "key": "nocFromLocalAuthority",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Bank Details",
      "formGroupName": "licenseBankDetails",
      "docList": [{
        "type": this.licenseConsts.FN_BK_CAN_CHEQUE,
        "key": "cancelCheque",
        "submitJsonKey": "licenseBankDetails",
        "submitSubJsonKey": "bankDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Attachments",
      "formGroupName": "attachments",
      "docList": []
    }]
    return obj
  }

  panelVintineryV2List() {
    let obj = [{
      "title": "Application to Run a Brewery in U.P",
      "formGroupName": "breweryForm",
      "docList": []
    }, {
      "title": "Applicant Details",
      "formGroupName": "applicantDetailsForm",
      "docList": [{
        "type": this.licenseConsts.SRC_APPLI_PAN,
        "key": "uploadPan",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_APPLI_PHOTO,
        "key": "photographOfTheApplicant",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_CHARACTER_CERTIFICATE_DOC,
        "key": "characterCertificate",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_AFFADAVAIT,
        "key": "affidavitEligibility",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_APPLI_ITR,
        "key": "uploadItr",
        "submitJsonKey": "applicantDetails",
        "submitSubJsonKey": "applicantDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Applicant Address",
      "formGroupName": "registeredHeadOffice",
      "docList": []
    }, {
      "title": "Brewery Unit Details",
      "formGroupName": "businessUnitDetails",
      "docList": [{
        "type": this.licenseConsts.SRC_ADDR_OWNERSHIIP_DOC,
        "key": "ownershipDocument",
        "submitJsonKey": "businessUnitDetails",
        "submitSubJsonKey": "businessUnitDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Brewery Premises Address",
      "formGroupName": "premisesAddressDetail",
      "docList": []
    }, {
      "title": "Vessel Detail",
      "formGroupName": "vessalDetailList",
      "arrayListName": "vessalDocumentList",
      "docList": [{
        "type": this.licenseConsts.FN_PLAN_ELEVATION_DOC,
        "key": "planElevation",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "vessalList",
        "arrayListName": "vessalDocumentList"
      }, {
        "type": this.licenseConsts.FN_FLOW_DIAGRAM_DOC,
        "key": "flowDiagram",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "vessalList",
        "arrayListName": "vessalDocumentList"
      }, {
        "type": this.licenseConsts.FN_ARRANGEMENT_OF_TEMPERATURE_CONTROL_DOC,
        "key": "arrangementOfTemperatureControl",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "vessalList",
        "arrayListName": "vessalDocumentList"
      }, {
        "type": this.licenseConsts.FN_GAUGE_CHART_DOC,
        "key": "gaugeChart",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "vessalList",
        "arrayListName": "vessalDocumentList"
      },
      {
        "type": 'ATTACHMENT_DRAWINGS',
        "key": "drawings",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "vessalList",
        "arrayListName": "vessalDocumentList"
      },
      {
        "type": 'ATTACHMENT_BLUEPRINT',
        "key": "blueprintOfTank",
        "submitJsonKey": "breweryLicenses",
        "submitSubJsonKey": "vessalList",
        "arrayListName": "vessalDocumentList"
      }]
    }, {
      "title": "Attachments",
      "formGroupName": "attachmentDetails",
      "docList": [{
        "type": this.licenseConsts.FN_ATTACH_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_DOC,
        "key": "nocFromUpPollutionControlBoard",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_NOC_FROM_UP_POLLUTION_CONTROL_BOARD_DOC_21,
        "key": "nocFromUpPollutionControlBoard21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_ATTACHMENT_BLUEPRINT_OF_PLAN_ELEVATION,
        "key": "bluePrintOfPlanElevation",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },
      {
        "type": this.licenseConsts.FN_ATTACH_FIRESAFTEYNOC,
        "key": "fireSafetyNoc",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_FIRESAFTEYNOC_21,
        "key": "fireSafetyNoc21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_BLUEPRINT,
        "key": "bluePrintsDoc",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_BLUEPRINT_21,
        "key": "bluePrintsDoc21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_NOC_FROM_MINISTRY_OF_ENVIRONMENT_AND_CLIMATE_CHANGE_DOC,
        "key": "nocFromMinistryOfEnvironmentAndClimateChange",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },
      {
        "type": this.licenseConsts.FN_ATTACH_NO_DUES_CERTIFICATE_BY_DM_OFFICE_DOC,
        "key": "noDuesCertificateByDMOffice",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_MINISTRY_OF_INDUSTRIES_REGISTRATION_CERTIFICATE_DOC,
        "key": "MinistryOfIndustriesRegistrationCertificate",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_OTHERS,
        "key": "otherDocument",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },
      {
        "type": this.licenseConsts.FN_ATTACH_COPY_OF_BLUEPRINT_OF_PLAN,
        "key": "copyOfBluePrintOfPlan",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_COPY_OF_BLUEPRINT_OF_PLAN_21,
        "key": "copyOfBluePrintOfPlan21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ADDI_GSTIN,
        "key": "gstRegistrationCertificate",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_COPY_OF_MOA_OF_THE_COMPANY,
        "key": "copyOfMoaOfTheCompany",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DETAILED_DOCUMENT_OF_PLANTS_AND_MACHINERIES,
        "key": "detailedDocumentOfPlantsAndMachineries",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DETAILED_DOCUMENT_OF_PLANTS_AND_MACHINERIES_21,
        "key": "detailedDocumentOfPlantsAndMachineries21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DEPICTING_FLOW_DIAGRAM,
        "key": "documentDepictingFlowDiagram",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DEPICTING_FLOW_DIAGRAM_21,
        "key": "documentDepictingFlowDiagram21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DETAILING_THE_LAND_WHERE_PLANT_INSTALLED,
        "key": "documentDetailingTheLandWherePlantInstalled",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DETAILING_THE_LAND_WHERE_PLANT_INSTALLED_21,
        "key": "documentDetailingTheLandWherePlantInstalled21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DETAILING_UTILITY_OF_BREWERY_WITH_BLUE_PRINT,
        "key": "documentDetailingUtilityOfBreweryWithBluePrint",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },{
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_DETAILING_UTILITY_OF_BREWERY_WITH_BLUE_PRINT_21,
        "key": "documentDetailingUtilityOfBreweryWithBluePrint21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_FOR_CALCULATION_OF_CAPACITY_OF_BREWERY,
        "key": "documentForCalculationOfCapacityOfBrewery",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_DOCUMENT_FOR_CALCULATION_OF_CAPACITY_OF_BREWERY_21,
        "key": "documentForCalculationOfCapacityOfBrewery21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_NOTARIZED_AFFIDAVIT,
        "key": "notarizedAffidavit",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      },  {
        "type": this.licenseConsts.FN_ATTACH_NOTARIZED_AFFIDAVIT_21,
        "key": "notarizedAffidavit21",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_APPLI_PAN,
        "key": "uploadPan",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_AFFADAVAIT,
        "key": "affidavitEligibility",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_APPLI_ITR,
        "key": "uploadItr",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_APPLICATION_FOR_SITE_INSPECTION,
        "key": "applicationForSiteInspection",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.FN_ATTACH_ATTACHMENT_NOC_FROM_LOCAL_AUTH,
        "key": "nocFromLocalAuthority",
        "submitJsonKey": "attachmentDetails",
        "submitSubJsonKey": "attachmentDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Bank Details",
      "formGroupName": "licenseBankDetails",
      "docList": [{
        "type": this.licenseConsts.FN_BK_CAN_CHEQUE,
        "key": "cancelCheque",
        "submitJsonKey": "licenseBankDetails",
        "submitSubJsonKey": "bankDocumentList",
        "arrayListName": null
      }]
    }, {
      "title": "Attachments",
      "formGroupName": "attachments",
      "docList": []
    }]
    return obj
  }

  bottelingCLB1List() {
    let obj = [{
      title: "Registration of Applicant for Bottling CL B1",
      formGroupName: "bottlingRegistrationForm",
      docList: []
    },
    {
      title: "Applicant Details",
      formGroupName: "applicantDetailsForm",
      docList: [
        {
          type: this.licenseConsts.FN_APPLI_PHOTO,
          key: "photographOfTheApplicant",
          submitJsonKey: "applicantDetails",
          submitSubJsonKey: "applicantDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.SRC_APPLI_PAN,
          key: "uploadPan",
          submitJsonKey: "applicantDetails",
          submitSubJsonKey: "applicantDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.SRC_APPLI_ITR,
          key: "uploadItr",
          submitJsonKey: "applicantDetails",
          submitSubJsonKey: "applicantDocumentList",
          arrayListName: null,
        }
      ]
    }, {
      title: "Applicant Address",
      formGroupName: "applicantAddress",
      docList: []
    },{
      title: "Parent Distillery/Brewery/Vintinery Unit Details",
      formGroupName: "bottlingParentUnitDetails",
      docList: [
        {
          type: this.licenseConsts.SRC_APPLI_LICENSE,
          key: "uploadLicense",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_APPLI_PAN,
          key: "panCertificate",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ADDI_GSTIN,
          key: "gstinCertificate",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_APPLI_ITR,
          key: "uploadItr",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        }
      ]
    }, {
      "title": "Unit Details",
      "formGroupName": "businessUnitDetails",
      "docList": [{
        "type": this.licenseConsts.SRC_ADDR_OWNERSHIIP_DOC,
        "key": "ownershipDocument",
        "submitJsonKey": "businessUnitDetails",
        "submitSubJsonKey": "businessUnitDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_AFFADAVAIT,
        "key": "affidavit",
        "submitJsonKey": "businessUnitDetails",
        "submitSubJsonKey": "businessUnitDocumentList",
        "arrayListName": null
      }]
    }, {
        title: 'Description of Bottling Premises',
        formGroupName: 'premisesAddressDetail',
        docList: []
      }, {
      title: "Warehouse Details",
      formGroupName: "wareHouseListRegistration",
      docList: []
    }, {
      title: "Attachments",
      formGroupName: "attachmentDetails",
      docList: [
        {
          type: this.licenseConsts.FN_ATTACH_BOUNDRY,
          key: "boundries",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_FIRESAFTEYNOC,
          key: "fireSafteyNoc",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_AFFADAVAIT,
          key: "affidavit",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_LAY_PLAN,
          key: "layoutPlan",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_CERT_OF_INCORP,
          key: "certificateOfIncorporation",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_RESU_OF_AUTH,
          key: "resolutionOfAuthorization",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_ATTACHMENT_FASSAI_CERTIFICATE,
          key: "fassaiCertificate",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_NOC_OF_PREMISES,
          key: "nocOfPremises",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_CERT_OF_POLLUT_CNTRL,
          key: "certificateOfPollutionControl",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_NO_DUES_DOC,
          key: "noDuesCertificate",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        }
      ]
    }, {
      title: "Bank Details",
      formGroupName: "licenseBankDetails",
      docList: [
        {
          type: this.licenseConsts.FN_BK_CAN_CHEQUE,
          key: "cancelCheque",
          submitJsonKey: "licenseBankDetails",
          submitSubJsonKey: "bankDocumentList",
          arrayListName: null,
        }
      ]
    }, {
      title: "Tank Registration",
      formGroupName: "tankListRegistration",
      docList: [
        {
          type: this.licenseConsts.FN_ATTACH_ATTACHMENT_DOCUMENT_DOC,
          key: "uploadDocument",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "tankList",
          arrayListName: "tankDocumentList"
        },
        {
          "type": this.licenseConsts.FN_PLAN_ELEVATION_DOC,
          "key": "planElevation",
          "submitJsonKey": "bottelingLicenses",
          "submitSubJsonKey": "tankList",
          "arrayListName": "tankDocumentList"
        }, {
          "type": this.licenseConsts.FN_FLOW_DIAGRAM_DOC,
          "key": "flowDiagram",
          "submitJsonKey": "bottelingLicenses",
          "submitSubJsonKey": "tankList",
          "arrayListName": "tankDocumentList"
        },{
          "type": this.licenseConsts.FN_GAUGE_CHART_DOC,
          "key": "gaugeChart",
          "submitJsonKey": "bottelingLicenses",
          "submitSubJsonKey": "tankList",
          "arrayListName": "tankDocumentList"
        },
        {
          type: 'ATTACHMENT_DRAWINGS',
          key: "drawings",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "tankList",
          arrayListName: "tankDocumentList"
        },
        {
          type: 'ATTACHMENT_BLUEPRINT',
          key: "blueprintOfTank",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "tankList",
          arrayListName: "tankDocumentList"
        }
      ]
    }, {
      title: "New Line Registration-CL B1",
      formGroupName: "bottelingNewLineRegistration",
      docList: [
        {
          type: this.licenseConsts.FN_ATTACH_ATTACHMENT_DOCUMENT_DOC,
          key: "upload",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottelingLineList",
          arrayListName: "bottelingLineDocumentList"
        }
      ]
    }]

    return obj;
  }

  bottelingCLB2List() {
    let obj = [{
      title: "Registration of Applicant for Bottling CL B1",
      formGroupName: "bottlingRegistrationForm",
      docList: []
    },
    {
      title: "Applicant Details",
      formGroupName: "applicantDetailsForm",
      docList: [
        {
          type: this.licenseConsts.FN_APPLI_PHOTO,
          key: "photographOfTheApplicant",
          submitJsonKey: "applicantDetails",
          submitSubJsonKey: "applicantDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.SRC_APPLI_PAN,
          key: "uploadPan",
          submitJsonKey: "applicantDetails",
          submitSubJsonKey: "applicantDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.SRC_APPLI_ITR,
          key: "uploadItr",
          submitJsonKey: "applicantDetails",
          submitSubJsonKey: "applicantDocumentList",
          arrayListName: null,
        }
      ]
    }, {
      title: "Applicant Address",
      formGroupName: "applicantAddress",
      docList: []
    }, {
      title: "Parent Distillery/Brewery/Vintinery Unit Details",
      formGroupName: "bottlingParentUnitDetails",
      docList: [
        {
          type: this.licenseConsts.SRC_APPLI_LICENSE,
          key: "uploadLicense",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_APPLI_PAN,
          key: "panCertificate",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ADDI_GSTIN,
          key: "gstinCertificate",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_APPLI_ITR,
          key: "uploadItr",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        }
      ]
    }, {
      "title": "Unit Details",
      "formGroupName": "businessUnitDetails",
      "docList": [{
        "type": this.licenseConsts.SRC_ADDR_OWNERSHIIP_DOC,
        "key": "ownershipDocument",
        "submitJsonKey": "businessUnitDetails",
        "submitSubJsonKey": "businessUnitDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_AFFADAVAIT,
        "key": "affidavit",
        "submitJsonKey": "businessUnitDetails",
        "submitSubJsonKey": "businessUnitDocumentList",
        "arrayListName": null
      }]
    }, {
        title: 'Description of Bottling Premises',
        formGroupName: 'premisesAddressDetail',
        docList: []
      }, {
      title: "Warehouse Details",
      formGroupName: "wareHouseListRegistration",
      docList: []
    }, {
      title: "Attachments",
      formGroupName: "attachmentDetails",
      docList: [
        {
          type: this.licenseConsts.FN_ATTACH_BOUNDRY,
          key: "boundries",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_FIRESAFTEYNOC,
          key: "fireSafteyNoc",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_AFFADAVAIT,
          key: "affidavit",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_LAY_PLAN,
          key: "layoutPlan",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_CERT_OF_INCORP,
          key: "certificateOfIncorporation",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_RESU_OF_AUTH,
          key: "resolutionOfAuthorization",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_ATTACHMENT_FASSAI_CERTIFICATE,
          key: "fassaiCertificate",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_NOC_OF_PREMISES,
          key: "nocOfPremises",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_CERT_OF_POLLUT_CNTRL,
          key: "certificateOfPollutionControl",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_NO_DUES_DOC,
          key: "noDuesCertificate",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_Tieup_LEASE_DOC,
          key: "leaseTieup",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        }
      ]
    }, {
      title: "Bank Details",
      formGroupName: "licenseBankDetails",
      docList: [
        {
          type: this.licenseConsts.FN_BK_CAN_CHEQUE,
          key: "cancelCheque",
          submitJsonKey: "licenseBankDetails",
          submitSubJsonKey: "bankDocumentList",
          arrayListName: null,
        }
      ]
    }]
    return obj
  }

  districtWarehouseDescription() {
    let LicUrl = LICENSE_URL.DISTRICT_WAREHOUSE;
    let obj = [
      {
        titleText: DISTRICT_WAREHOUSE_FL1_SUBCATEGORY_DESC,
        descText: 'FL1 supplies the finished goods to wholesale FL2 and FL2B.',
        url: LicUrl + DISTRICT_WAREHOUSE_FL1_SUBCATEGORY,
        renewLicense: false,
        subCategory: DISTRICT_WAREHOUSE_FL1_SUBCATEGORY,
      },
      {
        titleText: DISTRICT_WAREHOUSE_FL1A_SUBCATEGORY_DESC,
        descText: 'FL1A supplies the finished goods to wholesale FL2 and FL2B',
        url: LicUrl + DISTRICT_WAREHOUSE_FL1A_SUBCATEGORY,
        renewLicense: false,
        subCategory: DISTRICT_WAREHOUSE_FL1A_SUBCATEGORY,
      }
    ]

    return obj
  }

  bottelingFL3List() {
    let obj = [{
      title: "Registration of Applicant for Bottling CL B1",
      formGroupName: "bottlingRegistrationForm",
      docList: []
    },
    {
      title: "Applicant Details",
      formGroupName: "applicantDetailsForm",
      docList: [
        {
          type: this.licenseConsts.FN_APPLI_PHOTO,
          key: "photographOfTheApplicant",
          submitJsonKey: "applicantDetails",
          submitSubJsonKey: "applicantDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.SRC_APPLI_PAN,
          key: "uploadPan",
          submitJsonKey: "applicantDetails",
          submitSubJsonKey: "applicantDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.SRC_APPLI_ITR,
          key: "uploadItr",
          submitJsonKey: "applicantDetails",
          submitSubJsonKey: "applicantDocumentList",
          arrayListName: null,
        }
      ]
    }, {
      title: "Applicant Address",
      formGroupName: "applicantAddress",
      docList: []
    },{
      title: "Parent Distillery/Brewery/Vintinery Unit Details",
      formGroupName: "bottlingParentUnitDetails",
      docList: [
        {
          type: this.licenseConsts.SRC_APPLI_LICENSE,
          key: "uploadLicense",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_APPLI_PAN,
          key: "panCertificate",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ADDI_GSTIN,
          key: "gstinCertificate",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_APPLI_ITR,
          key: "uploadItr",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        }
      ]
    }, {
      "title": "Unit Details",
      "formGroupName": "businessUnitDetails",
      "docList": [{
        "type": this.licenseConsts.SRC_ADDR_OWNERSHIIP_DOC,
        "key": "ownershipDocument",
        "submitJsonKey": "businessUnitDetails",
        "submitSubJsonKey": "businessUnitDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_AFFADAVAIT,
        "key": "affidavit",
        "submitJsonKey": "businessUnitDetails",
        "submitSubJsonKey": "businessUnitDocumentList",
        "arrayListName": null
      }]
    }, {
        title: 'Description of Bottling Premises',
        formGroupName: 'premisesAddressDetail',
        docList: []
      }, {
      title: "Warehouse Details",
      formGroupName: "wareHouseListRegistration",
      docList: []
    }, {
      title: "Attachments",
      formGroupName: "attachmentDetails",
      docList: [
        {
          type: this.licenseConsts.FN_ATTACH_BOUNDRY,
          key: "boundries",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_FIRESAFTEYNOC,
          key: "fireSafteyNoc",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_AFFADAVAIT,
          key: "affidavit",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_LAY_PLAN,
          key: "layoutPlan",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_CERT_OF_INCORP,
          key: "certificateOfIncorporation",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_RESU_OF_AUTH,
          key: "resolutionOfAuthorization",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_ATTACHMENT_FASSAI_CERTIFICATE,
          key: "fassaiCertificate",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_NOC_OF_PREMISES,
          key: "nocOfPremises",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_CERT_OF_POLLUT_CNTRL,
          key: "certificateOfPollutionControl",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_NO_DUES_DOC,
          key: "noDuesCertificate",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        }
      ]
    }, {
      title: "Bank Details",
      formGroupName: "licenseBankDetails",
      docList: [
        {
          type: this.licenseConsts.FN_BK_CAN_CHEQUE,
          key: "cancelCheque",
          submitJsonKey: "licenseBankDetails",
          submitSubJsonKey: "bankDocumentList",
          arrayListName: null,
        }
      ]
    }, {
      title: "Tank Registration",
      formGroupName: "tankListRegistration",
      docList: [
        {
          type: this.licenseConsts.FN_ATTACH_ATTACHMENT_DOCUMENT_DOC,
          key: "uploadDocument",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "tankList",
          arrayListName: "tankDocumentList"
        },
        {
          "type": this.licenseConsts.FN_PLAN_ELEVATION_DOC,
          "key": "planElevation",
          "submitJsonKey": "bottelingLicenses",
          "submitSubJsonKey": "tankList",
          "arrayListName": "tankDocumentList"
        }, {
          "type": this.licenseConsts.FN_FLOW_DIAGRAM_DOC,
          "key": "flowDiagram",
          "submitJsonKey": "bottelingLicenses",
          "submitSubJsonKey": "tankList",
          "arrayListName": "tankDocumentList"
        }, {
          "type": this.licenseConsts.FN_GAUGE_CHART_DOC,
          "key": "gaugeChart",
          "submitJsonKey": "bottelingLicenses",
          "submitSubJsonKey": "tankList",
          "arrayListName": "tankDocumentList"
        },
        {
          "type": 'ATTACHMENT_DRAWINGS',
          "key": "drawings",
          "submitJsonKey": "bottelingLicenses",
          "submitSubJsonKey": "tankList",
          "arrayListName": "tankDocumentList"
        },
        {
          "type": 'ATTACHMENT_BLUEPRINT',
          "key": "blueprintOfTank",
          "submitJsonKey": "bottelingLicenses",
          "submitSubJsonKey": "tankList",
          "arrayListName": "tankDocumentList"
        }
      ]
    }, {
      title: "New Line Registration-FL 3",
      formGroupName: "bottelingNewLineRegistration",
      docList: [
        {
          type: this.licenseConsts.FN_ATTACH_ATTACHMENT_DOCUMENT_DOC,
          key: "upload",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottelingLineList",
          arrayListName: "bottelingLineDocumentList"
        },
        {
          "type": 'ATTACHMENT_DRAWINGS',
          "key": "drawings",
          "submitJsonKey": "bottelingLicenses",
          "submitSubJsonKey": "bottelingLineList",
          "arrayListName": "bottelingLineDocumentList"
        },
        {
          "type": 'ATTACHMENT_BLUEPRINT',
          "key": "blueprintOfTank",
          "submitJsonKey": "bottelingLicenses",
          "submitSubJsonKey": "bottelingLineList",
          "arrayListName": "bottelingLineDocumentList"
        }
      ]
    }]
    return obj
  }

  bottelingFL3AList() {
    let obj = [{
      title: "Registration of Applicant for Bottling CL B1",
      formGroupName: "bottlingRegistrationForm",
      docList: []
    },
    {
      title: "Applicant Details",
      formGroupName: "applicantDetailsForm",
      docList: [
        {
          type: this.licenseConsts.FN_APPLI_PHOTO,
          key: "photographOfTheApplicant",
          submitJsonKey: "applicantDetails",
          submitSubJsonKey: "applicantDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.SRC_APPLI_PAN,
          key: "uploadPan",
          submitJsonKey: "applicantDetails",
          submitSubJsonKey: "applicantDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.SRC_APPLI_ITR,
          key: "uploadItr",
          submitJsonKey: "applicantDetails",
          submitSubJsonKey: "applicantDocumentList",
          arrayListName: null,
        }
      ]
    }, {
      title: "Applicant Address",
      formGroupName: "applicantAddress",
      docList: []
    }, {
      title: "Parent Distillery/Brewery/Vintinery Unit Details",
      formGroupName: "bottlingParentUnitDetails",
      docList: [
        {
          type: this.licenseConsts.SRC_APPLI_LICENSE,
          key: "uploadLicense",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_APPLI_PAN,
          key: "panCertificate",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ADDI_GSTIN,
          key: "gstinCertificate",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_APPLI_ITR,
          key: "uploadItr",
          submitJsonKey: "bottelingLicenses",
          submitSubJsonKey: "bottlingParentUnitDetails",
          submitSubDocJsonKey: "parentUnitDocumentList",
          arrayListName: null,
        }
      ]
    }, {
      "title": "Unit Details",
      "formGroupName": "businessUnitDetails",
      "docList": [{
        "type": this.licenseConsts.SRC_ADDR_OWNERSHIIP_DOC,
        "key": "ownershipDocument",
        "submitJsonKey": "businessUnitDetails",
        "submitSubJsonKey": "businessUnitDocumentList",
        "arrayListName": null
      }, {
        "type": this.licenseConsts.SRC_ATTACH_AFFADAVAIT,
        "key": "affidavit",
        "submitJsonKey": "businessUnitDetails",
        "submitSubJsonKey": "businessUnitDocumentList",
        "arrayListName": null
      }]
    }, {
        title: 'Description of Bottling Premises',
        formGroupName: 'premisesAddressDetail',
        docList: []
      }, {
      title: "Warehouse Details",
      formGroupName: "wareHouseListRegistration",
      docList: []
    }, {
      title: "Attachments",
      formGroupName: "attachmentDetails",
      docList: [
        {
          type: this.licenseConsts.FN_ATTACH_BOUNDRY,
          key: "boundries",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_FIRESAFTEYNOC,
          key: "fireSafteyNoc",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_AFFADAVAIT,
          key: "affidavit",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_LAY_PLAN,
          key: "layoutPlan",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_CERT_OF_INCORP,
          key: "certificateOfIncorporation",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_RESU_OF_AUTH,
          key: "resolutionOfAuthorization",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_ATTACHMENT_FASSAI_CERTIFICATE,
          key: "fassaiCertificate",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_NOC_OF_PREMISES,
          key: "nocOfPremises",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_CERT_OF_POLLUT_CNTRL,
          key: "certificateOfPollutionControl",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        },
        {
          type: this.licenseConsts.FN_ATTACH_NO_DUES_DOC,
          key: "noDuesCertificate",
          submitJsonKey: "attachmentDetails",
          submitSubJsonKey: "attachmentDocumentList",
          arrayListName: null,
        }
      ]
    }, {
      title: "Bank Details",
      formGroupName: "licenseBankDetails",
      docList: [
        {
          type: this.licenseConsts.FN_BK_CAN_CHEQUE,
          key: "cancelCheque",
          submitJsonKey: "licenseBankDetails",
          submitSubJsonKey: "bankDocumentList",
          arrayListName: null,
        }
      ]
    }]
    return obj
  }

  autoPopulateValParentDistillery(parentApplicationData) {
    const list = [

      {
        keysList: [

          {
            key: "licenseNumber",
            fieldKey: "licenseNumber",
            subFieldKey: "",
            fieldType: "input",
          },
          {
            key: "validLicenseCopy",
            fieldKey: "validLicenseCopy",
            subFieldKey: "",
            fieldType: "input",
          },
          {
            key: "licenseExpiredDate",
            fieldKey: "licenseExpiredDate",
            subFieldKey: "",
            fieldType: "date",
          },
          {
            key: "unitPanNumber",
            fieldKey: "unitPanNumber",
            subFieldKey: "businessUnitDetails",
            fieldType: "input",
          },
          {
            key: "unitGSTNumber",
            fieldKey: "unitGSTNumber",
            subFieldKey: "businessUnitDetails",
            fieldType: "input",
          },
          {
            key: "gstinCertificate",
            fieldType: "file",
            fieldKey: "gstinCertificate",
            modelObjkey: "businessUnitDetails",
            respkey: "businessUnitDocumentList",
            modelfileName: "SRC_BUSSUNIT_GSTIN",
            fileName: "uploadGstin_file_source",
          },

          {
            key: "itrAssesmentYearCode",
            fieldKey: "itrAssesmentYearCode",
            subFieldKey: "applicantDetails",
            fieldType: "ddl",
          },
          {
            key: "itrAssesmentYearDesc",
            fieldKey: "itrAssesmentYearDesc",
            subFieldKey: "applicantDetails",
            fieldType: "ddl",
          },
          {
            key: "uploadItr",
            fieldType: "file",
            fieldKey: "uploadItr",
            modelObjkey: "applicantDetails",
            respkey: "documentList",
            modelfileName: "SRC_APPLI_ITR",
            fileName: "appli_itr_file_source",
          },
          {
            key: "previousItrNotFiled",
            fieldKey: "previousItrNotFiled",
            subFieldKey: "applicantDetails",
            fieldType: "input",
          },


          {
            key: "unitName",
            fieldKey: "unitName",
            subFieldKey: "businessUnitDetails",
            fieldType: "input",
          },

        ],
        formName: "parentDistilleryUnit",
      },
      {
        keysList: [
          {
            key: "country",
            fieldKey: "country",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "pinCode",
            fieldKey: "pinCode",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
          {
            key: "stateCode",
            fieldKey: "stateCode",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"

          },
          {
            key: "stateDesc",
            fieldKey: "stateDesc",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "districtCode",
            fieldKey: "districtCode",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "districtDesc",
            fieldKey: "districtDesc",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "tahsilCode",
            fieldKey: "tahsilCode",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "tahsilDesc",
            fieldKey: "tahsilDesc",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "localityType",
            fieldKey: "localityType",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "localityTypeDesc",
            fieldKey: "localityTypeDesc",
            subFieldKey: "addressList",
            fieldType: "ddl",
            addressIdentifier: "premises_address"
          },
          {
            key: "ward",
            fieldKey: "ward",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
          {
            key: "block",
            fieldKey: "block",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
          {
            key: "village",
            fieldKey: "village",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
          {
            key: "locality",
            fieldKey: "locality",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
          {
            key: "street",
            fieldKey: "street",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
          {
            key: "policeStationName",
            fieldKey: "policeStationName",
            subFieldKey: "addressList",
            fieldType: "input",
            addressIdentifier: "premises_address"
          },
        ],
        formName: "parentDistilleryAddress",
      },
    ];
    let obj = {};

    let localStore = parentApplicationData
    list.forEach((v) => {

      obj[v.formName] = {};
      v.keysList.forEach((h) => {
        if (h.fieldType == "file" && localStore[h.modelObjkey][h.respkey]) {
          let findVal = localStore[h.modelObjkey][h.respkey].find(
            (p) => p.name == h.modelfileName
          );
          console.log(findVal);
          if (findVal) {
            findVal.name = h.fileName;
            obj[v.formName][h.key] = findVal;
          }
        }
        else if (h.fieldKey && h.subFieldKey && h.addressIdentifier) {
          let findVal = localStore[h.subFieldKey].find(
            (p) => p.addressIdentifier == h.addressIdentifier
          );
          obj[v.formName][h.key] = findVal[h.key];
        } else if (h.fieldKey && h.subFieldKey) {
          obj[v.formName][h.key] = localStore[h.subFieldKey][h.fieldKey];
        } else if (h.fieldKey) {
          obj[v.formName][h.key] = localStore[h.fieldKey];
        }
      });
    });
    return obj;
  }

  validateCheckList(btnName, checklistValue, isCheckListRequired, isRecommnad, isApplicationApproved) {
    if(btnName === BTN_NAME_RECOMMAND) {

      if(isCheckListRequired && isRecommnad && !isApplicationApproved) {
        let count = 0;

        checklistValue.checkListDocument.forEach(el => {
          if(!el.isVerified) {
            count++
          }
        })

        if(count > 0) {
          this.alert.showError('All checklist should be verified as Yes');

          return false;
        } else {
          return true;
        }
      }else{
        return true;
      }
    } else {
      return true;
    }
  }

  removeFileFields(fileArr) {
    fileArr.forEach((ele) => {
      delete ele.id;
      delete ele.status;
    });
  }
}
