import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, AlertService, SpiritImportPermitService, LicenceManagementService, WholesaleIndentRequestService, TankregistartionServiceBrew } from '@app/services';
import { AllotmentRequestService, BrandlabelbrewService, IndentRequestService } from '@app/services/pd';
import { BottlingLineRegisterService } from '@app/services/brewery';
import { enaPurchaseOrderService } from '@app/services/pd/enapurchaseorder.service';
import { ApprovaltransferforredistillationService } from '@app/services/pd/approvaltransferforredistillation.service';
import { CommonApiServiceService } from '@app/services/helpdesk/common-api-service.service';
import { apiUrls } from '@appEnv/apiurls';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  username: string;
  isCustomer: boolean;
  isOfficier: boolean;
  panelOpenState = false;
  statusCountList = [];
  public totalCount: number;
  public totalCount1: number;
  edpDataArray: any = [];
  edpData: any;
  edpTotalCount: Number = 0;
  showMrp: boolean;
  edpLabelTotalCount: number=0;
  showLabelMrp: boolean;
  constructor(private authSer: AuthService,
    private router: Router, private licenceManagementService: LicenceManagementService,
    private alert: AlertService, private WIRService: WholesaleIndentRequestService,
    private enaPurchaseOrderService: enaPurchaseOrderService,
    private approvaltransferforredistillationService: ApprovaltransferforredistillationService,
    private edpService: CommonApiServiceService) { }


  LabelName = 'EDP/ECP Registration '
  licenseData = [];
  licenseDataResp: any;
  licenseDataCategoryNew = [];
  licenseDataCategoryRenew = [];
  licensenumber: any = [];
  licenseCount = 0;
  createdBy = ''
  designation = ''
  bgClass = ['dashboard_bg1', 'dashboard_bg2', 'dashboard_bg3', 'dashboard_bg4', 'dashboard_bg5'];
  // officernames
  additionalcommissioner = "Addi Commissioner".replace(/\s+/g, "").toUpperCase()
  commissioner = "COMMINSOR".replace(/\s+/g, "").toUpperCase()
  additionalcommissionerld = "AddiCommi (L&ID)".replace(/\s+/g, "").toUpperCase()
  deptcomisioner = "DEPTComMolasses".replace(/\s+/g, "").toUpperCase()
  decproduction = "DEC Production".replace(/\s+/g, "").toUpperCase()
  decdistribution = "DEC distribution".replace(/\s+/g, "").toUpperCase()
  exciseHq = "Excise HQ".replace(/\s+/g, "").toUpperCase()
  decLicensing = "DEC (Licensing)".replace(/\s+/g, "").toUpperCase()
  jecHq = "JEC HQ".replace(/\s+/g, "").toUpperCase()
  deo = "DEO".replace(/\s+/g, "").toUpperCase()
  aecBrewery = "AECBREWERY".replace(/\s+/g, "").toUpperCase()
  aecDistillery = "AECDISTILLERY".replace(/\s+/g, "").toUpperCase()
  jds = "JDS".replace(/\s+/g, "").toUpperCase()
  addladmin = "Addi Commissioner".replace(/\s+/g, "").toUpperCase()
  //officer name and designation
  showdashboard = false;
  initialLocalStorageValue: number;
  ngOnInit() {
    this.initialLocalStorageValue = this.getlocalstoragevalue();
    this.designation = localStorage.getItem('DESIGNATION').replace(/\s+/g, "").toUpperCase()
    this.createdBy = localStorage.getItem('USER_ID')
    this.isCustomer = this.authSer.isCustomer();
    this.isOfficier = !this.isCustomer;
    const authUserName = this.authSer.getUserFullName();
    this.username = authUserName ? authUserName : 'Admin';
    if (this.isOfficier) {
      this.enaPurchaseOrderService.showdashboardforofficerservice().subscribe((resp: any) => {
        if (resp == true) {
          this.getallscmmenulist()
          this.exportDispatchProcessBrewery();
          this.exportDispatchProcessDistillery();
          this.findDashBoardNumbers();
          this.findEdpCount()
          this.findEdpLabelCount()
          this.showdashboard = true
        }
        else {
          this.showdashboard = false
        }
      }
      );
    }

    let designation = (localStorage.getItem('DESIGNATION'))
    if (!designation.includes("AEC")) {
      this.LabelName = 'MRP Approval'
    } else {
      this.LabelName = 'MRP Approval'
    }
  }

  getlicenseNumber() {
    let req = {
      "username": localStorage.getItem('USER_NAME')
    }
    this.edpService.postPayment(apiUrls.getentitydetails, req).subscribe((resp: any) => {
      let array = []
      resp.map(element => {
        if (element.licenseNumber != undefined && element.licenseNumber != '') {
          array.push(element.licenseNumber)
          this.licensenumber.push(element.licenseNumber)
        }
      })


    })
  }


  getList(entity, status, screen, url, withoutlist?) {
    switch (entity) {
      case 'BREWERY':
        entity = 'brewery';
        break;
      case 'PD':
        entity = 'pd';
        break;
      case 'PARENT_UNIT':
        entity = 'parentunit';
        break;
      case 'MIXED':
        entity = 'mixed';
        break;
      case 'ID':
        entity = 'ind';
        break;
      case 'CBW':
        entity = 'importunit/cbw';
        break;
      case 'BIO_ZERO':
        entity = 'importunit/biozero';
        break;
      case 'BIO':
        entity = 'importunit/bio';
        break;
      case 'SUGAR_MILL':
        entity = 'sugarmill';
        break;
      case 'CSD_SUPPLY':
        entity = 'csdsupply';
        break;

    }
    if (withoutlist) {
      if (entity == 'csdsupply' && url == 'brand-label-registration/overview') {
        url = 'brand-label-registration-csd/overview'
      }
      this.router.navigate(['/' + entity + '/' + url],
        {
          queryParams: {
            status: status
          }
        });
    }
    else {
      if (entity == 'csdsupply' && url == 'brand-label-registration') {
        url = 'brand-label-registration-csd'
      }
      this.router.navigate(['/' + entity + '/' + url + '/list'],
        {
          queryParams: {
            status: status
          }
        });
    }


  }

  ConvertToInt(val) {
    return parseInt(val);
  }

  findDashBoardNumbers() {
    this.licenceManagementService.findDashBoardNumbers().subscribe((resp: any) => {
      const { responseCode: respCode, responseMessage: respMsg, content: respContent } = resp;
      if (respCode === 200) {
        this.licenseCount = respContent.allCount;

        this.licenseData = respContent.category;
        this.licenseDataResp = respContent;
        this.licenseDataCategoryNew = respContent.categoryOtherThanRenew;
        this.licenseDataCategoryRenew = respContent.categoryRenew;
        // this.licenseData.map(project => {
        //   project.color = this.bgClass[Math.floor(Math.random() * this.bgClass.length)];
        //   return project;
        // });
      }
      // else {
      //   this.alert.showError(respMsg);
      // }
    })
  }

  onLicenseClick(value, type?) {
    this.router.navigate(['licencemgnt/viewLicenseStatus', value.code, 'dashboardFilter', type]);
  }

  //   onLicenseClickNew(value) {
  //     console.log(value)
  //     this.router.navigate(['licencemgnt/viewLicenseStatus', value.code, 'dashboardFilter']);
  //   }

  //   onLicenseClickRenew(value,type) {
  //   this.router.navigate(['licencemgnt/viewLicenseStatus', value.code, 'dashboardFilter','RENEW']);
  //   let searchObj = {
  //     searchInput: {
  //       dashboardFilter: "dashboardFilter",
  //       processType:"RENEW",
  //       category:value.code
  //     },
  //     page: 0,
  //     pageSize: 10
  //   };

  //     this.licenceManagementService.searchLicenseApplications(searchObj).subscribe((resp: any) => {
  //       if (resp.responseCode === 200) {

  // console.log(resp)
  //       }
  //     });

  //   }

  async exportDispatchProcessBrewery() {
    let unitList = "";
    if (localStorage.getItem('ALLOCATED_UNIT')) {
      var uList = JSON.parse(localStorage.getItem('ALLOCATED_UNIT'));
      const unitListKey = uList.map(item => item.unit_code !== null ? "'" + item.unit_code + "'" : null).join();
      unitList = unitListKey;
    }
    let districtName = "";
    if (localStorage.getItem('ALLOCATED_DISTRICT')) {
      var disList = JSON.parse(localStorage.getItem('ALLOCATED_DISTRICT'));
      const districtList = disList.map(item => item.district_desc !== null ? "'" + item.district_desc + "'" : null).join();
      districtName = districtList;
    }
    let designation = "";
    if (localStorage.getItem('DESIGNATION')) {
      const designationKey = "'" + localStorage.getItem('DESIGNATION') + "'" || 'default value';
      designation = designationKey;
    }
    const payload = {
      "dataCode": "EXPORT_PERMIT_APPROVED_LIST_BASED_ON_DESG_GET_PAGINATION_COUNT",
      "placeholderKeyValueMap": {
        "status": "'IN-PROGRESS'",
        "entityCode": "null",
        "designationCode": "NULL",
        "createdDate": "NULL",
        "refNumber": "NULL",
        "exportTo": "NULL",
        "entityName": "NULL",
        "registrationType": "NULL",
        "entityType": "'BOTTELING'",
        districtName: districtName ? districtName : "NULL",
        unitList: unitList ? unitList : "NULL",
        designation: designation ? designation : "NULL",
      }
    }
    const dataCount = await this.getDataCount(payload, "EXPORT_PERMIT_APPROVED_LIST_BASED_ON_DESG_GET_PAGINATION_COUNT");
    this.totalCount = dataCount
  }

  async exportDispatchProcessDistillery() {
    let unitList = "";
    if (localStorage.getItem('ALLOCATED_UNIT')) {
      var uList = JSON.parse(localStorage.getItem('ALLOCATED_UNIT'));
      const unitListKey = uList.map(item => item.unit_code !== null ? "'" + item.unit_code + "'" : null).join();
      unitList = unitListKey;
    }
    let districtName = "";
    if (localStorage.getItem('ALLOCATED_DISTRICT')) {
      var disList = JSON.parse(localStorage.getItem('ALLOCATED_DISTRICT'));
      const districtList = disList.map(item => item.district_desc !== null ? "'" + item.district_desc + "'" : null).join();
      districtName = districtList;
    }
    let designation = "";
    if (localStorage.getItem('DESIGNATION')) {
      const designationKey = "'" + localStorage.getItem('DESIGNATION') + "'" || 'default value';
      designation = designationKey;
    }
    const payload = {
      "dataCode": "EXPORT_PERMIT_APPROVED_LIST_BASED_ON_DESG_GET_PAGINATION_COUNT",
      "placeholderKeyValueMap": {
        "status": "'IN-PROGRESS'",
        "entityCode": "NULL",
        "designationCode": "NULL",
        "refNumber": "NULL",
        "exportTo": "NULL",
        "createdDate": "NULL",
        "tpReferenceNumber": "NULL",
        "entityName": "NULL",
        "registrationType": "NULL",
        "entityType": "'DISTILLERY'",
        "districtName": districtName ? districtName : "NULL",
        "unitList": unitList ? unitList : "NULL",
        "designation": designation ? designation : "NULL",
      }
    }
    const dataCount = await this.getDataCount(payload, "EXPORT_PERMIT_APPROVED_LIST_BASED_ON_DESG_GET_PAGINATION_COUNT");
    this.totalCount1 = dataCount
  }

  async getDataCount(requestParam, dataCode) {
    var countParam = JSON.parse(JSON.stringify(requestParam));
    delete countParam['placeholderKeyValueMap']['pageSize'];
    delete countParam['placeholderKeyValueMap']['recordFrom'];
    countParam['dataCode'] = dataCode;
    const resp = await this.WIRService.getRecordCount(countParam);

    let tot = 0;
    if (resp && resp.statusCode === 0) {
      this.statusCountList = resp.responseContent;
      resp.responseContent.forEach(e => { tot = tot + e.cnt; });
    }
    return tot;
  }

  getListPage(module) {
    const status = "IN-PROGRESS";
    if (module == 'BOTTELING') {
      this.router.navigate(['/department/exportpermitapporval/list/' + status + '/' + module])
    } else if (module == 'DISTILLERY') {
      this.router.navigate(['/department/exportpermitapporval/list/' + status + '/' + module])
    }
  }

  allcarddetails: any = []
  totalcardcountscm = 0
  async getallscmmenulist() {
    let payload =
    {
      "isAllocatedLicenseRequired": true,
      "isDistrictFilterRequired": true,
      "isLicenseFilterRequired": true
    }
    let response = await this.enaPurchaseOrderService.getalldashboardvalue(payload)
    this.allcarddetails = response.content;
    for (const category in this.allcarddetails) {
      if (this.allcarddetails.hasOwnProperty(category)) {
        this.allcarddetails[category] = this.orderByCustomOrder(this.allcarddetails[category]);
      }
    }
    let value = 0;
    for (const category in this.allcarddetails) {
      if (this.allcarddetails[category].length > 0) {
        this.allcarddetails[category].forEach(elementrec => {
          if (elementrec.status == "IN_PROGRESS") {
            value = value + elementrec.statusRecordCount
          }

        });
      }
    }
    this.totalcardcountscm = value;
    this.addColorToEntities();

  }

  findEdpCount() {
    let req = {
      designationCode: localStorage.getItem('DESIGNATION'),
      licenseNo: []
    }
    this.edpService.postPayment(apiUrls.getEdpPendingCount, req).subscribe((resp: any) => {
      if (resp.errorCode === 200) {
        this.showMrp = true
        this.edpData = resp.data;
        this.edpTotalCount = 0;
        // resp.data.forEach(e => { this.edpTotalCount = this.edpTotalCount + e.count; });
        // for (var i = 0; i < resp.data.length; i++) {
        //   var data = resp.data[i];
        //   this.edpTotalCount += (data.count);
        // }
        let countarray=[]
        resp.data.forEach(element => {
          countarray.push(element.count)
          if (element.name == 'EDP REGISTRATION') {
            if (element.status == 'INPROGRESS') {
              element.count = element.count
              element.entityType = element.entityType
              element.color = element.color
            } else if (element.status == 'WAITINGFORDIGITALSIGNATURE') {
              element.count = element.count
            }
          } else if (element.name == 'ECP REGISTRATION') {
            if (element.status == 'INPROGRESS') {
              element.count = element.count
              element.entityType = element.entityType
              element.color = element.color
            } else if (element.status == 'WAITINGFORDIGITALSIGNATURE') {
              element.count = element.count
              element.entityType = element.entityType
              element.color = element.color
            }
            element.status = element.status
          } else if (element.name == 'EBP REGISTRATION') {
            if (element.status == 'INPROGRESS') {
              element.count = element.count
              element.entityType = element.entityType
              element.color = element.color
            } else if (element.status == 'WAITINGFORDIGITALSIGNATURE') {
              element.count = element.count
              element.entityType = element.entityType
              element.color = element.color

            }
            element.status = element.status
          }
          this.edpDataArray.push(element)



        })
        // this.edpData.map(project => {
        //   project.color = this.bgClass[Math.floor(Math.random() * this.bgClass.length)];
        //   return project;
        // });
        if(countarray.length){
          this.edpTotalCount= countarray.reduce( (a,b) => (a+b) );
        }
      } else {
        this.showMrp = false
      }
      return this.edpTotalCount;

      // else {
      //   this.alert.showError(respMsg);
      // }
    })
  }
  findEdpLabelCount() {
    let req = {
      designationCode: localStorage.getItem('DESIGNATION'),
      licenseNo: []
    }
    this.edpService.postPayment(apiUrls.getEdpLabelCount, req).subscribe((resp: any) => {
      if (resp.errorCode === 200) {
        this.showLabelMrp = true
        this.edpData = resp.data;
        this.edpLabelTotalCount = 0;
        // resp.data.forEach(e => { this.edpTotalCount = this.edpTotalCount + e.count; });
        // for (var i = 0; i < resp.data.length; i++) {
        //   var data = resp.data[i];
        //   this.edpTotalCount += (data.count);
        // }
        // this.edpTotalCount = this.edpLabelTotalCount + this.edpTotalCount
        let countarraylabel=[]
        resp.data.forEach(element => {
          countarraylabel.push(element.count)
          if (element.name == 'EDP REGISTRATION') {
            if (element.status == 'INPROGRESS') {
              element.count = element.count
              element.entityType = element.entityType
              element.color = element.color
            } else if (element.status == 'WAITINGFORDIGITALSIGNATURE') {
              element.count = element.count
            }
          } else if (element.name == 'ECP REGISTRATION') {
            if (element.status == 'INPROGRESS') {
              element.count = element.count
              element.entityType = element.entityType
              element.color = element.color
            } else if (element.status == 'WAITINGFORDIGITALSIGNATURE') {
              element.count = element.count
              element.entityType = element.entityType
              element.color = element.color
            }
            element.status = element.status
          } else if (element.name == 'EBP REGISTRATION') {
            if (element.status == 'INPROGRESS') {
              element.count = element.count
              element.entityType = element.entityType
              element.color = element.color
            } else if (element.status == 'WAITINGFORDIGITALSIGNATURE') {
              element.count = element.count
              element.entityType = element.entityType
              element.color = element.color

            }
            element.status = element.status
          }
          this.edpDataArray.push(element)
        })
        if(countarraylabel.length){
          this.edpLabelTotalCount= countarraylabel.reduce( (a,b) => (a+b) );
        }
      } else {
        this.showLabelMrp = false
      }
      return this.edpTotalCount;

      // else {
      //   this.alert.showError(respMsg);
      // }
    })
  }
  customOrder = ["BREWERY", "PD", "ID", "MIXED", "PARENT_UNIT", "SUGAR_MILL", "CBW", "BIO", "BIO_ZERO"];

  orderByCustomOrder(list: any[]): any[] {
    return list.sort((a, b) => this.customOrder.indexOf(a.entityType) - this.customOrder.indexOf(b.entityType));
  }
  addColorToEntities() {
    for (const category in this.allcarddetails) {
      if (this.allcarddetails.hasOwnProperty(category)) {
        this.allcarddetails[category] = this.allcarddetails[category].map(entity => ({ ...entity, color: this.getColor(entity.entityType) }));
      }
    }
  }

  getLicenseColor(index: number): string {
    // Assign colors based on the entity type
    if (index === 0) return 'dashboard_bg1';
    if (index === 1) return 'dashboard_bg3';
    if (index === 2) return 'dashboard_bg2';
    if (index === 3) return 'dashboard_bg5';

    // Add more conditions for other entity types

    // Default color if not matched
    return 'dashboard_bg2';
  }


  getColor(entityType: string): string {
    // Assign colors based on the entity type
    if (entityType === 'BREWERY') return 'dashboard_bg1';
    if (entityType === 'PD') return 'dashboard_bg3 dashboard_bg100';
    if (entityType === 'ID') return 'dashboard_bg3';
    if (entityType === 'MIXED') return 'dashboard_bg3 dashboard_bg101';
    if (entityType === 'PARENT_UNIT') return 'dashboard_bg2';
    if (entityType === 'SUGAR_MILL') return 'dashboard_bg6';
    if (entityType === 'CBW') return 'dashboard_bg5';
    if (entityType === 'BIO_ZERO') return 'dashboard_bg5';
    if (entityType === 'BIO') return 'dashboard_bg7';
    // Add more conditions for other entity types

    // Default color if not matched
    return 'dashboard_bg2';
  }
  convertToSentenceCase(inputString: string): string {
    // Remove underscores and add spaces
    const stringWithSpaces = inputString.replace(/_/g, ' ');


    if (inputString === 'PD') return 'POTABLE DISTILLERY';
    if (inputString === 'ID') return 'INDUSTRIAL DISTILLERY';
    if (inputString === 'MIXED') return 'MIXED DISTILLERY';
    return stringWithSpaces;

    //return stringWithSpaces;
  }

  totalcalculate(array) {
    let totalcount = 0
    if (array && array.length > 0) {
      array.forEach(element => {
        if (element.status == 'IN_PROGRESS') {
          totalcount = totalcount + Number(element.statusRecordCount)
        }
      });
      return totalcount;
    }
    else {
      return totalcount;
    }


  }

  totalcalculateentity(array, entitytype) {
    let totalcount = 0
    if (array && array.length > 0) {
      array.forEach(element => {
        if (element.status == 'IN_PROGRESS' && element.entityType == entitytype) {
          totalcount = totalcount + Number(element.statusRecordCount)
        }
      });
      return totalcount;
    }
    else {
      return totalcount;
    }


  }

  calculatelength(array) {
    let status = false
    let i = 0
    if (array) {
      let count = array.filter(a => a.status == 'IN_PROGRESS')
      i = count.length
    }
    if (i > 0) { status = true }
    else { status = false }
    return status;

  }
  getEDPList(data) {
    if (data.status == 'INPROGRESS' || data.status == 'WAITINGFORDIGITALSIGNATURE') {
      if (data.name == 'EBP REGISTRATION' || data.name == 'EDP REGISTRATION' || data.name == 'EDP LABEL OVERVIEW REGISTRATION'
        || data.name == 'EBP LABEL OVERVIEW REGISTRATION'
      ) {
        this.router.navigate(['/payment-and-reconciliation/edp-approval/Dashboard-edp-list', data.status, data.entityType, data.name])
      }
      else {

        this.router.navigate(['/payment-and-reconciliation/ecb-registration/ecb-dashboard-list', data.status, data.entityType, data.name])
      }
    }
  }

  calculatelengthentitytype(array, entitytype) {
    let status = false
    let i = 0
    if (array) {
      let count = array.filter(a => a.status == 'IN_PROGRESS' && a.entityType == entitytype)
      i = count.length
    }
    if (i > 0) { status = true }
    else { status = false }
    return status;

  }

  getListwithoutentity(status, screen, url) {

    this.router.navigate(['/' + url + '/list'],
      {
        queryParams: {
          status: status
        }
      });
  }

  setStep(index) {
    setTimeout(() => {
      localStorage.setItem('Dashboard_Expand', index); //for dashboard expand section
    }, 500);

  }
  getlocalstoragevalue() {
    if (localStorage.getItem('Dashboard_Expand')) {
      const storedValue = localStorage.getItem('Dashboard_Expand');
      return parseInt(storedValue, 10);
    }
  }
  removesetitem() {
    localStorage.removeItem('Dashboard_Expand');

  }

}

